import { FETCH_MESSAGES_FAILURE, FETCH_MESSAGES_REQUEST, FETCH_MESSAGES_SUCCESS, FETCH_MESSAGES_SUCCESS_2, FETCH_MESSAGES_SUCCESS_D, FETCH_MESSAGE_FAILURE, FETCH_MESSAGE_REQUEST, FETCH_MESSAGE_SUCCESS } from "../actions/actions";



// messageReducer.js
const initialState = {
    messages: [],
    messages2: [],
    messagesD: [],
    message: {},
    messageSelect: {},
    loadingMessage:false,
    loadingMessageSelect:false,
    loadingMessages:false,
    errorMessages:null,
    errorMessage:null
    // ... autres états spécifiques à messageReducer
  };
  
  const messageReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_MESSAGE_REQUEST:
        // Dispatch une action pour définir loading sur true dans le globalReducer
        return {
          ...state,
          loadingMessage:true,
          errorMessage:""
          // Autres manipulations d'état pour la demande de récupération des messages
        };
        case FETCH_MESSAGE_SUCCESS:
        return {
          ...state,
          loadingMessage:false,
          errorMessage:"",
          message:action.payload
        };
        case FETCH_MESSAGE_FAILURE:
        return {
          ...state,
          loadingMessage:false,
          errorMessage:action.payload
        };


        // MESSAGE
        // case FETCH_MESSAGE_REQUEST_SELECT:
        // // Dispatch une action pour définir loading sur true dans le globalReducer
        // return {
        //   ...state,
        //   loadingMessageSelect:true,
        //   errorMessages:""
        //   // Autres manipulations d'état pour la demande de récupération des messages
        // };
        // case FETCH_MESSAGE_SUCCESS_SELECT:
        // return {
        //   ...state,
        //   loadingMessageSelect:false,
        //   errorMessages:"",
        //   messageSelect:action.payload
        // };
        // case FETCH_MESSAGE_FAILURE_SELECT:
        // return {
        //   ...state,
        //   loadingMessageSelect:false,
        //   errorMessages:action.payload
        // };






      case FETCH_MESSAGES_REQUEST:
        return {
          ...state,
          loadingMessages:true,
          errorMessages:""
        };
       
      case FETCH_MESSAGES_SUCCESS:
        return {
          ...state,
          messages: action.payload,
          loadingMessages:false,
          errorMessages:""
          // Autres manipulations d'état pour le succès de la récupération des messages
        };
        case FETCH_MESSAGES_SUCCESS_2:
        return {
          ...state,
          messages2: action.payload,
          loadingMessages:false,
          errorMessages:""
          // Autres manipulations d'état pour le succès de la récupération des messages
        };

        case FETCH_MESSAGES_SUCCESS_D:
          return {
            ...state,
            messagesD: action.payload,
            loadingMessages:false,
            errorMessages:""
            // Autres manipulations d'état pour le succès de la récupération des messages
          };
        
      case FETCH_MESSAGES_FAILURE:
        // Dispatch une action pour définir loading sur false dans le globalReducer et enregistrer l'erreur
        return {
          ...state,
          loadingMessages:false,
          errorMessages:action.payload
          // Autres manipulations d'état pour l'échec de la récupération des messages
        };
      // ... autres cas pour d'autres actions liées aux messages

        
      default:
        return state;
    }
  };
  
  export default messageReducer;
  