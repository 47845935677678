import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './BenefitsSection.css'; // Import custom CSS for styling

const BenefitsSection = () => {
    return (
        <section className="benefits-section">
            <Container>
                <Row className="justify-content-center text-center">
                    <Col md={12}>
                        <h2 className="title text-white">Les Avantages de Rezaplus</h2>
                        <p className="description">Découvrez pourquoi choisir Rezaplus pour vos réservations de véhicules et de propriétés.</p>
                    </Col>
                </Row>
                <Row className="text-center mt-4">
                    <Col md={4}>
                        <div className="benefit border border-white rounded-lg">
                            <h3 class="text-white">93.6%</h3>
                            <p>Taux de satisfaction des clients</p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="benefit border border-white rounded-lg">
                            <h3 class="text-white">895</h3>
                            <p>Réservations effectuées </p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="benefit border border-white rounded-lg">
                            <h3 class="text-white">214</h3>
                            <p>Véhicules en ligne</p>
                        </div>
                    </Col>
                </Row>
                <Row className="text-center mt-4">
                    <Col md={12}>
                        <p className="reserve-text">Réservez dès aujourd{"'"}hui pour bénéficier de ces avantages !</p>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default BenefitsSection;
