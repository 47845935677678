import React, { useState, useEffect } from 'react';

const DeviceListPaymentPage = () => {
  const [paymentMethods, setPaymentMethods] = useState([]);

  // Simulation de l'DeviceListPaymentPageel API pour récupérer les moyens de paiement
  useEffect(() => {
    // Simuler la réponse d'une API
    const fetchPaymentMethods = async () => {
      const data = [
        {
          id: 1,
          name: "Espèces",
          description: "Paiement en espèces à la livraison.",
          imageUrl: "https://via.placeholder.com/150/0000FF/FFFFFF?text=Espèces"
        },
        {
          id: 2,
          name: "Stripe",
          description: "Paiement sécurisé avec Stripe.",
          imageUrl: "https://via.placeholder.com/150/FF0000/FFFFFF?text=Stripe"
        }
      ];
      setPaymentMethods(data);
    };

    fetchPaymentMethods();
  }, []);

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Moyens de Paiement</h2>
      <div className="row">
        {paymentMethods.map((method) => (
          <div key={method.id} className="col-md-6 mb-4">
            <div className="card">
              <img src={method.imageUrl} className="card-img-top" alt={method.name} />
              <div className="card-body">
                <h5 className="card-title">{method.name}</h5>
                <p className="card-text">{method.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DeviceListPaymentPage;
