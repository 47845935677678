import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { logoApplication } from '../../utils/dataApi/dataFormApi';
import { ROUTES } from '../../config/routingUrl';
import { useDispatch, useSelector } from 'react-redux';
import { UserConnexion } from '../../actions/request/UserRequest';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const LoginPage = () => {
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();
    const loadingUser = useSelector((state) => state.users.loadingUser);
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email('Adresse email invalide')
                .required('Veuillez entrer votre email'),
            password: Yup.string()
                .min(4, 'Le mot de passe doit contenir au moins 8 caractères')
                .required('Veuillez entrer votre mot de passe'),
        }),
        onSubmit: values => {
            dispatch(UserConnexion(values.email, values.password, navigate, toast));
        },
    });

    return (
        <div className="page-content bg-white">
            <section className="content-inner bg-white text-white py-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-8 col-md-10">
                            <div className="login-form bg-white p-4 rounded shadow-sm">
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="text-center mb-4">
                                        <img
                                            src={logoApplication}
                                            alt="Logo"
                                            className="img-fluid rounded-circle mb-3"
                                            style={{ width: '80px', height: '80px' }}
                                        />
                                        <h2 className="h4">Se connecter</h2>
                                    </div>

                                    <div className="form-group mb-3">
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <input
                                            type="email" style={{ height:"40px" }}
                                            className={`form-control ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`}
                                            id="email"
                                            name="email"
                                            placeholder="Entrez votre email"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.email}
                                        />
                                        {formik.touched.email && formik.errors.email ? (
                                            <div className="invalid-feedback">{formik.errors.email}</div>
                                        ) : null}
                                    </div>

                                    <div className="form-group mb-3">
                                        <label htmlFor="password" className="form-label">Mot de passe</label>
                                        <div className="input-group">
                                            <input style={{ height:"40px" }}
                                                type={showPassword ? 'text' : 'password'}
                                                className={`form-control ${formik.touched.password && formik.errors.password ? 'is-invalid' : ''}`}
                                                id="password"
                                                name="password"
                                                placeholder="Entrez votre mot de passe"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.password}
                                            />
                                            <button
                                                type="button" style={{ height:"40px" }}
                                                className="btn btn-sm btn-outline-light"
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                {showPassword ? '👁️' : '👁️‍🗨️'}
                                            </button>
                                            {formik.touched.password && formik.errors.password ? (
                                                <div className="invalid-feedback">{formik.errors.password}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-between mb-3">
                                        <a href="#" onClick={()=>navigate(`/${ROUTES.FORGETPASSWORDHASH}`)} className="text-primary">Mot de passe oublié ?</a>
                                    </div>

                                    <div>
                                        <button 
                                            type="submit" 
                                            className="btn btn-sm btn-primary w-100 justify-content-center rounded-4" 
                                            style={{ textAlign: "center" }} 
                                            disabled={loadingUser}
                                        >
                                            {loadingUser ? (
                                                <>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Chargement...
                                                </>
                                            ) : (
                                                'Connexion'
                                            )}
                                        </button>
                                    </div>

                                    <div className="text-center mt-3">
                                        <p className="mb-0 text">
                                            <span className="text-dark">Si vous n{"'"}avez pas de compte, </span>
                                            <Link to={`/${ROUTES.SIGNUP}`} className="text-primary">
                                                <strong> S{"'"}inscrire </strong>
                                            </Link>
                                        </p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default LoginPage;
