import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Button, Image, ListGroup, Modal, Form } from 'react-bootstrap';
import { FaDownload, FaMoneyCheckAlt, FaFileInvoiceDollar, FaEdit } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { InsurancePayById, fetchInsuranceById } from '../../../actions/request/InssuranceRequest';
import html2pdf from 'html2pdf.js'; // Pour télécharger la facture en PDF
import Select from 'react-select'; // Pour le choix du mode de paiement
import { toast } from 'react-toastify';
import { fetchPaymentsAll } from '../../../actions/request/PaymentRequest';
import { fetchPaymentTypesAll } from '../../../actions/request/PaymentTypeRequest';
import { getAndCheckLocalStorage } from '../../../utils/storage/localvalueFuction';
import { localStorageKeys } from '../../../utils/storage/localvalue';
import { baseurl } from '../../../utils/url/baseurl';
import axios from 'axios';
import { ROUTES } from '../../../config/routingUrl';
import { useRef } from 'react';

const InsuranceInvoiceDetailPage = () => {
    const navigate = useNavigate();
    const paramInsurance = useParams();
    const dispatch = useDispatch();
    const insurance = useSelector((state) => state.insurances.insurance);
    const paymentTypes = useSelector((state) => state.paymentTypes.paymentTypes);

    const [showPayModal, setShowPayModal] = useState(false);
    const [showPrintModal, setShowPrintModal] = useState(false);
    const [paymentProof, setPaymentProof] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [date, setdate] = useState();

    useEffect(() => {
        dispatch(fetchInsuranceById(paramInsurance?.id));
        dispatch(fetchPaymentTypesAll());
    }, [dispatch, paramInsurance?.id]);

    // Options pour le mode de paiement
    const paymentOptions = paymentTypes && paymentTypes.length > 0 ? paymentTypes.map((option) => {
        return (
            { value: option._id, label: option.name }
        )
    }) : []






    const [loading, setLoading] = useState(false);

    // Fonction pour gérer l'upload du fichier PDF avec Axios
    const handleFileUpload = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', baseurl.cloudinaryUploadPreset); // Ton preset d'upload Cloudinary

        setLoading(true); // Activer l'indicateur de chargement

        try {
            const response = await axios.post(`https://api.cloudinary.com/v1_1/${baseurl.cloudinaryCloudName}/auto/upload`, formData);
            const data = response.data;

            if (data.secure_url) {
                toast.success("Fichier uploadé avec succès :", { position: "bottom-right" })
                // console.log('Fichier uploadé avec succès :', data.secure_url);
                setPaymentProof(data.secure_url); // Stocker l'URL du fichier
            }

        } catch (error) {

            console.error('Erreur lors de l\'upload :', error);
            toast.error("Fichier non uploader", { position: "bottom-right" });

        } finally {
            setLoading(false); // Désactiver l'indicateur de chargement
        }
    };



    // Fonction de paiement
    const handlePayInsurance = () => {
        if (paymentMethod && paymentProof && date) {
            console.log(paymentMethod?.value);

            const insuraneData = {
                type: paymentMethod?.value,
                date: date,
                user: getAndCheckLocalStorage(localStorageKeys.userId),
                paymentFile: paymentProof
            }
            console.log(insuraneData)

            dispatch(InsurancePayById(paramInsurance?.id, insuraneData, toast))
            setShowPayModal(false);
        } else {
            toast.error("Veuillez choisir un mode de paiement et la date et télécharger la preuve.", { position: "bottom-right" })
            alert();
        }
    };

    // Fonction pour télécharger la facture en PDF
    const handleDownloadInvoice = () => {
        if (insurance) {
            setShowPrintModal(true);
        }
    };

    const invoiceRef = useRef();
    const handlePrintInvoice = () => {
        setShowPrintModal(false);

        const element = invoiceRef.current;
        const options = {
            margin: 1,
            filename: 'Facture_Reservation.pdf', // Nom du fichier PDF
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
            // Ajout des métadonnées du PDF
            pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
            pdfCallback: (pdf) => {
                pdf.setProperties({
                    title: 'Facture de réservation', // Titre du document PDF
                    subject: 'Détails de la réservation et paiement',
                    author: 'Votre Application',
                    keywords: 'facture, réservation, paiement',
                    creator: 'Resaplus'
                });
            }
        };

        html2pdf().set(options).from(element).save();
    };






    return (
        <div className="">
            {insurance ? (
                <Card className="p-4" ref={invoiceRef}>
                    <Card.Header className="text-center">
                        <h3 className="mb-0">Facture d{"'"}assurance</h3>
                        <p className="text-dark">Assurance {insurance?.type?.name || ""}</p>
                    </Card.Header>
                    <Card.Body>
                        <Row className="mb-4">
                            <Col>
                                <h5><strong>Numéro de police : </strong>{insurance?.policyNumber || ""}</h5>
                                <h5><strong>Assuré : </strong>{insurance?.userId?.firstname || ""} {insurance?.userId?.lastname || ""}</h5>
                                <h5><strong>Montant à payer : </strong>{insurance?.premiumAmount || ""} DZD</h5>
                            </Col>
                            <Col className="text-right">
                                <p><strong>Date de début : </strong>{new Date(insurance?.startDate).toLocaleDateString()}</p>
                                <p><strong>Date de fin : </strong>{new Date(insurance?.endDate).toLocaleDateString()}</p>
                                <p><strong>Statut de paiement : </strong>{insurance?.paymentStatus || ""}</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <Card className="p-3">
                                    <h5 className="text-dark">Détails de l{"'"}objet assuré</h5>
                                    {insurance?.insuredObjectType === 'Vehicle' && insurance?.vehicle && (
                                        <>
                                            <Image
                                                src={insurance.vehicle.images[0]}
                                                className="img-fluid rounded mb-3 border"
                                                alt={insurance.vehicle.brand || ""}
                                            />
                                            <p><strong>Véhicule : </strong>{insurance?.vehicle.brand || ""} {insurance?.vehicle?.model || ""} ({insurance?.vehicle?.year || ""})</p>
                                            <p><strong>Couleur : </strong>{insurance?.vehicle.color || ""}</p>
                                            <p><strong>Immatriculation : </strong>{insurance?.vehicle?.registrationNumber || ""}</p>
                                        </>
                                    )}
                                    {insurance?.insuredObjectType === 'Property' && insurance?.property && (
                                        <>
                                            <Image
                                                src={insurance.property.images[0]}
                                                className="img-fluid rounded mb-3 border"
                                                alt={insurance.property.name || ""}
                                            />
                                            <p><strong>Propriété : </strong>{insurance?.property.name || ""}</p>
                                            <p><strong>Adresse : </strong>{insurance?.property.address || ""}</p>
                                            <p><strong>Année de construction : </strong>{insurance?.property?.yearBuilt || ""}</p>
                                        </>
                                    )}
                                </Card>
                            </Col>

                            <Col md={6}>
                                <Card className="p-3">
                                    <h5 className="text-dark">Détails de la couverture</h5>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item><strong>Type d{"'"}assurance : </strong>{insurance?.type?.description || ""}</ListGroup.Item>
                                        <ListGroup.Item><strong>Prime annuelle : </strong>{insurance?.premiumAmount || ""} DZD</ListGroup.Item>
                                        <ListGroup.Item><strong>Date de début : </strong>{new Date(insurance?.startDate).toLocaleDateString()}</ListGroup.Item>
                                        <ListGroup.Item><strong>Date de fin : </strong>{new Date(insurance?.endDate).toLocaleDateString()}</ListGroup.Item>
                                        <ListGroup.Item><strong>Statut de paiement : </strong>{insurance?.paymentStatus || ""}</ListGroup.Item>
                                    </ListGroup>
                                </Card>
                            </Col>
                        </Row>

                        <div className="mt-4 text-center">
                            {
                                insurance?.status !== "Paid" &&
                                <Button variant="primary" size="sm" className="m-2" onClick={() => setShowPayModal(true)}>
                                    <FaMoneyCheckAlt /> Payer
                                </Button>
                            }

                            <Button variant="primary" size="sm" className="m-2" onClick={() => { navigate(`/${ROUTES.DASHBOARD}/${ROUTES.INSURANCES_LIST_PLATEFORM_EDIT}/${paramInsurance.id}`) }}>
                                <FaEdit /> Modifier
                            </Button>
                            <Button variant="primary" size="sm" className="m-2" onClick={handleDownloadInvoice}>
                                <FaDownload /> Télécharger
                            </Button>
                        </div>
                    </Card.Body>
                </Card>
            ) : (
                <p>Aucune assurance disponible.</p>
            )}

            {/* Modal pour confirmer le paiement */}
            <Modal show={showPayModal} onHide={() => setShowPayModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation du paiement</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Mode de paiement</Form.Label>
                            <Select
                                options={paymentOptions}
                                onChange={setPaymentMethod}
                                placeholder="Choisissez le mode de paiement"
                            />
                        </Form.Group>
                        <Form.Group controlId="prixexpense">
                            <Form.Label>Date de la dépense </Form.Label>
                            <Form.Control style={{ height: "40px" }}
                                type="date"
                                value={date}
                                onChange={(e) => {
                                    setdate(e.target.value);
                                }}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Justificatif de paiement</Form.Label>
                            <Form.Control
                                type="file"
                                accept="image/*"
                                onChange={(e) => handleFileUpload(e.target.files[0])}
                                disabled={loading} // Désactiver le champ pendant l'upload
                            />
                            {loading && <p>Chargement du fichier...</p>}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" size="sm" onClick={() => setShowPayModal(false)}>
                        Annuler
                    </Button>
                    <Button variant="primary" size="sm" disabled={loading} onClick={handlePayInsurance}>
                        Confirmer
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal pour confirmer l'impression */}
            <Modal show={showPrintModal} onHide={() => setShowPrintModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Imprimer la facture</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Souhaitez-vous télécharger ou imprimer la facture maintenant ?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" size="sm" onClick={() => setShowPrintModal(false)}>
                        Annuler
                    </Button>
                    <Button variant="primary" size="sm" onClick={handlePrintInvoice}>
                        Confirmer
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default InsuranceInvoiceDetailPage;
