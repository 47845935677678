import axios from "axios";
import { FETCH_COMPANY_AIRPORTS_FAILURE, FETCH_COMPANY_AIRPORTS_REQUEST, FETCH_COMPANY_AIRPORTS_SUCCESS, FETCH_COMPANY_AIRPORTS_SUCCESS_2, FETCH_COMPANY_AIRPORT_FAILURE, FETCH_COMPANY_AIRPORT_REQUEST, FETCH_COMPANY_AIRPORT_SUCCESS } from "../../app/actions/actions";
import { ROUTES } from "../../config/routingUrl";
import { dureeDeVie, setWithExpiration } from "../../utils/storage/localvalueFuction";
import { localStorageData, localStorageKeys } from "../../utils/storage/localvalue";
import { baseurl } from "../../utils/url/baseurl";
import { saveDataToFile } from "../DataLocal";




// All insurance of plateforme
export function fetchCompanyAiportsAll() {
    return async (dispatch) => {
        dispatch({ type: FETCH_COMPANY_AIRPORTS_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/companyairports/get_companyairports`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_COMPANY_AIRPORTS_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_COMPANY_AIRPORTS_SUCCESS_2, payload: response.data.data });
            // saveDataToFile(response.data.data, localStorageData.CompanyAiports);
        }).catch((error) => {
                //console.log(error);
                dispatch({ type: FETCH_COMPANY_AIRPORTS_FAILURE, payload: error.message });
            });
    }
}


// get insurance by id 
export function fetchCompanyAiportById(idCompanyAiport) {
    return async (dispatch) => {
        dispatch({ type: FETCH_COMPANY_AIRPORT_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/companyairports/get_companyairport/${idCompanyAiport}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_COMPANY_AIRPORT_SUCCESS, payload: response.data.data });
        })
            .catch((error) => {
                dispatch({ type: FETCH_COMPANY_AIRPORT_FAILURE, payload: error.message })
                //console.log(error);
            });
    }
}

