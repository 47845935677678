import axios from "axios";
import { FETCH_PROPERTYS_FAILURE, FETCH_PROPERTYS_REQUEST, FETCH_PROPERTYS_SUCCESS, FETCH_PROPERTYS_SUCCESS_2, FETCH_PROPERTY_FAILURE, FETCH_PROPERTY_REQUEST, FETCH_PROPERTY_SUCCESS } from "../../app/actions/actions";
import { ROUTES } from "../../config/routingUrl";
import { dureeDeVie, setWithExpiration } from "../../utils/storage/localvalueFuction";
import { localStorageData, localStorageKeys } from "../../utils/storage/localvalue";
import { baseurl } from "../../utils/url/baseurl";
import { saveDataToFile } from "../DataLocal";





// Create Property
export const PropertyCreate = (
    idUser, idTypeProperty, data,navigate,
    toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_PROPERTY_REQUEST });
        await axios
            .post(
                `${baseurl.url}/api/v1/propertys/add/by/${idUser}/type/${idTypeProperty}`,
                data,
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                }
            )
            .then((response) => {
                toast.success("Propriété créer avec succès",{position:"bottom-right"});
                dispatch({ type: FETCH_PROPERTY_SUCCESS });
                navigate(`/${ROUTES.DASHBOARD}/${ROUTES.PROPERTYS_LIST}`)
                // window.location.href = `/${ROUTES.LOGIN}`;
            })
            .catch((error) => {
                toast.error("Propriété non ajouter",{position:"bottom-right"})
                dispatch({ type: FETCH_PROPERTY_FAILURE, payload: error.message });
            });
    };
}





export const PropertyUpdateById = (
    idProperty,
    idUser, idTypeProperty, data,navigate,
    toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_PROPERTY_REQUEST });
        await axios
            .put(
                `${baseurl.url}/api/v1/propertys/update/${idProperty}/by/${idUser}/type/${idTypeProperty}`,
                data,
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                }
            )
            .then((response) => {
                toast.success("Propriété mis à jour avec succès");
                dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: response.data.data });
                navigate(`/${ROUTES.DASHBOARD}/${ROUTES.PROPERTYS_LIST}`)
                dispatch(fetchPropertyById(idProperty));
            })
            .catch((error) => {
                toast.error("Mise à jour impossible")
                dispatch({ type: FETCH_PROPERTY_FAILURE, payload: error.message });
            });
    };
}















// 
export const PropertyStatutUpdateById = (idProperty, idUser, status, toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_PROPERTY_REQUEST });
        await axios
            .patch(`${baseurl.url}/api/v1/propertys/status/${idProperty}/by/${idUser}`, { "status": status }, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            })
            .then((response) => {
                dispatch({ type: FETCH_PROPERTY_SUCCESS });
                dispatch(fetchPropertyById(idProperty));
                toast.success("Disponibilité mis à jour");
            })
            .catch((error) => {
                dispatch({ type: FETCH_PROPERTY_FAILURE, payload: error.message });
                toast.error("Disponibilété non mis ajour")
            });
    };
}







// All property of plateforme
export function fetchPropertysAll() {
    return async (dispatch) => {
        dispatch({ type: FETCH_PROPERTYS_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/propertys/get_propertys`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            // console.log(response.data.data);
            dispatch({ type: FETCH_PROPERTYS_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_PROPERTYS_SUCCESS_2, payload: response.data.data });
            saveDataToFile(response.data.data, localStorageData.Propertys);
        })
            .catch((error) => {
                //console.log(error);
                dispatch({ type: FETCH_PROPERTYS_FAILURE, payload: error.message });
            });
    }
}



// All property of plateforme of Owner
export function fetchPropertysAllByOwnerId(idOwner) {
    return async (dispatch) => {
        dispatch({ type: FETCH_PROPERTYS_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/propertys/get_propertys/by/${idOwner}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_PROPERTYS_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_PROPERTYS_SUCCESS_2, payload: response.data.data });
            saveDataToFile(response.data.data, localStorageData.Propertys);
        })
            .catch((error) => {
                //console.log(error);
                dispatch({ type: FETCH_PROPERTYS_FAILURE, payload: error.message });
            });
    }
}







// All vehicle of plateforme of Owner with availability filter
export function fetchPropertysAllResearch( availableStartDate, availableEndDate, companyId = null,address) {
    return async (dispatch) => {
        dispatch({ type: FETCH_PROPERTYS_REQUEST });

        // Préparer les paramètres de requête
        let queryParams = `?availableStartDate=${availableStartDate}&availableEndDate=${availableEndDate}`;
        if (companyId) {
            queryParams += `&companyId=${companyId}`;
        }
        if (address) {
            queryParams += `&address=${address}`;
        }

        // Construire l'URL avec les paramètres de requête
        const url = `${baseurl.url}/api/v1/propertys/get_propertys_params${queryParams}`;

        await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        })
        .then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_PROPERTYS_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_PROPERTYS_SUCCESS_2, payload: response.data.data });
        })
        .catch((error) => {
            //console.log(error);
            dispatch({ type: FETCH_PROPERTYS_FAILURE, payload: error.message });
        });
    }
}





// Fonction pour récupérer les propriétés avec des filtres facultatifs
export function fetchPropertysFilters(
    startDate = null,
    endDate = null,
    address = null,
    owner = null,
    userAdd = null
) {
    return async (dispatch) => {
        dispatch({ type: FETCH_PROPERTYS_REQUEST });

        // Créer un objet URLSearchParams pour les paramètres de requête
        const params = new URLSearchParams();

        // Ajouter des paramètres de date si disponibles
        if (startDate) params.append("startDate", startDate);
        if (endDate) params.append("endDate", endDate);
        if (address) params.append("address", address);
        if (owner) params.append("owner", owner);
        if (userAdd) params.append("userAdd", userAdd);

        // Construire l'URL avec les paramètres de requête
        const url = `${baseurl.url}/api/v1/propertys/get_propertys_filter?${params.toString()}`;

        await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        })
        .then((response) => {
            // console.log(response.data.data);
            dispatch({ type: FETCH_PROPERTYS_SUCCESS, payload: response.data.data });
        })
        .catch((error) => {
            //console.log(error);
            dispatch({ type: FETCH_PROPERTYS_FAILURE, payload: error.message });
        });
    };
}






// get property by id 
export function fetchPropertyById(idProperty) {
    return async (dispatch) => {
        dispatch({ type: FETCH_PROPERTY_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/propertys/get_property/${idProperty}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: response.data.data });
        })
            .catch((error) => {
                dispatch({ type: FETCH_PROPERTY_FAILURE, payload: error.message })
                //console.log(error);
            });
    }
}




