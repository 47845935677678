import axios from "axios";
import { FETCH_CUSTOMERS_FAILURE, FETCH_CUSTOMERS_REQUEST, FETCH_CUSTOMERS_SUCCESS, FETCH_CUSTOMERS_SUCCESS_2, FETCH_CUSTOMER_FAILURE, FETCH_CUSTOMER_REQUEST, FETCH_CUSTOMER_SUCCESS, } from "../../app/actions/actions";
import { ROUTES } from "../../config/routingUrl";
import { dureeDeVie, setWithExpiration } from "../../utils/storage/localvalueFuction";
import { localStorageData, localStorageKeys } from "../../utils/storage/localvalue";
import { baseurl } from "../../utils/url/baseurl";
import { saveDataToFile } from "../DataLocal";
import { profileRoleType } from "../../utils/dataApi/dataFormApi";





// Create Customer
export const CustomerCreate = (
    data,navigate,
    toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_CUSTOMER_REQUEST });
        await axios
            .post(
                `${baseurl.url}/api/v1/customers/add`,
                data,
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                }
            )
            .then((response) => {
                toast.success("Compte Client créer avec succès", { position: "bottom-right" });
                dispatch({ type: FETCH_CUSTOMER_SUCCESS });
                navigate(`/${ROUTES.LOGIN}`)
                // window.location.href = `/${ROUTES.LOGIN}`;
            })
            .catch((error) => {
                if (error.request.status == 450) {
                    toast.error(`${error.response.data.message}`,{position:"bottom-right"})
                }
                else if (error.request.status == 451) {
                    toast.error(`${error.response.data.message}`,{position:"bottom-right"})
                }else{
                    toast.error(`Compte non créer`,{position:"bottom-right"})
                }
                dispatch({ type: FETCH_CUSTOMER_FAILURE, payload: error.message });
            });
    };
}





export const CustomerUpdateById = (
    idCustomer,
    data,
    toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_CUSTOMER_REQUEST });
        await axios
            .put(
                `${baseurl.url}/api/v1/customers/edit/${idCustomer}`,
                data,
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                }
            )
            .then((response) => {
                toast.success("Compte client mis ajour avec succès");
                dispatch({ type: FETCH_CUSTOMER_SUCCESS, payload: response.data.data });
                // window.location.href = `/${ROUTES.LOGIN}`;
            })
            .catch((error) => {
                toast.error("Mise à jour impossible")
                dispatch({ type: FETCH_CUSTOMER_FAILURE, payload: error.message });
            });
    };
}















// Connexion Customer
export const CustomerConnexion = (usernameOremail, password, redirect, toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_CUSTOMER_REQUEST });
        await axios
            .post(`${baseurl.url}/api/v1/customers/auth/login`, {
                "email": usernameOremail,
                "password": password
            }, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            })
            .then((response) => {
                //console.log(response.data.data)
                dispatch({ type: FETCH_CUSTOMER_SUCCESS });
                //console.log(response.data.data);
                setWithExpiration(localStorageKeys.userId, response.data?.data?._id, dureeDeVie);
                setWithExpiration(localStorageKeys.userCoverPicture, response.data?.data?.profilePicture, dureeDeVie);
                setWithExpiration(localStorageKeys.userRole, response.data?.data?.role, dureeDeVie);
                setWithExpiration(localStorageKeys.userName, response.data?.data?.firstname + " " + response.data?.data?.lastname, dureeDeVie);
                setWithExpiration(localStorageKeys.profileRole, profileRoleType.CUSTOMER, dureeDeVie);
                dispatch(fetchCustomersAll());
                toast.success("Vous étes maintenant connecté");
                setTimeout(() => {
                    redirect(`/`);
                    window.location.href = `/${ROUTES.DASHBOARD}`;
                }, 1000);
            })
            .catch((error) => {
                dispatch({ type: FETCH_CUSTOMER_FAILURE, payload: error.message });
                toast.error("Connexion impossible")
            });
    };
}







// All customers of plateforme
export function fetchCustomersAll() {
    return async (dispatch) => {
        dispatch({ type: FETCH_CUSTOMERS_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/customers/get_customers`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_CUSTOMERS_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_CUSTOMERS_SUCCESS_2, payload: response.data.data });
            saveDataToFile(response.data.data, localStorageData.Customers);
        })
            .catch((error) => {
                //console.log(error);
                dispatch({ type: FETCH_CUSTOMERS_FAILURE, payload: error.message });
            });
    }
}











export function fetchCustomerById(idCustomer) {
    return async (dispatch) => {
        dispatch({ type: FETCH_CUSTOMER_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/customers/get_customer/${idCustomer}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_CUSTOMER_SUCCESS, payload: response.data.data });
        })
            .catch((error) => {
                dispatch({ type: FETCH_CUSTOMER_FAILURE, payload: error.message })
                //console.log(error);
            });
    }
}





export const CustomerUpdatePassword = (
    idCustomer,
    password,
    toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_CUSTOMERS_REQUEST });
        await axios
            .post(`${baseurl.url}/api/v1/customers/update-password/${idCustomer}`,
                {
                    password
                },
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                }
            )
            .then((response) => {
                toast.success("Mot de passe Modifier avec succès")
                dispatch({ type: FETCH_CUSTOMER_SUCCESS });
            })
            .catch((error) => {
                toast.error("Mot de passe non mis à jour")
                dispatch({ type: FETCH_CUSTOMER_FAILURE, payload: error.message });
            });
    };
}



// Reset password
export const CustomerResetPasswordForget = (
    email,
    password,
    setStep,
    showModal,
    toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_CUSTOMER_REQUEST });
        await axios
            .post(`${baseurl.url}/api/v1/customers/reset-password`,
                {
                    email,
                    password
                },
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                }
            )
            .then((response) => {
                toast.success("Mot de passe Modifier avec succès");
                dispatch({ type: FETCH_CUSTOMER_SUCCESS });
                setStep(1);
                showModal(true);
                window.location.href = `/${ROUTES.LOGIN}`;
            })
            .catch((error) => {
                toast.error("Mot de pass")
                dispatch({ type: FETCH_CUSTOMER_FAILURE, payload: error.message });
            });
    };
}




// Reset password
export const CustomerSendCodeverfiy = (
    email,
    phone,
    setSept,
    toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_CUSTOMER_REQUEST });
        await axios
            .post(`${baseurl.url}/api/v1/customers/send-code-verify`,
                {
                    email,
                    phone
                },
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                }
            )
            .then((response) => {
                toast.success("Un code vous été envoyer sur votre email . ");
                setSept(2)
                dispatch({ type: FETCH_CUSTOMER_SUCCESS });
            })
            .catch((error) => {
                toast.error("Le code n'a pas été entrez email valide . ")
                dispatch({ type: FETCH_CUSTOMER_FAILURE, payload: error.message });
            });
    };
}




// Reset password
export const CustomerverfiyCode = (
    email, phone,
    passwordverifield,
    setStep,
    toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_CUSTOMER_REQUEST });
        await axios
            .post(`${baseurl.url}/api/v1/customers/verfiy-code-reset`,
                {
                    _id: email || phone,
                    email,
                    phone,
                    passwordverifield,
                },
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                }
            )
            .then((response) => {
                toast.success("Le Code est accepter avec succès .");
                dispatch({ type: FETCH_CUSTOMER_SUCCESS });
                setStep(3)
            })
            .catch((error) => {
                toast.error("Le code n'a pas été accepter entrer un code valide ")
                dispatch({ type: FETCH_CUSTOMER_FAILURE, payload: error.message });
            });
    };
}




export const CustomerUpdateStatut = (
    idCustomer,
    toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_CUSTOMER_REQUEST });
        await axios
            .patch(`${baseurl.url}/api/v1/cutomers/statut_cutomer/${idCustomer}`,
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                }
            )
            .then((response) => {
                dispatch({ type: FETCH_CUSTOMER_SUCCESS, payload: response.data.data });
                toast.success(response.data.message, { position: "bottom-right" });
                dispatch(fetchCustomersAll());
            })
            .catch((error) => {
                toast.error(`${error.response.data.message}`, { position: "bottom-right" })
                dispatch({ type: FETCH_CUSTOMER_FAILURE, payload: error.message });
            });
    };
}
