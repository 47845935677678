import React from 'react';
import { facebookLink, logoApplication, youtubeLink } from '../utils/dataApi/dataFormApi';
import { Link } from 'react-router-dom';
import { ROUTES } from '../config/routingUrl';

const FooterWeb = () => {
    return (

        <footer class="text-center text-lg-start bg-body-tertiary text-muted bg-light">

            <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom ">
                <div>
                    <a href="" class="me-4 text-reset">
                        <i class="fab fa-facebook-f"></i>
                    </a>
                    <a href="" class="me-4 text-reset">
                        <i class="fab fa-twitter"></i>
                    </a>
                    <a href="" class="me-4 text-reset">
                        <i class="fab fa-google"></i>
                    </a>
                    <a href="" class="me-4 text-reset">
                        <i class="fab fa-instagram"></i>
                    </a>
                    <a href="" class="me-4 text-reset">
                        <i class="fab fa-linkedin"></i>
                    </a>
                    <a href="" class="me-4 text-reset">
                        <i class="fab fa-github"></i>
                    </a>
                </div>

            </section>
            <section class="">
                <div class="container text-center text-md-start mt-5">
                    <div class="row mt-3">
                        <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                            <h6 class="text-uppercase fw-bold mb-4">
                                <i class="fas fa-gem me-3"></i>Rezaplus
                            </h6>
                            <p class="text-body">
                                nous vous proposons une large gamme de véhicules adaptés à tous vos besoins.
                                Que ce soit pour un voyage d{"'"}affaires, des vacances en famille ou une
                                escapade entre amis, nous avons le véhicule parfait pour vous.
                            </p>
                        </div>
                        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                            <h6 class="text-uppercase fw-bold mb-4">
                                Liens Utilies
                            </h6>
                            <p>
                                <Link to={`/${ROUTES.ABOUTUS}`} class="text-body">Acceuil</Link>
                            </p>
                            <p>
                                <Link to={`/${ROUTES.CAR_LIST_WEB}`} class="text-body">Reservation</Link>
                            </p>
                            <p>
                                <Link to={`/${ROUTES.SIGNUP}`} class="text-body">Inscription</Link></p>
                            <p>
                                <Link to={`/${ROUTES.LOGIN}`} class="text-body">Connexion</Link>
                            </p>
                        </div>

                        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                            <h6 class="text-uppercase fw-bold mb-4">Contact</h6>
                            <p><i class="fas fa-home me-3"></i></p>
                            <p class="text-body">
                                <i class="fas fa-envelope me-3"></i>
                                rezaplus2024@gmail.com
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <div class="text-center py-1 text-body" style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
                © 2024 Copyright:
                <a class=" fw-bold" href="#">Rezaplus</a>
            </div>
        </footer>
    );
}

export default FooterWeb;
