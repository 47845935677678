import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const TransmissionListPage = () => {
  // Jeu de données initial pour les transmissions
  const initialTransmissions = [
    {
      name: 'Transmission Automatique',
      image: 'https://via.placeholder.com/150?text=Automatique',
      type: 'Automatic'
    },
    {
      name: 'Transmission Manuelle',
      image: 'https://via.placeholder.com/150?text=Manuelle',
      type: 'Manual'
    }
  ];

  const [transmissions, setTransmissions] = useState(initialTransmissions);
  const [newTransmission, setNewTransmission] = useState({
    name: '',
    image: '',
    type: ''
  });
  const [showModal, setShowModal] = useState(false);

  // Gestion de l'affichage de la modal
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  // Gestion de l'ajout d'une nouvelle transmission
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTransmission({
      ...newTransmission,
      [name]: value
    });
  };

  const addTransmission = (e) => {
    e.preventDefault();
    setTransmissions([...transmissions, newTransmission]);
    setNewTransmission({
      name: '',
      image: '',
      type: ''
    });
    handleClose(); // Fermer la modal après l'ajout
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Liste des Transmissions</h2>
      
      <Button variant="primary" onClick={handleShow}>
        Ajouter une Transmission
      </Button>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter une Transmission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={addTransmission}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">Nom de la Transmission</label>
              <input 
                type="text" 
                className="form-control" 
                id="name" 
                name="name" 
                value={newTransmission.name}
                onChange={handleInputChange}
                required 
              />
            </div>

            <div className="mb-3">
              <label htmlFor="image" className="form-label">URL de l'Image</label>
              <input 
                type="url" 
                className="form-control" 
                id="image" 
                name="image" 
                value={newTransmission.image}
                onChange={handleInputChange}
                required 
              />
            </div>

            <div className="mb-3">
              <label htmlFor="type" className="form-label">Type de Transmission</label>
              <select 
                className="form-select" 
                id="type" 
                name="type" 
                value={newTransmission.type}
                onChange={handleInputChange}
                required 
              >
                <option value="">Sélectionner le type</option>
                <option value="Automatic">Automatique</option>
                <option value="Manual">Manuelle</option>
              </select>
            </div>

            <Button type="submit" className="btn btn-sm btn-primary">
              Ajouter la Transmission
            </Button>
          </form>
        </Modal.Body>
      </Modal>

      <h3 className="mt-5">Liste des Transmissions</h3>
      <div className="row">
        {transmissions.map((transmission, index) => (
          <div key={index} className="col-md-4 mb-4">
            <div className="card">
              <img src={transmission.image} className="card-img-top" alt={transmission.name} />
              <div className="card-body">
                <h5 className="card-title">{transmission.name}</h5>
                <p className="card-text">
                  <strong>Type : </strong>{transmission.type}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TransmissionListPage;
