import { FETCH_AGENDAS_FAILURE, FETCH_AGENDAS_REQUEST, FETCH_AGENDAS_SUCCESS, FETCH_AGENDAS_SUCCESS_2, FETCH_AGENDA_FAILURE, FETCH_AGENDA_REQUEST, FETCH_AGENDA_SUCCESS } from "../actions/actions";



// agendaReducer.js
const initialState = {
    agendas: [],
    agendas2: [],
    agenda: {},
    agendaSelect: {},
    loadingAgenda:false,
    loadingAgendaSelect:false,
    loadingAgendas:false,
    errorAgendas:null,
    errorAgenda:null
    // ... autres états spécifiques à agendaReducer
  };
  
  const agendaReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_AGENDA_REQUEST:
        // Dispatch une action pour définir loading sur true dans le globalReducer
        return {
          ...state,
          loadingAgenda:true,
          errorAgendas:""
          // Autres manipulations d'état pour la demande de récupération des agendas
        };
        case FETCH_AGENDA_SUCCESS:
        return {
          ...state,
          loadingAgenda:false,
          errorAgendas:"",
          agenda:action.payload
        };
        case FETCH_AGENDA_FAILURE:
        return {
          ...state,
          loadingAgenda:false,
          errorAgendas:action.payload
        };


      case FETCH_AGENDAS_REQUEST:
        return {
          ...state,
          loadingAgendas:true,
          errorAgendas:""
        };
       
      case FETCH_AGENDAS_SUCCESS:
        return {
          ...state,
          agendas: action.payload,
          loadingAgendas:false,
          errorAgendas:""
          // Autres manipulations d'état pour le succès de la récupération des agendas
        };
        case FETCH_AGENDAS_SUCCESS_2:
        return {
          ...state,
          agendas2: action.payload,
          loadingAgendas:false,
          errorAgendas:""
          // Autres manipulations d'état pour le succès de la récupération des agendas
        };
        
      case FETCH_AGENDAS_FAILURE:
        // Dispatch une action pour définir loading sur false dans le globalReducer et enregistrer l'erreur
        return {
          ...state,
          loadingAgendas:false,
          errorAgendas:action.payload
          // Autres manipulations d'état pour l'échec de la récupération des agendas
        };
      // ... autres cas pour d'autres actions liées aux agendas

        
      default:
        return state;
    }
  };
  
  export default agendaReducer;
  