import {
  FETCH_EXPENSE_CATEGORYS_FAILURE, FETCH_EXPENSE_CATEGORYS_REQUEST, FETCH_EXPENSE_CATEGORYS_SUCCESS, FETCH_EXPENSE_CATEGORYS_SUCCESS_2,
  FETCH_EXPENSE_CATEGORYS_SUCCESS_D, FETCH_EXPENSE_CATEGORY_FAILURE, FETCH_EXPENSE_CATEGORY_REQUEST, FETCH_EXPENSE_CATEGORY_SUCCESS
} from
  "../actions/actions";

// expensecategoryReducer.js
const initialState = {
  expensecategorys: [],
  expensecategorys2: [],
  expensecategorytype: {},
  expensecategorySelect: {},
  loadingExpensecategory: false,
  loadingExpensecategorySelect: false,
  loadingExpensecategorys: false,
  errorExpensecategorys: null,
  errorExpensecategory: null
  // ... autres états spécifiques à expensecategoryReducer
};

const expensecategoryReducer = (state = initialState, action) => {
  switch (action.type) {

    case FETCH_EXPENSE_CATEGORY_REQUEST:
      // Dispatch une action pour définir loading sur true dans le globalReducer
      return {
        ...state,
        loadingExpensecategory: true,
        errorExpensecategory: ""
        // Autres manipulations d'état pour la demande de récupération des expensecategorys
      };

    case FETCH_EXPENSE_CATEGORY_SUCCESS:
      return {
        ...state,
        loadingExpensecategory: false,
        errorExpensecategory: "",
        expensecategorytype: action.payload
      };

    case FETCH_EXPENSE_CATEGORY_FAILURE:
      return {
        ...state,
        loadingExpensecategory: false,
        errorExpensecategory: action.payload
      };

    // expensecategorytype
    // case FETCH_EXPENSE_CATEGORY_REQUEST_SELECT:
    // // Dispatch une action pour définir loading sur true dans le globalReducer
    // return {
    // ...state,
    // loadingExpensecategorySelect:true,
    // errorExpensecategorys:""
    // // Autres manipulations d'état pour la demande de récupération des expensecategorys
    // };
    // case FETCH_EXPENSE_CATEGORY_SUCCESS_SELECT:
    // return {
    // ...state,
    // loadingExpensecategorySelect:false,
    // errorExpensecategorys:"",
    // expensecategorySelect:action.payload
    // };
    // case FETCH_EXPENSE_CATEGORY_FAILURE_SELECT:
    // return {
    // ...state,
    // loadingExpensecategorySelect:false,
    // errorExpensecategorys:action.payload
    // };

    case FETCH_EXPENSE_CATEGORYS_REQUEST:
      return {
        ...state,
        loadingExpensecategorys: true,
        errorExpensecategorys: ""
      };

    case FETCH_EXPENSE_CATEGORYS_SUCCESS:
      return {
        ...state,
        expensecategorys: action.payload,
        loadingExpensecategorys: false,
        errorExpensecategorys: ""
        // Autres manipulations d'état pour le succès de la récupération des expensecategorys
      };

    case FETCH_EXPENSE_CATEGORYS_SUCCESS_2:
      return {
        ...state,
        expensecategorys2: action.payload,
        loadingExpensecategorys: false,
        errorExpensecategorys: ""
        // Autres manipulations d'état pour le succès de la récupération des expensecategorys
      };

    case FETCH_EXPENSE_CATEGORYS_SUCCESS_D:
      return {
        ...state,
        expensecategorysD: action.payload,
        loadingExpensecategorys: false,
        errorExpensecategorys: ""
        // Autres manipulations d'état pour le succès de la récupération des expensecategorys
      };

    case FETCH_EXPENSE_CATEGORYS_FAILURE:
      // Dispatch une action pour définir loading sur false dans le globalReducer et enregistrer l'erreur
      return {
        ...state,
        loadingExpensecategorys: false,
        errorExpensecategorys: action.payload
        // Autres manipulations d'état pour l'échec de la récupération des expensecategorys
      };
    // ... autres cas pour d'autres actions liées aux expensecategorys

    default:
      return state;
  }
};

export default expensecategoryReducer;