import { FETCH_PROPERTY_TYPES_FAILURE, FETCH_PROPERTY_TYPES_REQUEST, FETCH_PROPERTY_TYPES_SUCCESS, FETCH_PROPERTY_TYPES_SUCCESS_2, FETCH_PROPERTY_TYPE_FAILURE, FETCH_PROPERTY_TYPE_REQUEST, FETCH_PROPERTY_TYPE_SUCCESS } from "../actions/actions";



// propertyTypeReducer.js
const initialState = {
    propertyTypes: [],
    propertyTypes2: [],
    propertyType: {},
    propertyTypeSelect: {},
    loadingPropertyType:false,
    loadingPropertyTypeSelect:false,
    loadingPropertyTypes:false,
    errorPropertyTypes:null,
    errorPropertyType:null
    // ... autres états spécifiques à propertyTypeReducer
  };
  
  const propertyTypeReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_PROPERTY_TYPE_REQUEST:
        // Dispatch une action pour définir loading sur true dans le globalReducer
        return {
          ...state,
          loadingPropertyType:true,
          errorPropertyTypes:""
          // Autres manipulations d'état pour la demande de récupération des propertyTypes
        };
        case FETCH_PROPERTY_TYPE_SUCCESS:
        return {
          ...state,
          loadingPropertyType:false,
          errorPropertyTypes:"",
          propertyType:action.payload
        };
        case FETCH_PROPERTY_TYPE_FAILURE:
        return {
          ...state,
          loadingPropertyType:false,
          errorPropertyTypes:action.payload
        };


      case FETCH_PROPERTY_TYPES_REQUEST:
        return {
          ...state,
          loadingPropertyTypes:true,
          errorPropertyTypes:""
        };
       
      case FETCH_PROPERTY_TYPES_SUCCESS:
        return {
          ...state,
          propertyTypes: action.payload,
          loadingPropertyTypes:false,
          errorPropertyTypes:""
          // Autres manipulations d'état pour le succès de la récupération des propertyTypes
        };
        case FETCH_PROPERTY_TYPES_SUCCESS_2:
        return {
          ...state,
          propertyTypes2: action.payload,
          loadingPropertyTypes:false,
          errorPropertyTypes:""
          // Autres manipulations d'état pour le succès de la récupération des propertyTypes
        };
        
      case FETCH_PROPERTY_TYPES_FAILURE:
        // Dispatch une action pour définir loading sur false dans le globalReducer et enregistrer l'erreur
        return {
          ...state,
          loadingPropertyTypes:false,
          errorPropertyTypes:action.payload
          // Autres manipulations d'état pour l'échec de la récupération des propertyTypes
        };
      // ... autres cas pour d'autres actions liées aux propertyTypes

        
      default:
        return state;
    }
  };
  
  export default propertyTypeReducer;

  

