import React, { useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const OccupancyRateChart = ({ data, type }) => {
  console.log("agrega ", data);

  const processedData = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const months = Array.from({ length: 12 }, (_, index) => ({
      month: `${new Date(0, index).toLocaleString('default', { month: 'long' }).toUpperCase()} ${currentYear}`,
      items: [],
    }));

    // Filtrer les données par type (véhicules ou propriétés)
    const filteredData = data.filter(item =>
      type === 'vehicle' ? item.item.vehiclebrand : item.item.address
    );

    // Ajouter les données aux mois correspondants
    filteredData.forEach(item => {
      const itemDates = item.dates; // Utiliser toutes les dates de réservation de cet item
      itemDates.forEach(dateRange => {
        const itemDate = new Date(dateRange.startDate); // Utiliser startDate pour déterminer le mois
        if (isNaN(itemDate)) {
          console.error("Date invalide : ", dateRange.startDate);
          return;
        }

        const month = `${itemDate.toLocaleString('default', { month: 'long' }).toUpperCase()} ${itemDate.getFullYear()}`;
        let targetMonth = months.find(m => m.month === month);

        if (targetMonth) {
          targetMonth.items.push({
            name: `${item.item.brand || item.item.name} (${itemDate.getFullYear()})`, // Ajout d'un identifiant unique
            occupancyRate: item.occupancyRate, // Taux d'occupation
            dates: item.dates,
          });
        }
      });
    });

    return months;
  }, [data, type]);

  // Fonction pour générer des couleurs distinctes pour chaque ligne
  const generateColor = (index) => {
    const colors = [
      '#FF0000', '#00FF00', '#0000FF', '#FF00FF', '#FFFF00', 
      '#00FFFF', '#FF8000', '#8000FF', '#00FF80', '#FF0080', 
      '#800000', '#008080', '#808000', '#800080', '#008000',
      '#8B4513', '#2E8B57', '#B22222', '#A52A2A', '#DAA520',
      '#556B2F', '#FF6347', '#FF1493', '#DC143C', '#4682B4'
    ];
    return colors[index % colors.length];  // Assure une couleur différente même si le nombre d'éléments dépasse la taille du tableau
  };

  return (
    <div style={{ width: '100%', height: 400 }}>
      <h6>{type === 'vehicle' ? 'Taux d\'occupation ' : 'Taux d\'occupation '}</h6>
      <ResponsiveContainer  style={{width:"100%", height:"90%"}}>
        <LineChart
          data={processedData}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="month"
            label={{ value: 'Mois', position: 'insideBottomRight', offset: 0 }}
          />
          <YAxis
            label={{
              value: 'Taux d\'occupation (%)',
              angle: -90,
              position: 'insideLeft'
            }}
          />
          <Tooltip
            content={<CustomTooltip />}
            cursor={{ stroke: 'black', strokeWidth: 1 }}
          />
          <Legend />

          {/* Tracer une ligne pour chaque mois et chaque item (véhicule ou propriété) */}
          {processedData.map((month, monthIndex) => (
            month?.items?.map((item, itemIndex) => (
              <Line
                key={`${month.month}-${item.name}`} // Utiliser le mois et le nom unique de l'élément pour la clé
                type="monotone"
                dataKey={`items[${itemIndex}].occupancyRate`} // Accéder à occupancyRate de chaque item
                name={item.name}
                stroke={generateColor(itemIndex)} // Assigner une couleur distincte
                activeDot={{ r: 8 }}
              />
            ))
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

// Tooltip personnalisé pour plus de détails
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{
        background: 'white',
        border: '1px solid #ccc',
        padding: '10px'
      }}>
        <p>{label}</p>
        {payload.map(item => (
          <p key={item.name} style={{ color: item.color }}>
            {item.name}: {item.value.toFixed(2)}%
          </p>
        ))}
      </div>
    );
  }
  return null;
};

export default OccupancyRateChart;
