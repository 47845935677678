import { FETCH_PAYMENTS_FAILURE, FETCH_PAYMENTS_REQUEST, FETCH_PAYMENTS_SUCCESS, FETCH_PAYMENTS_SUCCESS_2, FETCH_PAYMENTS_SUCCESS_D, FETCH_PAYMENT_FAILURE, FETCH_PAYMENT_REQUEST, FETCH_PAYMENT_SUCCESS } from "../actions/actions";



// paymentReducer.js
const initialState = {
    payments: [],
    payments2: [],
    payment: {},
    paymentSelect: {},
    loadingPayment:false,
    loadingPaymentSelect:false,
    loadingPayments:false,
    errorPayments:null,
    errorPayment:null
    // ... autres états spécifiques à paymentReducer
  };
  
  const paymentReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_PAYMENT_REQUEST:
        // Dispatch une action pour définir loading sur true dans le globalReducer
        return {
          ...state,
          loadingPayment:true,
          errorPayment:""
          // Autres manipulations d'état pour la demande de récupération des payments
        };
        case FETCH_PAYMENT_SUCCESS:
        return {
          ...state,
          loadingPayment:false,
          errorPayment:"",
          payment:action.payload
        };
        case FETCH_PAYMENT_FAILURE:
        return {
          ...state,
          loadingPayment:false,
          errorPayment:action.payload
        };


        // PAYMENT
        // case FETCH_PAYMENT_REQUEST_SELECT:
        // // Dispatch une action pour définir loading sur true dans le globalReducer
        // return {
        //   ...state,
        //   loadingPaymentSelect:true,
        //   errorPayments:""
        //   // Autres manipulations d'état pour la demande de récupération des payments
        // };
        // case FETCH_PAYMENT_SUCCESS_SELECT:
        // return {
        //   ...state,
        //   loadingPaymentSelect:false,
        //   errorPayments:"",
        //   paymentSelect:action.payload
        // };
        // case FETCH_PAYMENT_FAILURE_SELECT:
        // return {
        //   ...state,
        //   loadingPaymentSelect:false,
        //   errorPayments:action.payload
        // };






      case FETCH_PAYMENTS_REQUEST:
        return {
          ...state,
          loadingPayments:true,
          errorPayments:""
        };
       
      case FETCH_PAYMENTS_SUCCESS:
        return {
          ...state,
          payments: action.payload,
          loadingPayments:false,
          errorPayments:""
          // Autres manipulations d'état pour le succès de la récupération des payments
        };
        case FETCH_PAYMENTS_SUCCESS_2:
        return {
          ...state,
          payments2: action.payload,
          loadingPayments:false,
          errorPayments:""
          // Autres manipulations d'état pour le succès de la récupération des payments
        };

        case FETCH_PAYMENTS_SUCCESS_D:
          return {
            ...state,
            paymentsD: action.payload,
            loadingPayments:false,
            errorPayments:""
            // Autres manipulations d'état pour le succès de la récupération des payments
          };
        
      case FETCH_PAYMENTS_FAILURE:
        // Dispatch une action pour définir loading sur false dans le globalReducer et enregistrer l'erreur
        return {
          ...state,
          loadingPayments:false,
          errorPayments:action.payload
          // Autres manipulations d'état pour l'échec de la récupération des payments
        };
      // ... autres cas pour d'autres actions liées aux payments

        
      default:
        return state;
    }
  };
  
  export default paymentReducer;
  