import React from 'react'
import CarBarner from '../../components/web/cars/CarBarner'
import CarListFilterList from '../../components/web/cars/CarListFilterList'

const CariLstPage = () => {
  return (
    <div class="page-content bg-white">


      <div class="dlab-bnr-inr style-1 overlay-black-middle" style={{ backgroundImage: `url(guide-voiture.jpg)` }}>
        <div class="container">
          <div class="dlab-bnr-inr-entry">
            <h1 class="text-white">Recherche </h1>
            <div class="dlab-separator"></div>
          </div>
        </div>
      </div>
      {/*<CarBarner /> */}
      <CarListFilterList />

    </div>
  )
}

export default CariLstPage
