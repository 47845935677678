import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FaPlay } from 'react-icons/fa'; // Play icon for demonstration
import './HomeAbout.css';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../config/routingUrl';

const HomeAbout = () => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Image URLs
    const propertyImgUrl = "images/gallery/maxresdefault.jpg";
    const vehicleImgUrl = "images/gallery/pic7.jpg";

    return (
        <div className="home-container">
            <section className="promo-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <img src={propertyImgUrl} alt="Property Offer" className="img-fluid"  />
                        </div>
                        <div className="col-md-6">
                            <h3>Réservez votre séjour dès aujourd{"'"}hui!</h3>
                            <p>Nos propriétés offrent un confort inégalé, que ce soit pour des vacances en famille ou un séjour d'affaires. Profitez d'un cadre idyllique et de services de première qualité.</p>
                            <Link to={`/${ROUTES.CAR_LIST_WEB}`} className="btn btn-primary" onClick={handleShow}>Réservez maintenant</Link>
                        </div>
                    </div>
                </div>
            </section>

            <section className="promo-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h3>Location de voitures à des tarifs imbattables</h3>
                            <p>Choisissez parmi une large gamme de véhicules adaptés à tous vos besoins. Que vous partiez en voyage d'affaires ou en vacances, nous avons le véhicule qu'il vous faut!</p>
                            <Link to={`/${ROUTES.CAR_LIST_WEB}`} className="btn btn-primary" onClick={handleShow}>Réservez votre véhicule</Link>
                        </div>
                        <div className="col-md-6">
                            <img src={vehicleImgUrl} alt="Vehicle Offer" className="img-fluid"  />
                        </div>
                    </div>
                </div>
            </section>

            <section className="monthly-rental">
                <div className="container">
                    <h2>Réserver a partir maintenant </h2>
                    <p>Besoins d’une location de véhicule pour 30 jours ou plus? Prenez la route à partir de d{"'"}aujourd{"'"}hui.</p>
                    <p>Réservez aujourd'hui et profitez d'offres exclusives qui répondent à tous vos besoins de mobilité.</p>
                    <Link to={`/${ROUTES.CAR_LIST_WEB}`} className="btn btn-primary" onClick={handleShow}>Réserver aujourd{"'"}hui</Link>
                </div>
            </section>
        </div>
    );
};

export default HomeAbout;
