import axios from "axios";
import { FETCH_INSURANCES_FAILURE, FETCH_INSURANCES_REQUEST, FETCH_INSURANCES_SUCCESS, FETCH_INSURANCES_SUCCESS_2, FETCH_INSURANCE_FAILURE, FETCH_INSURANCE_REQUEST, FETCH_INSURANCE_SUCCESS } from "../../app/actions/actions";
import { ROUTES } from "../../config/routingUrl";
import { dureeDeVie, setWithExpiration } from "../../utils/storage/localvalueFuction";
import { localStorageData, localStorageKeys } from "../../utils/storage/localvalue";
import { baseurl } from "../../utils/url/baseurl";
import { saveDataToFile } from "../DataLocal";



// Create Insurance
export const InsuranceCreate = (
    data,navigate,
    toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_INSURANCE_REQUEST });
        await axios
            .post(
                `${baseurl.url}/api/v1/insurances/`,
                data,
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                }
            )
            .then((response) => {
                toast.success("Assurance Ajouté avec succès",{position:"bottom-right"});
                dispatch({ type: FETCH_INSURANCE_SUCCESS });
                navigate(`/${ROUTES.DASHBOARD}/${ROUTES.INSURANCES_LIST_PLATEFORM}`)
                // dispatch(fetchInsurancesAll())
            })
            .catch((error) => {
                toast.error("Assurance non ajouté ",{position:"bottom-right"})
                dispatch({ type: FETCH_INSURANCE_FAILURE, payload: error.message });
            });
    };
}



// update insurance 
export const InsuranceUpdateById = (idInsurance, data,navigate, toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_INSURANCE_REQUEST });
        await axios
            .put(`${baseurl.url}/api/v1/insurances/update/${idInsurance}`, data, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            })
            .then((response) => {
                dispatch({ type: FETCH_INSURANCE_SUCCESS, payload: response.data.data });
                dispatch(fetchInsuranceById(idInsurance));
                toast.success("Assurance mis à jour",{position:"bottom-right"});
                navigate(`/${ROUTES.DASHBOARD}/${ROUTES.INSURANCES_LIST_PLATEFORM}`)
            })
            .catch((error) => {
                dispatch({ type: FETCH_INSURANCE_FAILURE, payload: error.message });
                toast.error("Assurance non mis à jour",{position:"bottom-right"})
            });
    };
}





// All insurance of plateforme
export function fetchInsurancesAll() {
    return async (dispatch) => {
        dispatch({ type: FETCH_INSURANCES_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/insurances/get_insurances`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_INSURANCES_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_INSURANCES_SUCCESS_2, payload: response.data.data });
            saveDataToFile(response.data.data, localStorageData.Insurances);
        })
            .catch((error) => {
                //console.log(error);
                dispatch({ type: FETCH_INSURANCES_FAILURE, payload: error.message });
            });
    }
}



// All insurance of plateforme of vehicle or vehicle
export function fetchInsurancesAllByVehicleId(idVehicle) {
    return async (dispatch) => {
        dispatch({ type: FETCH_INSURANCES_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/insurances/get_insurances/vehicle/${idVehicle}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_INSURANCES_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_INSURANCES_SUCCESS_2, payload: response.data.data });
            saveDataToFile(response.data.data, localStorageData.Insurances);
        })
            .catch((error) => {
                //console.log(error);
                dispatch({ type: FETCH_INSURANCES_FAILURE, payload: error.message });
            });
    }
}



// All insurance of plateforme of vehicle or property
export function fetchInsurancesAllByPropertyId(idProperty) {
    return async (dispatch) => {
        dispatch({ type: FETCH_INSURANCES_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/insurances/get_insurances/property/${idProperty}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_INSURANCES_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_INSURANCES_SUCCESS_2, payload: response.data.data });
            saveDataToFile(response.data.data, localStorageData.Insurances);
        })
            .catch((error) => {
                //console.log(error);
                dispatch({ type: FETCH_INSURANCES_FAILURE, payload: error.message });
            });
    }
}





// All insurance of plateforme of vehicle
export function fetchInsurancesAllByUserId(idUser) {
    return async (dispatch) => {
        dispatch({ type: FETCH_INSURANCES_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/insurances/get_insurances/user/${idUser}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_INSURANCES_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_INSURANCES_SUCCESS_2, payload: response.data.data });
            saveDataToFile(response.data.data, localStorageData.Insurances);
        })
            .catch((error) => {
                //console.log(error);
                dispatch({ type: FETCH_INSURANCES_FAILURE, payload: error.message });
            });
    }
}




// All insurance of plateforme of Reservation
export function fetchInsurancesAllByReservationId(idReservation) {
    return async (dispatch) => {
        dispatch({ type: FETCH_INSURANCES_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/insurances/get_insurances/reservation/${idReservation}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_INSURANCES_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_INSURANCES_SUCCESS_2, payload: response.data.data });
            saveDataToFile(response.data.data, localStorageData.Insurances);
        })
            .catch((error) => {
                //console.log(error);
                dispatch({ type: FETCH_INSURANCES_FAILURE, payload: error.message });
            });
    }
}










// get insurance by id 
export function fetchInsuranceById(idInsurance) {
    return async (dispatch) => {
        dispatch({ type: FETCH_INSURANCE_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/insurances/get_insurance/${idInsurance}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_INSURANCE_SUCCESS, payload: response.data.data });
        })
            .catch((error) => {
                dispatch({ type: FETCH_INSURANCE_FAILURE, payload: error.message })

                //console.log(error);
            });
    }
}




// Payer un domage
export const InsurancePayById = (
    idInsurance, data,
    toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_INSURANCE_REQUEST });
        await axios
            .patch(
                `${baseurl.url}/api/v1/insurances/pay/${idInsurance}`,
                data,
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                }
            )
            .then((response) => {
                dispatch({ type: FETCH_INSURANCE_SUCCESS });
                toast.success("Assurance Payé avec succès", { postion: "bottom-right" });
                dispatch(fetchInsuranceById(idInsurance))
            })
            .catch((error) => {
                dispatch({ type: FETCH_INSURANCE_FAILURE, payload: error.message });

                toast.error(`${error.response.data.message}`, { position: "bottom-right" })
                

            });
    };
}
