import { FETCH_COMPANY_AIRPORTS_FAILURE, FETCH_COMPANY_AIRPORTS_REQUEST, FETCH_COMPANY_AIRPORTS_SUCCESS, FETCH_COMPANY_AIRPORTS_SUCCESS_2, FETCH_COMPANY_AIRPORT_FAILURE, FETCH_COMPANY_AIRPORT_REQUEST, FETCH_COMPANY_AIRPORT_SUCCESS } from "../actions/actions";



// companyairportReducer.js
const initialState = {
    companyairports: [],
    companyairports2: [],
    companyairport: {},
    companyairportSelect: {},
    loadingCompanyairport:false,
    loadingCompanyairportSelect:false,
    loadingCompanyairports:false,
    errorCompanyairports:null,
    errorCompanyairport:null
    // ... autres états spécifiques à companyairportReducer
  };
  
  const companyairportReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_COMPANY_AIRPORT_REQUEST:
        // Dispatch une action pour définir loading sur true dans le globalReducer
        return {
          ...state,
          loadingCompanyairport:true,
          errorCompanyairports:""
          // Autres manipulations d'état pour la demande de récupération des companyairports
        };
        case FETCH_COMPANY_AIRPORT_SUCCESS:
        return {
          ...state,
          loadingCompanyairport:false,
          errorCompanyairports:"",
          companyairport:action.payload
        };
        case FETCH_COMPANY_AIRPORT_FAILURE:
        return {
          ...state,
          loadingCompanyairport:false,
          errorCompanyairports:action.payload
        };


      case FETCH_COMPANY_AIRPORTS_REQUEST:
        return {
          ...state,
          loadingCompanyairports:true,
          errorCompanyairports:""
        };
       
      case FETCH_COMPANY_AIRPORTS_SUCCESS:
        return {
          ...state,
          companyairports: action.payload,
          loadingCompanyairports:false,
          errorCompanyairports:""
          // Autres manipulations d'état pour le succès de la récupération des companyairports
        };
        case FETCH_COMPANY_AIRPORTS_SUCCESS_2:
        return {
          ...state,
          companyairports2: action.payload,
          loadingCompanyairports:false,
          errorCompanyairports:""
          // Autres manipulations d'état pour le succès de la récupération des companyairports
        };
        
      case FETCH_COMPANY_AIRPORTS_FAILURE:
        // Dispatch une action pour définir loading sur false dans le globalReducer et enregistrer l'erreur
        return {
          ...state,
          loadingCompanyairports:false,
          errorCompanyairports:action.payload
          // Autres manipulations d'état pour l'échec de la récupération des companyairports
        };
      // ... autres cas pour d'autres actions liées aux companyairports

        
      default:
        return state;
    }
  };
  
  export default companyairportReducer;
  