import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Row, Col, Carousel, Dropdown, Badge, Card, Alert } from 'react-bootstrap';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../config/routingUrl';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPropertysAll, fetchPropertysAllByOwnerId, fetchPropertysFilters } from '../../../actions/request/PropertyRequest';
import { fetchPropertyTypesAll } from '../../../actions/request/PropertyTypeRequest';
import { emptyImage, profileRoleType } from '../../../utils/dataApi/dataFormApi';
import { getAndCheckLocalStorage } from '../../../utils/storage/localvalueFuction';
import { localStorageKeys } from '../../../utils/storage/localvalue';
import { RiEye2Fill, RiEyeCloseFill, RiOpenArmFill } from 'react-icons/ri';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { exportToExcelData, exportToPDFData } from '../../../utils/functionData/ExportFileDocument';

const renderStatusBadge = (status) => {
    switch (status) {
        case 'UNAVAILABLE':
            return <Badge bg="danger">Indisponilble</Badge>;
        case 'AVAILABLE':
            return <Badge bg="success">Disponilble</Badge>;
        case 'IN_REPAIR':
            return <Badge bg="danger">Maintenance</Badge>;
        default:
            return <Badge bg="secondary">{status}</Badge>;
    }
};

const PropertyManagementPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const properties = useSelector((state) => state.propertys.propertys);
    const propertyTypesList = useSelector((state) => state.propertyTypes.propertyTypes);
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [typeOptions, setTypeOptions] = useState([]);
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [filterStatus, setFilterStatus] = useState('Tous');
    const [filterType, setFilterType] = useState('Tous');
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        dispatch(fetchPropertyTypesAll());
        if (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER) {
            dispatch(fetchPropertysFilters("", "", "", "", getAndCheckLocalStorage(localStorageKeys.userId)));
        } else {
            dispatch(fetchPropertysFilters());
        }
    }, [dispatch]);

    const handleShowDetail = (property) => {
        setSelectedProperty(property);
        setShowDetailModal(true);
    };

    const handleCloseDetail = () => setShowDetailModal(false);

    const handleStatusChange = (selectedOption) => {
        setFilterStatus(selectedOption ? selectedOption.value : 'Tous');
    };

    const handleTypeChange = (selectedOption) => {
        setFilterType(selectedOption ? selectedOption.value : 'Tous');
    };

    const handleActionChange = (property, action) => {
        if (action === 'Détails') {
            navigate(`/${ROUTES.DASHBOARD}/${ROUTES.PROPERTYS_DETAIL}/${property._id}`);
        } else if (action === 'Modifier') {
            navigate(`/${ROUTES.DASHBOARD}/${ROUTES.PROPERTYS_EDIT}/${property._id}`);
        }
    };

    const statusOptions = [
        { value: '', label: 'Tous' },
        { value: 'AVAILABLE', label: 'Disponible' },
        { value: 'IN_REPAIR', label: 'En réparation' },
        { value: 'UNAVAILABLE', label: 'Indisponible' }
    ];

    useEffect(() => {
        if (propertyTypesList && propertyTypesList.length > 0) {
            setTypeOptions(propertyTypesList.map((type) => ({
                label: type.name,
                value: type._id
            })));
        }
    }, [propertyTypesList]);

    const filteredProperties = properties && properties.length > 0 ? properties.filter(property =>
        (filterStatus === 'Tous' || property.status === filterStatus) &&
        (filterType === 'Tous' || property.type._id === filterType) &&
        (property.address ? property.address.toLowerCase().includes(searchTerm.toLowerCase()) : false) &&
        // Filtrage par nom (si le champ name existe et correspond au terme de recherche)
        (property.name ? property.name.toLowerCase().includes(searchTerm.toLowerCase()) : false)
    ) : [];

    // Pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentProperties = filteredProperties.slice(indexOfFirstItem, indexOfLastItem);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredProperties.length / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);



    return (
        <div className=" " >

            <Card>
                <Card.Header>
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <div>
                            <h3 className="mb-4">Gestion des Propriétés</h3>
                        </div>
                        <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}>
                            <div className="p-2">
                                <Button
                                    variant="primary"
                                    size='sm'
                                    className="ml-2 mr-2"
                                    onClick={() => navigate(`/${ROUTES.DASHBOARD}/${ROUTES.PROPERTYS_ADD}`)}
                                >
                                    + Ajouter
                                </Button>
                            </div>

                            {
                                propertyTypesList && propertyTypesList.length > 0 &&
                                <Dropdown className="p-2">
                                    <Dropdown.Toggle variant="primary" className='btn btn-sm' id="dropdown-basic">
                                        Exporter
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => { exportToExcelData(propertyTypesList, "property", "Listes_des_propriétes") }}>Exporter en Excel</Dropdown.Item>
                                        <Dropdown.Item onClick={() => { exportToPDFData(propertyTypesList, "property", "Listes_des_propriétes") }}>Export en Pdf</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            }
                        </div>
                    </div>
                </Card.Header>
                <Card.Body style={{ minHeight: "400px" }} >

                    <div className="mb-3">
                        <Row>
                            <Col md={3}>
                                <Form.Control
                                    type="text"
                                    placeholder="Recherche"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    style={{ height: "40px" }}
                                />
                            </Col>
                        </Row>
                    </div>

                    {currentProperties.length > 0 ? (
                        <>
                            <Table striped bordered hover style={{ marginTop: "50px" }} responsive>
                                <thead>
                                    <tr>
                                        <th>Images</th>
                                        <th>Designation</th>
                                        <th>Adresse</th>
                                        <th>Type</th>
                                        <th>Superficie (m²)</th>
                                        <th>Prix (/Jour)</th>
                                        <th>En ligne</th>
                                        <th>Disponibilité</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentProperties.map(property => (
                                        <tr key={property._id}>
                                            <td>
                                                <Carousel style={{ width: '100px' }}>
                                                    {property && property.images && property.images.length > 0 ? property.images.map((img, idx) => (
                                                        <Carousel.Item key={idx}>
                                                            <img
                                                                className="d-block w-100"
                                                                src={img}
                                                                alt={`Slide ${idx}`}
                                                                style={{ width: '80px', height: '80px' }}
                                                            />
                                                        </Carousel.Item>

                                                    ))
                                                        :
                                                        <Carousel.Item >
                                                            <img src={emptyImage}
                                                                alt="vehicle"
                                                                className="d-block w-100"
                                                                style={{ width: "80px", height: "80px" }}
                                                            />
                                                        </Carousel.Item>
                                                    }
                                                </Carousel>
                                            </td>
                                            <td className="">{property.name}</td>
                                            <td className="">{property.address}</td>
                                            <td>{property.type?.name}</td>
                                            <td>{property.size} </td>
                                            <td>{property.basePricePerDay} </td>
                                            <td>
                                                {property && property.isVisible ? (
                                                    <span className="text-success">Oui</span>
                                                ) : (
                                                    <span className="text-danger">Non</span>
                                                )}
                                            </td>

                                            <td>{renderStatusBadge(property.status)}</td>
                                            <td>
                                                <Dropdown >
                                                    <Dropdown.Toggle id="dropdown-basic" size='sm'>
                                                        Actions
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item href="#/details" onClick={() => handleActionChange(property, 'Détails')}>
                                                            Détails
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#/edit" onClick={() => handleActionChange(property, 'Modifier')}>
                                                            Modifier cette propriété
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            {/* Pagination */}
                            <div className="pagination">
                                {pageNumbers.map(number => (
                                    <Button
                                        key={number}
                                        onClick={() => handlePageChange(number)}
                                        className={`page-item ${currentPage === number ? 'active' : ''}`}
                                    >
                                        {number}
                                    </Button>
                                ))}
                            </div>
                        </>) :
                        (
                            <Alert variant="light" className="text-center">Aucune propriété </Alert>
                        )}
                </Card.Body>
            </Card>


        </div>
    );
};

export default PropertyManagementPage;
