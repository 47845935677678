import React, { useState, useEffect } from 'react';
import { Button, Form, Modal, Table, Dropdown, Image, Col, Row, Card, Badge } from 'react-bootstrap'; // Ensure react-bootstrap is installed
import Select from 'react-select'; // Ensure react-select is installed
import "react-datepicker/dist/react-datepicker.css";
import { FaUsers } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { UserUpdateStatut, fetchUsersAll } from '../../../actions/request/UserRequest';
import { emptyImage } from '../../../utils/dataApi/dataFormApi';
import { toast } from 'react-toastify';
import { CustomerUpdateStatut, fetchCustomersAll } from '../../../actions/request/CustomerRequest';

const CustomerManagementPage = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const customerData = useSelector((state) => state.customers.customers);

    const [searchTerm, setSearchTerm] = useState('');
    const [selectedStatus, setSelectedStatus] = useState(null)

    useEffect(() => {
        dispatch(fetchCustomersAll());
    }, [dispatch]);


    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleStatusChange = (selectedOption) => {
        setSelectedStatus(selectedOption);
    };


    const renderStatusBadge = (status) => {
        switch (status) {
            case 'Active':
                return <Badge bg="success">Actif</Badge>;
            case 'Blocked':
                return <Badge bg="danger">Bloqué</Badge>;
            default:
                return <Badge bg="secondary">{status}</Badge>;
        }
    };

    const [userItem, setuserItem] = useState(null);
    const [actionModal, setactionModal] = useState("")
    const [showModal, setshowModal] = useState(false);
    const handleShowModal = (action, objet) => {
        setshowModal(true);
        setactionModal(action)
        setuserItem(objet);
    }
    const handleCloseModal = () => { setshowModal(false); }

    const handleSubmit = () => {
        if (actionModal == "unclock") {
            dispatch(CustomerUpdateStatut(userItem?._id, toast))
            handleCloseModal();
        }
        else if (actionModal == "activated") {
            dispatch(CustomerUpdateStatut(userItem?._id, toast))
            handleCloseModal();
        }
    }






    return (
        <div className="container-fluid p-3 " style={{ marginBottom: "200px" }}>


            <Card>
                <Card.Header>
                    <h3> <FaUsers /> Clients </h3>
                </Card.Header>
                <Card.Body style={{ minHeight: "500px" }}>
                    <Row>
                        <Col md={3}>
                            <Form.Group controlId="search">
                                <Form.Control
                                    type="text"
                                    placeholder="Recherche"
                                    value={searchTerm}
                                    style={{ height: "40px" }}
                                    onChange={handleSearchChange}
                                />
                            </Form.Group>
                        </Col>

                        <Col md={3}>
                            <Form.Group controlId="status">
                                <Select
                                    options={[
                                        { value: 'Active', label: 'Actif' },
                                        { value: 'Blocked', label: 'Bloqué' }
                                    ]}
                                    onChange={handleStatusChange}
                                    isClearable
                                    placeholder="Statut"
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Table striped bordered hover className="mt-5" responsive>
                        <thead>
                            <tr>
                                <th>Photo</th>
                                <th>Type</th>
                                <th>Nom & Prénoms</th>
                                <th>Email</th>
                                <th>Téléphone</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {customerData.map((user) => (
                                <tr key={user.id}>
                                    <td>
                                        {
                                            user.profilePicture ?
                                                <Image src={user.profilePicture} roundedCircle style={{ width: '50px', height: '50px' }} />
                                                :
                                                <Image src={emptyImage} roundedCircle style={{ width: '50px', height: '50px' }} />
                                        }
                                    </td>
                                    <td>
                                        {
                                            user && user.role && user.role == "COMPANY" ?
                                                "Entreprise" :
                                                "Particulier"
                                        }
                                    </td>
                                    <td>{user.firstname || ""} {user.lastname || ""}</td>
                                    <td>{user.email}</td>
                                    <td> {`+${user && user.codePostal && user.codePostal.indicatif ? user.codePostal.indicatif:""}`}  {user.phone}</td>
                                    <td>{renderStatusBadge(user.status)}</td>
                                    <td>
                                        <Dropdown>
                                            <Dropdown.Toggle size='sm' variant="light">
                                                Action
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {
                                                    user.status && user.status == "Active" ?
                                                        <Dropdown.Item href="#unclock" onClick={() => { handleShowModal("unclock", user) }}>Bloquer</Dropdown.Item>
                                                        :
                                                        <Dropdown.Item href="#/activated" onClick={() => { handleShowModal("activated", user) }}>Activer</Dropdown.Item>
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>


            <Modal show={showModal} onHide={handleCloseModal} size='lg' centered>
                <Modal.Header>
                    <h4>
                        {actionModal == "unclock" ? "Bloquer ce compte" : "Activer ce compte"}
                    </h4>
                </Modal.Header>
                <Modal.Body>
                    <div className='flex justify-content-center text-center' style={{ minHeight: "70px", width: "100%" }}>
                        <div>
                            <p>Êtes vous sur de vouloir <span class="font-weight-700">{actionModal == "unclock" ? "Bloquer ce compte " + userItem?.firstname + " " + userItem?.lastname
                                : "Activer ce compte" + userItem?.firstname + " " + userItem?.lastname}</span></p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" size="sm" variant="primary" onClick={handleCloseModal}>Fermer</Button>
                    {
                        actionModal == "unclock" ?
                            <Button type="button" size="sm" variant="danger" onClick={handleSubmit}>Bloquer</Button> :

                            actionModal == "activated" ?
                                <Button type="button" size="sm" variant="success" onClick={handleSubmit}>Activer</Button>
                                :
                                <Button type="button" size="sm" variant="primary">Confirmer</Button>
                    }
                </Modal.Footer>
            </Modal>


        </div>
    );
};

export default CustomerManagementPage;
