export const ROUTES = {
    HOME: "",
    ABOUTUS: "a-propos-de-nous",
    CONTACT: "support-contact",
    SIGNUP: "inscription",
    LOGIN: "login",
    PROFILE: "profile",
    CAR_LIST_WEB: "card-list-plateforme-availaid",
    CAR_DETAIL_VIEW: "car",

    RESERVATION_FORM_PAGE_WEB: "formulaire-de-reservation-online",
    RESULTS_PAGE: "resulpage-reservation",
    CAR_DETAIL_USER_PATRIMOINE_LIST: "plateforme-list-patrimoine-user",

    FORGETPASSWORDHASH: "Sx6gY98tSx/Sx6gY98tSx6gY98tE/9ESx6gY98tE99/6gY98tE9ESx6gY98tE99pasw/Sx6gY98tSx6gY98tE9ESx6gY98tE99word/",



    RESERVATIONS_INVOICE_WEB: "reservations-invoice",

    // adminsytration de la plateforme

    DASHBOARD: "dashboard",
    DASHBOARDREPORT: "Rapport-utilisation",

    CODEPROMOS_LIST: "code-promos-list",
    NOTIFICATIONS_LIST: "notification-list",
    EXPENCES_LIST: "expences-list",
    

    RESERVATIONS_REQUEST_ADD: "reservations-request-add",
    RESERVATIONS_REQUEST_LIST: "reservations-request-list",
    RESERVATIONS_LIST: "reservations-list",
    RESERVATIONS_LIST_CALENDAR: "reservations-calendar-list",
    RESERVATION_VIEW: "reservation-detail",

    RESERVATIONS_INVOICE_VIEW_ADMIN: "reservations-invoice-view",


    // Ecomerce Admin
    ECOMMERCE_PUBLICATIONS: "ecommerce-publications",
    ECOMMERCE_PUBLICATIONS_DETAIL: "ecommerce-publications-detail",
    ECOMMERCE_PUBLICATIONS_ADD: "add-ecommerce-publications",
    ECOMMERCE_PUBLICATIONS_EDIT: "update-ecommerce-publications",


    // CARS_LIST: "vehicles-list-online",
    PROPERTYS_LIST: "property-detail-online",
    PROPERTYS_DETAIL: "property-detail-online",
    PROPERTYS_ADD: "add-property-online",
    PROPERTYS_EDIT: "edit-property-online",


    // véhiclies
    CARS_LIST: "vehicles-list-online",
    CARS_DETAIL: "vehicle-detail-online",
    CARS_ADD: "add-vehicle-online",
    CARS_EDIT: "edit-vehicle-online",


    // Insurance
    INSURANCES_LIST_ADMIN: "insurances-list-admin",
    TRANSMISSIONS_LIST_ADMIN: "transmissions-list-admin",
    CATEGORYS_LIST_ADMIN: "categorys-list-admin",
    PAYMENTS_LIST_ADMIN: "payments-list-admin",



    // 
    REPORTING_LIST: "reporting",
    REPORTING_VEHICLE_USAGE: "vehicle-usage",
    REPORTING_VEHICLE_COSTS: "vehicle-costs",
    REPORTING_VEHICLE_USAGE: "finacial-reports",





    INSURANCES_LIST_PLATEFORM: "insurance-plateform-list-heritage",
    INSURANCES_LIST_PLATEFORM_ADD: "insurance-plateform-config",
    INSURANCES_LIST_PLATEFORM_DETAIL: "insurance-plateform-detail",
    INSURANCES_LIST_PLATEFORM_EDIT: "insurance-plateform-config-edit",



    PAYMENTS_LIST_PLATEFORM: "payments-client-list-plateform",
    PAYMENTS_DETAIL_PLATEFORM: "payment-detail-plateform",
    ALERTS_LIST_PLATEFORM: "alerts-client-list-plateform",
    DAMAGES_LIST_PLATEFORM: "damage-client-list-plateform",
    DAMAGES_LIST_PLATEFORM_DETAIL: "damage-plateform-detail",
    DAMAGES_PLATEFORM_ADD: "add-damage-vehicle",

    MAINTENANCE_LIST_PLATEFORME: "maintenance-list-plateforme",
    MAINTENANCE_LIST_CALENDAR: "maintenance-calendar-list",
    MAINTENANCE_PLATEFORME_DETAIL: "maintenance-detail",
    MAINTENANCE_PLATEFORME_ADD: "maintenance-plateforme-add",
    MAINTENANCE_PLATEFORME_EDIT: "maintenance-plateforme-config-edit",





    USERS_LIST_PLATEFORM: "users-list-plateform",
    USERS_ADD_PLATEFORM: "users-add-plateform",
    USERS_EDIT_PLATEFORM: "users-edit-plateform",

    CUSTOMERS_ADD_PLATEFORM: "customers-add-plateform",
    CUSTOMERS_EDIT_PLATEFORM: "customers-edit-plateform",
    CUSTOMERS_LIST_PLATEFORM: "customers-list-plateform",


    d27aK9Ab59G55J27VZv9WGxd8vtvZmwR :"d27aK9Ab59G55J27VZv9WGxd8vtvZmwRd27aK9Ab59d27aK9Ab59G55J2d27aK9Ab59G55J27VZv9WGxd8vtvZmwR7VZv9WGxd8vtvZmwRG55J27VZv9WGxd8vtvZmwR"






    // CARE_SUPPORT:"care-and-support",
    // PATIENTS_LIST:"patients-liste-save",
    // FINANCLAL_LIST:"transaction-financial-liste-save",
    // REPORTS_PATIENT_LIST:"order-list",
    // ORDERS_LIST:"order-list",
    // PRODUCTS_LIST:"products-list",
    // CHAT_LIST:"conversation-list",
    // APPOINNEMENT_LIST:"appoinement-list",
    // MESSAGES_SEND_LIST:"messages-list-send-patient",
    // INVENTORYS_LIST:"inventory-list",
    // SETTINGS_LIST:"settings",
    // // Iventaire action
    // INVETORY_DETAIL :"inventory-detail"

}