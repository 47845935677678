import axios from "axios";
import { FETCH_MAINTENANCES_FAILURE, FETCH_MAINTENANCES_REQUEST, FETCH_MAINTENANCES_SUCCESS, FETCH_MAINTENANCE_FAILURE, FETCH_MAINTENANCE_REQUEST, FETCH_MAINTENANCE_SUCCESS } from "../../app/actions/actions";
import { ROUTES } from "../../config/routingUrl";
import { dureeDeVie, setWithExpiration } from "../../utils/storage/localvalueFuction";
import { localStorageData, localStorageKeys } from "../../utils/storage/localvalue";
import { baseurl } from "../../utils/url/baseurl";
import { saveDataToFile } from "../DataLocal";





// Create Maintenance
export const MaintenanceCreate = (data, toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_MAINTENANCE_REQUEST });
        await axios
            .post(
                `${baseurl.url}/api/v1/maintenances`,
                data,
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                }
            )
            .then((response) => {
                toast.success("Maintenance ajoutée avec succès");
                dispatch({ type: FETCH_MAINTENANCE_SUCCESS, payload: response.data });
            })
            .catch((error) => {
                toast.error(`${error.response.data.message}`, { position: "bottom-right" });
                dispatch({ type: FETCH_MAINTENANCE_FAILURE, payload: error.message });
            });
    };
}


export const MaintenanceUpdateById = (idMaintenance,data, toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_MAINTENANCE_REQUEST });
        await axios
            .put(
                `${baseurl.url}/api/v1/maintenances/update/${idMaintenance}`,
                data,
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                }
            )
            .then((response) => {
                toast.success("Maintenance ajoutée avec succès");
                dispatch({ type: FETCH_MAINTENANCE_SUCCESS, payload: response.data });
            })
            .catch((error) => {
                toast.error(`${error.response.data.message}`, { position: "bottom-right" });
                dispatch({ type: FETCH_MAINTENANCE_FAILURE, payload: error.message });
            });
    };
}


// Cancel Maintenance
export const MaintenanceCancelById = (idMaintenance, toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_MAINTENANCE_REQUEST });
        await axios
            .patch(`${baseurl.url}/api/v1/maintenances/maintenances/${idMaintenance}/cancel`, {}, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            })
            .then((response) => {
                toast.success("Maintenance annulée avec succès");
                dispatch({ type: FETCH_MAINTENANCE_SUCCESS, payload: response.data });
            })
            .catch((error) => {
                toast.error(`${error.response.data.message}`, { position: "bottom-right" });
                dispatch({ type: FETCH_MAINTENANCE_FAILURE, payload: error.response.data.message });
            });
    };
}


// Delete Maintenance
export const MaintenanceDeleteById = (idMaintenance, toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_MAINTENANCE_REQUEST });
        await axios
            .delete(`${baseurl.url}/api/v1/maintenances/delete/${idMaintenance}`, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            })
            .then((response) => {
                toast.success("Maintenance supprimée avec succès");
                dispatch({ type: FETCH_MAINTENANCE_SUCCESS });
            })
            .catch((error) => {
                toast.error(`${error.response.data.message}`, { position: "bottom-right" });
                dispatch({ type: FETCH_MAINTENANCE_FAILURE, payload: error.response.data.message });
            });
    };
}


// Fetch all maintenances with optional filters
export function fetchMaintenancesAll(userAdd, startDate, endDate, property, vehicle, status) {
    return async (dispatch) => {
        dispatch({ type: FETCH_MAINTENANCES_REQUEST });

        // Construire les paramètres de la requête
        const params = new URLSearchParams();
        if (userAdd) params.append("userAdd", userAdd);
        if (startDate) params.append("startDate", startDate);
        if (endDate) params.append("endDate", endDate);
        if (property) params.append("property", property);
        if (vehicle) params.append("vehicle", vehicle);
        if (status) params.append("status", status);

        await axios.get(`${baseurl.url}/api/v1/maintenances/get_maintenances?${params.toString()}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        })
        .then((response) => {
            dispatch({ type: FETCH_MAINTENANCES_SUCCESS, payload: response.data.data });
            //console.log(response.data.data);
            saveDataToFile(response.data, localStorageData.Maintenances); // Sauvegarder localement si besoin
        })
        .catch((error) => {
            //console.log(error);
            dispatch({ type: FETCH_MAINTENANCES_FAILURE, payload: error.response?.data?.message || error.message });
        });
    };
}



// Get maintenance by id
export function fetchMaintenanceById(idMaintenance) {
    return async (dispatch) => {
        dispatch({ type: FETCH_MAINTENANCE_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/maintenances/get_maintenance/${idMaintenance}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            dispatch({ type: FETCH_MAINTENANCE_SUCCESS, payload: response.data.data });
        })
            .catch((error) => {
                dispatch({ type: FETCH_MAINTENANCE_FAILURE, payload: error.response.data.message })
                //console.log(error);
            });
    }
}
