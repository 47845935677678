import React from 'react';
import HomeVehicleContainerForm from './HomeVehicleContainerForm';
import HomePropertyContainerForm from './HomePropertyContainerForm';
import { Col, Row } from 'react-bootstrap';


const HomeBarner = () => {
    return (
        <div className="banner-one bg-light" style={{ width: "100%",display:"flex", justifyContent: "center" }}>
            <Row>
                <Col md={6}>
                    <HomeVehicleContainerForm />
                </Col>
                <Col md={6}>
                    <HomePropertyContainerForm />
                </Col>
            </Row>
        </div>
    );
};

export default HomeBarner;


