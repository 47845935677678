import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Card, Alert } from 'react-bootstrap';
import { MdOutlineWarning, MdDirectionsCar, MdBuild, MdCleaningServices, MdAssuredWorkload } from 'react-icons/md';
import Select from 'react-select';

const exampleAlerts = [
    {
        vehicle: 'Véhicule 1',
        maintenanceThreshold: '5000 km',
        renewalThreshold: '30 jours',
        cleaningSchedule: 'Hebdomadaire',
        repairSchedule: 'Après chaque utilisation'
    },
    {
        vehicle: 'Véhicule 2',
        maintenanceThreshold: '10000 km',
        renewalThreshold: '15 jours',
        cleaningSchedule: 'Mensuel',
        repairSchedule: 'Déclenché par rapport'
    }
    ,

];

const AlertsPage = () => {
    const [alertes, setAlertes] = useState(exampleAlerts);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [maintenanceThreshold, setMaintenanceThreshold] = useState('');
    const [renewalThreshold, setRenewalThreshold] = useState('');
    const [cleaningSchedule, setCleaningSchedule] = useState('');
    const [repairSchedule, setRepairSchedule] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const vehicles = [
        { value: 'vehicle1', label: 'Véhicule 1' },
        { value: 'vehicle2', label: 'Véhicule 2' }
    ];

    const cleaningOptions = [
        { value: 'after_use', label: 'Après chaque utilisation' },
        { value: 'weekly', label: 'Hebdomadaire' },
        { value: 'monthly', label: 'Mensuel' }
    ];

    const handleSaveAlert = () => {
        if (!selectedVehicle || !maintenanceThreshold || !renewalThreshold || !cleaningSchedule || !repairSchedule) {
            setAlertMessage('Veuillez remplir tous les champs.');
            setShowAlert(true);
            return;
        }

        const newAlert = {
            vehicle: selectedVehicle.label,
            maintenanceThreshold,
            renewalThreshold,
            cleaningSchedule: cleaningSchedule.label,
            repairSchedule
        };

        setAlertes([...alertes, newAlert]);
        setAlertMessage('Alerte ajoutée avec succès !');
        setShowAlert(true);

        setSelectedVehicle(null);
        setMaintenanceThreshold('');
        setRenewalThreshold('');
        setCleaningSchedule('');
        setRepairSchedule('');
    };

    return (
        <Container className="container">
            <h3 className="mb-4"><MdOutlineWarning /> Configuration des Alertes</h3>
            {showAlert && (
                <Alert variant={alertMessage.includes('succès') ? 'success' : 'danger'} onClose={() => setShowAlert(false)} dismissible>
                    {alertMessage}
                </Alert>
            )}

            <Row>
                <Col md={6}>
                    <Card>
                        <Card.Body>
                            <Form>
                                <Form.Group className="mb-3">
                                    <Form.Label>Sélection du véhicule</Form.Label>
                                    <Select
                                        value={selectedVehicle}
                                        onChange={setSelectedVehicle}
                                        options={vehicles}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Seuil d'alerte de maintenance (Kilométrage/Intervalle de temps)</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Entrez le seuil d'alerte de maintenance"
                                        value={maintenanceThreshold}
                                        onChange={(e) => setMaintenanceThreshold(e.target.value)}
                                        style={{ height: "40px" }}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Seuil d'alerte de renouvellement d'assurance, vignette, contrôle technique (Jours avant expiration)</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Entrez le seuil d'alerte de renouvellement"
                                        value={renewalThreshold}
                                        onChange={(e) => setRenewalThreshold(e.target.value)}
                                        style={{ height: "40px" }}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Calendrier de nettoyage</Form.Label>
                                    <Select
                                        value={cleaningSchedule}
                                        onChange={setCleaningSchedule}
                                        options={cleaningOptions}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Calendrier de réparation</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Entrez le calendrier de réparation"
                                        value={repairSchedule}
                                        onChange={(e) => setRepairSchedule(e.target.value)}
                                    />
                                </Form.Group>

                                <Button variant="primary" size='sm' onClick={handleSaveAlert}>
                                    Ajouter Alerte
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={6}>
                    <h4 className="mt-4">Alertes Configurées</h4>

                    {alertes.length === 0 ? (
                        <Alert variant="info">Aucune alerte configurée.</Alert>
                    ) : (
                        <Row>
                            {alertes.map((alerte, index) => (
                                <Col md={12} key={index} className="mb-3">
                                    <Card className="shadow-sm border-light">
                                        <Card.Body>
                                            <div className="d-flex">
                                                <div className="mr-3">
                                                    <MdDirectionsCar size={32} className="text-primary" />
                                                </div>
                                                <div>
                                                    <h5 className="card-title"><MdOutlineWarning /> {alerte.vehicle}</h5>
                                                    <p className="mb-1"><MdBuild /> <strong>Maintenance:</strong> {alerte.maintenanceThreshold}</p>
                                                    <p className="mb-1"><MdAssuredWorkload /> <strong>Renouvellement:</strong> {alerte.renewalThreshold}</p>
                                                    <p className="mb-1"><MdCleaningServices /> <strong>Nettoyage:</strong> {alerte.cleaningSchedule}</p>
                                                    <p className="mb-1"><MdBuild /> <strong>Réparation:</strong> {alerte.repairSchedule}</p>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default AlertsPage;
