import React, { useState, useEffect } from 'react';
import { Container, Table, Button, Modal, Form, Badge, Card, Col, Row, Image } from 'react-bootstrap';
import Select from 'react-select';
import { MdColorize, MdMap, MdSubscriptions } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPropertysAll } from '../../../actions/request/PropertyRequest';
import { fetchVehiclesAll } from '../../../actions/request/VehicleRequest';
import { fetchInssuranceTypesAll } from '../../../actions/request/InssuranceTypeRequest';
import { InsuranceCreate, InsuranceUpdateById, fetchInsuranceById, fetchInsurancesAll } from '../../../actions/request/InssuranceRequest';
import { toast } from 'react-toastify';
import { getAndCheckLocalStorage } from '../../../utils/storage/localvalueFuction';
import { localStorageKeys } from '../../../utils/storage/localvalue';
import moment from 'moment';
import { ROUTES } from '../../../config/routingUrl';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { baseurl } from '../../../utils/url/baseurl';
import { PiSelectionAllDuotone } from 'react-icons/pi';
import { handleImageUploadCloudOnly } from '../../../actions/upload/UploadFileCloud';

const UpdateInsuranceHeritagePage = () => {

  const paramInsurance = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const vehicleData = useSelector((state) => state.vehicles.vehicles);
  const propertyData = useSelector((state) => state.propertys.propertys);
  const insuranceTypes = useSelector((state) => state.insuranceTypes.insuranceTypes);
  const insurance = useSelector((state) => state.insurances.insurance);
  const loadingInsurance = useSelector((state) => state.insurances.loadingInsurance);

  useEffect(() => {
    dispatch(fetchPropertysAll());
    dispatch(fetchVehiclesAll());
    dispatch(fetchInssuranceTypesAll());
    dispatch(fetchInsurancesAll());
    dispatch(fetchInsuranceById(paramInsurance.id));

    fetchInsuranceByIdGet();
  }, [dispatch]);

  async function fetchInsuranceByIdGet() {
    await axios.get(`${baseurl.url}/api/v1/insurances/get_insurance/${paramInsurance.id || ""}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
      }
    }).then((response) => {
      console.log(response.data.data);
      const responseData = response.data.data;

      setType(responseData.type._id)
      setPremiumAmount(responseData.premiumAmount);
      setPolicyNumber(responseData.policyNumber);
      setStartDate(moment(responseData.startDate).format("YYYY-MM-DD"));
      setEndDate(moment(responseData.endDate).format("YYYY-MM-DD"));
      setInsuranceFile(responseData.insuranceFile);
      setFilePreview(responseData.insuranceFile);
    })
      .catch((error) => {
        console.log(error.message);
      });
  }

  const [showInsuranceModal, setShowInsuranceModal] = useState(false);
  const [selectedInsurance, setSelectedInsurance] = useState(null);
  const [filePreview, setFilePreview] = useState('');

  // États individuels pour chaque champ
  const [type, setType] = useState('');
  const [insuredObjectType, setInsuredObjectType] = useState('Vehicle');
  const [vehicle, setVehicle] = useState(null);
  const [property, setProperty] = useState(null);
  const [policyNumber, setPolicyNumber] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [premiumAmount, setPremiumAmount] = useState('');
  const [insuranceFile, setInsuranceFile] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'policyNumber':
        setPolicyNumber(value);
        break;
      case 'startDate':
        setStartDate(value);
        break;
      case 'endDate':
        setEndDate(value);
        break;
      case 'premiumAmount':
        const numericValue = parseFloat(value);
        // Vérifie si la valeur est un nombre valide et positif
        if (!isNaN(numericValue) && numericValue >= 0) {
          setPremiumAmount(value); // Si la valeur est valide et positive, mettez à jour l'état
        } else {
          setPremiumAmount(''); // Sinon, réinitialisez l'état
        }
        break;
      default:
        break;
    }
  };

  const [isLoading, setisLoading] = useState(false)
  const handleFileChange = async (e) => {
    setisLoading(true)
    const file = e.target.files[0];
    if (file) {
      if (file.size > 1 * 1024 * 1024) { // Limite de 1MB
        toast.error("Le fichier ne doit pas dépasser 1MB.");
        setisLoading(false)
        return;
      }
      const base64String = await handleImageUploadCloudOnly(file, toast)
      setInsuranceFile(base64String);
      setFilePreview(base64String);
      setisLoading(false)
    }
    setisLoading(false)
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setisLoading(true)
    const dataToSend = {
      type,
      policyNumber,
      startDate,
      endDate,
      premiumAmount,
      insuranceFile,
      userId: getAndCheckLocalStorage(localStorageKeys.userId),
    };
    if (!insuranceFile) {
      toast.error("Veillez Télécharger fichier de l'assurance", { position: "bottom-right" })
    } else {
      dispatch(InsuranceUpdateById(paramInsurance?.id, dataToSend, navigate, toast));
      setisLoading(false);
      setShowInsuranceModal(false);
    }

  };

  // Options pour react-select
  const insuranceTypeOptions = insuranceTypes && insuranceTypes.length > 0 ? insuranceTypes.map(type => ({ value: type._id, label: type.name })) : [];

  return (
    <div>
      <Card>
        <Card.Header><h3>Mettre à jour configuration de votre assurance</h3></Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={12}>
                {
                  insurance && insurance._id ?
                    <div class="card" style={{ width: "100%" }}>
                      <div class="card-body">
                        <Row>
                          <Col md={2}>
                            {
                              insurance.vehicle.images && insurance.vehicle.images.length && insurance.vehicle.images.length > 0 ?
                                <Image src={insurance.vehicle.images[0]} class="img-fluid rounded-md" style={{ width: "100px", height: "100px" }} alt={insurance.brand || insurance.model} /> : ""
                            }
                          </Col>
                          <Col md={10}>
                            <ul class="list-group list-group-flush">

                              {insurance && insurance.vehicle && insurance.vehicle.brand ? <li class="list-group-item">{insurance.vehicle.brand || ""}</li> : ""}
                              {insurance && insurance.vehicle && insurance.vehicle.model ? <li class="list-group-item">Model :  {insurance.vehicle.model || ""}</li> : ""}
                              {insurance && insurance.vehicle && insurance.vehicle.color ? <li class="list-group-item"><MdColorize /> {insurance.vehicle.color || ""}</li> : ""}

                              {insurance && insurance.property && insurance.property.name ? <li class="list-group-item">Titre : {insurance.property.name || ""}</li> : ""}
                              {insurance && insurance.property && insurance.property.address ? <li class="list-group-item"><MdMap /> {insurance.property.address || ""}</li> : ""}

                            </ul>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    : ""
                }
              </Col>

              <Col md={4}>
                <Form.Group controlId="type">
                  <Form.Label>Type d{"'"}Assurance</Form.Label>
                  <Select
                    required={true}
                    isClearable
                    options={insuranceTypeOptions}
                    value={insuranceTypeOptions.find(option => option.value === type)}
                    onChange={(selectedOption) => setType(selectedOption ? selectedOption.value : '')}
                  />
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group controlId="premiumAmount">
                  <Form.Label>Montant de la Prime</Form.Label>
                  <Form.Control required
                    style={{ height: "40px" }}
                    type="number"
                    placeholder=""
                    name="premiumAmount"
                    value={premiumAmount}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group controlId="policyNumber">
                  <Form.Label>Numéro de Police</Form.Label>
                  <Form.Control required
                    style={{ height: "40px" }}
                    type="text"
                    placeholder=""
                    name="policyNumber"
                    value={policyNumber}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group controlId="startDate">
                  <Form.Label>Date de Début</Form.Label>
                  <Form.Control required
                    style={{ height: "40px" }}
                    type="date"
                    name="startDate"
                    value={startDate}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group controlId="endDate">
                  <Form.Label>Date d{"'"}Expiration</Form.Label>
                  <Form.Control required
                    style={{ height: "40px" }}
                    type="date"
                    name="endDate"
                    value={endDate}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>

              <Col md={12}>
                <Form.Group controlId="insuranceFile">
                  <Form.Label>Fichier d{"'"}Assurance</Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                  {filePreview && <img src={filePreview} alt="Preview" style={{ width: '100px', marginTop: '10px' }} />}
                </Form.Group>
              </Col>

            </Row>

            {
              isLoading && loadingInsurance ?
                <p>Chargement en cours ...</p>
                :
                <Button type='submit' variant="primary" size="sm" className="mt-5">Mettre à jour </Button>
            }

          </Form>
        </Card.Body>

      </Card>

    </div>
  )
}

export default UpdateInsuranceHeritagePage