import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, Form, Button, Container, Row, Col, Pagination, Badge } from "react-bootstrap";
import { fetchVehiclesAll, fetchVehiclesAllResearch } from "../../../actions/request/VehicleRequest";
import { fetchPropertysAll, fetchPropertysAllResearch } from "../../../actions/request/PropertyRequest";
import { FaSearch } from 'react-icons/fa';
import "./CarListFilterList.css";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../config/routingUrl";
import { FETCH_PROPERTY_SUCCESS, FETCH_VEHICLE_SUCCESS } from "../../../app/actions/actions";
import { emptyImage } from "../../../utils/dataApi/dataFormApi";
import Select from 'react-select';
import moment from 'moment';
import { MdCarRental, MdHomeFilled } from "react-icons/md";
import axios from "axios";
import { baseurl } from "../../../utils/url/baseurl";
import { dureeDeVie, setWithExpiration } from "../../../utils/storage/localvalueFuction";
import { localStorageKeys } from "../../../utils/storage/localvalue";

const CarListFilterList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const vehicles = useSelector((state) => state.vehicles.vehicles);
    const propertys = useSelector((state) => state.propertys.propertys);

    const [availableStartDate, setAvailableStartDate] = useState('');
    const [availableStartTime, setAvailableStartTime] = useState({ value: '00:00', label: '00:00' });
    const [availableEndDate, setAvailableEndDate] = useState('');
    const [availableEndTime, setAvailableEndTime] = useState({ value: '00:00', label: '00:00' });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(9);
    const [filterType, setFilterType] = useState("vehicle");
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(0);

    const [filteredVehicles, setFilteredVehicles] = useState([]);
    const [filteredProperties, setFilteredProperties] = useState([]);


    // Création des options d'heures avec intervalles de 30 minutes
    const hourOptions = Array.from({ length: 24 }, (_, i) => {
        const hour = i.toString().padStart(2, '0');
        return [
            { value: `${hour}:00`, label: `${hour}:00` },
            { value: `${hour}:30`, label: `${hour}:30` }
        ];
    }).flat();



    useEffect(() => {
        dispatch(fetchVehiclesAll());
        dispatch(fetchPropertysAll());
    }, [dispatch]);

    useEffect(() => {
        // Mettre à jour le maxPrice après le chargement des véhicules
        if (vehicles.length > 0) {
            setMaxPrice(Math.max(...vehicles.map(v => v.basePricePerDay), 0));
        }
    }, [vehicles]);

    useEffect(() => {
        // Filtrage basé sur le prix
        const vehiclesFiltered = vehicles.sort((a, b) => b.basePricePerDay - a.basePricePerDay).filter((vehicle) => {
            const price = vehicle.basePricePerDay || 0;
            return price >= minPrice && price <= maxPrice;
        });

        const propertiesFiltered = propertys.sort((a, b) => b.basePricePerDay - a.basePricePerDay).filter((property) => {
            const price = property.price || 0; // Assurez-vous que `price` est défini
            return price >= minPrice && price <= maxPrice;
        });

        setFilteredVehicles(vehiclesFiltered);
        setFilteredProperties(propertiesFiltered);
    }, [vehicles, propertys, minPrice, maxPrice]);



    const currentItems = filterType === "vehicle"
        ? filteredVehicles.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
        : filteredProperties.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const combineDateAndTime = (date, time) => {
        return moment(`${date}T${time.value}:00.000+00:00`).toISOString();
    };

    const handleSearch = () => {
        setCurrentPage(1);


        // Combiner les dates et heures
        const startDateTime = combineDateAndTime(availableStartDate, availableStartTime);
        const endDateTime = combineDateAndTime(availableEndDate, availableEndTime);

        // Envoyer les dates combinées avec la requête de recherche
        dispatch(fetchPropertysAllResearch(startDateTime, endDateTime, "", formData?.agenceDepart));
        dispatch(fetchVehiclesAllResearch(startDateTime, endDateTime, "", formData?.agenceDepart));

        const filteredVehicles = vehicles.filter((vehicle) => {
            const price = vehicle.basePricePerDay || 0;
            return price >= minPrice && price <= maxPrice;

        });

        const filteredProperties = propertys.filter((property) => {
            const price = property.price || 0; // Assurez-vous que `price` est défini
            return price >= minPrice && price <= maxPrice;
        });


        // Mettre à jour les véhicules et propriétés filtrés dans l'état local
        setFilteredVehicles(filteredVehicles);
        setFilteredProperties(filteredProperties);
    };

    // Empêcher la sélection de dates passées
    const today = new Date().toISOString().split('T')[0];

    const [formData, setFormData] = useState({
        agenceDepart: '',
    });

    const [pickupLocation, setPickupLocation] = useState('');
    const [pickupSuggestions, setPickupSuggestions] = useState([]);

    // Obtenir les suggestions de lieu de départ
    const getPlacesByCountryStartLocation = async (query) => {
        try {
            const response = await axios.get(`${baseurl.url}/api/v1/places`, {
                params: { query: query },
            });
            const places = response.data.results;
            return places.map(place => place);
        } catch (error) {
            console.error("Erreur lors de la récupération des lieux : ", error);
        }
    };

    const handlePickupLocationChange = async (e) => {
        const value = e.target.value;
        setPickupLocation(value);
        if (value.length > 2) {
            const suggestions = await getPlacesByCountryStartLocation(value);
            setPickupSuggestions(suggestions);
        } else {
            setPickupSuggestions([]); // Réinitialiser la liste des suggestions
        }
    };

    const handlePickupSelection = (suggestion) => {
        setPickupLocation(`${suggestion.name} - ${suggestion.formatted_address}`);
        setFormData(prevState => ({
            ...prevState,
            agenceDepart: `${suggestion.name} - ${suggestion.formatted_address}`,
        }));

        setWithExpiration(localStorageKeys.agenceDepArt, `${suggestion.name} - ${suggestion.formatted_address}`, dureeDeVie);
        setWithExpiration(localStorageKeys.latSelect, suggestion?.geometry?.location?.lat, dureeDeVie);
        setWithExpiration(localStorageKeys.longSelect, suggestion?.geometry?.location?.lng, dureeDeVie);


        setPickupSuggestions([]);
    };

    const handleCleanSugestions = () => {
        setPickupSuggestions([]);
    };







    return (
        <Container fluid className="mt-5 mb-5" onClick={handleCleanSugestions}>
            <Row>
                {/* Filtres */}
                <Col lg={3}>
                    <Form className="mb-4">
                        <h5>Filtres</h5>
                        {/* Sélecteur de type (Véhicule ou Propriété) */}

                        <Form.Group>
                            <Form.Label>Type de filtre</Form.Label>
                            <div>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Véhicules"
                                    checked={filterType === "vehicle"}
                                    onChange={() => setFilterType("vehicle")}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Propriétés"
                                    checked={filterType === "property"}
                                    onChange={() => setFilterType("property")}
                                />
                            </div>
                        </Form.Group>

                        <div className="mb-3">
                            <label htmlFor="agenceDepart" className="form-label">Localisation</label>
                            <input
                                style={{ height: "40px" }}
                                type="text"
                                className="form-control"
                                id="agenceDepart"
                                name="agenceDepart"
                                placeholder="Saisissez un aéroport, une ville ou un code postal"
                                value={pickupLocation}
                                onChange={handlePickupLocationChange}
                            />
                            {pickupSuggestions && pickupSuggestions.length > 0 && (
                                <ul className="rounded p-1 border" style={{ position: 'absolute', zIndex: 1000, backgroundColor: 'white', maxWidth: "400px", listStyleType: 'none', padding: 0, maxHeight: "200px", overflow: "auto" }}>
                                    {pickupSuggestions.map((suggestion, index) => (
                                        <li className="border"
                                            key={index}
                                            style={{ padding: '5px', cursor: 'pointer' }}
                                            onClick={() => handlePickupSelection(suggestion)}
                                        >
                                            {`${suggestion.name} - ${suggestion.formatted_address}`}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>

                        {/* Filtres de prix */}
                        <Form.Group className="mb-3">
                            <Form.Label>Prix Min</Form.Label>
                            <Form.Control
                                style={{ height: "40px" }}
                                type="number"
                                value={minPrice}
                                onChange={(e) => {
                                    const value = Number.parseInt(e.target.value);
                                    if (value >= 0) { // Vérifie si le nombre est positif ou nul
                                        setMinPrice(value);
                                    }
                                }}
                                placeholder=""
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Prix Max</Form.Label>
                            <Form.Control
                                style={{ height: "40px" }}
                                type="number"
                                value={maxPrice}
                                onChange={(e) => {
                                    const value = Number.parseInt(e.target.value);
                                    if (value >= 0) { // Vérifie si le nombre est positif ou nul
                                        setMaxPrice(value);
                                    }
                                }}
                                placeholder=""
                            />
                        </Form.Group>

                        {/* Date et heure de début */}
                        <Form.Group>
                            <Form.Label>Date de début</Form.Label>
                            <Form.Control
                                style={{ height: "40px" }}
                                type="date"
                                min={today} // Empêcher la sélection d'une date passée
                                value={availableStartDate}
                                onChange={(e) => setAvailableStartDate(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Heure de début</Form.Label>
                            <Select
                                value={availableStartTime}
                                onChange={(selectedOption) => setAvailableStartTime(selectedOption)}
                                options={hourOptions}
                            />
                        </Form.Group>
                        
                        {/* Date et heure de fin */}
                        <Form.Group>
                            <Form.Label>Date de fin</Form.Label>
                            <Form.Control
                                style={{ height: "40px" }}
                                type="date"
                                min={availableStartDate || today} // Empêcher la sélection d'une date avant la date de début
                                value={availableEndDate}
                                onChange={(e) => setAvailableEndDate(e.target.value)}
                                disabled={!availableStartDate} // Désactiver le champ tant que la date de début n'est pas définie
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Heure de fin</Form.Label>
                            <Select
                                value={availableEndTime}
                                onChange={(selectedOption) => setAvailableEndTime(selectedOption)}
                                options={hourOptions}
                                isDisabled={!availableStartDate} // Désactiver tant que la date de début n'est pas définie
                            />
                        </Form.Group>

                        {/* Sélecteur pour le nombre d'éléments par page */}
                        <Form.Group>
                            <Form.Label>Nombre d{"'"}éléments par page</Form.Label>
                            <Form.Control as="select" value={itemsPerPage} onChange={(e) => setItemsPerPage(parseInt(e.target.value))}>
                                <option value={10}>10</option>
                                <option value={30}>30</option>
                            </Form.Control>
                        </Form.Group>
                        
                        {/* Bouton de recherche */}
                        <Button
                            variant="primary"
                            className="mt-3 w-100 justify-content-center"
                            onClick={handleSearch}
                        >
                            <div> <FaSearch /> <span>Rechercher</span> </div>

                        </Button>
                    </Form>
                </Col>

                {/* Liste des éléments (Véhicules ou Propriétés) */}
                <Col lg={9}>
                    <Row>
                        {currentItems.length > 0 ? (
                            currentItems.map((item) => (
                                <Col md={4} key={item._id} className="mb-4">
                                    <Card className="h-100 shadow-sm">
                                        {item && item.images && item.images.length > 0 ? (
                                            <Card.Img variant="top" className="img-fluid" style={{ height: "200px", width: "100%", objectFit: "contain" }} src={item.images[0]} alt={item.name || item.brand} />
                                        ) : (
                                            <Card.Img variant="top" className="img-fluid" style={{ height: "200px", width: "100%", objectFit: "contain" }} src={emptyImage} alt={item.name || item.brand} />
                                        )}
                                        <Card.Body>
                                            <Card.Title className="text-start">
                                                <h6 class="line-clamp-2 text-start text-truncate">{filterType === "vehicle" ? `${item.brand} ${item.model}` : item.name}</h6>

                                            </Card.Title>
                                            <Card.Text className="text-truncate">
                                                {filterType === "vehicle" ? (
                                                    <>
                                                        <strong>Couleur : </strong>{item.color}<br />
                                                        <strong>Prix : </strong>{item.basePricePerDay} DZD<br />
                                                    </>
                                                ) : (
                                                    <div className="text-truncate">
                                                        <strong>Adresse : </strong><span class="text-truncate">{item.address}</span><br />
                                                        <strong>Prix : </strong>{item.basePricePerDay} DZD<br />
                                                    </div>
                                                )}
                                            </Card.Text>
                                            <Button
                                                variant="primary"
                                                size="sm"
                                                className="btn w-100 justify-content-center"
                                                onClick={() => {
                                                    if (filterType === "vehicle") {
                                                        dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: item });
                                                        dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: {} });
                                                        navigate(`/${ROUTES.CAR_DETAIL_VIEW}/${item._id}/name/${item.brand}/description/${item.brand}`);
                                                    } else {
                                                        dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: {} });
                                                        dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: item });
                                                        navigate(`/${ROUTES.CAR_DETAIL_VIEW}/${item._id}/name/${item.name}/description/${item.name}`);
                                                    }
                                                }}
                                            >
                                                <div>{filterType === "vehicle" ? "Réserver" : "Réserver"}</div>
                                            </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))
                        ) : (
                            <p className="text-center">Aucun résultat ne correspond aux critères de recherche.</p>
                        )}
                    </Row>

                    {/* Pagination */}
                    <Pagination className="justify-content-center">
                        {Array.from({ length: Math.ceil((filterType === "vehicle" ? filteredVehicles.length : filteredProperties.length) / itemsPerPage) }, (_, i) => (
                            <Pagination.Item key={i + 1} active={i + 1 === currentPage} onClick={() => paginate(i + 1)}>
                                {i + 1}
                            </Pagination.Item>
                        ))}
                    </Pagination>
                </Col>
            </Row>
        </Container>
    );
};

export default CarListFilterList;
