const en = {
    // --- General Navigation ---
    dashboard: "Dashboard",        // Main screen with global statistics
    users: "Users",                // Section to manage users
    support: "Support",            // Section for support and assistance
    patients: "Patients",          // Section to manage patients
    laboratories: "Laboratories",  // Section to manage laboratories
    country: "Country",            // Section to manage countries
    categories: "Categories",      // Section to manage product categories
    products: "Products",          // Section to manage products
    exams: "Exams",                // Section to manage laboratory exams
    payments: "Payments",          // Section to manage payments
    orders: "Orders",              // Section to manage orders
    inventories: {                 // Managing different types of inventories
      daily: "Daily Inventory",      // Inventory done daily
      monthly: "Monthly Inventory",  // Inventory done monthly
      annual: "Annual Inventory",    // Inventory done annually
      general: "General Inventory",  // General inventory covering all products
    },
    settings: "Settings",          // Section for application settings
    suppliers: "Suppliers",        // Section to manage suppliers
  
    // --- Specific CRUD Actions ---
    new_patient: "New Patient",           // Button to add a new patient
    new_product: "Add Product",           // Button to add a new product
    new_order: "New Order",               // Button to create a new order
    edit_product: "Edit Product",         // Button to edit an existing product
    edit_patient: "Edit Patient",         // Button to edit an existing patient
    delete_product: "Delete Product",     // Button to delete a product
    delete_order: "Delete Order",         // Button to delete an order
    view_patient: "View Patient Details", // Button to view patient details
    add_lot: "Add Lot",                   // Button to add a product lot
    update_lot: "Update Lot",             // Button to update product lot information
  
    // --- Products / Inventories ---
    quantity: "Quantity",                 // Quantity of product in inventory
    expiration_date: "Expiration Date",   // Product expiration date
    batch_number: "Batch Number",         // Batch number of a product
    stock_status: "Stock Status",         // Stock status (e.g., In stock, Out of stock)
    expiration_warning: "Expiration Warning", // Alert when products are nearing expiration
  
    // --- Forms ---
    name: "Name",                  // Form field for name
    email: "Email",                // Form field for email
    password: "Password",          // Form field for password
    phone: "Phone",                // Form field for phone number
    address: "Address",            // Form field for address
    date_of_birth: "Date of Birth",// Form field for date of birth
    gender: "Gender",              // Form field for gender
    male: "Male",                  // Option for male gender
    female: "Female",              // Option for female gender
    select: "Select",              // Label for selection menu
    choose_file: "Choose file",    // Label for choosing a file
    submit_form: "Submit Form",    // Button to submit a form
  
    // --- Statuses ---
    active: "Active",              // Status for an active item
    inactive: "Inactive",          // Status for an inactive item
    pending: "Pending",            // Status for an item pending action
    approved: "Approved",          // Status for an approved item
    rejected: "Rejected",          // Status for a rejected item
    completed: "Completed",        // Status for a completed item
    failed: "Failed",              // Status for a failed item
    in_progress: "In Progress",    // Status for an item in progress
  
    // --- Notifications / Alerts ---
    success: "Success",            // Success message
    error: "Error",                // Error message
    warning: "Warning",            // Warning message
    information: "Information",    // Information message
    no_data: "No data available",  // Message when no data is available
    loading: "Loading...",         // Message when data is loading
  };
  
  export default en;
  