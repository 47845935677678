import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const CarRentalInsurancePage = () => {
  
  // Jeu de données initial pour les assurances de location de voiture avec une image
  const initialAssurances = [
    {
      name: 'Assurance Collision',
      phone: '+225 01 23 45 67',
      description: 'Couvre les dommages causés aux véhicules loués en cas de collision.',
      image: 'https://via.placeholder.com/150'
    },
    {
      name: 'Assurance Vol de Véhicule',
      phone: '+225 07 89 01 23',
      description: 'Protection contre le vol des véhicules de location.',
      image: 'https://via.placeholder.com/150'
    },
    {
      name: 'Assurance Responsabilité Civile',
      phone: '+225 05 67 89 34',
      description: 'Couvre les dommages causés à des tiers lors de la conduite du véhicule de location.',
      image: 'https://via.placeholder.com/150'
    }
  ];

  const [assurances, setAssurances] = useState(initialAssurances); // Liste d'assurances initiale
  const [newAssurance, setNewAssurance] = useState({
    name: '',
    phone: '',
    description: '',
    image: ''
  });
  const [showModal, setShowModal] = useState(false);

  // Gestion de l'affichage de la modal
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  // Gestion de l'ajout d'une nouvelle assurance
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAssurance({
      ...newAssurance,
      [name]: value
    });
  };

  const addAssurance = (e) => {
    e.preventDefault();
    setAssurances([...assurances, newAssurance]);
    setNewAssurance({
      name: '',
      phone: '',
      description: '',
      image: ''
    });
    handleClose(); // Fermer la modal après l'ajout
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Assurances de Location de Voiture</h2>

      <Button variant="primary" onClick={handleShow}>
        Ajouter une Assurance
      </Button>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter une Assurance de Location de Voiture</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={addAssurance}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">Nom de l{"'"}assurance</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={newAssurance.name}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="phone" className="form-label">Numéro de Téléphone</label>
              <input
                type="tel"
                className="form-control"
                id="phone"
                name="phone"
                value={newAssurance.phone}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="description" className="form-label">Description de l{"'"}assurance</label>
              <textarea
                className="form-control"
                id="description"
                name="description"
                rows="3"
                value={newAssurance.description}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="image" className="form-label">URL de l{"'"}image</label>
              <input
                type="url"
                className="form-control"
                id="image"
                name="image"
                value={newAssurance.image}
                onChange={handleInputChange}
                required
              />
            </div>

            <Button type="submit" className="btn btn-sm btn-primary">
              Ajouter l{"'"}assurance
            </Button>
          </form>
        </Modal.Body>
      </Modal>

      <h3 className="mt-5">Liste des Assurances de Location de Voiture</h3>
      <div className="row">
        {assurances.map((assurance, index) => (
          <div key={index} className="col-md-4 mb-4">
            <div className="card">
              <img src={assurance.image} className="card-img-top" alt={assurance.name} />
              <div className="card-body">
                <h5 className="card-title">{assurance.name}</h5>
                <p className="card-text">
                  <strong>Numéro de Téléphone : </strong>{assurance.phone}<br />
                  <strong>Description : </strong>{assurance.description}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CarRentalInsurancePage;
