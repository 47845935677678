const fr = {
    // --- Navigation générale ---
    dashboard: "Tableau de bord",   // Écran principal avec les statistiques globales
    users: "Utilisateurs",          // Section pour gérer les utilisateurs
    support: "Prise en charge",     // Section de support et assistance
    patients: "Patients",           // Section pour la gestion des patients
    laboratories: "Laboratoires",   // Section pour la gestion des laboratoires
    country: "Pays",                // Section pour la gestion des pays
    categories: "Catégories",       // Section pour la gestion des catégories de produits
    products: "Produits",           // Section pour la gestion des produits
    exams: "Examens",               // Section pour la gestion des examens en laboratoire
    payments: "Paiements",          // Section pour la gestion des paiements
    orders: "Commandes",            // Section pour la gestion des commandes
    inventories: {                  // Gestion des inventaires
      daily: "Inventaire journalier",  // Inventaire fait quotidiennement
      monthly: "Inventaire mensuel",   // Inventaire fait chaque mois
      annual: "Inventaire annuel",     // Inventaire fait chaque année
      general: "Inventaire général",   // Inventaire général couvrant tous les produits
    },
    settings: "Paramètres",         // Section pour les paramètres de l'application
    suppliers: "Fournisseurs",      // Section pour la gestion des fournisseurs
  
    // --- Actions CRUD spécifiques ---
    new_patient: "Nouveau Patient",         // Bouton pour ajouter un nouveau patient
    new_product: "Ajouter Produit",         // Bouton pour ajouter un nouveau produit
    new_order: "Nouvelle Commande",         // Bouton pour créer une nouvelle commande
    edit_product: "Modifier Produit",       // Bouton pour modifier un produit existant
    edit_patient: "Modifier Patient",       // Bouton pour modifier un patient
    delete_product: "Supprimer Produit",    // Bouton pour supprimer un produit
    delete_order: "Supprimer Commande",     // Bouton pour supprimer une commande
    view_patient: "Voir Détails du Patient",// Bouton pour voir les détails d'un patient
    add_lot: "Ajouter Lot",                 // Bouton pour ajouter un lot de produits
    update_lot: "Mettre à jour Lot",        // Bouton pour mettre à jour les informations d'un lot
  
    // --- Produits / Inventaires ---
    quantity: "Quantité",                   // Quantité de produit dans un inventaire
    expiration_date: "Date d'expiration",   // Date d'expiration d'un produit
    batch_number: "Numéro de lot",          // Numéro de lot d'un produit
    stock_status: "État du stock",          // Statut du stock (ex : En stock, Rupture de stock)
    expiration_warning: "Avertissement d'expiration", // Alerte lorsque les produits approchent de leur expiration
  
    // --- Formulaires ---
    name: "Nom",                    // Champ de formulaire pour le nom
    email: "Email",                 // Champ de formulaire pour l'email
    password: "Mot de passe",       // Champ de formulaire pour le mot de passe
    phone: "Téléphone",             // Champ de formulaire pour le numéro de téléphone
    address: "Adresse",             // Champ de formulaire pour l'adresse
    date_of_birth: "Date de naissance", // Champ de formulaire pour la date de naissance
    gender: "Genre",                // Champ de formulaire pour le genre
    male: "Homme",                  // Option pour le genre homme
    female: "Femme",                // Option pour le genre femme
    select: "Sélectionner",         // Libellé pour un menu de sélection
    choose_file: "Choisir un fichier", // Libellé pour choisir un fichier
    submit_form: "Soumettre le formulaire", // Bouton pour soumettre un formulaire
  
    // --- Statuts ---
    active: "Actif",                // Statut pour un élément actif
    inactive: "Inactif",            // Statut pour un élément inactif
    pending: "En attente",          // Statut pour un élément en attente
    approved: "Approuvé",           // Statut pour un élément approuvé
    rejected: "Rejeté",             // Statut pour un élément rejeté
    completed: "Terminé",           // Statut pour un élément complété
    failed: "Échoué",               // Statut pour un élément échoué
    in_progress: "En cours",        // Statut pour un élément en cours
  
    // --- Notifications / Alertes ---
    success: "Succès",              // Message de succès
    error: "Erreur",                // Message d'erreur
    warning: "Avertissement",       // Message d'avertissement
    information: "Information",     // Message d'information
    no_data: "Pas de données disponibles", // Message lorsque aucune donnée n'est disponible
    loading: "Chargement en cours", // Message lorsque des données sont en cours de chargement
  };
  
  export default fr;
  