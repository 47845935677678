import { FETCH_DAMAGE_TYPES_FAILURE, FETCH_DAMAGE_TYPES_REQUEST, FETCH_DAMAGE_TYPES_SUCCESS, FETCH_DAMAGE_TYPES_SUCCESS_2, FETCH_DAMAGE_TYPE_FAILURE, FETCH_DAMAGE_TYPE_REQUEST, FETCH_DAMAGE_TYPE_SUCCESS } from "../actions/actions";



// damageTypeReducer.js
const initialState = {
    damageTypes: [],
    damageTypes2: [],
    damageType: {},
    damageTypeSelect: {},
    loadingDamageType:false,
    loadingDamageTypeSelect:false,
    loadingDamageTypes:false,
    errorDamageTypes:null,
    errorDamageType:null
    // ... autres états spécifiques à damageTypeReducer
  };
  
  const damageTypeReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_DAMAGE_TYPE_REQUEST:
        // Dispatch une action pour définir loading sur true dans le globalReducer
        return {
          ...state,
          loadingDamageType:true,
          errorDamageTypes:""
          // Autres manipulations d'état pour la demande de récupération des damageTypes
        };
        case FETCH_DAMAGE_TYPE_SUCCESS:
        return {
          ...state,
          loadingDamageType:false,
          errorDamageTypes:"",
          damageType:action.payload
        };
        case FETCH_DAMAGE_TYPE_FAILURE:
        return {
          ...state,
          loadingDamageType:false,
          errorDamageTypes:action.payload
        };


      case FETCH_DAMAGE_TYPES_REQUEST:
        return {
          ...state,
          loadingDamageTypes:true,
          errorDamageTypes:""
        };
       
      case FETCH_DAMAGE_TYPES_SUCCESS:
        return {
          ...state,
          damageTypes: action.payload,
          loadingDamageTypes:false,
          errorDamageTypes:""
          // Autres manipulations d'état pour le succès de la récupération des damageTypes
        };
        case FETCH_DAMAGE_TYPES_SUCCESS_2:
        return {
          ...state,
          damageTypes2: action.payload,
          loadingDamageTypes:false,
          errorDamageTypes:""
          // Autres manipulations d'état pour le succès de la récupération des damageTypes
        };
        
      case FETCH_DAMAGE_TYPES_FAILURE:
        // Dispatch une action pour définir loading sur false dans le globalReducer et enregistrer l'erreur
        return {
          ...state,
          loadingDamageTypes:false,
          errorDamageTypes:action.payload
          // Autres manipulations d'état pour l'échec de la récupération des damageTypes
        };
      // ... autres cas pour d'autres actions liées aux damageTypes

        
      default:
        return state;
    }
  };
  
  export default damageTypeReducer;
  