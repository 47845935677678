import { FETCH_VEHICLE_BRANDS_FAILURE, FETCH_VEHICLE_BRANDS_REQUEST, FETCH_VEHICLE_BRANDS_SUCCESS, FETCH_VEHICLE_BRANDS_SUCCESS_2, FETCH_VEHICLE_BRAND_FAILURE, FETCH_VEHICLE_BRAND_REQUEST, FETCH_VEHICLE_BRAND_SUCCESS } from "../actions/actions";



// vehiclebrandReducer.js
const initialState = {
    vehiclebrands: [],
    vehiclebrands2: [],
    vehiclebrand: {},
    vehiclebrandSelect: {},
    loadingVehiclebrand:false,
    loadingVehiclebrandSelect:false,
    loadingVehiclebrands:false,
    errorVehiclebrands:null,
    errorVehiclebrand:null
    // ... autres états spécifiques à vehiclebrandReducer
  };
  
  const vehiclebrandReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_VEHICLE_BRAND_REQUEST:
        // Dispatch une action pour définir loading sur true dans le globalReducer
        return {
          ...state,
          loadingVehiclebrand:true,
          errorVehiclebrands:""
          // Autres manipulations d'état pour la demande de récupération des vehiclebrands
        };
        case FETCH_VEHICLE_BRAND_SUCCESS:
        return {
          ...state,
          loadingVehiclebrand:false,
          errorVehiclebrands:"",
          vehiclebrand:action.payload
        };
        case FETCH_VEHICLE_BRAND_FAILURE:
        return {
          ...state,
          loadingVehiclebrand:false,
          errorVehiclebrands:action.payload
        };


      case FETCH_VEHICLE_BRANDS_REQUEST:
        return {
          ...state,
          loadingVehiclebrands:true,
          errorVehiclebrands:""
        };
       
      case FETCH_VEHICLE_BRANDS_SUCCESS:
        return {
          ...state,
          vehiclebrands: action.payload,
          loadingVehiclebrands:false,
          errorVehiclebrands:""
          // Autres manipulations d'état pour le succès de la récupération des vehiclebrands
        };
        case FETCH_VEHICLE_BRANDS_SUCCESS_2:
        return {
          ...state,
          vehiclebrands2: action.payload,
          loadingVehiclebrands:false,
          errorVehiclebrands:""
          // Autres manipulations d'état pour le succès de la récupération des vehiclebrands
        };
        
      case FETCH_VEHICLE_BRANDS_FAILURE:
        // Dispatch une action pour définir loading sur false dans le globalReducer et enregistrer l'erreur
        return {
          ...state,
          loadingVehiclebrands:false,
          errorVehiclebrands:action.payload
          // Autres manipulations d'état pour l'échec de la récupération des vehiclebrands
        };
      // ... autres cas pour d'autres actions liées aux vehiclebrands

        
      default:
        return state;
    }
  };
  
  export default vehiclebrandReducer;
  