import React, { useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link, useNavigate } from 'react-router-dom';
import { carData } from '../cars/carData';
import { ROUTES } from '../../../config/routingUrl';
import { Button } from 'react-bootstrap';
import { MdArrowRight, MdArrowLeft, MdPerson, MdModelTraining, MdColorize, MdLocationOn } from 'react-icons/md'; // Import both left and right icons
import "./HomeEcommerce.css"
import { useDispatch, useSelector } from 'react-redux';
import { fetchVehiclesAll } from '../../../actions/request/VehicleRequest';
import { fetchPropertysAll } from '../../../actions/request/PropertyRequest';
import { FETCH_PROPERTY_SUCCESS, FETCH_RESERVATION_SUCCESS, FETCH_VEHICLE_SUCCESS } from '../../../app/actions/actions';
import { emptyImage } from '../../../utils/dataApi/dataFormApi';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 1 // Optional: Defines how many slides to scroll at once
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};

const HomeEcommerce = () => {

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const vehicles = useSelector((state) => state.vehicles.vehicles);
    const propertys = useSelector((state) => state.propertys.propertys);
    useEffect(() => {
        dispatch(fetchVehiclesAll())
        dispatch(fetchPropertysAll())
    }, [])


    return (
        <section className="content-inner-2" style={{ marginBottom: "100px" }}>

            {
                propertys && propertys.length > 0 &&
                (
                    <div className="container" >
                        <div className="section-head" style={{ display: "flex", justifyContent: "space-between" }}>
                            <h3>VOITURES RECENTES</h3>
                            <span type="button" class="btn btn-sm btn-primary" onClick={() => { navigate(`/${ROUTES.CAR_LIST_WEB}`) }}>
                                Voire Plus
                            </span>
                        </div>

                        <Carousel
                            responsive={responsive}
                            infinite={true}
                            autoPlay={true}
                            keyBoardControl
                            transitionDuration={6000}
                        >
                            {
                                vehicles && vehicles.length > 0 && vehicles.sort((a, b) => b.basePricePerDay - a.basePricePerDay).map((car) => (
                                    <div key={car._id} className="pointer-event car-list-box box-sm m-3" style={{ maxHeight: "400px" , cursor:"pointer"  }}
                                        onClick={() => {
                                            dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: car });
                                            dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: {} });
                                            navigate(`/${ROUTES.CAR_DETAIL_VIEW}/${car._id}/name/${car.brand}/description/${car.brand}`);
                                        }}

                                    >
                                        {
                                            car && car.images && car.images.length > 0 ?
                                                <img src={car.images[0]} alt={car.brand} style={{ width: "100%", minHeight: "200px", maxHeight: "200px" }} className="car-image" />
                                                :
                                                <img src={emptyImage} alt={car.brand} style={{ width: "100%", minHeight: "200px", maxHeight: "200px" }} className="car-image" />
                                        }

                                        <div className="list-info">
                                            <h5 className="title mb-0">
                                                <Link class="text-truncate" style={{ fontSize: "20px", maxWidth: "200px" }}

                                                    onClick={() => {

                                                        dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: car });
                                                        dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: {} });
                                                        navigate(`/${ROUTES.CAR_DETAIL_VIEW}/${car._id}/name/${car.brand}/description/${car.brand}`);
                                                    }}

                                                    to={`/${ROUTES.CAR_DETAIL_VIEW}/${car._id}/name/${car.brand}/description/${car.brand}`}>
                                                    {car.brand}
                                                </Link>
                                            </h5>
                                            <div className="car-type"> Catégorie  {car.type?.name}</div>
                                            <span className="badge m-b30">{car.basePricePerDay} DZD</span>
                                            <div className="feature-list">
                                                <div>
                                                    <label><MdColorize /> <span>Couleur</span></label>
                                                    <p className="value">{car.color}</p>
                                                </div>
                                                <div>
                                                    <label><MdModelTraining size={20} /> <span> Model </span></label>
                                                    <p className="value">{car.model}</p>
                                                </div>
                                                <div>
                                                    <label><MdPerson size={20} /><span>Passagers</span></label>
                                                    <p className="value">{car.passenger}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </Carousel>
                    </div>
                )
            }




            {/*Proprités */}

            {
                propertys && propertys.length > 0 &&
                (
                    <div className="container" style={{ paddingTop: "50px" }} >
                        <div className="section-head" style={{ display: "flex", justifyContent: "space-between" }}>
                            <h3>PROPRIETE RECENTES </h3>
                            <span type="button" class="btn btn-sm btn-primary" onClick={() => { navigate(`/${ROUTES.CAR_LIST_WEB}`) }}>
                                Voire Plus
                            </span>
                        </div>

                        {/* Carousel for car listings */}


                        <Carousel
                            responsive={responsive}
                            infinite={true}
                            autoPlay={true}
                            keyBoardControl
                            transitionDuration={6000}
                        >
                            {propertys && propertys.length > 0 && propertys.sort((a, b) => b.basePricePerDay - a.basePricePerDay).map((car) => (
                                <div key={car._id} className="pointer-event car-list-box box-sm m-3" style={{ maxHeight: "400px", cursor: "pointer" }}
                                    onClick={() => {
                                        dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: car });
                                        dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: {} });
                                        navigate(`/${ROUTES.CAR_DETAIL_VIEW}/${car._id}/name/${car.name}/description/${car.name}`);
                                    }}

                                >
                                    {
                                        car && car.images && car.images.length > 0 ?
                                            <img src={car.images[0]} alt={car.name} style={{ width: "100%", minHeight: "200px", maxHeight: "200px" }} className="car-image" />
                                            : ""
                                    }

                                    <div className="list-info">
                                        <h5 className="title mb-0">
                                            <Link class="text-truncate" style={{ fontSize: "20px" }}
                                                onClick={() => {
                                                    dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: car });
                                                    dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: {} });
                                                    navigate(`/${ROUTES.CAR_DETAIL_VIEW}/${car._id}/name/${car.name}/description/${car.name}`);
                                                }}

                                                to={`/${ROUTES.CAR_DETAIL_VIEW}/${car._id}/name/${car.name}/description/${car.name}`}>
                                                {car.name}
                                            </Link>
                                        </h5>
                                        <div className="car-type text-truncate">Catégorie : {car.type.name}</div>
                                        <span className="badge m-b30">{car.basePricePerDay} DZD </span>
                                        <div className="feature-list">
                                            <div>
                                                <label class="text-truncate"><MdLocationOn /> <span>Addresse</span></label>
                                                <div className="text-truncate">{car.address}</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Carousel>


                    </div>
                )
            }



        </section>
    );
};

// Custom left arrow button
const CustomLeftArrow = ({ onClick }) => {
    return (
        <Button variant='primary' onClick={onClick}>
            <i className="carousel-arrow"><MdArrowLeft /></i> {/* Left arrow icon */}
        </Button>
    );
};

// Custom right arrow button
const CustomRightArrow = ({ onClick }) => {
    return (
        <Button variant='primary' onClick={onClick}>
            <i className="carousel-arrow"><MdArrowRight /></i> {/* Right arrow icon */}
        </Button>
    );
};

export default HomeEcommerce;
