import axios from "axios";
import { FETCH_PAYMENTS_FAILURE, FETCH_PAYMENTS_REQUEST, FETCH_PAYMENTS_SUCCESS, FETCH_PAYMENT_FAILURE, FETCH_PAYMENT_REQUEST, FETCH_PAYMENT_SUCCESS, FETCH_PAYMENTS_SUCCESS_2 } from "../../app/actions/actions";
import { localStorageData } from "../../utils/storage/localvalue";
import { baseurl } from "../../utils/url/baseurl";
import { saveDataToFile } from "../DataLocal";



export function fetchPaymentsAll(startDate,endDate) {
    return async (dispatch) => {
        dispatch({ type: FETCH_PAYMENT_REQUEST });

        const params = new URLSearchParams();
        if (startDate) params.append("startDate", startDate);
        if (endDate) params.append("endDate", endDate);

        await axios.get(`${baseurl.url}/api/v1/payments/get_payments?${params.toString()}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data)
            dispatch({ type: FETCH_PAYMENTS_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_PAYMENTS_SUCCESS_2, payload: response.data.data });
        })
        .catch((error) => {
            dispatch({ type: FETCH_PAYMENTS_FAILURE, payload: error.message });
        });
    }
}





export function fetchPaymentsAllByArtisan(idArtisan,startDate,endDate) {
    return async (dispatch) => {
        dispatch({ type: FETCH_PAYMENTS_REQUEST });
        const params = new URLSearchParams();
        if (startDate) params.append("startDate", startDate);
        if (endDate) params.append("endDate", endDate);
        await axios.get(`${baseurl.url}/api/v1/payments/get_payments/artisan/${idArtisan}?${params.toString()}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            dispatch({ type: FETCH_PAYMENTS_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_PAYMENTS_SUCCESS_2, payload: response.data.data });
            saveDataToFile(response.data.data, localStorageData.Payments);
        })
        .catch((error) => {
            dispatch({ type: FETCH_PAYMENTS_FAILURE, payload: error.message });
        });
    }
}









export function fetchPaymentById(idPayment) {
    return async (dispatch) => {
        dispatch({ type: FETCH_PAYMENT_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/payments/get_payment/${idPayment}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            dispatch({ type: FETCH_PAYMENT_SUCCESS, payload: response.data.data });
        })
        .catch((error) => {
            dispatch({ type: FETCH_PAYMENT_FAILURE, payload: error.message });
        });
    }
}




