import { FETCH_PAYMENT_TYPES_FAILURE, FETCH_PAYMENT_TYPES_REQUEST, FETCH_PAYMENT_TYPES_SUCCESS, FETCH_PAYMENT_TYPES_SUCCESS_2, FETCH_PAYMENT_TYPE_FAILURE, FETCH_PAYMENT_TYPE_REQUEST, FETCH_PAYMENT_TYPE_SUCCESS } from "../actions/actions";



// paymentTypeReducer.js
const initialState = {
    paymentTypes: [],
    paymentTypes2: [],
    paymentType: {},
    paymentTypeSelect: {},
    loadingPaymentType:false,
    loadingPaymentTypeSelect:false,
    loadingPaymentTypes:false,
    errorPaymentTypes:null,
    errorPaymentType:null
    // ... autres états spécifiques à paymentTypeReducer
  };
  
  const paymentTypeReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_PAYMENT_TYPE_REQUEST:
        // Dispatch une action pour définir loading sur true dans le globalReducer
        return {
          ...state,
          loadingPaymentType:true,
          errorPaymentTypes:""
          // Autres manipulations d'état pour la demande de récupération des paymentTypes
        };
        case FETCH_PAYMENT_TYPE_SUCCESS:
        return {
          ...state,
          loadingPaymentType:false,
          errorPaymentTypes:"",
          paymentType:action.payload
        };
        case FETCH_PAYMENT_TYPE_FAILURE:
        return {
          ...state,
          loadingPaymentType:false,
          errorPaymentTypes:action.payload
        };


      case FETCH_PAYMENT_TYPES_REQUEST:
        return {
          ...state,
          loadingPaymentTypes:true,
          errorPaymentTypes:""
        };
       
      case FETCH_PAYMENT_TYPES_SUCCESS:
        return {
          ...state,
          paymentTypes: action.payload,
          loadingPaymentTypes:false,
          errorPaymentTypes:""
          // Autres manipulations d'état pour le succès de la récupération des paymentTypes
        };
        case FETCH_PAYMENT_TYPES_SUCCESS_2:
        return {
          ...state,
          paymentTypes2: action.payload,
          loadingPaymentTypes:false,
          errorPaymentTypes:""
          // Autres manipulations d'état pour le succès de la récupération des paymentTypes
        };
        
      case FETCH_PAYMENT_TYPES_FAILURE:
        // Dispatch une action pour définir loading sur false dans le globalReducer et enregistrer l'erreur
        return {
          ...state,
          loadingPaymentTypes:false,
          errorPaymentTypes:action.payload
          // Autres manipulations d'état pour l'échec de la récupération des paymentTypes
        };
      // ... autres cas pour d'autres actions liées aux paymentTypes

        
      default:
        return state;
    }
  };
  
  export default paymentTypeReducer;

  

