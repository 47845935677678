import axios from "axios";
import { FETCH_DAMAGES_FAILURE, FETCH_DAMAGES_REQUEST, FETCH_DAMAGES_SUCCESS, FETCH_DAMAGES_SUCCESS_2, FETCH_DAMAGE_FAILURE, FETCH_DAMAGE_REQUEST, FETCH_DAMAGE_SUCCESS,} from "../../app/actions/actions";
import { ROUTES } from "../../config/routingUrl";
import { dureeDeVie, setWithExpiration } from "../../utils/storage/localvalueFuction";
import { localStorageData, localStorageKeys } from "../../utils/storage/localvalue";
import { baseurl } from "../../utils/url/baseurl";
import { saveDataToFile } from "../DataLocal";



// Create Damage
export const DamageCreate = (
    data,
    navigate,
    toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_DAMAGE_REQUEST });
        await axios
            .post(
                `${baseurl.url}/api/v1/damages/`,
                data,
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                }
            )
            .then((response) => {
                toast.success("Domage Ajouté a la reservaiton avec succès");
                dispatch({ type: FETCH_DAMAGE_SUCCESS });
                navigate(`/${ROUTES.DASHBOARD}/${ROUTES.DAMAGES_LIST_PLATEFORM}`)
            })
            .catch((error) => {
                toast.error("Dommage non ajouter")
                dispatch({ type: FETCH_DAMAGE_FAILURE, payload: error.message });
            });
    };
}



// update damage 
export const DamageUpdateById = (idDamage, data,navigate, toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_DAMAGE_REQUEST });
        await axios
            .put(`${baseurl.url}/api/v1/damages/update/${idDamage}`, data, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            })
            .then((response) => {
                dispatch({ type: FETCH_DAMAGE_SUCCESS });
                dispatch(fetchDamageById(idDamage));
                toast.success("Domage mis à jour");
                navigate(`/${ROUTES.DASHBOARD}/${ROUTES.DAMAGES_LIST_PLATEFORM}`)
            })
            .catch((error) => {
                dispatch({ type: FETCH_DAMAGE_FAILURE, payload: error.message });
                toast.error("Domage non mis à jour")
            });
    };
}





// All damage of plateforme
export function fetchDamagesAll() {
    return async (dispatch) => {
        dispatch({ type: FETCH_DAMAGES_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/damages/get_damages`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_DAMAGES_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_DAMAGES_SUCCESS_2, payload: response.data.data });
            saveDataToFile(response.data.data, localStorageData.Damages);
        })
            .catch((error) => {
                //console.log(error);
                dispatch({ type: FETCH_DAMAGES_FAILURE, payload: error.message });
            });
    }
}






// All damage of plateforme of vehicle
export function fetchDamagesAllByVehicleId(idVehicle) {
    return async (dispatch) => {
        dispatch({ type: FETCH_DAMAGES_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/damages/get_damages/vehicle/${idVehicle}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_DAMAGES_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_DAMAGES_SUCCESS_2, payload: response.data.data });
            saveDataToFile(response.data.data, localStorageData.Damages);
        })
            .catch((error) => {
                //console.log(error);
                dispatch({ type: FETCH_DAMAGES_FAILURE, payload: error.message });
            });
    }
}





// All damage of plateforme of vehicle
export function fetchDamagesAllByUserId(iduser) {
    return async (dispatch) => {
        dispatch({ type: FETCH_DAMAGES_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/damages/get_damages/client/${iduser}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_DAMAGES_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_DAMAGES_SUCCESS_2, payload: response.data.data });
            // saveDataToFile(response.data.data, localStorageData.Damages);
        })
            .catch((error) => {
                //console.log(error);
                dispatch({ type: FETCH_DAMAGES_FAILURE, payload: error.message });
            });
    }
}






// All damage of plateforme of property
export function fetchDamagesAllByPropertyId(idProperty) {
    return async (dispatch) => {
        dispatch({ type: FETCH_DAMAGES_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/damages/get_damages/property/${idProperty}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_DAMAGES_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_DAMAGES_SUCCESS_2, payload: response.data.data });
            saveDataToFile(response.data.data, localStorageData.Damages);
        })
            .catch((error) => {
                //console.log(error);
                dispatch({ type: FETCH_DAMAGES_FAILURE, payload: error.message });
            });
    }
}


// All damage of plateforme of Reservation
export function fetchDamagesAllByReservationId(idReservation) {
    return async (dispatch) => {
        dispatch({ type: FETCH_DAMAGES_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/damages/get_damages/reservation/${idReservation}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_DAMAGES_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_DAMAGES_SUCCESS_2, payload: response.data.data });
            saveDataToFile(response.data.data, localStorageData.Damages);
        })
            .catch((error) => {
                //console.log(error);
                dispatch({ type: FETCH_DAMAGES_FAILURE, payload: error.message });
            });
    }
}








// All damage of plateforme of property
export function fetchDamagesAllByDateRange(startDate, endDate, startTime, endTime) {
    return async (dispatch) => {
        dispatch({ type: FETCH_DAMAGES_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/damages/get_damages/date-range${startDate ? "?startDate=" + startDate : ""}${endDate ? "?endDate=" + endDate : ""}${startTime ? "?startTime=" + startTime : ""}${endTime ? "?endTime=" + endTime : ""}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_DAMAGES_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_DAMAGES_SUCCESS_2, payload: response.data.data });
            saveDataToFile(response.data.data, localStorageData.Damages);
        })
            .catch((error) => {
                //console.log(error);
                dispatch({ type: FETCH_DAMAGES_FAILURE, payload: error.message });
            });
    }
}



// get damage by id 
export function fetchDamageById(idDamage) {
    return async (dispatch) => {
        dispatch({ type: FETCH_DAMAGE_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/damages/get_damage/${idDamage}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            // console.log(response.data.data);
            dispatch({ type: FETCH_DAMAGE_SUCCESS, payload: response.data.data });
        })
            .catch((error) => {
                dispatch({ type: FETCH_DAMAGE_FAILURE, payload: error.message })
                //console.log(error);
            });
    }
}




// Payer un domage
export const DamagePayById = (
    idDamage,
    data,
    toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_DAMAGE_REQUEST });
        await axios
            .post(
                `${baseurl.url}/api/v1/damages/pay/${idDamage}`,
                data,
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                }
            )
            .then((response) => {
                toast.success("Domage Payé avec succès");
                dispatch({ type: FETCH_DAMAGE_SUCCESS });
            })
            .catch((error) => {
                toast.error("Création de cet compte impossible")
                dispatch({ type: FETCH_DAMAGE_FAILURE, payload: error.message });
            });
    };
}
