
import { FETCH_CONVERSATIONS_FAILURE, FETCH_CONVERSATIONS_SEND, FETCH_CONVERSATIONS_SUCCESS, FETCH_CONVERSATIONS_SUCCESS_2, FETCH_CONVERSATIONS_SUCCESS_FILTER, FETCH_CONVERSATIONS_SUCCESS_FILTER_2, FETCH_PARTICIPANTS_SUCCESS, FETCH_PARTICIPANTS_SUCCESS_2, REQUEST_FAILURE_CONVERSATION, REQUEST_SUCCESS_CONVERSATION, SELECT_USER_CONVERSATION, SEND_REQUEST_CONVERSATION } from "../actions/actions";



// userReducer.js
const initialState = {
  conversations: [],
  conversations2: [],
  conversation: {},

  participants: [],
  participants2: [],

  userSelectConversation :{},

  loadingConversation: false,
  loadingConversations: false,
  errorConversations: null | ""
  // ... autres états spécifiques à userReducer
};

const conversationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONVERSATIONS_SEND:
      return {
        ...state,
        loadingConversations: true,
        errorConversations: null,
      };
    case FETCH_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        conversations: action.payload,
        loadingConversations: false,
        errorConversations: null
      };

    case FETCH_CONVERSATIONS_SUCCESS_2:
      return {
        ...state,
        conversations2: action.payload,
        loadingConversations: false,
        errorConversations: null
      };


      case FETCH_CONVERSATIONS_SUCCESS_FILTER:
      return {
        ...state,
        conversations: action.payload,
        loadingConversations: false,
        errorConversations: null
      };


      case FETCH_CONVERSATIONS_SUCCESS_FILTER_2:
      return {
        ...state,
        conversations2: action.payload,
        loadingConversations: false,
        errorConversations: null
      };


    case FETCH_CONVERSATIONS_FAILURE:
      return {
        ...state,
        loadingConversations: false,
        errorConversations: action.payload,
      };
    case SEND_REQUEST_CONVERSATION:
      return {
        ...state,
        loadingConversation: true,
        errorConversation: ""
      };
    case REQUEST_FAILURE_CONVERSATION:
      return {
        ...state,
        loadingConversation: false,
        errorConversation: action.payload
      };
    case REQUEST_SUCCESS_CONVERSATION:
      return {
        ...state,
        conversation: action.payload,
        loadingConversation: false,
        errorConversation: false
      };


      // participants
      case FETCH_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        participants: action.payload,
        loadingConversations: false,
        errorConversations: null
      };

    case FETCH_PARTICIPANTS_SUCCESS_2:
      return {
        ...state,
        participants2: action.payload,
        loadingConversations: false,
        errorConversations: null
      };

      case SELECT_USER_CONVERSATION:
      return {
        ...state,
        userSelectConversation: action.payload,
      };

    // ... handle other actions
    default:
      return state;
  }
};

export default conversationReducer;