import axios from "axios";
import { FETCH_CODE_PROMOS_FAILURE, FETCH_CODE_PROMOS_REQUEST, FETCH_CODE_PROMOS_SUCCESS, FETCH_CODE_PROMOS_SUCCESS_2, FETCH_CODE_PROMO_FAILURE, FETCH_CODE_PROMO_REQUEST, FETCH_CODE_PROMO_SUCCESS } from "../../app/actions/actions";
import { baseurl } from "../../utils/url/baseurl";
import { getAndCheckLocalStorage } from "../../utils/storage/localvalueFuction";
import { localStorageKeys } from "../../utils/storage/localvalue";
import { profileRoleType } from "../../utils/dataApi/dataFormApi";




// Récupérer tous les codes promo
export function fetchCodePromosAll() {
    return async (dispatch) => {
        dispatch({ type: FETCH_CODE_PROMOS_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/codepromos/get_codepromos`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_CODE_PROMOS_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_CODE_PROMOS_SUCCESS_2, payload: response.data.data });
        }).catch((error) => {
            //console.log(error);
            dispatch({ type: FETCH_CODE_PROMOS_FAILURE, payload: error.message });
        });
    }
}




// Récupérer tous les codes promo d'un utilisateur
export function fetchCodePromosOfIdUserAll(idUser) {
    return async (dispatch) => {
        dispatch({ type: FETCH_CODE_PROMOS_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/codepromos/get_codepromos/by/${idUser}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_CODE_PROMOS_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_CODE_PROMOS_SUCCESS_2, payload: response.data.data });
        }).catch((error) => {
            //console.log(error);
            dispatch({ type: FETCH_CODE_PROMOS_FAILURE, payload: error.message });
        });
    }
}



// Récupérer un code promo par ID
export function fetchCodePromoById(idCodePromo) {
    return async (dispatch) => {
        dispatch({ type: FETCH_CODE_PROMO_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/codepromos/get_codepromo/${idCodePromo}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_CODE_PROMO_SUCCESS, payload: response.data.data });
        })
            .catch((error) => {
                dispatch({ type: FETCH_CODE_PROMO_FAILURE, payload: error.message })
                //console.log(error);
            });
    }
}




// Mettre à jour un code promo
export function fetchCodePromoByUpdateId(idCodePromo, data, toast) {
    return async (dispatch) => {
        dispatch({ type: FETCH_CODE_PROMO_REQUEST });
        await axios.put(`${baseurl.url}/api/v1/codepromos/update/${idCodePromo}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_CODE_PROMO_SUCCESS, payload: response.data.data });
            toast.success(`${response.data.message}`, { position: "bottom-right" });
            if (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER) {
                dispatch(fetchCodePromosOfIdUserAll(getAndCheckLocalStorage(localStorageKeys.userId)));
            } else {
                fetchCodePromosAll();
            }
        })
            .catch((error) => {
                dispatch({ type: FETCH_CODE_PROMO_FAILURE, payload: error.message })
                toast.error(`${error.response.data.message}`, { position: "bottom-right" });
                //console.log(error);
            });
    }
}



// Créer un nouveau code promo
export function createPromoCode(data, toast) {
    return async (dispatch) => {
        dispatch({ type: FETCH_CODE_PROMO_REQUEST });
        await axios.post(`${baseurl.url}/api/v1/codepromos`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_CODE_PROMO_SUCCESS, payload: response.data.data });
            toast.success(`${response.data.message}`, { position: "bottom-right" });
            if (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER) {
                dispatch(fetchCodePromosOfIdUserAll(getAndCheckLocalStorage(localStorageKeys.userId)));
            } else {
                fetchCodePromosAll();
            }
        }).catch((error) => {
            dispatch({ type: FETCH_CODE_PROMO_FAILURE, payload: error.message });
            toast.error(`${error.response.data.message}`, { position: "bottom-right" });
            //console.log(error);
        });
    }
}
