import React, { useState, useEffect } from 'react';
import { Form, Button, Spinner, Card, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Select from "react-select";
import { fetchCodePostalsAll } from '../../actions/request/CodePostalRequest';
import { UserCreate } from '../../actions/request/UserRequest';
import { logoApplication } from '../../utils/dataApi/dataFormApi';
import { CustomerCreate } from '../../actions/request/CustomerRequest';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../config/routingUrl';

function RegistrationPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const codepostals = useSelector((state) => state.codepostals.codepostals);
    const loadingUser = useSelector((state) => state.users.loadingUser);

    const [isShowPassword, setisShowPassword] = useState(false)

    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        codePostal: '',
        gender: 'Male',
        dateOfBirth: '',
        password: '',
        confirmPassword: '',
        profileType: 'Client',
        role: 'INDIVIDUAL',
        companyName: '',
        contactName: '',
        companyAddress: '',
        numberTVA: '',
        licenceCommerciale: null,
        isPrestataire: false,
    });

    useEffect(() => {
        dispatch(fetchCodePostalsAll());
    }, [dispatch]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSelectChange = (selectedOption, { name }) => {
        setFormData({
            ...formData,
            [name]: selectedOption ? selectedOption.value : '',
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.email || !formData.password || !formData.phone || !formData.codePostal || !formData.confirmPassword) {
            toast.error("Veuillez remplir tous les champs obligatoires", { position: "bottom-right" });
            return;
        }

        if (formData.password !== formData.confirmPassword) {
            toast.error("Les mots de passe ne correspondent pas", { position: "bottom-right" });
            return;
        }

        // Détermine si l'utilisateur est un client ou un prestataire
        try {
            if (formData.isPrestataire) {
                formData.profileType = "Prestataire";
                if (!formData.role) {
                    toast.error("Choix du type de profile obligatoire", { position: "bottom-right" })
                } else {
                    dispatch(UserCreate(formData, navigate, toast));
                }
            } else {
                formData.profileType = "Client";
                dispatch(CustomerCreate(formData, navigate, toast));
            }
        } catch (error) {
            console.error(error);
        }
    };

    const codePostalOptions = codepostals.map((code) => ({
        value: code._id,
        label: `+${code.indicatif} - (${code.country})`,
    }));

    const genderOptions = [
        { value: 'Male', label: 'Homme' },
        { value: 'Female', label: 'Femme' },
    ];

    const roleOptions = [
        { value: 'INDIVIDUAL', label: 'Particulier' },
        { value: 'COMPANY', label: 'Entreprise' },
    ];

    return (
        <div className="page-content bg-white">
            <section className="content-inner bg-white text-white py-5">
                <div className="">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-8 col-md-10">
                            <div className="login-form bg-white p-4 rounded shadow-sm">
                                <div className="text-center mb-4">
                                    <img src={logoApplication} alt="Inscription" style={{ width: '50px', height: '50px' }} />
                                    <h4>Inscription</h4>
                                    <p>Veuillez créer un compte pour accéder à nos services</p>
                                </div>

                                <Card className="p-5">
                                    <Form onSubmit={handleSubmit}>
                                        {loadingUser ? (
                                            <div className="text-center">
                                                <Spinner animation="border" variant="primary" />
                                                <p>Chargement en cours...</p>
                                            </div>
                                        ) : (
                                            <>
                                                <div className=" row">
                                                    {[
                                                        { label: 'Email*:', name: 'email', type: 'email' },
                                                        { label: 'Téléphone*:', name: 'phone', type: 'number' },
                                                        { label: 'Mot de passe*:', name: 'password', type: isShowPassword ? "text" : "password" },
                                                        { label: 'Confirmer le mot de passe*:', name: 'confirmPassword', type: isShowPassword ? "text" : "password" },
                                                    ].map((field, index) => (
                                                        <div key={index} className="col-md-12 form-group">
                                                            <label className="text-dark">{field.label}</label>
                                                            <input
                                                                style={{ height: "40px" }}
                                                                type={field.type}
                                                                className="form-control"
                                                                name={field.name}
                                                                value={formData[field.name]}
                                                                onChange={handleChange}
                                                                required={field.label.includes('*')}
                                                            />
                                                        </div>
                                                    ))}

                                                    <div className="col-md-12 form-group">
                                                        <label className="text-dark">Indicatif*:</label>
                                                        <Select className="text-dark"
                                                            name="codePostal"
                                                            isClearable
                                                            options={codePostalOptions}
                                                            onChange={handleSelectChange}
                                                            placeholder="Sélectionner un code postal"
                                                        />
                                                    </div>

                                                    {/*<div className="col-md-12 form-group">
                                                        <label className="text-dark">Genre:</label>
                                                        <Select className="text-dark"
                                                            name="gender"
                                                            isClearable
                                                            options={genderOptions}
                                                            onChange={handleSelectChange}
                                                            placeholder="Sélectionner un genre"
                                                        />
                                                    </div> */}

                                                    <div className="col-md-12 form-group">
                                                        <Form.Check
                                                            type="checkbox"
                                                            id="prestataireCheck"
                                                            label="Je souhaite devenir Prestataire"
                                                            name="isPrestataire"
                                                            checked={formData.isPrestataire}
                                                            onChange={handleChange}
                                                            className="text-dark"
                                                        />
                                                    </div>

                                                    {formData.isPrestataire && (
                                                        <div className="col-md-12 form-group">
                                                            <label className="text-dark">Type de profile</label>
                                                            <Select className="text-dark"
                                                                name="role"
                                                                isClearable
                                                                options={roleOptions}
                                                                onChange={handleSelectChange}
                                                                placeholder="Sélectionner un rôle"
                                                            />
                                                        </div>
                                                    )}


                                                    <div className="col-md-12 form-group">
                                                        <Form.Check
                                                            type="checkbox"
                                                            id="prestataireCheck"
                                                            label="Voire mot de passe"
                                                            name="isPrestataire"
                                                            checked={isShowPassword}
                                                            onChange={(e) => { setisShowPassword(e.target.checked) }}
                                                            className="text-dark"
                                                        />
                                                    </div>
                                                </div>
                                                <hr className="my-5" />
                                                <div className="text-center">
                                                    <Button variant="primary" size="sm" type="submit" className="w-100 justify-content-center" >
                                                        <div className='text-center'>
                                                            S{"'"}inscrire
                                                        </div>
                                                    </Button>
                                                </div>
                                                <div className="text-center mt-4">
                                                    <span variant="link" size="sm" onClick={() => navigate('/login')} className="text-dark">
                                                        Vous avez déjà un compte ? <Link to={`/${ROUTES.LOGIN}`}>Connectez-vous ici</Link>
                                                    </span>
                                                </div>
                                            </>
                                        )}
                                    </Form>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default RegistrationPage;