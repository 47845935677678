import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const VehicleTypeListPage = () => {
  // Jeu de données initial pour les types de véhicules
  const initialVehicleTypes = [
    {
      name: 'Bus',
      image: 'https://via.placeholder.com/150?text=Bus'
    },
    {
      name: 'Minicar',
      image: 'https://via.placeholder.com/150?text=Minicar'
    },
    {
      name: 'Camion',
      image: 'https://via.placeholder.com/150?text=Camion'
    }
  ];

  const [vehicleTypes, setVehicleTypes] = useState(initialVehicleTypes);
  const [newVehicleType, setNewVehicleType] = useState({
    name: '',
    image: ''
  });
  const [showModal, setShowModal] = useState(false);

  // Gestion de l'affichage de la modal
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  // Gestion de l'ajout d'un nouveau type de véhicule
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewVehicleType({
      ...newVehicleType,
      [name]: value
    });
  };

  const addVehicleType = (e) => {
    e.preventDefault();
    setVehicleTypes([...vehicleTypes, newVehicleType]);
    setNewVehicleType({
      name: '',
      image: ''
    });
    handleClose(); // Fermer la modal après l'ajout
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Liste des Types de Véhicules</h2>
      
      <Button variant="primary" onClick={handleShow}>
        Ajouter un Type de Véhicule
      </Button>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un Type de Véhicule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={addVehicleType}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">Nom du Type de Véhicule</label>
              <input 
                type="text" 
                className="form-control" 
                id="name" 
                name="name" 
                value={newVehicleType.name}
                onChange={handleInputChange}
                required 
              />
            </div>

            <div className="mb-3">
              <label htmlFor="image" className="form-label">URL de l'Image</label>
              <input 
                type="url" 
                className="form-control" 
                id="image" 
                name="image" 
                value={newVehicleType.image}
                onChange={handleInputChange}
                required 
              />
            </div>

            <Button type="submit" className="btn btn-sm btn-primary">
              Ajouter le Type de Véhicule
            </Button>
          </form>
        </Modal.Body>
      </Modal>

      <h3 className="mt-5">Liste des Types de Véhicules</h3>
      <div className="row">
        {vehicleTypes.map((vehicleType, index) => (
          <div key={index} className="col-md-4 mb-4">
            <div className="card">
              <img src={vehicleType.image} className="card-img-top" alt={vehicleType.name} />
              <div className="card-body">
                <h5 className="card-title">{vehicleType.name}</h5>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VehicleTypeListPage;
