import { FETCH_RESERVATION_TYPES_FAILURE, FETCH_RESERVATION_TYPES_REQUEST, FETCH_RESERVATION_TYPES_SUCCESS, FETCH_RESERVATION_TYPES_SUCCESS_2, FETCH_RESERVATION_TYPE_FAILURE, FETCH_RESERVATION_TYPE_REQUEST, FETCH_RESERVATION_TYPE_SUCCESS } from "../actions/actions";



// reservationTypeReducer.js
const initialState = {
    reservationTypes: [],
    reservationTypes2: [],
    reservationType: {},
    reservationTypeSelect: {},
    loadingReservationType:false,
    loadingReservationTypeSelect:false,
    loadingReservationTypes:false,
    errorReservationTypes:null,
    errorReservationType:null
    // ... autres états spécifiques à reservationTypeReducer
  };
  
  const reservationTypeReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_RESERVATION_TYPE_REQUEST:
        // Dispatch une action pour définir loading sur true dans le globalReducer
        return {
          ...state,
          loadingReservationType:true,
          errorReservationTypes:""
          // Autres manipulations d'état pour la demande de récupération des reservationTypes
        };
        case FETCH_RESERVATION_TYPE_SUCCESS:
        return {
          ...state,
          loadingReservationType:false,
          errorReservationTypes:"",
          reservationType:action.payload
        };
        case FETCH_RESERVATION_TYPE_FAILURE:
        return {
          ...state,
          loadingReservationType:false,
          errorReservationTypes:action.payload
        };


      case FETCH_RESERVATION_TYPES_REQUEST:
        return {
          ...state,
          loadingReservationTypes:true,
          errorReservationTypes:""
        };
       
      case FETCH_RESERVATION_TYPES_SUCCESS:
        return {
          ...state,
          reservationTypes: action.payload,
          loadingReservationTypes:false,
          errorReservationTypes:""
          // Autres manipulations d'état pour le succès de la récupération des reservationTypes
        };
        case FETCH_RESERVATION_TYPES_SUCCESS_2:
        return {
          ...state,
          reservationTypes2: action.payload,
          loadingReservationTypes:false,
          errorReservationTypes:""
          // Autres manipulations d'état pour le succès de la récupération des reservationTypes
        };
        
      case FETCH_RESERVATION_TYPES_FAILURE:
        // Dispatch une action pour définir loading sur false dans le globalReducer et enregistrer l'erreur
        return {
          ...state,
          loadingReservationTypes:false,
          errorReservationTypes:action.payload
          // Autres manipulations d'état pour l'échec de la récupération des reservationTypes
        };
      // ... autres cas pour d'autres actions liées aux reservationTypes

        
      default:
        return state;
    }
  };
  
  export default reservationTypeReducer;

  

