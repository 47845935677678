import { FETCH_CURRENCYS_FAILURE, FETCH_CURRENCYS_REQUEST, FETCH_CURRENCYS_SUCCESS, FETCH_CURRENCYS_SUCCESS_2, FETCH_CURRENCY_FAILURE, FETCH_CURRENCY_REQUEST, FETCH_CURRENCY_SUCCESS } from "../actions/actions";



// currencyReducer.js
const initialState = {
    currencys: [],
    currencys2: [],
    currency: {},
    currencySelect: {},
    loadingCurrency:false,
    loadingCurrencySelect:false,
    loadingCurrencys:false,
    errorCurrencys:null,
    errorCurrency:null
    // ... autres états spécifiques à currencyReducer
  };
  
  const currencyReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_CURRENCY_REQUEST:
        // Dispatch une action pour définir loading sur true dans le globalReducer
        return {
          ...state,
          loadingCurrency:true,
          errorCurrencys:""
          // Autres manipulations d'état pour la demande de récupération des currencys
        };
        case FETCH_CURRENCY_SUCCESS:
        return {
          ...state,
          loadingCurrency:false,
          errorCurrencys:"",
          currency:action.payload
        };
        case FETCH_CURRENCY_FAILURE:
        return {
          ...state,
          loadingCurrency:false,
          errorCurrencys:action.payload
        };


      case FETCH_CURRENCYS_REQUEST:
        return {
          ...state,
          loadingCurrencys:true,
          errorCurrencys:""
        };
       
      case FETCH_CURRENCYS_SUCCESS:
        return {
          ...state,
          currencys: action.payload,
          loadingCurrencys:false,
          errorCurrencys:""
          // Autres manipulations d'état pour le succès de la récupération des currencys
        };
        case FETCH_CURRENCYS_SUCCESS_2:
        return {
          ...state,
          currencys2: action.payload,
          loadingCurrencys:false,
          errorCurrencys:""
          // Autres manipulations d'état pour le succès de la récupération des currencys
        };
        
      case FETCH_CURRENCYS_FAILURE:
        // Dispatch une action pour définir loading sur false dans le globalReducer et enregistrer l'erreur
        return {
          ...state,
          loadingCurrencys:false,
          errorCurrencys:action.payload
          // Autres manipulations d'état pour l'échec de la récupération des currencys
        };
      // ... autres cas pour d'autres actions liées aux currencys

        
      default:
        return state;
    }
  };
  
  export default currencyReducer;
  