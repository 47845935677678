import React, { useState, useEffect } from 'react';
import { Button, Card, Row, Col, Modal, Form } from 'react-bootstrap';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { MdErrorOutline, MdSearch, MdTimer } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVehiclesAllResearch } from '../../actions/request/VehicleRequest';
import { useLocation, useNavigate } from 'react-router-dom';
import { emptyImage } from '../../utils/dataApi/dataFormApi';
import moment from 'moment';
import { ROUTES } from '../../config/routingUrl';
import { FETCH_PROPERTYS_SUCCESS, FETCH_PROPERTY_SUCCESS, FETCH_VEHICLES_SUCCESS, FETCH_VEHICLE_SUCCESS } from '../../app/actions/actions';
import { fetchPropertysAllResearch } from '../../actions/request/PropertyRequest';

const VehicleSearchResultsPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const vehicles = useSelector((state) => state.vehicles.vehicles);
    const propertys = useSelector((state) => state.propertys.propertys);

    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 8;
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(0);
    const [showProperties, setShowProperties] = useState(false);

    const location = useLocation();
    const { agenceDepart, startDateTime, endDateTime, patrimoine } = location.state;

    useEffect(() => {
        setLoading(true);


        if (patrimoine == "Vehicle") {
            setShowProperties(false)
            dispatch({ type: FETCH_PROPERTYS_SUCCESS, payload: [] });
            dispatch(fetchVehiclesAllResearch(startDateTime, endDateTime, "", agenceDepart));
            setLoading(false);
        } else {
            setShowProperties(true);
            dispatch({ type: FETCH_VEHICLES_SUCCESS, payload: [] });
            dispatch(fetchPropertysAllResearch(startDateTime, endDateTime, "", agenceDepart));
            setLoading(false);
        }

    }, [dispatch, startDateTime, endDateTime]);

    

    useEffect(() => {
        const maxPriceValue = Math.max(...vehicles.map(v => v.basePricePerDay), 0);
        setMaxPrice(maxPriceValue);
    }, [vehicles]);

    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const filteredVehicles = vehicles.filter(item => {
        const price = item.basePricePerDay || 0;
        return price >= minPrice && price <= maxPrice;
    });
    const filteredProperties = propertys.filter(item => {
        const price = item.price || 0; // Assurez-vous que `price` est défini
        return price >= minPrice && price <= maxPrice;
    });

    const currentItems = showProperties ? filteredProperties : filteredVehicles;
    const itemsToDisplay = currentItems.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(currentItems.length / itemsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <>
            {/* Modal de chargement transparent */}
            <Modal show={loading} centered backdrop="static" keyboard={false}>
                <Modal.Body className="text-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Chargement...</span>
                    </div>
                    <p className="mt-3">Chargement en cours...</p>
                </Modal.Body>
            </Modal>

            <div className="container-fluid mt-5">
                {/* Section d'informations supplémentaires */}
                <div className="py-3 mb-4">
                    <Card className="p-3">
                        <h5>Informations de la recherche {patrimoine}</h5>
                        <Row>
                            {agenceDepart && (
                                <Col md={4}>
                                    <strong>Localisation:</strong> {agenceDepart}
                                </Col>
                            )}
                            {startDateTime && (
                                <Col md={4}>
                                    <span><MdTimer /> </span> <strong>Date de début:</strong> {moment(startDateTime).format('DD MMM YYYY HH:mm')}
                                </Col>
                            )}
                            {endDateTime && (
                                <Col md={4}>
                                    <span><MdTimer /> </span> <strong>Date de fin:</strong> {moment(endDateTime).format('DD MMM YYYY HH:mm')}
                                </Col>
                            )}
                        </Row>
                        {/* Filtre pour les prix et choix d'affichage */}
                        <div class="my-3">
                            <Row>
                                <Col md={5}>
                                    <label>Prix Min</label>
                                    <input
                                        style={{ height: "40px" }}
                                        type="number"
                                        value={minPrice}
                                        onChange={(e) => {
                                            const value = Number.parseInt(e.target.value);
                                            if (value >= 0) { // Vérifie si le nombre est positif ou nul
                                                setMinPrice(value);
                                            }
                                        }}
                                        placeholder=""
                                        className="form-control"
                                    />
                                </Col>
                                <Col md={5}>
                                    <label>Prix Max</label>
                                    <input
                                        style={{ height: "40px" }}
                                        type="number"
                                        value={maxPrice}
                                        onChange={(e) => {
                                            const value = Number.parseInt(e.target.value);
                                            if (value >= 0) { // Vérifie si le nombre est positif ou nul
                                                setMaxPrice(value);
                                            }
                                        }}
                                        placeholder=""
                                        className="form-control"
                                    />
                                </Col>

                            </Row>
                        </div>
                        <Row className="mt-3">
                            <Form className="mb-4">
                                {/* Sélecteur de type (Véhicule ou Propriété) */}
                                {/*<Form.Group>
                                    <Form.Label>Type d{"'"}élements</Form.Label>
                                    <div>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Véhicules"
                                            checked={!showProperties}
                                            onChange={() => setShowProperties(false)}
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Propriétés"
                                            checked={showProperties}
                                            onChange={() => setShowProperties(true)}
                                        />
                                    </div>
                                </Form.Group> */}
                            </Form>
                        </Row>

                    </Card>
                </div>

                <div className="py-3">
                    <h3 className="text-center mb-4">
                        {currentItems.length > 0 ? `${currentItems.length} ELEMENTS TROUVÉS` : 'AUCUN RÉSULTAT'}
                    </h3>
                </div>

                {currentItems.length === 0 ? (
                    <div className="text-center justify-content-center align-content-center" style={{ height: '400px' }}>
                        <MdErrorOutline size={50} color="grey" />
                        <p>Aucun élément trouvé pour votre recherche. Veuillez essayer avec d{"'"}autres critères.</p>
                    </div>
                ) : (
                    <>
                        <Row>
                            {itemsToDisplay.map((item, index) => (
                                <Col md={4} key={item._id} className="mb-4">
                                    <Card className="h-100 shadow-sm">
                                        {item && item.images && item.images.length > 0 ? (
                                            <Card.Img variant="top" className="img-fluid" style={{ height: "200px", width: "100%", objectFit: "contain" }} src={item.images[0]} alt={item.name || item.brand} />
                                        ) : (
                                            <Card.Img variant="top" className="img-fluid" style={{ height: "200px", width: "100%", objectFit: "contain" }} src={emptyImage} alt={item.name || item.brand} />
                                        )}
                                        <Card.Body>
                                            <Card.Title className="text-start">
                                                <h6 class="line-clamp-2 text-start text-truncate">{!showProperties ? `${item.brand} ${item.model}` : item.name}</h6>
                                            </Card.Title>
                                            <Card.Text className="text-truncate">
                                                {!showProperties ? (
                                                    <>
                                                        <strong>Couleur : </strong>{item.color}<br />
                                                        <strong>Prix : </strong>{item.basePricePerDay} DZD<br />
                                                    </>
                                                ) : (
                                                    <div className="text-truncate">
                                                        <strong>Adresse : </strong><span class="text-truncate">{item.address}</span><br />
                                                        <strong>Prix : </strong>{item.basePricePerDay} DZD<br />
                                                    </div>
                                                )}
                                            </Card.Text>
                                            <Button
                                                variant="primary"
                                                size="sm"
                                                className="btn w-100 justify-content-center"
                                                onClick={() => {
                                                    if (!showProperties) {
                                                        dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: item });
                                                        dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: {} });
                                                        navigate(`/${ROUTES.CAR_DETAIL_VIEW}/${item._id}/name/${item.brand}/description/${item.brand}`);
                                                    } else {
                                                        dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: {} });
                                                        dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: item });
                                                        navigate(`/${ROUTES.CAR_DETAIL_VIEW}/${item._id}/name/${item.name}/description/${item.name}`);
                                                    }
                                                }}
                                            >
                                                <div>{!showProperties ? "Réserver" : "Réserver"}</div>
                                            </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>

                        {/* Pagination */}
                        <div className="d-flex justify-content-center align-items-center mt-4">
                            <Button variant="primary" size="sm" onClick={handlePreviousPage} disabled={currentPage === 1}>
                                <FaArrowLeft />
                            </Button>
                            <span className="mx-3">{currentPage} / {totalPages}</span>
                            <Button variant="primary" size="sm" onClick={handleNextPage} disabled={currentPage === totalPages}>
                                <FaArrowRight />
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default VehicleSearchResultsPage;
