import { FETCH_INSURANCES_FAILURE, FETCH_INSURANCES_REQUEST, FETCH_INSURANCES_SUCCESS, FETCH_INSURANCES_SUCCESS_2, FETCH_INSURANCE_FAILURE, FETCH_INSURANCE_REQUEST, FETCH_INSURANCE_SUCCESS } from "../actions/actions";



// insuranceReducer.js
const initialState = {
    insurances: [],
    insurances2: [],
    insurance: {},
    insuranceSelect: {},
    loadingInsurance:false,
    loadingInsuranceSelect:false,
    loadingInsurances:false,
    errorInsurances:null,
    errorInsurance:null
    // ... autres états spécifiques à insuranceReducer
  };
  
  const insuranceReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_INSURANCE_REQUEST:
        // Dispatch une action pour définir loading sur true dans le globalReducer
        return {
          ...state,
          loadingInsurance:true,
          errorInsurance:""
          // Autres manipulations d'état pour la demande de récupération des insurances
        };
        case FETCH_INSURANCE_SUCCESS:
        return {
          ...state,
          loadingInsurance:false,
          errorInsurance:"",
          insurance:action.payload
        };
        case FETCH_INSURANCE_FAILURE:
        return {
          ...state,
          loadingInsurance:false,
          errorInsurance:action.payload
        };


      case FETCH_INSURANCES_REQUEST:
        return {
          ...state,
          loadingInsurances:true,
          errorInsurances:""
        };
       
      case FETCH_INSURANCES_SUCCESS:
        return {
          ...state,
          insurances: action.payload,
          loadingInsurances:false,
          errorInsurances:""
          // Autres manipulations d'état pour le succès de la récupération des insurances
        };
        case FETCH_INSURANCES_SUCCESS_2:
        return {
          ...state,
          insurances2: action.payload,
          loadingInsurances:false,
          errorInsurances:""
          // Autres manipulations d'état pour le succès de la récupération des insurances
        };
        
      case FETCH_INSURANCES_FAILURE:
        // Dispatch une action pour définir loading sur false dans le globalReducer et enregistrer l'erreur
        return {
          ...state,
          loadingInsurances:false,
          errorInsurances:action.payload
          // Autres manipulations d'état pour l'échec de la récupération des insurances
        };
      // ... autres cas pour d'autres actions liées aux insurances

        
      default:
        return state;
    }
  };
  
  export default insuranceReducer;
  