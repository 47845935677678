import { Container, Row, Col, Card, Badge, Button } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import { MdDirectionsCar, MdColorLens, MdAvTimer, MdPerson, MdOutlineMoney, MdModeOfTravel } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_PROPERTY_SUCCESS, FETCH_VEHICLE_SUCCESS } from '../../../app/actions/actions';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../../config/routingUrl';
import { RiEye2Fill } from 'react-icons/ri';
import { emptyImage } from '../../../utils/dataApi/dataFormApi';

const VehicleDetails = ({ vehicle }) => {

  const navigate = useNavigate();

  const paramVehcile = useParams();

  const dispatch = useDispatch();
  // const vehicles = useSelector((state) => state.vehicles.vehicles)

  // useEffect(() => {
  //     console.log(paramVehcile?.id);
  //     const objetTrouve = vehicles && [...vehicles].length > 0 ? [...vehicles].find(objet => objet._id === paramVehcile?.id) : vehicle ;
  //     dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: objetTrouve });
  // }, [paramVehcile]);


  const images = vehicle && vehicle.images && vehicle.images.length > 0 ? vehicle.images.map((image) => ({
    original: image,
    thumbnail: image
  })) : [{
    original: emptyImage,
    thumbnail: emptyImage
  }];

  return (
    <Container className="my-5">

      <Row>
        <Col md={6}>
          <Card className="shadow-sm" style={{ height: '100%', }}>
            <Card.Body>
              {/* Galerie d'images */}
              <ImageGallery
                items={images}
                showThumbnails={true}
                showFullscreenButton={false}
                showPlayButton={false}
                renderItem={(item) => (
                  <div style={{
                    height: '300px', // Hauteur fixe
                    width: '100%',
                    overflow: 'hidden'
                  }}>
                    <img
                      src={item.original}
                      alt=""
                      style={{
                        height: '100%',
                        width: '100%',
                        objectFit: 'contain'
                      }}
                    />
                  </div>
                )}

              />
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="shadow-sm">
            <Card.Body>
              <h2 className="mb-3"><MdDirectionsCar /> {vehicle.brand} </h2>
              <hr />
              <p><MdModeOfTravel /> <strong>Modèle :</strong> {vehicle.model}</p>
              <p><MdModeOfTravel /> <strong>Type Voiture :</strong> {vehicle?.type?.name}</p>
              <p><MdAvTimer /> <strong>Année:</strong> {vehicle.year}</p>
              <p><MdColorLens /> <strong>Couleur:</strong> {vehicle.color}</p>
              <p><MdAvTimer /> <strong>Kilométrage:</strong> {vehicle.mileage} km</p>

              {/* Section de prix */}
              {/* <div className="price-section my-3">
                <Badge pill variant="success" className="mb-3">Disponible</Badge>
              </div> */}
              <hr />
              <ul class="list-group">
                <li class="list-group-item" style={{ display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between" }}>
                  <div> <MdPerson /> Propriétaire </div>
                  <div>
                    <Button variant="primary" size="sm"
                      onClick={() => { navigate(`/${ROUTES.CAR_DETAIL_USER_PATRIMOINE_LIST}/${vehicle.addBy || ""}/person-or-company/${vehicle.brand || ""}`) }}
                    >
                      <RiEye2Fill /> Détail
                    </Button>
                  </div>
                </li>
              </ul>
              <hr />

              {/* Section de prix */}
              <div className="price-section my-3">
                <h4><MdOutlineMoney /> Prix: {vehicle.basePricePerDay} DZD</h4>

              </div>

              <Button variant="primary" className="mt-4 "
                onClick={() => {
                  dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: vehicle });
                  dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: {} });
                  navigate(`/${ROUTES.RESERVATION_FORM_PAGE_WEB}`, { state: vehicle })
                }}
              >Reserver</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default VehicleDetails;
