import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Row, Col, Carousel, Dropdown, Container, Badge, Card, Pagination, Alert } from 'react-bootstrap';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../config/routingUrl';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVehiclesAll, fetchVehiclesAllByOwnerId, fetchVehiclesAllFilter } from '../../../actions/request/VehicleRequest';
import { fetchVehicleTypesAll } from '../../../actions/request/VehicleTypeRequest';
import { RiEye2Fill, RiEyeCloseFill } from 'react-icons/ri';
import { getAndCheckLocalStorage } from '../../../utils/storage/localvalueFuction';
import { localStorageKeys } from '../../../utils/storage/localvalue';
import { emptyImage, profileRoleType } from '../../../utils/dataApi/dataFormApi';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { exportToExcelData, exportToPDFData } from '../../../utils/functionData/ExportFileDocument';

const renderStatusBadge = (status) => {
    switch (status) {
        case 'UNAVAILABLE':
            return <Badge bg="danger">Indisponible</Badge>;
        case 'AVAILABLE':
            return <Badge bg="success">Disponible</Badge>;
        case 'IN_REPAIR':
            return <Badge bg="danger">Maintenance</Badge>;
        default:
            return <Badge bg="secondary">{status}</Badge>;
    }
};

const VehicleManagementPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const vehicles = useSelector((state) => state.vehicles.vehicles);
    const vehicleTypesList = useSelector((state) => state.vehicleTypes.vehicleTypes);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [typeOptions, setTypeOptions] = useState([]);
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [filterStatus, setFilterStatus] = useState('Tous');
    const [filterTransmission, setFilterTransmission] = useState('Tous');
    const [filterType, setFilterType] = useState('Tous');
    const [filterColor, setFilterColor] = useState('Tous');
    const [searchTerm, setSearchTerm] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Number of items per page

    useEffect(() => {
        dispatch(fetchVehicleTypesAll());
        if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.USER) {
            dispatch(fetchVehiclesAllFilter("", "", "", getAndCheckLocalStorage(localStorageKeys.userId)));
        } else if (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.CUSTOMER) {
        } else {
            dispatch(fetchVehiclesAllFilter());
        }
    }, [dispatch]);

    const handleShowDetail = (vehicle) => {
        setSelectedVehicle(vehicle);
        setShowDetailModal(true);
    };

    const handleCloseDetail = () => setShowDetailModal(false);

    const handleStatusChange = (selectedOption) => {
        setFilterStatus(selectedOption ? selectedOption.value : 'Tous');
    };

    const handleTransmissionChange = (selectedOption) => {
        setFilterTransmission(selectedOption ? selectedOption.value : 'Tous');
    };

    const handleTypeChange = (selectedOption) => {
        setFilterType(selectedOption ? selectedOption.value : 'Tous');
    };

    const handleColorChange = (selectedOption) => {
        setFilterColor(selectedOption ? selectedOption.value : 'Tous');
    };

    const handleActionChange = (car, action) => {
        if (action === 'Aperçu') {
            navigate(`/${ROUTES.DASHBOARD}/${ROUTES.CARS_DETAIL}/${car._id}`);
        } else if (action === 'Modifier') {
            navigate(`/${ROUTES.DASHBOARD}/${ROUTES.CARS_EDIT}/${car._id}`);
        }
    };

    useEffect(() => {
        if (vehicleTypesList && vehicleTypesList.length > 0) {
            setTypeOptions(vehicleTypesList.map((type) => ({
                label: type.name,
                value: type._id
            })));
        }
    }, [vehicleTypesList]);

    const filteredVehicles = vehicles && vehicles.length > 0 ? vehicles.filter(vehicle =>
        (filterStatus === 'Tous' || vehicle.status === filterStatus) &&
        (filterTransmission === 'Tous' || vehicle.transmission === filterTransmission) &&
        (filterType === 'Tous' || vehicle.type === filterType) &&
        (filterColor === 'Tous' || vehicle.color === filterColor) &&
        (vehicle.name ? vehicle.name.toLowerCase().includes(searchTerm.toLowerCase()) : false ||
            vehicle.brand ? vehicle.brand.toLowerCase().includes(searchTerm.toLowerCase()) : false)
    ) : [];

    const totalPages = Math.ceil(filteredVehicles.length / itemsPerPage);
    const paginatedVehicles = filteredVehicles.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div style={{ marginBottom: "200px" }}>
            <Card>
                <Card.Header>
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <div><h3 className="mb-4">Gestion des Véhicules</h3></div>
                        <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}>
                            <div className="p-2">
                                <Button
                                    variant="primary"
                                    size='sm'
                                    onClick={() => navigate(`/${ROUTES.DASHBOARD}/${ROUTES.CARS_ADD}`)}
                                >
                                    + Ajouter
                                </Button>
                            </div>

                            {
                                vehicles && vehicles.length > 0 &&
                                <Dropdown  className="p-2">
                                    <Dropdown.Toggle variant="primary" className='btn btn-sm' id="dropdown-basic">
                                        Exporter
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => { exportToExcelData(vehicles, "vehicle", "Listes_des_véhicules") }}>Exporter en Excel</Dropdown.Item>
                                        <Dropdown.Item onClick={() => { exportToPDFData(vehicles, "vehicle", "Listes_des_véhicules") }}>Export en Pdf</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            }

                        </div>
                    </div>
                </Card.Header>

                <Card.Body style={{ minHeight: "400px" }}>
                    <Row className="mb-3">
                        <Col md={3}>
                            <Form.Control
                                type="text"
                                placeholder="Recherche"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                style={{ height: "40px" }}
                            />
                        </Col>
                    </Row>

                    {paginatedVehicles.length > 0 ? (
                        <>
                            <Table striped borderless bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>Image</th>
                                        <th>Désignation</th>
                                        <th>Marque</th>
                                        <th>Modèle</th>
                                        <th>Prix (/Jour)</th>
                                        <th>En ligne</th>
                                        <th>Disponibilité</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedVehicles.map((vehicle) => (
                                        <tr key={vehicle._id}>
                                            <td>
                                                <Carousel style={{ width: '80px' }}>
                                                    {vehicle && vehicle.images && vehicle.images.length > 0 ? vehicle.images.map((img, idx) => (
                                                        <Carousel.Item key={idx}>
                                                            <img
                                                                className="d-block w-100"
                                                                src={img}
                                                                alt={`Slide ${idx}`}
                                                                style={{ width: '80px', height: '80px' }}
                                                            />
                                                        </Carousel.Item>
                                                    )) :
                                                        <Carousel.Item >
                                                            <img src={emptyImage}
                                                                alt="vehicle"
                                                                className="d-block w-100"
                                                                style={{ width: "80px", height: "80px" }}
                                                            />
                                                        </Carousel.Item>
                                                    }
                                                </Carousel>
                                            </td>
                                            <td className="">{vehicle.brand}</td>
                                            <td>{vehicle.vehiclebrand}</td>
                                            <td>{vehicle.model}</td>
                                            <td>{vehicle.basePricePerDay}</td>
                                            <td>
                                                {vehicle && vehicle.isVisible ? (
                                                    <span className="text-success">Oui</span>
                                                ) : (
                                                    <span className="text-danger">Non</span>
                                                )}
                                            </td>
                                            <td>{renderStatusBadge(vehicle.status)}</td>
                                            <td>
                                                <Dropdown>
                                                    <Dropdown.Toggle id="dropdown-basic" size='sm'>
                                                        Actions
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={() => handleActionChange(vehicle, 'Aperçu')}>Détails</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => handleActionChange(vehicle, 'Modifier')}>Modifier</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            {/* Pagination */}
                            <Pagination className="justify-content-start">
                                {[...Array(totalPages).keys()].map(pageNumber => (
                                    <Pagination.Item
                                        key={pageNumber + 1}
                                        active={pageNumber + 1 === currentPage}
                                        onClick={() => handlePageChange(pageNumber + 1)}
                                    >
                                        {pageNumber + 1}
                                    </Pagination.Item>
                                ))}
                            </Pagination>
                        </>
                    ) : (
                        <Alert variant="light" className="text-center">Aucun véhicule</Alert>
                    )}
                </Card.Body>
            </Card>
        </div>
    );
};

export default VehicleManagementPage;
