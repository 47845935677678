import React, { useState } from 'react';
import { Table, Card, Container, Row, Col, Button, Form, Badge } from 'react-bootstrap';
import Select from 'react-select';
import { FaMoneyCheckAlt } from 'react-icons/fa';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPaymentsAll } from '../../../actions/request/PaymentRequest';
import { useEffect } from 'react';
import moment from 'moment';
import { getAndCheckLocalStorage } from '../../../utils/storage/localvalueFuction';
import { localStorageKeys } from '../../../utils/storage/localvalue';
import { profileRoleType } from '../../../utils/dataApi/dataFormApi';
import { MdSearch } from 'react-icons/md';
import { ROUTES } from '../../../config/routingUrl';
import { useNavigate } from 'react-router-dom';
import { RiEye2Fill } from 'react-icons/ri';
import { fetchPropertysFilters } from '../../../actions/request/PropertyRequest';
import { fetchVehiclesAllFilter } from '../../../actions/request/VehicleRequest';




const renderStatusBadge = (status) => {
    switch (status) {
        case 'Completed':
            return <Badge bg="success">Effectué</Badge>;
        case 'Failed':
            return <Badge bg="danger">Échoué</Badge>;
        case 'Pending':
            return <Badge bg="warning">En attente</Badge>;
    }
};



const PaiementsCuctomersPage = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const paymentsData = useSelector((state) => state.payments.payments)
    useEffect(() => {
        dispatch(fetchPaymentsAll())
    }, [dispatch]);

    useEffect(() => {
        if (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER) {
            // console.log(paymentsData)
            const paymentGetData = paymentsData && paymentsData.length > 0 ? [...paymentsData].filter((pay) => pay && pay.reservation && pay.reservation.userAdd && pay.reservation.userAdd == getAndCheckLocalStorage(localStorageKeys.userId)) : []
            setpayments(paymentGetData);
            // console.log(paymentGetData);
            dispatch(fetchPropertysFilters("","","","",getAndCheckLocalStorage(localStorageKeys.userId)));
            dispatch(fetchVehiclesAllFilter("","","","","",getAndCheckLocalStorage(localStorageKeys.userId),"","",""));
        } else {
            const paymentGetData = paymentsData && paymentsData.length > 0 ? [...paymentsData].filter((pay) => pay.reservation && pay.reservation.client && pay.reservation.client._id && pay.reservation.client._id == getAndCheckLocalStorage(localStorageKeys.userId)) : []
            setpayments(paymentGetData)
            // console.log(paymentGetData);
            dispatch(fetchPropertysFilters());
            dispatch(fetchVehiclesAllFilter());
        }
    }, [paymentsData])


    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [payments, setpayments] = useState([]);



    const paiementsInitiaux = [
        { id: 1, nomClient: 'Jean Dupont', montant: 5000, date: '2024-09-01', statut: 'Payé', section: 'A' },
        { id: 2, nomClient: 'Marie Curie', montant: 7500, date: '2024-09-02', statut: 'En attente', section: 'B' },
        { id: 3, nomClient: 'Paul Durant', montant: 3000, date: '2024-09-03', statut: 'Payé', section: 'A' },
        { id: 4, nomClient: 'Alice Martin', montant: 6500, date: '2024-09-04', statut: 'Annulé', section: 'C' },
    ];

    const [paiements, setPaiements] = useState(paiementsInitiaux);
    const [filtreSection, setFiltreSection] = useState(null);
    const [filtreStatut, setFiltreStatut] = useState(null);
    const [dateDebut, setDateDebut] = useState(null);
    const [dateFin, setDateFin] = useState(null);

    // Options des sections pour react-select
    const optionsSections = [
        { value: 'A', label: 'Section A' },
        { value: 'B', label: 'Section B' },
        { value: 'C', label: 'Section C' },
    ];

    // Options du statut
    const optionsStatut = [
        { value: 'Payé', label: 'Payé' },
        { value: 'En attente', label: 'En attente' },
        { value: 'Annulé', label: 'Annulé' },
    ];

    // Fonction pour filtrer les paiements
    const filtrerPaiements = () => {
        let paiementsFiltres = [...paiementsInitiaux];

        // Filtrer par section
        if (filtreSection) {
            paiementsFiltres = paiementsFiltres.filter(p => p.section === filtreSection.value);
        }

        // Filtrer par statut
        if (filtreStatut) {
            paiementsFiltres = paiementsFiltres.filter(p => p.statut === filtreStatut.value);
        }

        // Filtrer par dates
        if (dateDebut && dateFin) {
            paiementsFiltres = paiementsFiltres.filter(p => {
                const datePaiement = new Date(p.date);
                return datePaiement >= dateDebut && datePaiement <= dateFin;
            });
        }

        setPaiements(paiementsFiltres);
    };

    // Réinitialiser les filtres
    const reinitialiserFiltres = () => {
        setFiltreSection(null);
        setFiltreStatut(null);
        setDateDebut(null);
        setDateFin(null);
        setPaiements(paiementsInitiaux);
    };



    const [showLoading, setshowLoading] = useState(false);
    const handleShowLoading = () => { setshowLoading(true) }
    const handleCloseLoading = () => { setshowLoading(false) }



    const handleSearchFilter = () => {
        handleShowLoading();
        if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.USER) {
            // administrateur de la plateforme en question
            setTimeout(() => {
                dispatch(fetchPaymentsAll(startDate, endDate));
                const paymentGetData = paymentsData?.length > 0
                    ? [...paymentsData].filter((pay) => pay.reservation?.userAdd === getAndCheckLocalStorage(localStorageKeys.userId))
                    : [];
                setpayments(paymentGetData);
            }, 2000);
            handleCloseLoading()
        } else {
            dispatch(fetchPaymentsAll(startDate, endDate));
            const paymentGetData = paymentsData?.length > 0
                ? [...paymentsData].filter((pay) => pay.reservation?.client?._id === getAndCheckLocalStorage(localStorageKeys.userId))
                : [];
            setpayments(paymentGetData);
            handleCloseLoading()
        }
        handleCloseLoading();
    }




    return (
        <div className="">
            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                            <h3 className="d-flex align-items-center">
                                <FaMoneyCheckAlt className="me-2" /> Paiements
                            </h3>
                        </Card.Header>
                        <Card.Body>
                            <Row className="mb-3">

                                <Col md={3}>
                                    <Form.Group>
                                        <Form.Label>Début</Form.Label>
                                        <Form.Control
                                            style={{ height: "40px" }}
                                            type="date"
                                            value={startDate}
                                            onChange={(e) => setStartDate(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group>
                                        <Form.Label>Fin</Form.Label>
                                        <Form.Control
                                            style={{ height: "40px" }}
                                            type="date"
                                            value={endDate}
                                            onChange={(e) => setEndDate(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={2} className="d-flex align-items-end">
                                    <Button onClick={handleSearchFilter} variant="primary" size="sm">
                                        <MdSearch />
                                    </Button>
                                </Col>
                            </Row>



                            <div style={{ minHeight: "500px", justifyContent: "center", }}>

                                {
                                    payments && payments.length > 0 ?

                                        <Table striped bordered hover responsive >
                                            <thead>
                                                <tr>
                                                    <th>Reservation</th>
                                                    <th>Montant </th>
                                                    <th>Date</th>
                                                    <th>Statut</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {payments && payments.length > 0 && payments.map((paiement) => (
                                                    <tr key={paiement._id}>
                                                        <td>
                                                            {paiement.reservation.ordre || ""}
                                                        </td>
                                                        <td>{Number(paiement.amount).toFixed(2)}</td>
                                                        <td>{moment(paiement.createdAt).format("DD-MM-YYYY")}</td>
                                                        <td>
                                                            {renderStatusBadge(paiement.status)}
                                                        </td>
                                                        <td>
                                                            <Button variant='primary' size="sm" onClick={() => { navigate(`/${ROUTES.DASHBOARD}/${ROUTES.PAYMENTS_DETAIL_PLATEFORM}/${paiement._id}`) }}>
                                                                <RiEye2Fill /> Détails
                                                            </Button>
                                                        </td>

                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>

                                        :
                                        <p>Aucun paiement</p>
                                }
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default PaiementsCuctomersPage;
