import React, { useState } from 'react';
import { Button, Form, Container, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AdminConnexion } from '../../actions/request/AdminRequest';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const AdminLoginPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loadingAdmin = useSelector((state) => state.admins.loadingAdmin);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false); // État pour contrôler la visibilité du mot de passe

    const handleSubmit = (e) => {
        e.preventDefault();
        // Ajoutez ici la logique pour gérer la connexion
        console.log('Email:', email);
        console.log('Mot de passe:', password);
        dispatch(AdminConnexion(email, password, navigate, toast));
    };

    return (
        <Container fluid className="d-flex justify-content-center align-items-center vh-100 bg-primary">
            <div className="login-card">
                <h4 className="text-center mb-4 text-white">Connexion Administrateur</h4>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label className="text-white">Email</Form.Label>
                        <Form.Control
                            style={{ height: "40px" }}
                            type="email"
                            placeholder="Entrez votre email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="login-input"
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Label className="text-white">Mot de passe</Form.Label>

                        <Form.Control
                            style={{ height: "40px" }}
                            type={showPassword ? "text" : "password"} // Change le type selon l'état de la case à cocher
                            placeholder="Entrez votre mot de passe"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className="login-input"
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                        <Form.Check
                            type="checkbox"
                            label="Voir le mot de passe"
                            className="ms-2"
                            onChange={() => setShowPassword(!showPassword)} // Change l'état de visibilité
                        />
                    </Form.Group>

                    <Button variant="light" type="submit" className="w-100 mt-3 text-center justify-content-center">
                        {
                            loadingAdmin ?
                                <div class="justify-content-center"><Spinner /></div> :
                                <div>Se connecter</div>
                        }
                    </Button>
                </Form>
            </div>
        </Container>
    );
};

export default AdminLoginPage;
