import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Button, Image, ListGroup, Modal, Form } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { FaDownload, FaMoneyCheckAlt } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { DamagePayById, fetchDamageById } from '../../../actions/request/DamageRequest';
import html2pdf from 'html2pdf.js'; // Pour télécharger la facture au format PDF
import Select from 'react-select'; // Pour la sélection du moyen de paiement
import { toast } from 'react-toastify';
import { fetchPaymentTypesAll } from '../../../actions/request/PaymentTypeRequest';
import { getAndCheckLocalStorage } from '../../../utils/storage/localvalueFuction';
import { localStorageKeys } from '../../../utils/storage/localvalue';
import { baseurl } from '../../../utils/url/baseurl';
import axios from 'axios';
import { RiEye2Fill } from 'react-icons/ri';
import { ROUTES } from '../../../config/routingUrl';
import { MdImage } from 'react-icons/md';
import { emptyImage } from '../../../utils/dataApi/dataFormApi';

const DamageInvoiceDetailPage = () => {

    const navigate = useNavigate();

    const paramDamage = useParams();
    const dispatch = useDispatch();
    const damage = useSelector((state) => state.damages.damage);
    const paymentTypes = useSelector((state) => state.paymentTypes.paymentTypes);

    const [showPayModal, setShowPayModal] = useState(false);
    const [showPrintModal, setShowPrintModal] = useState(false);
    const [paymentProof, setPaymentProof] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [loading, setLoading] = useState(false);
    const [date, setdate] = useState();

    useEffect(() => {
        // Récupérer les détails du dommage et les types de paiement
        dispatch(fetchDamageById(paramDamage?.id));
        dispatch(fetchPaymentTypesAll());
    }, [dispatch, paramDamage?.id]);

    const paymentOptions = paymentTypes?.length > 0 ? paymentTypes.map((option) => ({
        value: option._id,
        label: option.name,
    })) : [];

    const afterUsePhotos = (damage?.afterUsePhoto || []).map(image => ({
        original: image,
        thumbnail: image
    }));

    const beforeUsePhotos = (damage?.beforeUsePhoto || []).map(image => ({
        original: image,
        thumbnail: image
    }));

    // Gérer le téléchargement de fichiers (PDF) pour la preuve de paiement
    const handleFileUpload = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', baseurl.cloudinaryUploadPreset); // Preset d'upload Cloudinary

        setLoading(true); // Activer l'indicateur de chargement

        try {
            const response = await axios.post(`https://api.cloudinary.com/v1_1/${baseurl.cloudinaryCloudName}/auto/upload`,
                formData);
            const data = response.data;

            if (data.secure_url) {
                toast.success("Fichier téléchargé avec succès", { position: "bottom-right" });
                setPaymentProof(data.secure_url); // Stocker l'URL du fichier
            }
        } catch (error) {
            console.error('Erreur lors du téléchargement du fichier :', error);
            toast.error("Échec du téléchargement du fichier", { position: "bottom-right" });
        } finally {
            setLoading(false); // Désactiver l'indicateur de chargement
        }
    };

    // Fonction de paiement
    const handlePayDamage = () => {

        if (paymentMethod && paymentProof && date) {
            const paymentData = {
                type: paymentMethod?.value,
                date: date,
                user: getAndCheckLocalStorage(localStorageKeys.userId),
                userId: getAndCheckLocalStorage(localStorageKeys.userId),
                vehicle: damage?.reservation?.vehicle?._id || null,
                property: damage?.reservation?.property?._id || null,
                paymentFile: paymentProof,
            };

            dispatch(DamagePayById(paramDamage?.id, paymentData, toast));
            setShowPayModal(false);
        } else {
            toast.error("Veuillez sélectionner un moyen de paiement  et la date . et télécharger la preuve", { position: "bottom-right" });
        }
    };

    // Gérer le téléchargement de la facture au format PDF
    const handleDownloadInvoice = () => {
        if (damage) {
            setShowPrintModal(true);
        }
    };

    const handlePrintInvoice = () => {
        const element = document.getElementById('invoice');
        const clone = element.cloneNode(true); // Cloner l'élément
        clone.style.display = 'block'; // Rendre visible pour l'impression
        html2pdf(clone);
        setShowPrintModal(false);
    };

    const [showModalCardId, setshowModalCardId] = useState(false)
    const [showModalDriverFile, setshowModalDriverFile] = useState(false);

    const imagesDriverFile = (damage?.beforeUsePhoto || []).map(image => ({
        original: image,
        thumbnail: image
    }));

    const imagesidCardFile = (damage?.afterUsePhoto || []).map(image => ({
        original: image,
        thumbnail: image
    }));

    const handleShowModalCardId = () => { setshowModalCardId(true) }
    const handleCloseModalCardId = () => { setshowModalCardId(false) }

    const handleShowModalDriver = () => { setshowModalDriverFile(true) }
    const handleCloseModalDriver = () => { setshowModalDriverFile(false) }

    return (
        <div className="">
            {damage ? (
                <Card className="p-4">
                    <Card.Header className="text-center">
                        <h3 className="mb-0">Facture de dommage</h3>
                        <p className="text-dark">Numéro de réclamation: {damage?.reservation?.ordre || ""}</p>
                    </Card.Header>
                    <Card.Body>
                        <Row className="mb-4">
                            <Col>
                                <h5><strong>Client : </strong>{damage?.reservation?.firstname || ""}
                                    {damage?.reservation?.lastname || ""}</h5>
                                <h5><strong>Montant dû: </strong>{damage?.estimatedCost || ""} DZD</h5>
                            </Col>
                            <Col className="text-right">
                                <p>
                                    <strong> Reservation : </strong>{damage?.reservation?.ordre || ""}

                                    <span>
                                        <Button variant="primary" size="sm" onClick={() => {
                                            navigate(`/${ROUTES.DASHBOARD}/${ROUTES.RESERVATION_VIEW}/${damage.reservation._id || ""}`)
                                        }} >
                                            <RiEye2Fill />
                                        </Button>
                                    </span>
                                </p>
                                <p><strong>Date de l{"'"}incident: </strong>{new Date(damage?.damageDate).toLocaleDateString()}</p>
                                <p><strong>Statut: </strong>{damage?.status || ""}</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <hr />
                            </Col>
                            <Col md={6}>
                                <Card className="p-3">
                                    <h5 className="text-dark">Détails du dommage</h5>

                                    {damage?.relatedObjectType === 'Vehicle' && damage?.reservation?.vehicle ? (
                                        <>
                                            <Image src={damage?.reservation?.vehicle?.images && damage.reservation.vehicle.images.length > 0
                                                ? damage.reservation.vehicle.images[0]
                                                : emptyImage
                                            }
                                                className="img-fluid rounded mb-3 border"
                                                alt={damage?.reservation?.vehicle?.brand || "Image du véhicule ou la "}

                                            />
                                            <p>
                                                <strong>Véhicule: </strong>
                                                {damage?.reservation?.vehicle?.brand || "Inconnu"} {damage?.reservation?.vehicle.model
                                                    || "Modèle inconnu"} ({damage?.reservation?.vehicle?.year || "Année inconnue"})
                                            </p>
                                            <p>
                                                <strong>Couleur: </strong>{damage?.reservation?.vehicle?.color || "Couleur inconnue"}
                                            </p>
                                            <p>
                                                <strong>Numéro d{"'"}immatriculation:
                                                </strong>{damage?.reservation?.vehicle?.registrationNumber || "Numéro non fourni"}
                                            </p>
                                        </>
                                    ) : damage?.relatedObjectType === 'Property' && damage?.reservation?.property ? (
                                        <>
                                            <Image src={damage?.reservation?.property?.images && damage.reservation.property.images.length >
                                                0
                                                ? damage.reservation.property.images[0]
                                                : emptyImage
                                            }
                                                className="img-fluid rounded mb-3 border"
                                                alt={damage?.reservation?.property?.name || "Image de propriété"}
                                                style={{ width: "100px", height: "100px" }}
                                            />
                                            <p>
                                                <strong>Propriété: </strong>{damage?.reservation?.property.name || "Propriété inconnue"}
                                            </p>
                                            <p>
                                                <strong>Adresse: </strong>{damage?.reservation?.property.address || "Adresse non fournie"}
                                            </p>
                                        </>
                                    ) : (
                                        <p>Aucune information disponible sur le véhicule ou la propriété.</p>
                                    )}

                                </Card>
                            </Col>

                            <Col md={6}>
                                <Card className="p-3">
                                    <h5 className="text-dark">Détails de la couverture</h5>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item>
                                            <strong>Type de dommage: </strong>{damage?.type?.name || "Type inconnu"}
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <strong>Commentaire: </strong>{damage?.description || "Pas de commentaire fourni."}
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <strong>Coût estimé: </strong>{damage?.estimatedCost ? `${damage.estimatedCost} DZD` : "Coût non estimé."}
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <strong>Date de l{"'"}incident: </strong>{damage?.damageDate ? new
                                                Date(damage.damageDate).toLocaleDateString() : "Date non fournie."}
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <strong>Statut: </strong>{damage?.status || "Statut inconnu."}
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            Photo avant utilisation:
                                            <Button size="sm" variant="primary" onClick={handleShowModalDriver}>
                                                <RiEye2Fill />
                                            </Button>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            Photo après utilisation:
                                            <Button size="sm" variant="primary" onClick={handleShowModalCardId}>
                                                <RiEye2Fill />
                                            </Button>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Card>
                            </Col>

                            <Col>
                                <hr />
                            </Col>
                            <Col md={12}>
                                <Col>

                                </Col>
                            </Col>
                        </Row>

                        <div className="mt-4 text-center">
                        {
                            damage?.status !=="Paid" &&
                            <Button variant="primary" size="sm" className="m-2" onClick={() => setShowPayModal(true)}>
                                <FaMoneyCheckAlt /> Payer
                            </Button>
                        }
                            
                            <Button variant="primary" size="sm" className="m-2" onClick={handleDownloadInvoice}>
                                <FaDownload /> Télécharger
                            </Button>
                        </div>
                    </Card.Body>
                </Card>
            ) : (
                <p>Dommage non trouvé</p>
            )}

            {/* Modal pour confirmer le paiement */}
            <Modal show={showPayModal} onHide={() => setShowPayModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmer le moyen de paiement</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Moyen de paiement</Form.Label>
                            <Select options={paymentOptions} onChange={setPaymentMethod}
                                placeholder="Sélectionnez le moyen de paiement" />
                        </Form.Group>

                        <Form.Group controlId="prixexpense">
                            <Form.Label>Date de la dépense </Form.Label>
                            <Form.Control style={{ height: "40px" }}
                                type="date"
                                value={date}
                                onChange={(e) => {
                                    setdate(e.target.value);
                                }}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Fichier de preuve </Form.Label>
                            <Form.Control type="file" accept="image/*" onChange={(e) => handleFileUpload(e.target.files[0])}
                                disabled={loading}
                            />
                            {loading && <p>Téléchargement du fichier...</p>}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" size="sm" onClick={() => setShowPayModal(false)}>
                        Retour
                    </Button>
                    <Button variant="primary" size="sm" disabled={loading} onClick={handlePayDamage}>
                        Confirmer
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal pour confirmer l'impression de la facture */}
            <Modal show={showPrintModal} onHide={() => setShowPrintModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Imprimer le dommage</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Êtes-vous sûr de vouloir imprimer ?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" size="sm" onClick={() => setShowPrintModal(false)}>
                        Retour
                    </Button>
                    <Button variant="primary" size="sm" onClick={handlePrintInvoice}>
                        Imprimer
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Contenu de la facture pour l'impression */}

            {/* Contenu de la facture pour l'impression */}
            <div id="invoice" style={{ display: 'none' }}>
                <Card>
                    <Card.Header>
                        <h3>Facture de dommage</h3>
                        <p>Reservation : {damage?.reservation?.ordre || ""}</p>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col>
                                <h5><strong>Client : </strong>{damage?.reservation?.firstname || ""}
                                    {damage?.reservation?.lastname || ""}</h5>
                                <h5><strong>Montant dû: </strong>{damage?.estimatedCost || ""} DZD</h5>
                            </Col>
                            {/*<Col className="text-right">
                                <p><strong>Date de l{"'"}incident: </strong>{new Date(damage?.damageDate).toLocaleDateString()}</p>
                            </Col> */}
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Card className="p-3">
                                    <h5 className="text-dark">Détails du dommage</h5>
                                    {damage?.relatedObjectType === 'Vehicle' && damage?.reservation?.vehicle && (
                                        <>
                                            <p><strong>Véhicule: </strong>{damage?.reservation?.vehicle.brand || ""}
                                                {damage?.reservation?.vehicle.model || ""} ({damage?.reservation?.vehicle.year || ""})
                                            </p>
                                            <p><strong>Couleur: </strong>{damage?.reservation?.vehicle.color || ""}</p>
                                            <p><strong>Numéro d{"'"}immatriculation:
                                            </strong>{damage?.reservation?.vehicle.registrationNumber || ""}</p>
                                        </>
                                    )}

                                    {damage?.relatedObjectType === 'Property' && damage?.reservation?.property && (
                                        <>
                                            <p><strong>Propriété : </strong>{damage?.reservation?.property.name || ""}</p>
                                            <p><strong>Addresse : </strong>{damage?.reservation?.property.address || ""}</p>

                                        </>
                                    )}
                                    {/* Ajoutez d'autres types d{"'"}objets ici si nécessaire */}
                                </Card>
                            </Col>

                            <Col md={6}>
                                <Card className="p-3">
                                    <h5 className="text-dark">Détails de la couverture</h5>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item><strong>Type de dommage: </strong>{damage?.type?.name || ""}</ListGroup.Item>
                                        <ListGroup.Item><strong>Commentaire: </strong>{damage?.description || ""}</ListGroup.Item>
                                        <ListGroup.Item><strong>Coût estimé: </strong>{damage?.estimatedCost || ""} DZD
                                        </ListGroup.Item>
                                        <ListGroup.Item><strong>Date de l{"'"}incident : </strong>{new
                                            Date(damage?.damageDate).toLocaleDateString()}</ListGroup.Item>

                                    </ListGroup>
                                </Card>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>

            {/*photo pièce d'identité */}
            <Modal show={showModalDriverFile} onHide={handleCloseModalDriver} centered size='lg'>
                <Modal.Header>
                    <MdImage /> Photo avant utilisation <strong></strong></Modal.Header>
                <Modal.Body>
                    {imagesDriverFile.length > 0 && (
                        <div className="mb-4">
                            <ImageGallery items={beforeUsePhotos} showThumbnails={true} showFullscreenButton={false}
                                showPlayButton={false} renderItem={(item) => (
                                    <div style={{
                                        height: '300px', // Hauteur fixe
                                        width: '100%',
                                        overflow: 'hidden'
                                    }}>
                                        <img src={item.original} alt="" style={{
                                            height: '100%',
                                            width: '100%',
                                            objectFit: 'contain'
                                        }} />
                                    </div>
                                )}

                            />
                        </div>
                    )}
                </Modal.Body>
            </Modal>

            <Modal show={showModalCardId} onHide={handleCloseModalCardId} centered size='lg'>
                <Modal.Header>
                    <MdImage /> Photo après utilisation<strong></strong> </Modal.Header>
                <Modal.Body>
                    {imagesidCardFile.length > 0 && (
                        <div className="mb-4">
                            <ImageGallery items={afterUsePhotos} showThumbnails={true} showFullscreenButton={false}
                                showPlayButton={false} renderItem={(item) => (
                                    <div style={{
                                        height: '300px', // Hauteur fixe
                                        width: '100%',
                                        overflow: 'hidden'
                                    }}>
                                        <img src={item.original} alt="" style={{
                                            height: '100%',
                                            width: '100%',
                                            objectFit: 'contain'
                                        }} />
                                    </div>
                                )}

                            />
                        </div>
                    )}
                </Modal.Body>
            </Modal>

        </div>
    );
};

export default DamageInvoiceDetailPage;