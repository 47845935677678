import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div class="page-wrap d-flex flex-row align-items-center bg-light" style={{alignItems:"center",height:"100vh"}}>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-12 text-center">
                        <span class="display-1 d-block">404</span>
                        <div class="mb-4 lead">Page non trouvée .</div>
                        <Link to="/" class="btn btn-link"> Retour </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
