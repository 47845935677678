import { FETCH_VEHICLE_MODELS_FAILURE, FETCH_VEHICLE_MODELS_REQUEST, FETCH_VEHICLE_MODELS_SUCCESS, FETCH_VEHICLE_MODELS_SUCCESS_2, FETCH_VEHICLE_MODEL_FAILURE, FETCH_VEHICLE_MODEL_REQUEST, FETCH_VEHICLE_MODEL_SUCCESS } from "../actions/actions";



// vehiclemodelReducer.js
const initialState = {
    vehiclemodels: [],
    vehiclemodels2: [],
    vehiclemodel: {},
    vehiclemodelSelect: {},
    loadingVehiclemodel:false,
    loadingVehiclemodelSelect:false,
    loadingVehiclemodels:false,
    errorVehiclemodels:null,
    errorVehiclemodel:null
    // ... autres états spécifiques à vehiclemodelReducer
  };
  
  const vehiclemodelReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_VEHICLE_MODEL_REQUEST:
        // Dispatch une action pour définir loading sur true dans le globalReducer
        return {
          ...state,
          loadingVehiclemodel:true,
          errorVehiclemodels:""
          // Autres manipulations d'état pour la demande de récupération des vehiclemodels
        };
        case FETCH_VEHICLE_MODEL_SUCCESS:
        return {
          ...state,
          loadingVehiclemodel:false,
          errorVehiclemodels:"",
          vehiclemodel:action.payload
        };
        case FETCH_VEHICLE_MODEL_FAILURE:
        return {
          ...state,
          loadingVehiclemodel:false,
          errorVehiclemodels:action.payload
        };


      case FETCH_VEHICLE_MODELS_REQUEST:
        return {
          ...state,
          loadingVehiclemodels:true,
          errorVehiclemodels:""
        };
       
      case FETCH_VEHICLE_MODELS_SUCCESS:
        return {
          ...state,
          vehiclemodels: action.payload,
          loadingVehiclemodels:false,
          errorVehiclemodels:""
          // Autres manipulations d'état pour le succès de la récupération des vehiclemodels
        };
        case FETCH_VEHICLE_MODELS_SUCCESS_2:
        return {
          ...state,
          vehiclemodels2: action.payload,
          loadingVehiclemodels:false,
          errorVehiclemodels:""
          // Autres manipulations d'état pour le succès de la récupération des vehiclemodels
        };
        
      case FETCH_VEHICLE_MODELS_FAILURE:
        // Dispatch une action pour définir loading sur false dans le globalReducer et enregistrer l'erreur
        return {
          ...state,
          loadingVehiclemodels:false,
          errorVehiclemodels:action.payload
          // Autres manipulations d'état pour l'échec de la récupération des vehiclemodels
        };
      // ... autres cas pour d'autres actions liées aux vehiclemodels

        
      default:
        return state;
    }
  };
  
  export default vehiclemodelReducer;
  