import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Modal, Form, Row, Col, Table, Dropdown } from 'react-bootstrap';
import { MdOutlineDiscount, MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCodePromosAll, fetchCodePromosOfIdUserAll, createPromoCode, fetchCodePromoByUpdateId, fetchCodePromoById } from '../../../actions/request/CodePromoRequest';
import { getAndCheckLocalStorage } from '../../../utils/storage/localvalueFuction';
import { localStorageKeys } from '../../../utils/storage/localvalue';
import { toast } from 'react-toastify';

const CodePromoPage = () => {
    const dispatch = useDispatch();
    const codepromos = useSelector((state) => state.codepromos.codepromos);
    const [showModal, setShowModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [promoCodeData, setPromoCodeData] = useState({
        discountPercentage: '',
        expiryDate: '',
    });
    const [selectedPromoCode, setSelectedPromoCode] = useState(null);
    const [showCode, setShowCode] = useState({});
    const itemsPerPage = 5;

    useEffect(() => {
        dispatch(fetchCodePromosOfIdUserAll(getAndCheckLocalStorage(localStorageKeys.userId)));
    }, [dispatch]);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => {
        setShowModal(false);
        setPromoCodeData({ discountPercentage: '', expiryDate: '' });
        setSelectedPromoCode(null);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPromoCodeData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleCreateOrUpdatePromoCode = () => {
        if (selectedPromoCode) {
            dispatch(fetchCodePromoByUpdateId(selectedPromoCode?._id, promoCodeData, toast));
        } else {
            const userAdd = getAndCheckLocalStorage(localStorageKeys.userId);
            dispatch(createPromoCode({ ...promoCodeData, userAdd }, toast));
        }
        handleCloseModal();
    };

    const handleEditPromoCode = (promoCode) => {
        setSelectedPromoCode(promoCode);
        setPromoCodeData({
            discountPercentage: promoCode.discountPercentage,
            expiryDate: promoCode.expiryDate.split('T')[0],
        });
        handleShowModal();
    };

    const handleValidatePromoCode = (code) => {
        dispatch(fetchCodePromoById(code));
    };

    const handleToggleShowCode = (promoId) => {
        setShowCode((prevState) => ({ ...prevState, [promoId]: !prevState[promoId] }));
    };

    const paginatedPromoCodes = codepromos.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <div>
            <Card>
                <Card.Header>
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <div>
                            <h3 className="mb-4"><MdOutlineDiscount /> Code Promo</h3>
                        </div>
                        <div>
                            <Button title="Nouveau code promo"
                                variant="primary"
                                size='sm'
                                className="ml-2"
                                onClick={handleShowModal}
                            >
                                + Ajouter
                            </Button>
                        </div>
                    </div>
                </Card.Header>

                <Card.Body style={{ minHeight: "400px" }} >
                    {paginatedPromoCodes && paginatedPromoCodes.length > 0 ? (
                        <div className="align-content-between">
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Code Promo</th>
                                        <th>Reduction (%)</th>
                                        <th>Expiration</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedPromoCodes.map((promo) => (
                                        <tr key={promo._id}>
                                            <td>
                                                <Button variant="link" onClick={() => handleToggleShowCode(promo._id)}>
                                                    {showCode[promo._id] ? <MdVisibilityOff /> : <MdVisibility />}
                                                </Button>
                                                {showCode[promo._id] ? promo.code : '****'}
                                            </td>
                                            <td>{promo.discountPercentage}%</td>
                                            <td>{new Date(promo.expiryDate).toLocaleDateString()}</td>
                                            <td>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="primary" size="sm">
                                                        Actions
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={() => handleEditPromoCode(promo)}>Modifier</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => handleValidatePromoCode(promo._id)}>Vérifier</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            <div className="d-flex justify-content-start mt-4">
                                <Button
                                    variant="primary"
                                    size="sm" className="p-2"
                                    disabled={currentPage === 1}
                                    onClick={() => setCurrentPage(currentPage - 1)}
                                >
                                    Précédent
                                </Button>
                                <Button
                                    variant="primary"
                                    size="sm" className="p-2"
                                    disabled={currentPage * itemsPerPage >= codepromos.length}
                                    onClick={() => setCurrentPage(currentPage + 1)}
                                >
                                    Suivant
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <Alert variant="light" style={{ display:"flex",justifyContent:"center" }}>Aucun code promo</Alert>
                    )}
                </Card.Body>
            </Card>

            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedPromoCode ? 'Modifier le Code Promo' : 'Nouveau Code Promo'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="discountPercentage">
                            <Form.Label>Pourcentage de Réduction</Form.Label>
                            <Form.Control
                                style={{ height: "40px" }}
                                type="number"
                                name="discountPercentage"
                                value={promoCodeData.discountPercentage}
                                onChange={handleInputChange}
                                min="0"
                                max="100"
                            />
                        </Form.Group>
                        <Form.Group controlId="expiryDate">
                            <Form.Label>Date d{"'"}Expiration</Form.Label>
                            <Form.Control
                                style={{ height: "40px" }}
                                type="date"
                                name="expiryDate"
                                value={promoCodeData.expiryDate}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" size="sm" onClick={handleCloseModal}>Annuler</Button>
                    <Button variant="primary" size="sm" onClick={handleCreateOrUpdatePromoCode}>{selectedPromoCode ? 'Mettre à Jour' : 'Créer'}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default CodePromoPage;