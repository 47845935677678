import { Container, Row, Col, Card, Badge, Button } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import { MdHome, MdLocationOn, MdBuild, MdPerson, MdFormatSize, MdOutlineMoney } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { FETCH_PROPERTY_SUCCESS, FETCH_VEHICLE_SUCCESS } from '../../../app/actions/actions';
import { useNavigate } from 'react-router-dom';
import { RiEye2Fill } from 'react-icons/ri';
import { ROUTES } from '../../../config/routingUrl';
import { emptyImage } from '../../../utils/dataApi/dataFormApi';

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix for missing marker icon issue
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const PropertyDetails = ({ property }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const propertys = useSelector((state) => state.propertys.propertys)
  useEffect(() => {
    if (propertys && propertys.length > 0) {
      const objetTrouve = propertys && propertys.length > 0 ? [...propertys].find(objet => objet._id === property?._id) :
        property;
      dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: objetTrouve });
    }
  }, [dispatch]);

  const images = property && property.images && property.images.length > 0 ? property.images.map((image) => ({
    original: image,
    thumbnail: image
  })) : [{
    original: emptyImage,
    thumbnail: emptyImage
  }];

  return (
    <Container className="my-5">
      <Row>
        <Col md={6}>
          <Card className="shadow-sm" style={{ height: '100%', }}>
            <Card.Body>
              {/* Galerie d'images */}
              <ImageGallery items={images} showThumbnails={true} showFullscreenButton={false} showPlayButton={false}
                renderItem={(item) => (
                  <div style={{
                    height: '300px', // Hauteur fixe
                    width: '100%',
                    overflow: 'hidden'
                  }}>
                    <img src={item.original} alt="" style={{
                      height: '100%',
                      width: '100%',
                      objectFit: 'contain'
                    }} />
                  </div>
                )}

              />

              
                {
                  property && property.lat && property.lng && (

                    <div>
                      <div class="py-3">
                        <h6> <MdLocationOn/>  Localisation </h6>
                       {/* <p> {property && property.address ? property.address : ""}</p> */}
                      </div>
                      <MapContainer center={[property.lat, property.lng]} zoom={13} style={{ height: "400px", width: "100%" }}>
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
                        <Marker position={[property.lat, property.lng]}>
                          <Popup>
                            Location: {property.lat}, {property.lng}
                          </Popup>
                        </Marker>
                      </MapContainer>
                    </div>
                  )
                }
              
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="shadow-sm">
            <Card.Body>
              <h2 className="mb-3">
                <MdHome /> {property.name}</h2>
              <hr />
              <p>
                <MdLocationOn /> <strong>Adresse:</strong> {property.address}</p>
              <p>
                <MdFormatSize /> <strong>Superficie:</strong> {property.size} m²</p>
              <p>
                <MdBuild /> <strong>Année de construction:</strong> {property.yearBuilt}</p>
              <hr />
              <ul class="list-group">
                <li class="list-group-item "
                  style={{ display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between" }}>
                  <div>
                    <MdPerson /> Propriétaire </div>

                  <div>
                    <Button size='sm' onClick={() => {
                      navigate(`/${ROUTES.CAR_DETAIL_USER_PATRIMOINE_LIST}/${property.addBy ||
                        ""}/person-or-company/${property.name || ""}`)
                    }}
                    >
                      <RiEye2Fill />
                    </Button>
                  </div>

                </li>
              </ul>

              <hr />
              {/* Section de prix */}
              <div className="price-section my-3">
                <h4>
                  <MdOutlineMoney /> Prix: {property.basePricePerDay} DZD</h4>

              </div>

              {/* Frais supplémentaires */}

              <Button variant="primary" className="mt-4" onClick={() => {
                dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: {} });
                dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: property });
                navigate(`/${ROUTES.RESERVATION_FORM_PAGE_WEB}`, { state: property })
              }}
              >Réserver</Button>
            </Card.Body>
          </Card>
        </Col>


      </Row>
    </Container>
  );
};

export default PropertyDetails;