import React, { useEffect, useRef, useState } from 'react'
import { ROUTES } from '../../config/routingUrl';
import { fetchVehicleById } from '../../actions/request/VehicleRequest';
import { fetchPropertyById } from '../../actions/request/PropertyRequest';
import { fetchReservationTypesAll } from '../../actions/request/ReservationTypeRequest';
import { fetchCustomersAll } from '../../actions/request/CustomerRequest';
import { fetchCodePostalsAll } from '../../actions/request/CodePostalRequest';
import axios from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Accordion, Card, Col, Row, Button, Image } from 'react-bootstrap';
import { baseurl, checkWordInURL } from '../../utils/url/baseurl';
import { toast } from 'react-toastify';
import { handleImageUploadCloud } from '../../actions/upload/UploadFileCloud';
import { ageDateNaissanceMax, emptyImage, logoApplication } from '../../utils/dataApi/dataFormApi';
import Select from "react-select";
import { MdColorLens, MdColorize, MdMap, MdSend } from 'react-icons/md';
import moment from 'moment';
import { dureeDeVie, getAndCheckLocalStorage, setWithExpiration } from '../../utils/storage/localvalueFuction';
import { localStorageData, localStorageKeys } from '../../utils/storage/localvalue';
import { getDataFromFile, saveDataToFile } from '../../actions/DataLocal';
import { fetchCompanyAiportsAll } from '../../actions/request/CompanyAiportRequest';

const ReservationFormPage = () => {




  const location = useLocation();
  const params = useParams();  // Récupère les paramètres d'URL
  const { _id, name, brand } = location.state;


  const navigate = useNavigate();
  const dispatch = useDispatch();
  const companyairports = useSelector((state) => state.companyairports.companyairports)
  const vehicle = useSelector((state) => state.vehicles.vehicle);
  const property = useSelector((state) => state.propertys.property);
  const reservationTypes = useSelector((state) => state.reservationTypes.reservationTypes);
  const customers = useSelector((state) => state.customers.customers);
  const codepostals = useSelector((state) => state.codepostals.codepostals);


  const [selectedType, setSelectedType] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [startHour, setStartHour] = useState({ value: '10:00', label: '10:00' });
  const [endDate, setEndDate] = useState({ value: '10:00', label: '10:00' });
  const [endHour, setEndHour] = useState({ value: '10:00', label: '10:00' });
  const [specialRequests, setSpecialRequests] = useState('');
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [codePostal, setCodePostal] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");


  // infos more
  const [idCard, setidCard] = useState("");
  const [idCardFile, setidCardFile] = useState("");


  const [driverLicenseNumber, setdriverLicenseNumber] = useState("");
  const [driverLicenseFile, setdriverLicenseFile] = useState("");


  const [dateOfBirth, setdateOfBirth] = useState("");

  const [latStart, setlatStart] = useState(null);
  const [lngStart, setlngStart] = useState(null);

  const [latEnd, setlatEnd] = useState(null);
  const [lngEnd, setlngEnd] = useState(null);

  const [companyAirport, setcompanyAirport] = useState();
  const [numberVolAiport, setnumberVolAiport] = useState();

  // companyAirport:companyAirport , 
  // numberVolAiport : numberVolAiport



  const [isLoading, setisLoading] = useState(false);




  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    setStartDate(newStartDate);
    if (endDate) {
      const start = new Date(newStartDate);
      const end = new Date(endDate);
      if (start > end) {
        setEndDate('');
        toast.error("La date de fin est inférieure à la date de début", { position: "bottom-right" });
      }
    }
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    setEndDate(newEndDate);
    if (startDate) {
      const start = new Date(startDate);
      const end = new Date(newEndDate);
      if (end < start) {
        setStartDate('');
        toast.info("La date de début est supérieure à la date de fin", { position: "bottom-right" });
      }
    }
    const currentDate = new Date();
    if (new Date(newEndDate) < currentDate) {
      setStartDate('');
      setEndDate('');
      toast.info("La date de fin est dans le passé", { position: "bottom-right" });
    }
  };


  const handleFileDriverFile = (e) => {
    setdriverLicenseFile(e.target.files);
  };


  const handleFileIdCardFile = (e) => {
    setidCardFile(e.target.files);
  };


  const [activeKey, setActiveKey] = useState(null); // État pour gérer la section active

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key); // Active ou désactive la section
  };


  const section1Ref = useRef(null);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };


  const [locationStart, setlocationStart] = useState('');
  const [locationEnd, setlocationEnd] = useState('');


  const [pickupLocation, setPickupLocation] = useState('');
  const [returnLocation, setReturnLocation] = useState('');
  const [pickupSuggestions, setPickupSuggestions] = useState([]);
  const [returnSuggestions, setReturnSuggestions] = useState([]);

  // Obtenir les suggestions de lieu de départ
  const getPlacesByCountryStartLocation = async (query) => {
    try {
      const response = await axios.get(`${baseurl.url}/api/v1/places`, {
        params: { query: query },
      });
      const places = response.data.results;
      return places.map(place => place);
    } catch (error) {
      console.error("Erreur lors de la récupération des lieux : ", error);
    }
  };

  // Obtenir les suggestions de lieu de retour
  const getPlacesByCountryEndLocation = async (query) => {
    try {
      const response = await axios.get(`${baseurl.url}/api/v1/places`, {
        params: { query: query },
      });
      const places = response.data.results;
      return places.map(place => place);
    } catch (error) {
      console.error("Erreur lors de la récupération des lieux : ", error);
    }
  };

  const handlePickupLocationChange = async (e) => {
    const value = e.target.value;
    setlocationStart(value);
    if (value.length > 2) {
      const suggestions = await getPlacesByCountryStartLocation(value);
      setPickupSuggestions(suggestions);
    } else {
      setPickupSuggestions([]); // Réinitialiser la liste des suggestions
    }
  };

  const handleReturnLocationChange = async (e) => {
    const value = e.target.value;
    setlocationEnd(value);
    if (value.length > 2) {
      const suggestions = await getPlacesByCountryEndLocation(value);
      setReturnSuggestions(suggestions);
    } else {
      setReturnSuggestions([]);
    }
  };

  const handlePickupSelection = (suggestion) => {

    setlocationStart(`${suggestion.name} - ${suggestion.formatted_address}`);
    setPickupLocation(`${suggestion.name} - ${suggestion.formatted_address}`);
    setlatStart(suggestion?.geometry?.location?.lat,);
    setlngStart(suggestion?.geometry?.location?.lng);

    // valeur locale en faite
    setWithExpiration(localStorageKeys.agenceDepArt, `${suggestion.name} - ${suggestion.formatted_address}`, dureeDeVie);
    setWithExpiration(localStorageKeys.latSelect, suggestion?.geometry?.location?.lat, dureeDeVie);
    setWithExpiration(localStorageKeys.longSelect, suggestion?.geometry?.location?.lng, dureeDeVie);

    // Met à jour newVehicle en une seule fois avec toutes les nouvelles valeurs

    console.log({
      address: `${suggestion.name} - ${suggestion.formatted_address}`,
      lat: suggestion?.geometry?.location?.lat,
      lng: suggestion?.geometry?.location?.lng
    });


    setPickupSuggestions([]);

    // Vérifier si le lieu de départ est identique au lieu de retour
    if (returnLocation && returnLocation === suggestion) {
      setPickupLocation('');
      setReturnLocation('');
      toast.error("Le lieu de départ et le lieu de retour ne peuvent pas être identiques.", { position: "bottom-right" });
    }
  };

  const handleReturnSelection = (suggestion) => {
    setlocationEnd(`${suggestion.name} - ${suggestion.formatted_address}`)
    setReturnLocation(`${suggestion.name} - ${suggestion.formatted_address}`);
    setlatEnd(suggestion?.geometry?.location?.lat,)
    setlngEnd(suggestion?.geometry?.location?.lng);


    setWithExpiration(localStorageKeys.agenceRetour, `${suggestion.name} - ${suggestion.formatted_address}`, dureeDeVie);

    setWithExpiration(localStorageKeys.latSelectRetour, suggestion?.geometry?.location?.lat, dureeDeVie);
    setWithExpiration(localStorageKeys.longSelectRetour, suggestion?.geometry?.location?.lng, dureeDeVie);



    // Met à jour newVehicle en une seule fois avec toutes les nouvelles valeurs
    console.log({
      address: `${suggestion.name} - ${suggestion.formatted_address}`,
      lat: suggestion?.geometry?.location?.lat,
      lng: suggestion?.geometry?.location?.lng
    })
    setReturnSuggestions([]);

    // Vérifier si le lieu de retour est identique au lieu de départ
    if (pickupLocation && pickupLocation === suggestion) {
      setPickupLocation('');
      setReturnLocation('');
      toast.error("Le lieu de départ et le lieu de retour ne peuvent pas être identiques.", { position: "bottom-right" });
    }
  };



  const handleCleanSugestions = () => {
    setPickupSuggestions([]);
    setReturnSuggestions([]);
  }







  useEffect(() => {

    // console.log(location.state)

    // valuer local à jour
    setStartDate(getAndCheckLocalStorage(localStorageKeys.dateDepart));
    setEndDate(getAndCheckLocalStorage(localStorageKeys.dateRetour));

    setStartHour(getDataFromFile(localStorageData.heureDepart) || { value: "10:00", label: "10:00" });
    setEndHour(getDataFromFile(localStorageData.heureRetour) || { value: "10:00", label: "10:00" });

    // information personnal
    setFirstname(getAndCheckLocalStorage(localStorageKeys.firstNameReservaiton));
    setLastname(getAndCheckLocalStorage(localStorageKeys.lastNameReservation));
    setEmail(getAndCheckLocalStorage(localStorageKeys.emailReservation));
    setPhone(getAndCheckLocalStorage(localStorageKeys.phoneReservation));
    setidCard(getAndCheckLocalStorage(localStorageKeys.idCardReservation));
    setdriverLicenseNumber(getAndCheckLocalStorage(localStorageKeys.driverLicenseNumberReservation, driverLicenseNumber || "", dureeDeVie));
    setdateOfBirth(getAndCheckLocalStorage(localStorageKeys.dateBirthDayreservation));
    setCodePostal(getDataFromFile(localStorageData.codePostalReservation) || null);

    // information address localisation
    setPickupLocation(getAndCheckLocalStorage(localStorageKeys.agenceDepArt));
    setlocationStart(getAndCheckLocalStorage(localStorageKeys.agenceDepArt))
    setlatStart(getAndCheckLocalStorage(localStorageKeys.latSelect));
    setlngStart(getAndCheckLocalStorage(localStorageKeys.longSelect));

    setReturnLocation(getAndCheckLocalStorage(localStorageKeys.agenceRetour));
    setlocationEnd(getAndCheckLocalStorage(localStorageKeys.agenceRetour));
    setlatEnd(getAndCheckLocalStorage(localStorageKeys.latSelectRetour));
    setlngEnd(getAndCheckLocalStorage(localStorageKeys.longSelectRetour));

    // information date
    setStartDate(getAndCheckLocalStorage(localStorageKeys.dateDepart));
    setEndDate(getAndCheckLocalStorage(localStorageKeys.dateRetour));

    setStartHour(getDataFromFile(localStorageData.heureDepart) || null);
    setEndHour(getDataFromFile(localStorageData.heureRetour) || null);

    // dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: {} })
    // dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: {} })
    dispatch(fetchVehicleById(_id || ""));
    dispatch(fetchPropertyById(_id || ""));
    dispatch(fetchReservationTypesAll());
    dispatch(fetchCustomersAll());
    dispatch(fetchCodePostalsAll());
    dispatch(fetchCompanyAiportsAll())

  }, [dispatch, _id]);



  const images = vehicle && vehicle.images ? vehicle.images.map((image) => ({
    original: image,
    thumbnail: image,
  })) : property && property.images ? property.images.map((image) => ({
    original: image,
    thumbnail: image,
  })) : [];

  const companyAirportOptions = companyairports && companyairports.length > 0 ? companyairports.map((type) => ({
    value: type._id,
    label: type.name,
  })) : [];


  const typeOptions = reservationTypes && reservationTypes.length > 0 ? reservationTypes.map((type) => ({
    value: type._id,
    label: type.name,
  })) : [];

  const clientOptions = customers && customers.length > 0 ? customers.map((client) => ({
    value: client._id,
    label: `${client.firstname} ${client.lastname}`,
  })) : [];



  const indicatifOptions = codepostals && codepostals.length > 0 ? codepostals.map((client) => ({
    value: client._id,
    label: `+${client.indicatif} ${client.country}`,
  })) : [];



  // Calculate the maximum date (16 years ago) using Moment.js
  const maxDate = moment().subtract(ageDateNaissanceMax, 'years').format('YYYY-MM-DD');



  // Options d'heures (par intervalles de 30 minutes)
  const hourOptions = Array.from({ length: 24 }, (_, i) => {
    const hour = i.toString().padStart(2, '0');
    return [
      { value: `${hour}:00`, label: `${hour}:00` },
      { value: `${hour}:30`, label: `${hour}:30` }
    ];
  }).flat();

  // Combiner la date et l'heure pour obtenir une date complète au format ISO
  const combineDateAndTime = (date, time) => {
    const [hours, minutes] = time.value.split(':');
    const combined = new Date(date);
    combined.setHours(hours);
    combined.setMinutes(minutes);
    combined.setSeconds(0);
    combined.setMilliseconds(0);
    return combined.toISOString(); // Convertir en format ISO 8601
  };

  const newMinDate = moment(startDate).add(2, 'days').format('YYYY-MM-DD');

  const submitReservation = async (e) => {
    e.preventDefault();
    setisLoading(true);

    try {
      // Vérification des dates et heures de début et de fin
      if (!startDate || !endDate || !startHour || !endHour) {
        toast.error("Veuillez sélectionner les dates et heures de début et de fin.", { position: "bottom-right" });
        setisLoading(false);
        return;
      }

      // Combinaison des dates et heures
      const startDateTime = combineDateAndTime(startDate, startHour);
      const endDateTime = combineDateAndTime(endDate, endHour);

      // Validation de véhicule ou propriété sélectionné(e)
      if (!vehicle?._id && !property?._id) {
        toast.error("Veuillez sélectionner un véhicule ou une propriété.", { position: "bottom-right" });
        setisLoading(false);
        return;
      }

      // Validation des informations de contact de l'utilisateur
      if (!firstname || !lastname || !email || !phone || !codePostal?.value || !dateOfBirth) {
        toast.error("Veuillez remplir tous les champs de contact requis (prénom, nom, email, téléphone, code postal, date de naissance).", { position: "bottom-right" });
        setisLoading(false);
        return;
      }

      // Validation des documents d'identité
      if (!idCard && !driverLicenseNumber) {
        toast.error("Pièce d'identité ou permis de conduire obligatoire", { position: "bottom-right" });
        setisLoading(false);
        return;
      }

      // Vérification des coordonnées géographiques
      if (vehicle?._id && (!latStart || !lngStart || !latEnd || !lngEnd)) {
        toast.error("Veuillez vérifier les coordonnées géographiques de départ et d'arrivée.", { position: "bottom-right" });
        setisLoading(false);
        return;
      }

      // Chargement des images si elles existent
      const idCardPhotosUrls = idCardFile ? await handleImageUploadCloud(idCardFile, toast) : [];
      const driverPhotosUrls = driverLicenseFile ? await handleImageUploadCloud(driverLicenseFile, toast) : [];

      if (idCardPhotosUrls.length === 0 && driverPhotosUrls.length === 0) {
        toast.error("Veuillez télécharger les fichiers requis (carte d'identité et permis de conduire).", { position: "bottom-right" });
        setisLoading(false);
        return;
      }


      // information personnal
      setWithExpiration(localStorageKeys.firstNameReservaiton, firstname, dureeDeVie);
      setWithExpiration(localStorageKeys.lastNameReservation, lastname, dureeDeVie);
      setWithExpiration(localStorageKeys.emailReservation, email, dureeDeVie);
      setWithExpiration(localStorageKeys.phoneReservation, phone, dureeDeVie);
      setWithExpiration(localStorageKeys.idCardReservation, idCard || "", dureeDeVie);
      setWithExpiration(localStorageKeys.driverLicenseNumberReservation, driverLicenseNumber || "", dureeDeVie);
      setWithExpiration(localStorageKeys.dateBirthDayreservation, dateOfBirth, dureeDeVie);
      saveDataToFile(localStorageData.codePostalReservation, codePostal);

      // information address localisation
      setWithExpiration(localStorageKeys.agenceDepArt, pickupLocation, dureeDeVie);
      setWithExpiration(localStorageKeys.agenceRetour, returnLocation, dureeDeVie);

      setWithExpiration(localStorageKeys.latSelect, latStart, dureeDeVie);
      setWithExpiration(localStorageKeys.longSelect, lngStart, dureeDeVie);

      setWithExpiration(localStorageKeys.latSelectRetour, latEnd, dureeDeVie);
      setWithExpiration(localStorageKeys.longSelectRetour, lngEnd, dureeDeVie);

      // information date
      setWithExpiration(localStorageKeys.dateDepart, startDate, dureeDeVie);
      setWithExpiration(localStorageKeys.dateRetour, endDate, dureeDeVie);

      saveDataToFile(localStorageData.heureDepart, startHour);
      saveDataToFile(localStorageData.heureRetour, endHour);



      // Création de l'objet de réservation
      const reservationData = {
        reservationElement: vehicle?._id ? "Vehicle" : "Property",
        type: selectedType?.value,
        vehicle: vehicle?._id,
        property: property?._id,
        firstname,
        lastname,
        email,
        phone,
        codePostal: codePostal?.value,
        startDate: startDateTime,
        endDate: endDateTime,
        specialRequests,
        basePricePerDay: vehicle?.basePricePerDay || property?.basePricePerDay,
        commissionPercentage: vehicle?.commissionPercentage || property?.commissionPercentage,
        deliveryFee: vehicle?.deliveryFee || property?.deliveryFee,
        image: images[0] || logoApplication,
        userAdd: vehicle?.addBy || property?.addBy,
        idCard,
        idCardFile: idCardPhotosUrls,
        driverLicenseNumber,
        driverLicenseFile: driverPhotosUrls,
        dateOfBirth,
        pickupLocation,
        returnLocation,
        latStart,
        lngStart,
        latEnd,
        lngEnd,

        companyAirport: companyAirport?.value || "",
        numberVolAiport: numberVolAiport
      };

      // Redirection avec les données de réservation
      const urlGet = checkWordInURL(ROUTES.DASHBOARD);
      navigate(urlGet ? `/${ROUTES.DASHBOARD}/${ROUTES.RESERVATIONS_INVOICE_WEB}` : `/${ROUTES.RESERVATIONS_INVOICE_WEB}`, { state: reservationData });
    } catch (error) {
      console.log(error)
      toast.error("Une erreur s'est produite lors de la soumission de la réservation. Veuillez réessayer.", { position: "bottom-right" });
    } finally {
      setisLoading(false);
    }
  };







  // Empêcher la sélection de dates passées
  const today = new Date().toISOString().split('T')[0];




  return (
    <div class="container">








      <Row>
        <Col md={12} onClick={handleCleanSugestions}>
          <Card className="mt-4">
            <Card.Header><h4>Formulaire de reservation</h4></Card.Header>
            <Card.Body>
              <Form onSubmit={submitReservation}>
                <Row>


                  <Col md={12}>
                    {
                      vehicle || property ?
                        <div class="card" style={{ width: "100%" }}>
                          <div class="card-body">
                            <Row>
                              <Col md={2}>
                                {vehicle && vehicle.images && vehicle.images &&
                                  <Image src={vehicle.images[0] || emptyImage} class="img-fluid rounded-md" style={{ width: "100px", height: "100px" }} alt={vehicle?.brand || ""} />
                                }
                                {property && property.images && property.images &&
                                  <Image src={property.images[0] || emptyImage} class="img-fluid rounded-md" style={{ width: "100px", height: "100px" }} alt={property?.name || ""} />
                                }
                              </Col>
                              <Col md={10}>
                                <ul class="list-group list-group-flush">
                                  {
                                    vehicle && vehicle.brand ? <li class="list-group-item">{vehicle?.brand || ""}</li> : ""
                                  }
                                  {
                                    vehicle && vehicle.model ? <li class="list-group-item">Model :  {vehicle?.model || ""}</li> : ""
                                  }
                                  {
                                    vehicle && vehicle.color ? <li class="list-group-item"><MdColorLens /> {vehicle?.color || ""}</li> : ""
                                  }

                                  {
                                    property && property.name ? <li class="list-group-item">titre: {property?.name || ""}</li> : ""
                                  }
                                  {
                                    property && property.address ? <li class="list-group-item"><MdMap /> {property?.address || ""}</li> : ""
                                  }



                                </ul>
                              </Col>
                            </Row>
                          </div>
                        </div>
                        : ""
                    }
                  </Col>


                  <Col md={4}>
                    <Form.Group controlId="firstname">
                      <Form.Label>Nom <span class="text-danger">*</span></Form.Label>
                      <Form.Control
                        type="text"
                        value={firstname}
                        onChange={(e) => setFirstname(e.target.value)}
                        style={{ height: '40px' }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="lastname">
                      <Form.Label>Prénoms <span class="text-danger">*</span></Form.Label>
                      <Form.Control
                        type="text"
                        value={lastname}
                        onChange={(e) => setLastname(e.target.value)}

                        style={{ height: '40px' }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="email">
                      <Form.Label>Email <span class="text-danger text-lg">*</span></Form.Label>
                      <Form.Control
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}

                        style={{ height: '40px' }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="codePostal">
                      <Form.Label>Indicatif téléphone <span class="text-danger">*</span></Form.Label>
                      <Select
                        options={indicatifOptions}
                        value={codePostal}
                        onChange={setCodePostal}
                        placeholder=""
                        styles={{ control: (provided) => ({ ...provided, height: '40px' }) }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="phone">
                      <Form.Label>Téléphone <span class="text-danger">*</span></Form.Label>
                      <Form.Control
                        type="number"
                        min={100000}
                        max={1000000000000000000}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}

                        style={{ height: '40px' }}
                      />
                    </Form.Group>
                  </Col>




                  {/*Infomtion dur les les doucment nécessaire pour la reservaiton */}


                  <Col md={4}>
                    <Form.Group controlId="firstname">
                      <Form.Label>Date de naissance <span class="text-danger">*</span></Form.Label>
                      <Form.Control
                        max={maxDate}
                        type="date" class="date-picker"
                        value={dateOfBirth}
                        onChange={(e) => setdateOfBirth(e.target.value)}

                        style={{ height: '40px' }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group controlId="lastname">
                      <Form.Label>N° Pièce d{"'"}identité <span class="text-danger">*</span></Form.Label>
                      <Form.Control
                        type="text"
                        value={idCard}
                        onChange={(e) => setidCard(e.target.value)}

                        style={{ height: '40px' }}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={3}>
                    <Form.Group controlId="lastname">
                      <Form.Label> Pièce d{"'"} identité ( Recto verso ) <span class="text-danger">*</span></Form.Label>
                      <Form.Control
                        type="file" multiple accept="image/*" onChange={handleFileIdCardFile}

                        style={{ height: '40px' }}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={3}>
                    <Form.Group controlId="lastname">
                      <Form.Label>N° Passport / Permis  </Form.Label>
                      <Form.Control
                        type="text"
                        value={driverLicenseNumber}
                        onChange={(e) => setdriverLicenseNumber(e.target.value)}

                        style={{ height: '40px' }}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={3}>
                    <Form.Group controlId="lastname">
                      <Form.Label> Permis / Passport (Recto Verso) </Form.Label>
                      <Form.Control
                        type="file" multiple accept="image/*" onChange={handleFileDriverFile}

                        style={{ height: '40px' }}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={3}>
                    <Form.Group controlId="startDate">
                      <Form.Label>Date de début <span class="text-danger">*</span></Form.Label>
                      <Form.Control
                        type="date" class="date-picker"
                        min={today}
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}

                        style={{ height: '40px' }}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={3}>
                    <Form.Group controlId="startHour">
                      <Form.Label>Heure de début <span class="text-danger">*</span></Form.Label>
                      <Select

                        value={startHour}
                        onChange={(selectedOption) => setStartHour(selectedOption)}
                        options={hourOptions}
                      />
                    </Form.Group>
                  </Col>

                  {/* Date de fin et heure */}
                  <Col md={3}>
                    <Form.Group controlId="endDate">
                      <Form.Label>Date de fin <span class="text-danger">*</span></Form.Label>
                      <Form.Control
                        type="date" class="date-picker"
                        min={newMinDate || today}
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}

                        style={{ height: '40px' }}
                        disabled={!startDate}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group controlId="endHour">
                      <Form.Label>Heure de fin <span class="text-danger">*</span></Form.Label>
                      <Select

                        value={endHour}
                        onChange={(selectedOption) => setEndHour(selectedOption)}
                        options={hourOptions}
                        isDisabled={!startDate}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group controlId="endHour">
                      <Form.Label>Compagnie Aérienne </Form.Label>
                      <Select

                        value={companyAirport}
                        onChange={(selectedOption) => setcompanyAirport(selectedOption)}
                        options={companyAirportOptions}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group controlId="endDate">
                      <Form.Label>Numéro de vol</Form.Label>
                      <Form.Control
                        type="text"
                        value={numberVolAiport}
                        onChange={(e) => setnumberVolAiport(e.target.value)}
                        style={{ height: '40px' }}
                      />
                    </Form.Group>
                  </Col>


                  {/* Lieu de départ */}
                  {
                    brand && (
                      <Col md={6}>
                        <Form.Group controlId="pickupLocation">
                          <Form.Label>Lieu de départ <span class="text-danger">*</span></Form.Label>
                          <Form.Control
                            type="text"
                            value={locationStart}
                            onChange={handlePickupLocationChange}
                            style={{ height: '40px' }}
                            placeholder="Entrez un lieu"
                          />
                          {/* Liste déroulante des suggestions */}
                          {pickupSuggestions && pickupSuggestions.length > 0 && (
                            <ul className="rounded p-1 border" style={{ position: 'absolute', zIndex: 1000, backgroundColor: 'white', maxwidth: "400px", listStyleType: 'none', padding: 0, maxHeight: "200px", overflow: "auto" }}>
                              {pickupSuggestions.map((suggestion, index) => (
                                <li className="border"
                                  key={index}
                                  style={{ padding: '5px', cursor: 'pointer' }}
                                  onClick={() => handlePickupSelection(suggestion)}
                                >
                                  {`${suggestion.name} - ${suggestion.formatted_address}`}
                                </li>
                              ))}
                            </ul>
                          )}
                        </Form.Group>
                      </Col>
                    )
                  }

                  {/* Lieu de retour */}
                  {
                    brand && (
                      <Col md={6}>
                        <Form.Group controlId="returnLocation">
                          <Form.Label>Lieu de retour <span class="text-danger">*</span></Form.Label>
                          <Form.Control
                            type="text"
                            value={locationEnd}
                            onChange={handleReturnLocationChange}
                            style={{ height: '40px' }}
                            placeholder="Entrez un lieu"
                          />
                          {/* Liste déroulante des suggestions */}
                          {returnSuggestions && returnSuggestions.length > 0 && (
                            <ul className="rounded p-1 border" style={{ position: 'absolute', zIndex: 1000, backgroundColor: 'white', maxWidth: "400px", listStyleType: 'none', padding: 0, maxHeight: "200px", overflow: "auto" }}>
                              {returnSuggestions.map((suggestion, index) => (
                                <li className="border"
                                  key={index}
                                  style={{ padding: '5px', cursor: 'pointer' }}
                                  onClick={() => handleReturnSelection(suggestion)}
                                >
                                  {`${suggestion.name} - ${suggestion.formatted_address}`}
                                </li>
                              ))}
                            </ul>
                          )}
                        </Form.Group>
                      </Col>
                    )
                  }


                  <Col md={12}>
                    <Form.Group controlId="specialRequests">
                      <Form.Label>Information suplémentaire</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={specialRequests}
                        onChange={(e) => setSpecialRequests(e.target.value)}
                        style={{ height: '100px' }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div class="mt-5" style={{ justifyContent: "start" }}>

                  {
                    isLoading ?
                      <p>Chargement en cours ...</p>
                      :
                      <Button type="submit" variant="primary" disabled={isLoading} className="rounded" >
                        <MdSend /> Réserver
                      </Button>
                  }

                </div>
              </Form>
            </Card.Body>
          </Card>




        </Col >



      </Row >








    </div >
  )
}

export default ReservationFormPage;
