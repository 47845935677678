import { FETCH_CODE_PROMOS_FAILURE, FETCH_CODE_PROMOS_REQUEST, FETCH_CODE_PROMOS_SUCCESS, FETCH_CODE_PROMOS_SUCCESS_2, FETCH_CODE_PROMO_FAILURE, FETCH_CODE_PROMO_REQUEST, FETCH_CODE_PROMO_SUCCESS } from "../actions/actions";



// codepromoReducer.js
const initialState = {
    codepromos: [],
    codepromos2: [],
    codepromo: {},
    codepromoSelect: {},
    loadingCodepromo:false,
    loadingCodepromoSelect:false,
    loadingCodepromos:false,
    errorCodepromos:null,
    errorCodepromo:null
    // ... autres états spécifiques à codepromoReducer
  };
  
  const codepromoReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_CODE_PROMO_REQUEST:
        // Dispatch une action pour définir loading sur true dans le globalReducer
        return {
          ...state,
          loadingCodepromo:true,
          errorCodepromos:""
          // Autres manipulations d'état pour la demande de récupération des codepromos
        };
        case FETCH_CODE_PROMO_SUCCESS:
        return {
          ...state,
          loadingCodepromo:false,
          errorCodepromos:"",
          codepromo:action.payload
        };
        case FETCH_CODE_PROMO_FAILURE:
        return {
          ...state,
          loadingCodepromo:false,
          errorCodepromos:action.payload
        };


      case FETCH_CODE_PROMOS_REQUEST:
        return {
          ...state,
          loadingCodepromos:true,
          errorCodepromos:""
        };
       
      case FETCH_CODE_PROMOS_SUCCESS:
        return {
          ...state,
          codepromos: action.payload,
          loadingCodepromos:false,
          errorCodepromos:""
          // Autres manipulations d'état pour le succès de la récupération des codepromos
        };
        case FETCH_CODE_PROMOS_SUCCESS_2:
        return {
          ...state,
          codepromos2: action.payload,
          loadingCodepromos:false,
          errorCodepromos:""
          // Autres manipulations d'état pour le succès de la récupération des codepromos
        };
        
      case FETCH_CODE_PROMOS_FAILURE:
        // Dispatch une action pour définir loading sur false dans le globalReducer et enregistrer l'erreur
        return {
          ...state,
          loadingCodepromos:false,
          errorCodepromos:action.payload
          // Autres manipulations d'état pour l'échec de la récupération des codepromos
        };
      // ... autres cas pour d'autres actions liées aux codepromos

        
      default:
        return state;
    }
  };
  
  export default codepromoReducer;
  