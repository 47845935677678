import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Modal, Table, Dropdown, Image } from 'react-bootstrap';
import { MdOutlineNotificationsActive } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNotificationsAll } from '../../../actions/request/NotificationRequest';
import { getAndCheckLocalStorage } from '../../../utils/storage/localvalueFuction';
import { localStorageKeys } from '../../../utils/storage/localvalue';
import { RiEye2Fill } from 'react-icons/ri';
import { emptyImage } from '../../../utils/dataApi/dataFormApi';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../config/routingUrl';

const NotificationPage = () => {
    const  navigate = useNavigate();
    const dispatch = useDispatch();
    const notifications = useSelector((state) => state.notifications.notifications);
    const [showModal, setShowModal] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        // Fetch all notifications for the current user
        dispatch(fetchNotificationsAll(getAndCheckLocalStorage(localStorageKeys.userId)));
    }, [dispatch]);

    const handleShowModal = (notification) => {
        setSelectedNotification(notification);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedNotification(null);
    };

    const paginatedNotifications = notifications.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);



    return (
        <div>
            <Card>
                <Card.Header>
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <div>
                            <h3 className="mb-4"> <MdOutlineNotificationsActive /> Notifications </h3>
                        </div>
                    </div>
                </Card.Header>

                <Card.Body style={{ minHeight: "400px" }}>
                    {paginatedNotifications && paginatedNotifications.length > 0 ? (
                        <div className="align-content-between">
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Image</th>
                                        <th>Message</th>
                                        <th>Type</th>
                                        <th>Date</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedNotifications.map((notification) => (
                                        <tr key={notification._id}>
                                        <td>
                                        
                                        {
                                            notification.reservation && notification.reservation.vehicle && notification.reservation.vehicle.images ?
                                                <Image src={notification.reservation.vehicle.images[0] || emptyImage} style={{height:"70px",width:"70px"}} /> : ""
                                        }
                                        {
                                            notification.reservation && notification.reservation.property && notification.reservation.property.images ?
                                                <Image src={notification.reservation.property.images[0] || emptyImage} style={{height:"70px",width:"70px"}} /> : ""
                                        }
                                        {
                                            notification.maintenance && notification.maintenance.vehicle && notification.maintenance.vehicle.images ?
                                                <Image src={notification.maintenance.vehicle.images[0] || emptyImage} style={{height:"70px",width:"70px"}} /> : ""
                                        }
                                        {
                                            notification.maintenance && notification.maintenance.property && notification.maintenance.property.images ?
                                                <Image src={notification.maintenance.property.images[0] || emptyImage} style={{height:"70px",width:"70px"}} /> : ""
                                        }
                                        {
                                            notification.property && notification.property && notification.property.images && notification.property.images.length > 0 ?
                                                <Image src={notification.property.images[0] || emptyImage} style={{height:"70px",width:"70px"}} /> : ""
                                        }
                                        {
                                            notification.vehicle && notification.vehicle && notification.vehicle.images && notification.vehicle.images.length > 0 ?
                                                <Image src={notification.property.images[0] || emptyImage} style={{height:"70px",width:"70px"}} /> : ""
                                        }
                                        </td>
                                            <td>{notification.message}</td>
                                            <td>{notification.type}</td>
                                            <td>{new Date(notification.createdAt).toLocaleDateString()}</td>
                                            <td>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="primary" size="sm">
                                                        Actions
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={() => handleShowModal(notification)}>Voir Détails</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            <div className="d-flex justify-content-start mt-4">
                                <Button
                                    variant="primary"
                                    size="sm" className="p-2"
                                    disabled={currentPage === 1}
                                    onClick={() => setCurrentPage(currentPage - 1)}
                                >
                                    Précédent
                                </Button>
                                <Button
                                    variant="primary"
                                    size="sm" className="p-2"
                                    disabled={currentPage * itemsPerPage >= notifications.length}
                                    onClick={() => setCurrentPage(currentPage + 1)}
                                >
                                    Suivant
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <Alert variant="light" style={{ display: "flex", justifyContent: "center" }}>Aucune notification</Alert>
                    )}
                </Card.Body>
            </Card>

            {/* Modal to show detailed notification */}
            {selectedNotification && (
                <Modal show={showModal} onHide={handleCloseModal} size='md' centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Détails de la Notification</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div class="my-2">
                            <div>
                                {
                                    selectedNotification.reservation && selectedNotification.reservation.vehicle && selectedNotification.reservation.vehicle.images ?
                                        <Image src={selectedNotification.reservation.vehicle.images[0] || emptyImage} /> : ""
                                }
                                {
                                    selectedNotification.reservation && selectedNotification.reservation.property && selectedNotification.reservation.property.images ?
                                        <Image src={selectedNotification.reservation.property.images[0] || emptyImage} /> : ""
                                }


                                {
                                    selectedNotification.maintenance && selectedNotification.maintenance.vehicle && selectedNotification.maintenance.vehicle.images ?
                                        <Image src={selectedNotification.maintenance.vehicle.images[0] || emptyImage} /> : ""
                                }
                                {
                                    selectedNotification.maintenance && selectedNotification.maintenance.property && selectedNotification.maintenance.property.images ?
                                        <Image src={selectedNotification?.maintenance.property.images[0] || emptyImage} /> : ""
                                }


                                {
                                    selectedNotification.property && selectedNotification.property && selectedNotification.property.images && selectedNotification.property.images.length > 0 ?
                                        <Image src={selectedNotification?.property?.images[0] || emptyImage} /> : ""
                                }
                                {
                                    selectedNotification.vehicle && selectedNotification.vehicle && selectedNotification.vehicle.images && selectedNotification.vehicle.images.length > 0 ?
                                        <Image src={selectedNotification?.property?.images[0] || emptyImage} /> : ""
                                }
                            </div>
                        </div>
                        <p><strong>Message :</strong> {selectedNotification?.message || ""}</p>
                        <p><strong>Type :</strong> {selectedNotification?.type || ""}</p>
                        <p><strong>Date de Création :</strong> {new Date(selectedNotification?.createdAt).toLocaleString()}</p>
                        {selectedNotification?.reservation && <p><strong>Réservation :</strong> {selectedNotification?.reservation?.ordre || ""} <Button size="sm" variant='primary' onClick={()=>{
                            navigate(`/${ROUTES.DASHBOARD}/${ROUTES.RESERVATION_VIEW}/${selectedNotification.reservation._id}`)
                        }}><RiEye2Fill /></Button></p>}
                        {selectedNotification.vehicle && <p><strong>Véhicule :</strong> {selectedNotification?.vehicle?.brand || ""}</p>}
                        {selectedNotification.property && <p><strong>Propriété :</strong> {selectedNotification?.property?.name || ""}</p>}
                        {selectedNotification.maintenance && <p><strong>Maintenance :</strong> {selectedNotification?.maintenance?.description || ""}</p>}
                        {selectedNotification.insurance && <p><strong>Assurance :</strong> {selectedNotification?.insurance?.policyNumber || ""} - {selectedNotification?.insurance?.type?.name || ""}</p>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="light" size="sm" onClick={handleCloseModal}>Fermer</Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
};

export default NotificationPage;
