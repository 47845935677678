import {
  FETCH_EXPENSES_FAILURE, FETCH_EXPENSES_REQUEST, FETCH_EXPENSES_SUCCESS, FETCH_EXPENSES_SUCCESS_2,
  FETCH_EXPENSES_SUCCESS_D, FETCH_EXPENSE_FAILURE, FETCH_EXPENSE_REQUEST, FETCH_EXPENSE_SUCCESS
} from
  "../actions/actions";

// expenseReducer.js
const initialState = {
  expenses: [],
  expenses2: [],
  expense: {},
  expenseSelect: {},
  loadingExpense: false,
  loadingExpenseSelect: false,
  loadingExpenses: false,
  errorExpenses: null,
  errorExpense: null
  // ... autres états spécifiques à expenseReducer
};

const expenseReducer = (state = initialState, action) => {
  switch (action.type) {

    case FETCH_EXPENSE_REQUEST:
      // Dispatch une action pour définir loading sur true dans le globalReducer
      return {
        ...state,
        loadingExpense: true,
        errorExpense: ""
        // Autres manipulations d'état pour la demande de récupération des expenses
      };

    case FETCH_EXPENSE_SUCCESS:
      return {
        ...state,
        loadingExpense: false,
        errorExpense: "",
        expense: action.payload
      };

    case FETCH_EXPENSE_FAILURE:
      return {
        ...state,
        loadingExpense: false,
        errorExpense: action.payload
      };

    // EXPENSE
    // case FETCH_EXPENSE_REQUEST_SELECT:
    // // Dispatch une action pour définir loading sur true dans le globalReducer
    // return {
    // ...state,
    // loadingExpenseSelect:true,
    // errorExpenses:""
    // // Autres manipulations d'état pour la demande de récupération des expenses
    // };
    // case FETCH_EXPENSE_SUCCESS_SELECT:
    // return {
    // ...state,
    // loadingExpenseSelect:false,
    // errorExpenses:"",
    // expenseSelect:action.payload
    // };
    // case FETCH_EXPENSE_FAILURE_SELECT:
    // return {
    // ...state,
    // loadingExpenseSelect:false,
    // errorExpenses:action.payload
    // };

    case FETCH_EXPENSES_REQUEST:
      return {
        ...state,
        loadingExpenses: true,
        errorExpenses: ""
      };

    case FETCH_EXPENSES_SUCCESS:
      return {
        ...state,
        expenses: action.payload,
        loadingExpenses: false,
        errorExpenses: ""
        // Autres manipulations d'état pour le succès de la récupération des expenses
      };

    case FETCH_EXPENSES_SUCCESS_2:
      return {
        ...state,
        expenses2: action.payload,
        loadingExpenses: false,
        errorExpenses: ""
        // Autres manipulations d'état pour le succès de la récupération des expenses
      };

    case FETCH_EXPENSES_SUCCESS_D:
      return {
        ...state,
        expensesD: action.payload,
        loadingExpenses: false,
        errorExpenses: ""
        // Autres manipulations d'état pour le succès de la récupération des expenses
      };

    case FETCH_EXPENSES_FAILURE:
      // Dispatch une action pour définir loading sur false dans le globalReducer et enregistrer l'erreur
      return {
        ...state,
        loadingExpenses: false,
        errorExpenses: action.payload
        // Autres manipulations d'état pour l'échec de la récupération des expenses
      };
    // ... autres cas pour d'autres actions liées aux expenses

    default:
      return state;
  }
};

export default expenseReducer;