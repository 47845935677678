import React, { useState } from 'react';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../config/routingUrl';
import axios from 'axios';
import { baseurl } from '../../../utils/url/baseurl';
import { toast } from 'react-toastify';
import { dureeDeVie, setWithExpiration } from '../../../utils/storage/localvalueFuction';
import { localStorageData, localStorageKeys } from '../../../utils/storage/localvalue';
import { saveDataToFile } from '../../../actions/DataLocal';

// Création des options d'heures avec intervalles de 30 minutes
const hourOptions = Array.from({ length: 24 }, (_, i) => {
    const hour = i.toString().padStart(2, '0');
    return [
        { value: `${hour}:00`, label: `${hour}:00` },
        { value: `${hour}:30`, label: `${hour}:30` }
    ];
}).flat();

const HomeVehicleContainerForm = () => {

    const navigate = useNavigate();

    const responsive = {
        superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 1 },
        desktop: { breakpoint: { max: 3000, min: 1024 }, items: 1 },
        tablet: { breakpoint: { max: 1024, min: 464 }, items: 1 },
        mobile: { breakpoint: { max: 464, min: 0 }, items: 1 }
    };

    const [formData, setFormData] = useState({
        agenceDepart: '',
        dateDepart: '',
        heureDepart: { value: '10:00', label: '10:00' },
        dateRetour: '',
        heureRetour: { value: '10:00', label: '10:00' },
        agePlus25: false,
        lat: "",
        lng: "",
        patrimoine:"Vehicle"
    });

    // Fonction pour gérer les changements dans les champs du formulaire
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    // Gestion de la sélection des heures
    const handleHourChange = (selectedOption, name) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: selectedOption,
        }));
    };

    // Fonction pour combiner les dates et heures en un objet Date au format ISO 8601
    const combineDateAndTime = (date, time) => {
        const [hours, minutes] = time.value.split(':');
        const combined = new Date(date);
        combined.setHours(hours);
        combined.setMinutes(minutes);
        combined.setSeconds(0);
        combined.setMilliseconds(0);
        return combined.toISOString(); // Convertir en format ISO 8601
    };

    // Gestion de la soumission du formulaire
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {

                // valuer local à jour
                setWithExpiration(localStorageKeys.dateDepart ,formData.dateDepart,dureeDeVie);
                setWithExpiration(localStorageKeys.dateRetour ,formData.dateRetour,dureeDeVie);

                // valeur saveDateFile
                saveDataToFile(formData.heureDepart,localStorageData.heureDepart);
                saveDataToFile(formData.heureRetour,localStorageData.heureRetour);


                const startDateTime = combineDateAndTime(formData.dateDepart, formData.heureDepart);
                const endDateTime = combineDateAndTime(formData.dateRetour, formData.heureRetour);

                navigate(`/${ROUTES.RESULTS_PAGE}`, { state: { startDateTime, endDateTime, ...formData } });


            } catch (error) {
                console.error("Erreur lors de l'ajout de la propriété:", error);
                toast.error("Veuillez renseigner tous les champs du formulaire", { position: "bottom-right" });
            }
        }
    };

    // Empêcher la sélection de dates passées
    const today = new Date().toISOString().split('T')[0];

    // Vérifier que la date de retour est supérieure ou égale à la date de départ
    const isDateRetourValid = formData.dateRetour >= formData.dateDepart;

    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let formErrors = {};
        if (!formData.agenceDepart) formErrors.agenceDepart = "La localisation du lieu est requise";
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const [pickupLocation, setPickupLocation] = useState('');
    const [pickupSuggestions, setPickupSuggestions] = useState([]);

    // Obtenir les suggestions de lieu de départ
    const getPlacesByCountryStartLocation = async (query) => {
        try {
            const response = await axios.get(`${baseurl.url}/api/v1/places`, {
                params: { query: query },
            });
            const places = response.data.results;
            return places.map(place => place);
        } catch (error) {
            console.error("Erreur lors de la récupération des lieux : ", error);
        }
    };

    const handlePickupLocationChange = async (e) => {
        const value = e.target.value;
        setPickupLocation(value);
        if (value.length > 2) {
            const suggestions = await getPlacesByCountryStartLocation(value);
            setPickupSuggestions(suggestions);
        } else {
            setPickupSuggestions([]); // Réinitialiser la liste des suggestions
        }
    };

    const handlePickupSelection = (suggestion) => {
        setPickupLocation(`${suggestion.name} - ${suggestion.formatted_address}`);
        setFormData(prevState => ({
            ...prevState,
            agenceDepart: `${suggestion.name} - ${suggestion.formatted_address}`,
            lat: suggestion?.geometry?.location?.lat,
            lng: suggestion?.geometry?.location?.lng
        }));

        setWithExpiration(localStorageKeys.agenceDepArt, `${suggestion.name} - ${suggestion.formatted_address}`, dureeDeVie);
        setWithExpiration(localStorageKeys.agenceRetour, `${suggestion.name} - ${suggestion.formatted_address}`, dureeDeVie);
        setWithExpiration(localStorageKeys.latSelect, suggestion?.geometry?.location?.lat, dureeDeVie);
        setWithExpiration(localStorageKeys.longSelect, suggestion?.geometry?.location?.lng, dureeDeVie);
        setWithExpiration(localStorageKeys.latSelectRetour, suggestion?.geometry?.location?.lat, dureeDeVie);
        setWithExpiration(localStorageKeys.longSelectRetour, suggestion?.geometry?.location?.lng, dureeDeVie);
        setPickupSuggestions([]);
    };

    const handleCleanSugestions = () => {
        setPickupSuggestions([]);
    };

    return (
        <div className="banner-content">

            {/* New Reservation Prompt Section */}
            <div className="reservation-prompt">
                <h2>Réservez votre véhicule avec Rezaplus!</h2>
                <p>
                    Avec Rezaplus, profitez de la commodité de réserver votre location de voiture en ligne.
                    Commencez dès maintenant et assurez-vous d{"'"}avoir le véhicule parfait pour votre voyage.
                </p>
            </div>

            {/* Formulaire de réservation */}
            <form onSubmit={handleSubmit} className="bg-white p-5 rounded shadow-none">
                <div className="mb-3">
                    <label htmlFor="agenceDepart" className="form-label">Localisation</label>
                    <input style={{ height: "40px" }} type="text" className="form-control" id="agenceDepart" name="agenceDepart"
                        placeholder="Saisissez un aéroport, une ville ou un code postal" value={pickupLocation}
                        onChange={handlePickupLocationChange} />
                    {errors.agenceDepart && <div className="invalid-feedback d-block">{errors.agenceDepart}</div>}
                    {pickupSuggestions && pickupSuggestions.length > 0 && (
                        <ul className="rounded p-1 border"
                            style={{ position: 'absolute', zIndex: 1000, backgroundColor: 'white', maxWidth: "400px", listStyleType: 'none', padding: 0, maxHeight: "200px", overflow: "auto" }}>
                            {pickupSuggestions.map((suggestion, index) => (
                                <li className="border" key={index} style={{ padding: '5px', cursor: 'pointer' }} onClick={() =>
                                    handlePickupSelection(suggestion)}
                                >
                                    {`${suggestion.name} - ${suggestion.formatted_address}`}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                {/* Date et heure de départ */}
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <label htmlFor="dateDepart" className="form-label">Date de départ</label>
                        <input style={{ height: "40px" }} type="date" className="form-control date-picker" id="dateDepart"
                            name="dateDepart" value={formData.dateDepart} min={today} onChange={handleChange} required />
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="heureDepart" className="form-label">Heure de départ</label>
                        <Select id="heureDepart" name="heureDepart" options={hourOptions} value={formData.heureDepart}
                            onChange={(selectedOption) =>
                                handleHourChange(selectedOption, 'heureDepart')}
                        />
                    </div>
                </div>

                {/* Date et heure de retour */}
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <label htmlFor="dateRetour" className="form-label">Date de retour</label>
                        <input style={{ height: "40px" }} type="date" className="form-control date-picker" id="dateRetour"
                            name="dateRetour" value={formData.dateRetour} min={formData.dateDepart} onChange={handleChange} required />
                        {!isDateRetourValid && (
                            <small className="text-danger">La date de retour doit être supérieure ou égale à la date de
                                départ.</small>
                        )}
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="heureRetour" className="form-label">Heure de retour</label>
                        <Select id="heureRetour" name="heureRetour" options={hourOptions} value={formData.heureRetour}
                            onChange={(selectedOption) =>
                                handleHourChange(selectedOption, 'heureRetour')}
                        />
                    </div>
                </div>

                {/* Bouton de soumission */}
                <button type="submit" className="btn btn-primary" disabled={!isDateRetourValid}>
                    Soumettre
                </button>
            </form>
        </div>
    )
}

export default HomeVehicleContainerForm