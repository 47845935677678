import axios from "axios";
import { FETCH_MAINTENANCE_CATEGORYS_FAILURE, FETCH_MAINTENANCE_CATEGORYS_REQUEST, FETCH_MAINTENANCE_CATEGORYS_SUCCESS, FETCH_MAINTENANCE_CATEGORYS_SUCCESS_2, FETCH_MAINTENANCE_CATEGORY_FAILURE, FETCH_MAINTENANCE_CATEGORY_REQUEST, FETCH_MAINTENANCE_CATEGORY_SUCCESS } from "../../app/actions/actions";
import { ROUTES } from "../../config/routingUrl";
import { dureeDeVie, setWithExpiration } from "../../utils/storage/localvalueFuction";
import { localStorageData, localStorageKeys } from "../../utils/storage/localvalue";
import { baseurl } from "../../utils/url/baseurl";
import { saveDataToFile } from "../DataLocal";



// Create MaintenanceCategory
export const MaintenanceCategoryCreate = (
    data,
    toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_MAINTENANCE_CATEGORY_REQUEST });
        await axios
            .post(
                `${baseurl.url}/api/v1/maintenancecategorys/`,
                data,
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                }
            )
            .then((response) => {
                toast.success(" Nouvelle categorie de maintenance s créer avec succès");
                dispatch({ type: FETCH_MAINTENANCE_CATEGORY_SUCCESS });
            })
            .catch((error) => {
                toast.error("Type de MaintenanceCategory non ajouté")
                dispatch({ type: FETCH_MAINTENANCE_CATEGORY_FAILURE, payload: error.message });
            });
    };
}



// update insurance 
export const MaintenanceCategoryUpdateById = (idMaintenanceCategory, data, toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_MAINTENANCE_CATEGORY_REQUEST });
        await axios
            .put(`${baseurl.url}/api/v1/maintenancecategorys/update/${idMaintenanceCategory}`, data, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            })
            .then((response) => {
                dispatch({ type: FETCH_MAINTENANCE_CATEGORY_SUCCESS });
                dispatch(fetchMaintenanceCategoryById(idMaintenanceCategory));
                toast.success("Type de MaintenanceCategory mis à jour");
            })
            .catch((error) => {
                dispatch({ type: FETCH_MAINTENANCE_CATEGORY_FAILURE, payload: error.message });
                toast.error("Type de MaintenanceCategory non mis à jour")
            });
    };
}





// All insurance of plateforme
export function fetchMaintenanceCategorysAll() {
    return async (dispatch) => {
        dispatch({ type: FETCH_MAINTENANCE_CATEGORYS_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/maintenancecategorys/get_maintenancecategorys`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_MAINTENANCE_CATEGORYS_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_MAINTENANCE_CATEGORYS_SUCCESS_2, payload: response.data.data });
            saveDataToFile(response.data.data, localStorageData.MaintenanceCategorys);
        })
            .catch((error) => {
                //console.log(error);
                dispatch({ type: FETCH_MAINTENANCE_CATEGORYS_FAILURE, payload: error.message });
            });
    }
}


// get insurance by id 
export function fetchMaintenanceCategoryById(idMaintenanceCategory) {
    return async (dispatch) => {
        dispatch({ type: FETCH_MAINTENANCE_CATEGORY_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/maintenancecategorys/get_maintenancecategory/${idMaintenanceCategory}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_MAINTENANCE_CATEGORY_SUCCESS, payload: response.data.data });
        })
            .catch((error) => {
                dispatch({ type: FETCH_MAINTENANCE_CATEGORY_FAILURE, payload: error.message })
                //console.log(error);
            });
    }
}

