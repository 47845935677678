import { FETCH_INSURANCE_TYPES_FAILURE, FETCH_INSURANCE_TYPES_REQUEST, FETCH_INSURANCE_TYPES_SUCCESS, FETCH_INSURANCE_TYPES_SUCCESS_2, FETCH_INSURANCE_TYPE_FAILURE, FETCH_INSURANCE_TYPE_REQUEST, FETCH_INSURANCE_TYPE_SUCCESS } from "../actions/actions";



// insuranceTypeReducer.js
const initialState = {
    insuranceTypes: [],
    insuranceTypes2: [],
    insuranceType: {},
    insuranceTypeSelect: {},
    loadingInsuranceType:false,
    loadingInsuranceTypeSelect:false,
    loadingInsuranceTypes:false,
    errorInsuranceTypes:null,
    errorInsuranceType:null
    // ... autres états spécifiques à insuranceTypeReducer
  };
  
  const insuranceTypeReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_INSURANCE_TYPE_REQUEST:
        // Dispatch une action pour définir loading sur true dans le globalReducer
        return {
          ...state,
          loadingInsuranceType:true,
          errorInsuranceTypes:""
          // Autres manipulations d'état pour la demande de récupération des insuranceTypes
        };
        case FETCH_INSURANCE_TYPE_SUCCESS:
        return {
          ...state,
          loadingInsuranceType:false,
          errorInsuranceTypes:"",
          insuranceType:action.payload
        };
        case FETCH_INSURANCE_TYPE_FAILURE:
        return {
          ...state,
          loadingInsuranceType:false,
          errorInsuranceTypes:action.payload
        };


      case FETCH_INSURANCE_TYPES_REQUEST:
        return {
          ...state,
          loadingInsuranceTypes:true,
          errorInsuranceTypes:""
        };
       
      case FETCH_INSURANCE_TYPES_SUCCESS:
        return {
          ...state,
          insuranceTypes: action.payload,
          loadingInsuranceTypes:false,
          errorInsuranceTypes:""
          // Autres manipulations d'état pour le succès de la récupération des insuranceTypes
        };
        case FETCH_INSURANCE_TYPES_SUCCESS_2:
        return {
          ...state,
          insuranceTypes2: action.payload,
          loadingInsuranceTypes:false,
          errorInsuranceTypes:""
          // Autres manipulations d'état pour le succès de la récupération des insuranceTypes
        };
        
      case FETCH_INSURANCE_TYPES_FAILURE:
        // Dispatch une action pour définir loading sur false dans le globalReducer et enregistrer l'erreur
        return {
          ...state,
          loadingInsuranceTypes:false,
          errorInsuranceTypes:action.payload
          // Autres manipulations d'état pour l'échec de la récupération des insuranceTypes
        };
      // ... autres cas pour d'autres actions liées aux insuranceTypes

        
      default:
        return state;
    }
  };
  
  export default insuranceTypeReducer;
  