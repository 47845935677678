import React, { useState } from 'react';
import { Tab, Tabs, Form, Button, Image, Spinner, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCodePostalsAll } from '../../../actions/request/CodePostalRequest';
import { useEffect } from 'react';
import { UserCreate } from '../../../actions/request/UserRequest';
import { toast } from 'react-toastify';
import Select from "react-select";
import ProfileAccount from './ProfileAccount';
import { MdPersonOutline } from 'react-icons/md';
import ProfilePassword from './ProfilePassword';


function ProfileUserPage() {
    const [key, setKey] = useState('profile');
    const dispatch = useDispatch();
    const loadingUser = useSelector((state) => state.users.loadingUser);
    const codepostals = useSelector((state) => state.codepostals.codepostals);

    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        companyName: '',
        email: '',
        phone: '',
        codePostal: '',
        gender: 'Male',
        dateOfBirth: '',
        profilePicture: '',
        profession: '',
        address: '',
        addressPostal: '',
        numberTVA: '',
        role: 'SUPER_ADMIN',
        description: '',
        location: '',
    });

    useEffect(() => {
        // Fetch codePostals and companies from API
        dispatch(fetchCodePostalsAll());
    }, [dispatch]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSelectChange = (selectedOption, { name }) => {
        setFormData({
            ...formData,
            [name]: selectedOption ? selectedOption.value : '',
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Submit logic here
        dispatch(UserCreate(formData, toast))
    };

    // Prepare options for react-select
    const codePostalOptions = codepostals.map((code) => ({
        value: code._id,
        label: `${code.indicatif} - ${code.country}`,
    }));

    const genderOptions = [
        { value: 'Male', label: 'Homme' },
        { value: 'Female', label: 'Femme' },
    ];

    const roleOptions = [
        { value: 'INDIVIDUAL', label: 'Individu' },
        { value: 'COMPANY', label: 'Entreprise' },
        { value: 'ADMIN', label: 'Administrateur' },
        { value: 'SUPER_ADMIN', label: 'Super Administrateur' },
    ];

    return (
        <div className="m-b40">
            <Card>
                <Card.Header> <h3 className="mb-4"><MdPersonOutline/> Profil </h3> </Card.Header>
                <Card.Body>


                    <Tabs
                        id="profile-tabs"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                    >
                        <Tab eventKey="profile" title="Profil">

                            <ProfileAccount />

                        </Tab>
                        <Tab eventKey="change-password" title="Changer le Mot de Passe">
                            <ProfilePassword/>
                        </Tab>
                    </Tabs>


                </Card.Body>
            </Card>



        </div>
    );
}

export default ProfileUserPage;
