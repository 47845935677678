import { FETCH_MAINTENANCE_CATEGORYS_FAILURE, FETCH_MAINTENANCE_CATEGORYS_REQUEST, FETCH_MAINTENANCE_CATEGORYS_SUCCESS, FETCH_MAINTENANCE_CATEGORYS_SUCCESS_2, FETCH_MAINTENANCE_CATEGORY_FAILURE, FETCH_MAINTENANCE_CATEGORY_REQUEST, FETCH_MAINTENANCE_CATEGORY_SUCCESS } from "../actions/actions";



// maintenanceCategoryReducer.js
const initialState = {
    maintenanceCategorys: [],
    maintenanceCategorys2: [],
    maintenanceCategory: {},
    maintenanceCategorySelect: {},
    loadingMaintenanceCategory:false,
    loadingMaintenanceCategorySelect:false,
    loadingMaintenanceCategorys:false,
    errorMaintenanceCategorys:null,
    errorMaintenanceCategory:null
    // ... autres états spécifiques à maintenanceCategoryReducer
  };
  
  const maintenanceCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_MAINTENANCE_CATEGORY_REQUEST:
        // Dispatch une action pour définir loading sur true dans le globalReducer
        return {
          ...state,
          loadingMaintenanceCategory:true,
          errorMaintenanceCategorys:""
          // Autres manipulations d'état pour la demande de récupération des maintenanceCategorys
        };
        case FETCH_MAINTENANCE_CATEGORY_SUCCESS:
        return {
          ...state,
          loadingMaintenanceCategory:false,
          errorMaintenanceCategorys:"",
          maintenanceCategory:action.payload
        };
        case FETCH_MAINTENANCE_CATEGORY_FAILURE:
        return {
          ...state,
          loadingMaintenanceCategory:false,
          errorMaintenanceCategorys:action.payload
        };


      case FETCH_MAINTENANCE_CATEGORYS_REQUEST:
        return {
          ...state,
          loadingMaintenanceCategorys:true,
          errorMaintenanceCategorys:""
        };
       
      case FETCH_MAINTENANCE_CATEGORYS_SUCCESS:
        return {
          ...state,
          maintenanceCategorys: action.payload,
          loadingMaintenanceCategorys:false,
          errorMaintenanceCategorys:""
          // Autres manipulations d'état pour le succès de la récupération des maintenanceCategorys
        };
        case FETCH_MAINTENANCE_CATEGORYS_SUCCESS_2:
        return {
          ...state,
          maintenanceCategorys2: action.payload,
          loadingMaintenanceCategorys:false,
          errorMaintenanceCategorys:""
          // Autres manipulations d'état pour le succès de la récupération des maintenanceCategorys
        };
        
      case FETCH_MAINTENANCE_CATEGORYS_FAILURE:
        // Dispatch une action pour définir loading sur false dans le globalReducer et enregistrer l'erreur
        return {
          ...state,
          loadingMaintenanceCategorys:false,
          errorMaintenanceCategorys:action.payload
          // Autres manipulations d'état pour l'échec de la récupération des maintenanceCategorys
        };
      // ... autres cas pour d'autres actions liées aux maintenanceCategorys

        
      default:
        return state;
    }
  };
  
  export default maintenanceCategoryReducer;
  