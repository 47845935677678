import { FETCH_TRIPS_FAILURE, FETCH_TRIPS_REQUEST, FETCH_TRIPS_SUCCESS, FETCH_TRIPS_SUCCESS_2, FETCH_TRIP_FAILURE, FETCH_TRIP_REQUEST, FETCH_TRIP_SUCCESS } from "../actions/actions";



// tripReducer.js
const initialState = {
    trips: [],
    trips2: [],
    trip: {},
    tripSelect: {},
    loadingTrip:false,
    loadingTripSelect:false,
    loadingTrips:false,
    errorTrips:null,
    errorTrip:null
    // ... autres états spécifiques à tripReducer
  };
  
  const tripReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_TRIP_REQUEST:
        // Dispatch une action pour définir loading sur true dans le globalReducer
        return {
          ...state,
          loadingTrip:true,
          errorTrips:""
          // Autres manipulations d'état pour la demande de récupération des trips
        };
        case FETCH_TRIP_SUCCESS:
        return {
          ...state,
          loadingTrip:false,
          errorTrips:"",
          trip:action.payload
        };
        case FETCH_TRIP_FAILURE:
        return {
          ...state,
          loadingTrip:false,
          errorTrips:action.payload
        };


      case FETCH_TRIPS_REQUEST:
        return {
          ...state,
          loadingTrips:true,
          errorTrips:""
        };
       
      case FETCH_TRIPS_SUCCESS:
        return {
          ...state,
          trips: action.payload,
          loadingTrips:false,
          errorTrips:""
          // Autres manipulations d'état pour le succès de la récupération des trips
        };
        case FETCH_TRIPS_SUCCESS_2:
        return {
          ...state,
          trips2: action.payload,
          loadingTrips:false,
          errorTrips:""
          // Autres manipulations d'état pour le succès de la récupération des trips
        };
        
      case FETCH_TRIPS_FAILURE:
        // Dispatch une action pour définir loading sur false dans le globalReducer et enregistrer l'erreur
        return {
          ...state,
          loadingTrips:false,
          errorTrips:action.payload
          // Autres manipulations d'état pour l'échec de la récupération des trips
        };
      // ... autres cas pour d'autres actions liées aux trips

        
      default:
        return state;
    }
  };
  
  export default tripReducer;

  

