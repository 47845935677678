import React, { useState, useEffect, useMemo } from 'react';
import { Line, Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement, Title,
    Tooltip, Legend
} from 'chart.js';
import { Badge, Button, Card, Col, Dropdown, Form, Modal, Row, Spinner, Tab, Table, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReservationsAll, fetchReservationsAllByidClientId, fetchReservationsAllByidUserId } from
    '../../actions/request/ReservationRequest';
import { fetchDamagesAll, fetchDamagesAllByUserId } from '../../actions/request/DamageRequest';
import { fetchPropertysAll, fetchPropertysAllByOwnerId, fetchPropertysFilters } from
    '../../actions/request/PropertyRequest';
import { fetchVehiclesAll, fetchVehiclesAllByOwnerId, fetchVehiclesAllFilter } from
    '../../actions/request/VehicleRequest';
import { fetchInsurancesAll, fetchInsurancesAllByUserId } from '../../actions/request/InssuranceRequest';
import { fetchPaymentsAll } from '../../actions/request/PaymentRequest';
import moment from 'moment';
import { profileRoleType } from '../../utils/dataApi/dataFormApi';
import { localStorageKeys } from '../../utils/storage/localvalue';
import { getAndCheckLocalStorage } from '../../utils/storage/localvalueFuction';
import { RiEye2Fill, RiFileExcel2Fill, RiFilePdf2Fill } from 'react-icons/ri';
import { ROUTES } from '../../config/routingUrl';
import { useNavigate } from 'react-router-dom';
import { MdCopyAll, MdFilePresent, MdOutlineSearch, MdReport, MdWeb } from 'react-icons/md';
import Select from "react-select";
import { toast } from 'react-toastify';
import html2canvas from 'html2canvas';
import * as XLSX from "xlsx";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { fetchExpenseCategorysAll } from '../../actions/request/ExpenseCategoryRequest';
import { fetchExpensessAll } from '../../actions/request/ExpenseRequest';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement, Title, Tooltip, Legend);

const StatisticsReportPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const expenses = useSelector((state) => state.expenses.expenses);
    const expensecategorys = useSelector((state) => state.expensecategorys.expensecategorys);

    const reservations = useSelector((state) => state.reservations.reservations);
    const paymentsData = useSelector((state) => state.payments.payments);
    const damages = useSelector((state) => state.damages.damages);
    const propertys = useSelector((state) => state.propertys.propertys);
    const vehicles = useSelector((state) => state.vehicles.vehicles);
    const insurances = useSelector((state) => state.insurances.insurances);

    const [propertySelect, setpropertySelect] = useState();
    const [vehicleSelect, setvehicleSelect] = useState();
    const [payments, setpayments] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [showLoading, setshowLoading] = useState(false);
    const [showWebSite, setshowWebSite] = useState(false);
    const [showClientModal, setShowClientModal] = useState(false);
    const [uniqueClients, setUniqueClients] = useState([]);

    // Function to generate PDF report
    const generatePDF = async () => {
        const elementToExclude = document.getElementById('element-to-exclude');

        if (elementToExclude) {
            // Masquer l'élément avant la génération du PDF
            elementToExclude.style.display = 'none';

            const doc = new jsPDF();
            const reportContent = document.getElementById('report-content'); // ID du contenu à exporter

            if (reportContent) {
                const canvas = await html2canvas(reportContent);
                const imgData = canvas.toDataURL('image/png');
                doc.addImage(imgData, 'PNG', 10, 10, 190, 0);
                doc.save('report.pdf');
            } else {
                toast.error('Impossible de générer le rapoort veillez réesayer encore', { position: "bottom-right" });
            }

            // Afficher à nouveau l'élément
            elementToExclude.style.display = 'block';
        }
    };


    const exportPDF = async () => {
        const elementToExclude = document.getElementById('element-to-exclude');

        if (elementToExclude) {
            // Masquer l'élément avant la génération du PDF
            elementToExclude.style.display = 'none';

            const doc = new jsPDF();
            const reportContent = document.getElementById('report-content-pdf'); // ID du contenu à exporter

            if (reportContent) {
                const canvas = await html2canvas(reportContent);
                const imgData = canvas.toDataURL('image/png');
                doc.addImage(imgData, 'PNG', 10, 10, 190, 0);
                doc.save('Export.pdf');
            } else {
                toast.error('Impossible de générer le rapoort veillez réesayer encore', { position: "bottom-right" });
            }

            // Afficher à nouveau l'élément
            elementToExclude.style.display = 'block';
        }
    };

    // Fonction pour générer le fichier Excel
    const exportToExcel = () => {
        const workbook = XLSX.utils.book_new();
        const worksheetData = reservations.map(reservation => ({
            Ordre: reservation.ordre,
            Propriété: reservation.property?.name || "",
            Véhicule: reservation.vehicle?.brand || "",
            DateCréation: moment(reservation.createdAt).format('DD MMM YYYY'),
            Statut: reservation.status,
            // PourcentageUtilisation: ((calculateDays(reservation.startDate, reservation.endDate) /
            //     moment(endDate).diff(moment(startDate), 'days')) * 100).toFixed(2),
            Client: `${reservation && reservation.client && reservation.client.firstname ? reservation.client.firstname : ""}
${reservation && reservation.client && reservation.client.lastname ? reservation.client.lastname : ""}`,
            Début: moment(reservation.startDate).format("DD-MM-YYYY - HH:mm"),
            Fin: moment(reservation.endDate).format("DD-MM-YYYY - HH:mm"),
            Prix: Number(reservation.totalPrice).toFixed(1) || "0"
        }));

        const worksheet = XLSX.utils.json_to_sheet(worksheetData);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Réservations');
        XLSX.writeFile(workbook, 'report.xlsx');
    };


    const [chartData, setChartData] = useState(null);

    // Récupération des données lors du chargement du composant
    useEffect(() => {
        const userId = getAndCheckLocalStorage(localStorageKeys.userId);
        const userRole = getAndCheckLocalStorage(localStorageKeys.profileRole);

        if (userRole === profileRoleType.USER) {
            dispatch(fetchExpenseCategorysAll());
            dispatch(fetchExpensessAll());
            dispatch(fetchDamagesAllByUserId(userId));
            dispatch(fetchInsurancesAllByUserId(userId));
            dispatch(fetchPropertysFilters("", "", "", "", userId));
            dispatch(fetchVehiclesAllFilter("", "", "", userId));
            dispatch(fetchPaymentsAll());
            dispatch(fetchReservationsAllByidUserId(userId));
        } else if (userRole === profileRoleType.CUSTOMER) {
            dispatch(fetchReservationsAllByidClientId(userId));
        } else {
            dispatch(fetchDamagesAll());
            dispatch(fetchInsurancesAll());
            dispatch(fetchPropertysFilters());
            dispatch(fetchVehiclesAllFilter());
            dispatch(fetchPaymentsAll());
            dispatch(fetchReservationsAll());
        }
    }, [dispatch]);

    useEffect(() => {
        const userId = getAndCheckLocalStorage(localStorageKeys.userId);
        const userRole = getAndCheckLocalStorage(localStorageKeys.profileRole);

        let paymentGetData = [];
        if (userRole === profileRoleType.USER) {
            paymentGetData = paymentsData?.filter(pay => pay.reservation?.userAdd === userId) || [];
        } else if (userRole === profileRoleType.CUSTOMER) {
            paymentGetData = paymentsData?.filter(pay => pay.reservation?.client?._id === userId) || [];
        } else {
            paymentGetData = paymentsData || [];
        }
        setpayments(paymentGetData);
    }, [paymentsData]);

    // Filtrage des clients uniques
    useEffect(() => {
        const uniqueClientsSet = new Set(reservations.map(reservation => reservation.client._id));
        const clientsArray = [...uniqueClientsSet].map(id => {
            const clientReservations = reservations.filter(res => res.client._id === id);
            const usagePercentage = (clientReservations.length / reservations.length * 100).toFixed(2);
            const client = clientReservations[0]?.client; // Get the first client details
            return client ? {
                id: client._id,
                firstname: client.firstname,
                lastname: client.lastname,
                email: client.email,
                phone: client.phone,
                address: client.address,
                usagePercentage,
            } : null;
        }).filter(client => client !== null);
        setUniqueClients(clientsArray);



    }, [reservations]);




    useEffect(() => {
        // Regrouper les dépenses par catégorie
        const categories = expenses.reduce((acc, expense) => {
            const categoryName = expense?.category?.name || expense?.insurance?.type?.name || expense?.damage?.type?.name || expense?.maintenance?.category?.name;
            if (!acc[categoryName]) {
                acc[categoryName] = 0;
            }
            acc[categoryName] += expense.amount;
            return acc;
        }, {});

        // Préparer les données pour le graphique circulaire
        const data = {
            labels: Object.keys(categories),
            datasets: [
                {
                    data: Object.values(categories),
                    backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
                    hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF']
                }
            ]
        };

        setChartData(data);
    }, [expenses]);





    const groupByMonth = (data, field) => {
        const grouped = Array(12).fill(0);
        data.forEach(item => {
            const month = moment(item.createdAt).month();
            grouped[month] += item[field];
        });
        return grouped;
    };

    const paymentsByMonth = payments ? groupByMonth(payments, 'amount') : [];
    const damagesAndInsurancesByMonth = expenses && expenses.length > 0 ? groupByMonth(expenses, 'amount') : [];
    const totalDamages = damages ? damages.reduce((sum, damage) => sum + damage.estimatedCost, 0) : 0;
    const totalInsurances = insurances ? insurances.reduce((sum, insurance) => sum + insurance.premiumAmount, 0) : 0;

    const lineChartData = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: 'Revenus (Paiements)',
                data: paymentsByMonth,
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
            },
            {
                label: 'Dépenses (Dommages et Assurances)',
                data: damagesAndInsurancesByMonth,
                borderColor: 'rgba(255, 99, 132, 1)',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
            },
        ],
    };

    // Function to calculate days between two dates
    const calculateDays = (startDate, endDate) => {
        const start = moment(startDate);
        const end = moment(endDate);
        return end.diff(start, 'days') + 1; // Includes the first day
    };

    // Calculer le taux d'occupation
    


    // Utilisation de la fonction pour obtenir le taux d'occupation
    
    const reservationStatusCounts = (reservations) => {
        const statusCounts = { pending: 0, confirmed: 0, cancelled: 0, completed: 0 };
        reservations.forEach((reservation) => {
            if (reservation.status === 'PENDING') statusCounts.pending++;
            else if (reservation.status === 'CONFIRMED') statusCounts.confirmed++;
            else if (reservation.status === 'CANCELLED') statusCounts.cancelled++;
            else if (reservation.status === 'COMPLETED') statusCounts.completed++;
        });
        return statusCounts;
    };

    const statusCounts = reservationStatusCounts(reservations);

    const pieChartData = {
        labels: ['En attente', 'Confirmées', 'Annulées', 'Terminées'],
        datasets: [
            {
                data: [
                    statusCounts.pending,
                    statusCounts.confirmed,
                    statusCounts.cancelled,
                    statusCounts.completed
                ],
                backgroundColor: ['#FFCE56', '#36A2EB', '#FF6384', '#4BC0C0'],
                hoverBackgroundColor: ['#FFCE56', '#36A2EB', '#FF6384', '#4BC0C0'],
            },
        ],
    };

    const renderStatusBadge = (status) => {
        switch (status) {
            case 'PENDING':
                return <Badge bg="warning">En attente</Badge>;
            case 'CONFIRMED':
                return <Badge bg="success">Confirmée</Badge>;
            case 'CANCELLED':
                return <Badge bg="danger">Annulée</Badge>;
            case 'COMPLETED':
                return <Badge bg="primary">Terminée</Badge>;
            default:
                return <Badge bg="secondary">{status}</Badge>;
        }
    };

    // Options de filtre
    const vehicleOptions = vehicles.map(item => ({ value: item._id, label: item.brand })) || [];
    const propertyOptions = propertys.map(item => ({ value: item._id, label: item.name })) || [];

    const handleShowLoading = () => { setshowLoading(true) }
    const handleCloseLoading = () => { setshowLoading(false) }

    const handleSearchFilter = () => {
        handleShowLoading();
        const userId = getAndCheckLocalStorage(localStorageKeys.userId);
        const userRole = getAndCheckLocalStorage(localStorageKeys.profileRole);

        if (userRole === profileRoleType.USER) {
            dispatch(fetchExpensessAll(startDate, endDate, "", "", propertySelect?.value, vehicleSelect?.value, userId));
            dispatch(fetchReservationsAllByidUserId(userId, startDate, endDate, propertySelect?.value, vehicleSelect?.value, "", customerSelect?.value));
            dispatch(fetchPaymentsAll(startDate, endDate));
        } else if (userRole === profileRoleType.CUSTOMER) {
            dispatch(fetchReservationsAllByidClientId(userId, startDate, endDate, propertySelect?.value, vehicleSelect?.value, ""));
            dispatch(fetchPaymentsAll(startDate, endDate));
        } else {
            dispatch(fetchExpensessAll(startDate, endDate, "", "", propertySelect?.value, vehicleSelect?.value));
            dispatch(fetchReservationsAll(startDate, endDate, propertySelect?.value, vehicleSelect?.value, ""));
            dispatch(fetchPaymentsAll(startDate, endDate));
        }
        handleCloseLoading();
    }

    const handleShowWebsite = () => { setshowWebSite(true) }
    const handleCloseWebsite = () => { setshowWebSite(false) }

    const handleShowClientModal = () => setShowClientModal(true);
    const handleCloseClientModal = () => setShowClientModal(false);



    const [showVehicleModal, setShowVehicleModal] = useState(false);
    const [showPropertyModal, setShowPropertyModal] = useState(false);

    const handleShowVehicleModal = () => setShowVehicleModal(true);
    const handleCloseVehicleModal = () => setShowVehicleModal(false);

    const handleShowPropertyModal = () => setShowPropertyModal(true);
    const handleClosePropertyModal = () => setShowPropertyModal(false);




    const getCleanUrl = () => {
        const { protocol, hostname, port } = window.location;
        const portPart = port ? `:${port}` : '';
        return `${protocol}//${hostname}${portPart}`;
    };

    // Nouvelle fonction pour obtenir le nombre de clients uniques
    const getUniqueClientsCount = (reservations) => {
        const uniqueClients = new Set(reservations.map(reservation => reservation.client._id));
        return uniqueClients.size; // Renvoie le nombre de clients uniques
    };

    const uniqueClientsCount = getUniqueClientsCount(reservations);

    const [customerSelect, setcustomerSelect] = useState();
    const customersOptions = uniqueClients && uniqueClients.length > 0 ? [...uniqueClients].map((item) => ({ value: item._id, label: item.firstname + " " + item.lastname + " tel :" + item.phone })) : []


    // Fonction pour calculer le total des jours d'occupation
    const calculateTotalOccupiedDays = () => {
        return reservations.reduce((total, reservation) => {
            const daysTaken = calculateDays(reservation.startDate, reservation.endDate);
            return total + daysTaken;
        }, 0);
    };

    // Utilisation de la fonction dans le composant
    const totalOccupiedDays = calculateTotalOccupiedDays();


    // Fonction pour calculer le nombre total de véhicules réservés
    const calculateTotalVehiclesReserved = () => {
        const vehicleIds = new Set(reservations.map(reservation => reservation.vehicle?._id));
        return vehicleIds.size; // Retourne le nombre unique de véhicules
    };

    // Fonction pour calculer le nombre total de propriétés réservées
    const calculateTotalPropertiesReserved = () => {
        const propertyIds = new Set(reservations.map(reservation => reservation.property?._id));
        return propertyIds.size; // Retourne le nombre unique de propriétés
    };

    // Utilisation des fonctions dans le composant
    const totalVehiclesReserved = calculateTotalVehiclesReserved();
    const totalPropertiesReserved = calculateTotalPropertiesReserved();


    const pluralize = (count, singular, plural) => {
        return count === 1 ? singular : plural || singular + 's';
    };







    // Fonction pour récupérer la liste des véhicules réservés
    const getReservedVehicles = () => {
        const vehicleIds = new Set(reservations.map(reservation => reservation.vehicle?._id));
        return Array.from(vehicleIds).map(id => {
            return vehicles.find(vehicle => vehicle._id === id);
        }).filter(vehicle => vehicle !== undefined); // Filtrer les véhicules valides
    };

    // Fonction pour récupérer la liste des propriétés réservées
    const getReservedProperties = () => {
        const propertyIds = new Set(reservations.map(reservation => reservation.property?._id));
        return Array.from(propertyIds).map(id => {
            return propertys.find(property => property._id === id);
        }).filter(property => property !== undefined); // Filtrer les propriétés valides
    };

    // Utilisation des fonctions dans le composant
    const reservedVehicles = getReservedVehicles();
    const reservedProperties = getReservedProperties();



    // Fonction pour compter les réservations confirmées
    const countConfirmedReservations = () => {
        return reservations.filter(reservation => reservation.status === 'CONFIRMED').length || 0;
    };

    // Fonction pour compter les réservations en cours
    const countOngoingReservations = () => {
        return reservations.filter(reservation => reservation.status === 'PENDING').length || 0; // Assurez-vous que 'IN_PROGRESS' est le bon statut utilisé
    };

    // Fonction pour compter les réservations terminées
    const countCompletedReservations = () => {
        return reservations.filter(reservation => reservation.status === 'COMPLETED').length || 0;
    };

    // Utilisation des fonctions dans le composant
    const totalConfirmedReservations = countConfirmedReservations();
    const totalOngoingReservations = countOngoingReservations();
    const totalCompletedReservations = countCompletedReservations();


    // Calculer le total des dépenses supplémentaires
    const totalExpenses = expenses?.reduce((acc, expense) => acc + expense.amount, 0) || 0;

    const totalPayments = payments?.reduce((acc, payment) => acc + payment.amount, 0) || 0;

    // Calcul du total général avec la réduction
    const total = totalDamages + totalExpenses;



    // Expense export en exel 
    // Fonction pour exporter en Excel
    const exportToExcelExpense = () => {
        const worksheet = XLSX.utils.json_to_sheet(
            expenses.map((expense) => ({
                "Désignation": expense?.category?.name || expense?.insurance?.type?.name || expense?.damage?.type?.name || expense?.maintenance?.category?.name || "Autres",
                "Model / Adresse": expense?.vehicle?.brand || expense?.vehicle?.model || expense?.vehicle?.vehiclebrand || expense?.property?.name || expense?.property?.address || expense?.damage?.reservation?.vehicle?.brand || expense?.damage?.reservation?.property?.name || "",
                "Montant (DZD)": expense?.amount || 0,
                "Date estimé": moment(expense?.date).format("DD-MM-YYYY"),
                "Date de création ": moment(expense?.createdAt).format("DD-MM-YYYY"),
            }))
        );
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, worksheet, "Report");
        XLSX.writeFile(wb, "Dépense_rezaplus.xlsx");
    };

    // Fonction pour exporter en PDF
    const exportToPDFExpense = () => {
        const doc = new jsPDF();

        // Ajoute le titre
        doc.text("Depense supplémentaires", 14, 10);

        // Données de la table
        const tableData = expenses.map((expense) => [
            expense?.category?.name || expense?.insurance?.type?.name || expense?.damage?.type?.name || expense?.maintenance?.category?.name || "Autres",
            expense?.vehicle?.brand || expense?.vehicle?.model || expense?.vehicle?.vehiclebrand || expense?.property?.name || expense?.property?.address || expense?.damage?.reservation?.vehicle?.brand || expense?.damage?.reservation?.property?.name || "",
            expense?.amount || 0,
            moment(expense?.date).format("DD-MM-YYYY"),
            moment(expense?.createdAt).format("DD-MM-YYYY"),
        ]);

        // Options de table
        doc.autoTable({
            head: [
                [
                    "Désignation",
                    "Model / Adresse",
                    "Montant (DZD)",
                    "Date estimé",
                    "Date Création",
                ]
            ],
            body: tableData,
            startY: 20,
            theme: "grid",
        });

        doc.save("Dépense_rezaplus.pdf");
    };







    // Paiements
    // Fonction pour exporter en Excel
    const exportToExcelPayment = () => {
        const worksheet = XLSX.utils.json_to_sheet(
            payments.map((paiement) => ({
                "Reservation": paiement?.reservation?.ordre || "",
                "Patrimoine": paiement?.vehicle?.brand || paiement?.vehicle?.model || paiement?.vehicle?.vehiclebrand || paiement?.property?.name || paiement?.property?.address || "",
                "Montant (DZD)": paiement?.amount || 0,
                "Début de réservation": moment(paiement?.reservation?.startDate || "").format("DD-MM-YYYY HH:MM"),
                "Fin de réservation": moment(paiement?.reservation?.endDate || "").format("DD-MM-YYYY HH:MM"),
                "Date de création ": moment(paiement?.createdAt).format("DD-MM-YYYY")
            }))
        );
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, worksheet, "Paiements");
        XLSX.writeFile(wb, `Paiment${startDate} ${endDate}_rezaplus.xlsx`);
    };

    // Fonction pour exporter en PDF
    const exportToPDFPayment = () => {
        const doc = new jsPDF();

        // Ajoute le titre
        doc.text("Paiments " + startDate + " " + endDate, 14, 10);

        // Données de la table
        const tableData = payments.map((paiement) => [
            paiement?.reservation?.ordre || "",
            paiement?.vehicle?.brand || paiement?.vehicle?.model || paiement?.vehicle?.vehiclebrand || paiement?.property?.name || paiement?.property?.address || "",
            paiement?.amount || 0,
            moment(paiement?.reservation?.startDate || "").format("DD-MM-YYYY HH:MM"),
            moment(paiement?.reservation?.endDate || "").format("DD-MM-YYYY HH:MM"),
            moment(paiement?.createdAt).format("DD-MM-YYYY"),
        ]);

        // Options de table
        doc.autoTable({
            head: [
                [
                    "Réservation",
                    "Patrimoine",
                    "Montant (DZD)",
                    "Début de réservation",
                    "Fin de réservation",
                    "Date Création",
                ]
            ],
            body: tableData,
            startY: 20,
            theme: "grid",
        });

        doc.save(`Paiments${startDate} ${endDate}_rezaplus.pdf`);
    };







    const aggregatedData = useAggregatedData();
    // Calcul des totaux
    const totals = aggregatedData.reduce(
        (acc, entry) => {
            acc.totalPayments += entry.totalPayments;
            acc.totalExpenses += entry.totalExpenses;
            acc.totalDaysReserved += entry.totalDaysReserved;
            acc.totalDaysInReservationMonths += entry.totalDaysInReservationMonths; // Total des jours concernés par les mois
            acc.clientsCount += entry.clientsCount;
            return acc;
        },
        {
            totalPayments: 0,
            totalExpenses: 0,
            totalDaysReserved: 0,
            totalDaysInReservationMonths: 0, // Nouveau champ
            clientsCount: 0,
        }
    );

    // Nouvelle fonction de calcul du taux d'occupation total
    const calculateOccupancyRate = (totalDaysReserved, totalDaysInReservationMonths) => {
        // Calculer le taux d'occupation en fonction des jours réservés et des jours concernés
        return (totalDaysReserved / totalDaysInReservationMonths) * 100;
    };

    // Utilisation dans votre composant
    const totalOccupancyRate = calculateOccupancyRate(
        totals.totalDaysReserved,
        totals.totalDaysInReservationMonths
    );








    return (
        <div className="" id="report-content">
            <Card className="mb-4">
                <Card.Header>
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <div>
                            <h4 className="mb-4">Rapport</h4>
                        </div>
                        <div>


                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    {getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.ADMIN ||
                        getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.USER ||
                        getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.SUPPORT ?
                        <ul className="list-group">
                            <li className="list-group-item"
                                style={{ display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between" }}>
                                <div className="flex" style={{ display: "flex", flexWrap: "wrap", maxWidth: "100%" }}>
                                    <Form>
                                        <Row>
                                            <Col md={4}>
                                                <Form.Group controlId="vehicleSelect" style={{ minWidth: "150px" }}>
                                                    <Form.Label>Véhicule</Form.Label>
                                                    <Select value={vehicleSelect} onChange={setvehicleSelect} options={vehicleOptions}
                                                        isClearable placeholder="Choix" />
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group controlId="propertySelect" style={{ minWidth: "150px" }}>
                                                    <Form.Label>Propriété</Form.Label>
                                                    <Select value={propertySelect} isClearable isSearchable onChange={setpropertySelect}
                                                        options={propertyOptions} placeholder="Choix" />
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group controlId="customerSelect" style={{ minWidth: "150px" }}>
                                                    <Form.Label>Client</Form.Label>
                                                    <Select value={customerSelect} onChange={setcustomerSelect} options={customersOptions}
                                                        isClearable placeholder="Choix" />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    <div className="p-1">
                                        <label>{""}</label>
                                        <input type="date" style={{ height: "40px" }} className="form-control" value={startDate}
                                            onChange={(e) => setStartDate(e.target.value)} />
                                    </div>
                                    <div className="p-1">
                                        <label>{""}</label>
                                        <input type="date" style={{ height: "40px" }} className="form-control" value={endDate}
                                            onChange={(e) => setEndDate(e.target.value)} />
                                    </div>
                                    <div className="m-1">
                                        <label>{""}</label>
                                        <div>
                                            <Button variant='primary' id="element-to-exclude" size='sm' onClick={handleSearchFilter}>
                                                <MdOutlineSearch />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        : ""
                    }
                </Card.Body>
            </Card>

            {/* Cartes des statistiques */}

            <Tabs defaultActiveKey="generale" id="reservation-details-tabs" className="mb-3">

                {/* Tab pour Total des jours d'occupation */}
                <Tab eventKey="generale" title="Vue génerale">
                    <Row>
                        <Col md={3}>
                            <Card className="mb-4">
                                <Card.Body>
                                    <p className="card-text"><strong>Revenus</strong></p>
                                    <hr />
                                    <p className="card-text text-lg">
                                        {totalPayments} DZD
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card className="mb-4">
                                <Card.Body>
                                    <p className="card-text"><strong>Dépenses</strong></p>
                                    <hr />
                                    <p className="card-text text-lg">
                                        {totalExpenses} DZD
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card className="mb-4">
                                <Card.Body>
                                    <p className="card-text"><strong>Réservations</strong></p>
                                    <hr />
                                    <p className="card-text text-lg">
                                        <span style={{ fontSize: "30px" }}>{reservations && reservations.length > 0 ?
                                            Number(reservations.length) : "Aucune"}</span>
                                    </p>
                                    <hr />
                                    <p className="card-text text-lg">
                                        <span className="text-warning"> {totalOngoingReservations} En cours </span>
                                        <span className="text-success"> {totalConfirmedReservations} Confirmés </span>
                                        <span className="text-primary"> {totalCompletedReservations} Terminés </span>
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={3}>
                            <Card className="mb-4">
                                <Card.Body>
                                    <p className="card-text"><strong>Jours</strong></p>
                                    <hr />
                                    <p className="card-text text-lg">
                                        <span style={{ fontSize: "30px" }}>{totals?.totalDaysReserved?.toFixed(0)}</span>
                                    </p>

                                </Card.Body>
                            </Card>
                        </Col>

                        {(
                            getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.USER ||
                            getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.ADMIN ||
                            getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.SUPER_ADMIN ||
                            getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.SUPPORT) &&
                            <Col md={3}>
                                <Card className="mb-4">
                                    <Card.Body>
                                        <p className="card-text"><strong>Clients </strong></p>
                                        <hr />
                                        <p className="card-text text-lg">
                                            <span style={{ fontSize: "30px" }}>{uniqueClientsCount > 0 ? uniqueClientsCount : "Aucun"}</span>

                                            {
                                                uniqueClientsCount > 0 && (<Button size="sm" variant='primary' id="element-to-exclude" onClick={handleShowClientModal}
                                                    className="ms-2">
                                                    Voir tous

                                                </Button>)
                                            }
                                        </p>
                                    </Card.Body>
                                </Card>
                            </Col>
                        }

                        <Col md={3}>
                            <Card className="mb-4">
                                <Card.Body>
                                    <p className="card-text"><strong>Nombre de véhicules réservés</strong></p>
                                    <hr />
                                    <p className="card-text text-lg">
                                        <span style={{ fontSize: "30px" }}>{reservedVehicles.length}

                                            {
                                                reservedVehicles.length > 0 && (<Button size="sm" variant='primary' id="element-to-exclude" onClick={handleShowVehicleModal}
                                                    className="ms-2">
                                                    Voir tous
                                                </Button>)
                                            }

                                        </span>
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card className="mb-4">
                                <Card.Body>
                                    <p className="card-text"><strong>Nombre de propriétés réservées</strong></p>
                                    <hr />
                                    <p className="card-text text-lg">
                                        <span style={{ fontSize: "30px" }}>{reservedProperties.length}

                                            {
                                                reservedProperties.length > 0 && (<Button size="sm" variant='primary' id="element-to-exclude" onClick={handleShowPropertyModal}
                                                    className="ms-2">
                                                    Voir tous
                                                </Button>)
                                            }

                                        </span>
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Tab>

                {/*Depense de placteforme question */}
                <Tab eventKey="expenses" title="Dépenses Supplémentaires">
                    {
                        // Depenses
                        getAndCheckLocalStorage(localStorageKeys.userRole) == profileRoleType.SUPER_ADMIN ||
                            getAndCheckLocalStorage(localStorageKeys.userRole) == profileRoleType.ADMIN ||
                            getAndCheckLocalStorage(localStorageKeys.userRole) == profileRoleType.USER ||
                            getAndCheckLocalStorage(localStorageKeys.userRole) == profileRoleType.SUPPORT
                            ?
                            <Row>


                                <Col md={12}>

                                    <Card>
                                        <Card.Header>

                                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                                <h5>Dépenses supplémentaires</h5>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="primary" size="sm" id="dropdown-basic">
                                                        Exporter
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item as="button" onClick={exportToExcelExpense}>
                                                            Exporter en Excel
                                                        </Dropdown.Item>
                                                        <Dropdown.Item as="button" onClick={exportToPDFExpense}>
                                                            Exporter en PDF
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>

                                        </Card.Header>
                                        <Card.Body>
                                            <Card.Text>
                                                <strong>Montant  </strong>{totalExpenses}
                                            </Card.Text>

                                            {/* Tableau des rapports filtrés */}
                                            {
                                                expenses && expenses.length > 0 ?
                                                    <Table striped bordered hover responsive >
                                                        <thead>
                                                            <tr>
                                                                <th>Frais Divers</th>
                                                                <th>Patrimoine</th>
                                                                <th>Montant</th>
                                                                <th>Date Estimé</th>
                                                                <th>Date Création</th>
                                                                <th>Statut</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {expenses && expenses.length > 0 && expenses.map((expense, index) => (
                                                                <tr >

                                                                    <td>
                                                                        {
                                                                            expense?.category?.name || expense?.insurance?.type?.name || expense?.damage?.type?.name || expense?.maintenance?.category?.name || "Autres"
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            expense?.vehicle?.brand || expense?.vehicle?.model || expense?.vehicle?.vehiclebrand || expense?.property?.name || expense?.property?.address || expense?.damage?.reservation?.vehicle?.brand || expense?.damage?.reservation?.property?.name || ""
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <strong>{expense?.amount || ""}</strong>
                                                                    </td>
                                                                    <td>
                                                                        <strong>{moment(expense?.date).format("DD-MM-YYYY")}</strong>
                                                                    </td>
                                                                    <td>
                                                                        <strong>{moment(expense?.createdAt).format("DD-MM-YYYY")}</strong>
                                                                    </td>
                                                                    <td>
                                                                        <Badge variant="primary">{expense?.status == "Completed" ? "Validé" : expense.status}</Badge>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                    :
                                                    <div style={{ width: "100%", height: "100%", justifyContent: "center", alignContent: "center" }}>
                                                        <div> Aucune dépense externe</div>
                                                    </div>
                                            }
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            : ""}
                </Tab>







                {/*Paiment de plateforme */}


                <Tab eventKey="payments" title="Paiements">
                    {
                        // Depenses
                        getAndCheckLocalStorage(localStorageKeys.userRole) == profileRoleType.SUPER_ADMIN ||
                            getAndCheckLocalStorage(localStorageKeys.userRole) == profileRoleType.ADMIN ||
                            getAndCheckLocalStorage(localStorageKeys.userRole) == profileRoleType.USER ||
                            getAndCheckLocalStorage(localStorageKeys.userRole) == profileRoleType.SUPPORT
                            ?
                            <Row>
                                <Col md={12}>

                                    <Card>
                                        <Card.Header>
                                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                                <h5>Paiements</h5>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="primary" size="sm" id="dropdown-basic">
                                                        Exporter
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item as="button" onClick={exportToExcelPayment}>
                                                            Exporter en Excel
                                                        </Dropdown.Item>
                                                        <Dropdown.Item as="button" onClick={exportToPDFPayment}>
                                                            Exporter en PDF
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>

                                        </Card.Header>
                                        <Card.Body>
                                            <Card.Text>
                                                <strong>Montant  </strong>{totalPayments}
                                            </Card.Text>
                                            {
                                                payments && payments.length > 0 ?

                                                    <Table striped bordered hover responsive >
                                                        <thead>
                                                            <tr>
                                                                <th>Reservation</th>
                                                                <th>Patrimoine</th>
                                                                <th>Montant </th>
                                                                <th>Date</th>
                                                                <th>Statut</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {payments && payments.length > 0 && payments.map((paiement) => (
                                                                <tr key={paiement._id}>
                                                                    <td>
                                                                        {paiement?.reservation?.ordre || ""}
                                                                    </td>
                                                                    <td>
                                                                        {paiement?.vehicle?.brand || paiement?.vehicle?.model || paiement?.vehicle?.vehiclebrand || paiement?.property?.name || paiement?.property?.address || ""}
                                                                    </td>
                                                                    <td>{Number(paiement?.amount).toFixed(2)}</td>
                                                                    <td>{moment(paiement?.createdAt).format("DD-MM-YYYY")}</td>
                                                                    <td>
                                                                        {renderStatusBadge(paiement.status)}
                                                                    </td>
                                                                    <td>
                                                                        <Button variant='primary' size="sm" onClick={() => { navigate(`/${ROUTES.DASHBOARD}/${ROUTES.PAYMENTS_DETAIL_PLATEFORM}/${paiement._id}`) }}>
                                                                            <RiEye2Fill /> Détails
                                                                        </Button>
                                                                    </td>

                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                    :
                                                    <p>Aucun paiement</p>
                                            }
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            : ""}

                </Tab>


                <Tab eventKey="cumuls" title="Cumuls">
                    <VehiclePropertyReport />
                </Tab>

                <Tab eventKey="graphiques" title="Graphiques" className='text-dark' style={{ color: "black" }}>
                    <Card>
                        <Card.Header><h4>Graphiques</h4></Card.Header>
                        <Card.Body>
                            <Row>

                                <Col md={6}>
                                    <div>
                                        <h6>Catégorie de dépenses</h6>
                                        <hr />
                                        {chartData ? (
                                            <Pie data={chartData} />
                                        ) : (
                                            <p>Chargement des données...</p>
                                        )}
                                    </div>
                                </Col>

                                {
                                    reservations && reservations.length > 0 ?
                                        <Col md={6}>
                                            <Card className="mb-4">
                                                <Card.Body>
                                                    <h6>Réservations par État</h6>
                                                    <hr />
                                                    <Pie data={pieChartData} />
                                                </Card.Body>
                                            </Card>
                                        </Col> : ""
                                }

                                {
                                    (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER || profileRoleType.SUPER_ADMIN | - profileRoleType.ADMIN || profileRoleType.SUPPORT) &&
                                    (reservations && reservations.length > 0) &&
                                    (
                                        <Col md={12}>
                                            <Card className="mb-4">
                                                <Card.Body>
                                                    <h6>Revenus et Dépenses</h6>
                                                    <hr />
                                                    <Line data={lineChartData} />
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )
                                }
                            </Row>

                        </Card.Body>

                    </Card>

                </Tab>
            </Tabs>





            {/* Table pour afficher toutes les réservations */}
            

            <Modal show={showLoading} size='sm' onHide={handleCloseLoading} centered style={{ background: "transparent" }}>
                <Modal.Body>
                    <div
                        style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignContent: "center" }}>
                        <div>
                            <Spinner variant='primary' />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Modal pour afficher la liste des clients */}
            <Modal show={showClientModal} onHide={handleCloseClientModal} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Liste des Clients</Modal.Title>
                </Modal.Header>
                <Modal.Body className="py-5" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    {uniqueClients.length > 0 ? (
                        <Table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Nom</th>
                                    <th>Prénom</th>
                                    <th>Email</th>
                                    <th>Téléphone</th>
                                    <th>Adresse</th>
                                    <th>Pourcentage d{"'"}utilisation (%)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {uniqueClients.map(client => (
                                    <tr >
                                        <td>{client?.firstname || ""}</td>
                                        <td>{client?.lastname || ""}</td>
                                        <td>{client?.email || ""}</td>
                                        <td>{client?.phone || ""}</td>
                                        <td>{client?.address || ""}</td>
                                        <td>{client?.usagePercentage || ""}</td> {/* Affichage du pourcentage d'utilisation */}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <div className="justify-content-center py-5">
                            <p className="text-center">Aucun client</p>
                        </div>
                    )
                    }
                </Modal.Body>
            </Modal>




            {/*// Affichage des modals*/}
            <Modal show={showVehicleModal} onHide={handleCloseVehicleModal} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Liste des Véhicules Réservés</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>Designation</th>
                                <th>Marque</th>
                                <th>Model</th>
                                <th>Prix journalier</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reservedVehicles.length > 0 ? reservedVehicles.map(vehicle => (
                                <tr >
                                    <td>{vehicle?.brand || ""}</td>
                                    <td>{vehicle?.vehiclebrand || ""}</td>
                                    <td>{vehicle?.model || ""}</td>
                                    <td>{vehicle?.basePricePerDay || ""}</td>
                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan="4">Aucun véhicule réservé</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>

            <Modal show={showPropertyModal} onHide={handleClosePropertyModal} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Liste des Propriétés Réservées</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>Nom</th>
                                <th>Adresse</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reservedProperties.length > 0 ? reservedProperties.map(property => (
                                <tr key={property._id}>
                                    <td>{property?.name || ""}</td>
                                    <td>{property?.address || ""}</td>
                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan="3">Aucune propriété réservée</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>





        </div>
    )
};

export default StatisticsReportPage;





export const useAggregatedData = () => {
    const reservations = useSelector((state) => state.reservations.reservations);
    const expenses = useSelector((state) => state.expenses.expenses);

    const getDaysInMonth = (year, month) => {
        // Retourne le nombre de jours dans un mois donné
        return new Date(year, month + 1, 0).getDate();
    };

    const calculateOccupancyRate = (reservedDays, totalDays) => {
        // Calculer le taux d'occupation en fonction des jours réservés et des jours totaux
        return (reservedDays / totalDays) * 100;
    };

    const splitReservationByMonth = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const reservationsByMonth = [];

        while (start <= end) {
            const year = start.getFullYear();
            const month = start.getMonth();
            const daysInMonth = getDaysInMonth(year, month);

            // Calculer les jours réservés pour le mois actuel
            const firstDayOfMonth = new Date(year, month, 1);
            const lastDayOfMonth = new Date(year, month, daysInMonth);
            const daysInCurrentMonth =
                (Math.min(end, lastDayOfMonth) - Math.max(start, firstDayOfMonth)) /
                (1000 * 60 * 60 * 24) + 1;

            reservationsByMonth.push({
                year,
                month,
                daysReserved: daysInCurrentMonth,
            });

            // Passer au mois suivant
            start.setMonth(start.getMonth() + 1);
            start.setDate(1);
        }

        return reservationsByMonth;
    };

    const aggregatedData = useMemo(() => {
        const data = {};

        // Parcours des réservations
        reservations.forEach((reservation) => {
            const { vehicle, property, startDate, endDate, status, client, totalPrice } = reservation;
            const isValidReservation = ["CONFIRMED", "COMPLETED"].includes(status);

            const key = vehicle?._id || property?._id; // Clé basée sur le véhicule ou la propriété
            if (!key) return;

            // Initialisation si l'élément n'existe pas encore
            if (!data[key]) {
                data[key] = {
                    item: vehicle || property,
                    totalPayments: 0,
                    totalExpenses: 0,
                    totalDaysReserved: 0, // Nombre total de jours réservés
                    clients: new Set(),
                    totalDaysInReservationMonths: 0, // Nombre total de jours dans les mois couverts par les réservations
                    dates: [], // Tableau des dates (startDate, endDate)
                };
            }

            if (isValidReservation) {
                // Fractionner les jours réservés par mois
                const monthlyReservations = splitReservationByMonth(startDate, endDate);

                // Ajouter les jours réservés et les jours du mois pour chaque segment
                monthlyReservations.forEach(({ daysReserved, year, month }) => {
                    data[key].totalDaysReserved += daysReserved;
                    data[key].totalDaysInReservationMonths += getDaysInMonth(year, month);
                });

                 // Ajouter les informations sur les dates (startDate, endDate)
                 data[key].dates.push({ startDate, endDate });

                data[key].totalPayments += totalPrice;
                data[key].clients.add(client?._id);
            }
        });

        // Parcours des dépenses
        expenses.forEach((expense) => {
            const { vehicle, property, amount } = expense;
            const key = vehicle?._id || property?._id;
            if (key && data[key]) {
                data[key].totalExpenses += amount;
            }
        });

        // Transformation des données pour calcul des indicateurs supplémentaires
        return Object.values(data).map((entry) => ({
            ...entry,
            clientsCount: entry.clients.size, // Nombre unique de clients
            occupancyRate: calculateOccupancyRate(
                entry.totalDaysReserved,
                entry.totalDaysInReservationMonths
            ),
        }));
    }, [reservations, expenses]);

    

    return aggregatedData;
};





const VehiclePropertyReport = () => {
    const aggregatedData = useAggregatedData();

    // Taux de conversion fictifs
    const conversionRates = {
        DinarToEuro: 1 / 256, // Calculé comme 0.00391
        DinarToDollar: 1 / 244, // Exemple : si 1 Dollar = 300 Dinars
    };

    // Fonction pour convertir les montants
    const convertCurrency = (amount, rate) => (amount * rate).toFixed(2);

    // Calcul des totaux
     // Calcul des totaux
     const totals = aggregatedData.reduce(
        (acc, entry) => {
            acc.totalPayments += entry.totalPayments;
            acc.totalExpenses += entry.totalExpenses;
            acc.totalDaysReserved += entry.totalDaysReserved;
            acc.totalDaysInReservationMonths += entry.totalDaysInReservationMonths; // Total des jours concernés par les mois
            acc.clientsCount += entry.clientsCount;
            return acc;
        },
        {
            totalPayments: 0,
            totalExpenses: 0,
            totalDaysReserved: 0,
            totalDaysInReservationMonths: 0, // Nouveau champ
            clientsCount: 0,
        }
    );

    // Nouvelle fonction de calcul du taux d'occupation total
    const calculateOccupancyRate = (totalDaysReserved, totalDaysInReservationMonths) => {
        // Calculer le taux d'occupation en fonction des jours réservés et des jours concernés
        return (totalDaysReserved / totalDaysInReservationMonths) * 100;
    };

    // Utilisation dans votre composant
    const totalOccupancyRate = calculateOccupancyRate(
        totals.totalDaysReserved,
        totals.totalDaysInReservationMonths
    );



    // Fonction pour exporter en Excel
    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(
            aggregatedData.map((entry) => ({
                "Designation": entry.item.brand || entry.item.name,
                "Model / Adresse": entry.item.model || entry.item.address,
                "Total Paiements (DZD)": entry.totalPayments,
                "Total Paiements (€)": convertCurrency(entry.totalPayments, conversionRates.DinarToEuro),
                "Total Dépenses (DZD)": entry.totalExpenses,
                "Total Dépenses (€)": convertCurrency(entry.totalExpenses, conversionRates.DinarToEuro),
                "Nombre de Jours Réservés": entry.totalDaysReserved.toFixed(0),
                "Taux d'Occupation (%)": entry.occupancyRate.toFixed(2),
                "Nombre de Clients": entry.clientsCount
            }))
        );
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, worksheet, "Report");
        XLSX.writeFile(wb, "Cumuls_activite_rezaplus.xlsx");
    };

    // Fonction pour exporter en PDF
    const exportToPDF = () => {
        const doc = new jsPDF();

        // Ajoute le titre
        doc.text("Cumuls des Activités", 14, 10);

        // Données de la table
        const tableData = aggregatedData.map((entry) => [
            entry.item.brand || entry.item.name,
            entry.item.model || entry.item.address,
            entry.totalPayments.toLocaleString(),
            convertCurrency(entry.totalPayments, conversionRates.DinarToEuro),
            entry.totalExpenses.toLocaleString(),
            convertCurrency(entry.totalExpenses, conversionRates.DinarToEuro),
            entry.totalDaysReserved.toFixed(0),
            entry.occupancyRate.toFixed(2),
            entry.clientsCount
        ]);

        // Options de table
        doc.autoTable({
            head: [
                [
                    "Designation",
                    "Model / Adresse",
                    "Total Paiements (DZD)",
                    "Total Paiements (€)",
                    "Total Dépenses (DZD)",
                    "Total Dépenses (€)",
                    "Nombre de Jours Réservés",
                    "Taux d'Occupation (%)",
                    "Nombre de Clients"
                ]
            ],
            body: tableData,
            startY: 20,
            theme: "grid",
        });

        // Ajouter la ligne des totaux
        doc.autoTable({
            body: [
                [
                    "TOTALS",
                    "",
                    totals.totalPayments.toLocaleString(),
                    convertCurrency(totals.totalPayments, conversionRates.DinarToEuro),
                    totals.totalExpenses.toLocaleString(),
                    convertCurrency(totals.totalExpenses, conversionRates.DinarToEuro),
                    totals.totalDaysReserved.toFixed(0),
                    totalOccupancyRate.toFixed(2),
                    totals.clientsCount
                ]
            ],
            startY: doc.lastAutoTable.finalY + 10, // Positionner en dessous de la table précédente
            theme: "grid",
        });

        doc.save("Cumuls_activite_rezaplus.pdf");
    };

    return (
        <div className="container mt-5">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h5 className="mb-4 text-left">Cumuls des activités</h5>
                <Dropdown>
                    <Dropdown.Toggle variant="primary" size="sm" id="dropdown-basic">
                        Exporter
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item as="button" onClick={exportToExcel}>
                            Exporter en Excel
                        </Dropdown.Item>
                        <Dropdown.Item as="button" onClick={exportToPDF}>
                            Exporter en PDF
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <Table responsive hover bordered striped>
                <thead>
                    <tr>
                        <th>Designation</th>
                        <th>Model / Adresse</th>
                        <th>Total Paiements (DZD)</th>
                        <th>Total Paiements (€)</th>
                        <th>Total Dépenses (DZD)</th>
                        <th>Total Dépenses (€)</th>
                        <th>Nombre de Jours Réservés</th>
                        <th>Taux d{"'"}Occupation (%)</th>
                        <th>Nombre de Clients</th>
                    </tr>
                </thead>
                <tbody>
                    {aggregatedData.map((entry, index) => (
                        <tr key={index}>
                            <td>{entry.item.brand || entry.item.name}</td>
                            <td>{entry.item.model || entry.item.address}</td>
                            <td>{entry.totalPayments.toLocaleString()} DZD</td>
                            <td>
                                {convertCurrency(entry.totalPayments, conversionRates.DinarToEuro)} €
                            </td>
                            <td>{entry.totalExpenses.toLocaleString()} DZD</td>
                            <td>
                                {convertCurrency(entry.totalExpenses, conversionRates.DinarToEuro)} €
                            </td>
                            <td>{entry.totalDaysReserved.toFixed(0)} jours</td>
                            <td>{entry.occupancyRate.toFixed(2)}%</td>
                            <td>{entry.clientsCount}</td>
                        </tr>
                    ))}
                </tbody>
                <tfoot class="font-bold">
                    <tr>
                        <td colSpan="2"><strong>TOTALS</strong></td>
                        <td>{totals.totalPayments.toLocaleString()} DZD</td>
                        <td>
                            {convertCurrency(totals.totalPayments, conversionRates.DinarToEuro)} €
                        </td>
                        <td>{totals.totalExpenses.toLocaleString()} DZD</td>
                        <td>
                            {convertCurrency(totals.totalExpenses, conversionRates.DinarToEuro)} €
                        </td>
                        <td>{totals.totalDaysReserved.toFixed(0)} jours</td>
                        <td>{totalOccupancyRate.toFixed(2)}%</td>
                        <td>{totals.clientsCount}</td>
                    </tr>
                </tfoot>
            </Table>
        </div>
    );
};

