import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Badge, Modal, Button, Table, Row, Col, Card, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReservationsAll, fetchReservationsAllByidClientId, fetchReservationsAllByidUserId } from '../../../actions/request/ReservationRequest';
import { MdOutlineFilterList, MdSearch } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../config/routingUrl';
import { localStorageKeys } from '../../../utils/storage/localvalue';
import { getAndCheckLocalStorage } from '../../../utils/storage/localvalueFuction';
import { profileRoleType } from '../../../utils/dataApi/dataFormApi';
import { fetchPropertysAllByOwnerId, fetchPropertysFilters } from '../../../actions/request/PropertyRequest';
import { fetchVehiclesAllByOwnerId, fetchVehiclesAllFilter } from '../../../actions/request/VehicleRequest';
import { RiEye2Fill } from 'react-icons/ri';

const localizer = momentLocalizer(moment);

const renderStatusBadge = (status) => {
    switch (status) {
        case 'PENDING':
            return <Badge bg="warning">En attente</Badge>;
        case 'CONFIRMED':
            return <Badge bg="success">Confirmée</Badge>;
        case 'CANCELLED':
            return <Badge bg="danger">Annulée</Badge>;
        case 'COMPLETED':
            return <Badge bg="primary">Terminée</Badge>;
        default:
            return <Badge bg="secondary">{status}</Badge>;
    }
};

const ReservationCalendarPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const vehicles = useSelector((state) => state.vehicles.vehicles);
    const propertys = useSelector((state) => state.propertys.propertys);
    const reservationData2 = useSelector((state) => state.reservations.reservations2);
    const reservations = useSelector((state) => state.reservations.reservations2);

    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [reservationData, setreservationData] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    // Ajout des états pour les filtres
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    // Récupération des données de véhicules et de propriétés
    //  filtre des option
    const vehicleOptions = vehicles && vehicles.length > 0 ? vehicles.map((item) => {
        return ({
            value: item._id, label: item.brand
        })
    }) : [];

    const propertyOptions = propertys && propertys.length > 0 ? propertys.map((item) => {
        return ({
            value: item._id, label: item.name
        })
    }) : [];

    useEffect(() => {
        if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.USER) {

            dispatch(fetchPropertysFilters("", "", "", "", getAndCheckLocalStorage(localStorageKeys.userId)));
            dispatch(fetchVehiclesAllFilter("", "", "", getAndCheckLocalStorage(localStorageKeys.userId)));
            dispatch(fetchReservationsAllByidUserId(getAndCheckLocalStorage(localStorageKeys.userId)));
        } else if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.CUSTOMER) {
            dispatch(fetchReservationsAllByidClientId(getAndCheckLocalStorage(localStorageKeys.userId)));
        }
        else {
            dispatch(fetchPropertysFilters());
            dispatch(fetchVehiclesAllFilter());
            dispatch(fetchReservationsAll());
        }
    }, [dispatch]);

    useEffect(() => {
        if (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER) {
            const reservationGetData = reservations && reservations.length > 0 ? [...reservations].filter((reserv) => (reserv.vehicle && reserv.vehicle.addBy && reserv.vehicle.addBy == getAndCheckLocalStorage(localStorageKeys.userId)) || (reserv.property && reserv.property.addBy && reserv.property.addBy == getAndCheckLocalStorage(localStorageKeys.userId))) : [];
            setreservationData(reservationGetData);
        } else {
            setreservationData(reservations);
        }
    }, [reservations]);

    const handleClose = () => setShowModal(false);
    const handleShow = (event) => {
        setSelectedEvent(event);
        setShowModal(true);
    };

    const formatReservationsForCalendar = () => {
        return reservationData && reservationData.length > 0 ? reservationData.map(reservation => ({
            ordre: reservation.ordre,
            title: `${reservation && reservation.vehicle && reservation.vehicle.brand ? reservation.vehicle.brand : ""} ${reservation && reservation.property && reservation.property.name ? reservation.property.name : ""}
            (${reservation && reservation.client && reservation.client.firstname ? reservation.client.firstname : ""} ${reservation && reservation.client && reservation.client.lastname ? reservation.client.lastname : ""})`,
            start: new Date(reservation.startDate),
            end: new Date(reservation.endDate),
            allDay: true,
            resource: reservation,
        })) : [];
    };

    useEffect(() => {
        setEvents(formatReservationsForCalendar());
    }, [reservationData]);

    const eventStyleGetter = (event) => {
        const backgroundColor = event.resource.status === 'PENDING' ? '#f9c74f' :
            event.resource.status === 'CONFIRMED' ? '#43aa8b' :
                event.resource.status === 'COMPLETED' ? '#00bb2e' :
                    event.resource.status === 'CANCELLED' ? '#f94144' : '#577590';
        return { style: { backgroundColor, color: 'white', borderRadius: '5px' } };
    };

    // function filter
    const handleFilter = () => {
        if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.USER) {
            // administrateur de la plateforme en question

            dispatch(fetchReservationsAllByidUserId(
                getAndCheckLocalStorage(localStorageKeys.userId),
                startDate, endDate, selectedProperty?.value, selectedVehicle?.value, ""
            ))


        } else if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.CUSTOMER) {
            dispatch(fetchReservationsAllByidClientId(
                getAndCheckLocalStorage(localStorageKeys.userId),
                startDate, endDate, selectedProperty?.value, selectedVehicle?.value
            ));
        }
        else {
            dispatch(fetchReservationsAll(startDate, endDate, selectedProperty?.value, selectedVehicle?.value, ""));

        }
    }


    const paginatedEvents = events.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage * itemsPerPage < events.length) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <div className="">
            <Card>
                <Card.Header><h3>Calendrier des réservations</h3></Card.Header>
                <Card.Body>

                    {/* Ajout du système de filtres */}
                    <div className="filter-container mb-4">
                        <Row>
                        
                            {
                                (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN ||
                                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT
                                ) &&
                                vehicleOptions && vehicleOptions.length > 0 && (
                                    <Col md={3}>
                                        <Select
                                            value={selectedVehicle}
                                            onChange={setSelectedVehicle}
                                            isClearable
                                            options={vehicleOptions}
                                            placeholder="Véhicule"
                                            isDisabled={!!selectedProperty} // Désactiver si une propriété est sélectionnée
                                        />
                                    </Col>
                                )
                            }


                            {
                                (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN ||
                                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT
                                ) &&
                                propertyOptions && propertyOptions.length > 0 && (
                                    <Col md={3}>
                                        <Select
                                            value={selectedProperty}
                                            onChange={setSelectedProperty}
                                            isClearable
                                            options={propertyOptions}
                                            placeholder="Propriété"
                                            isDisabled={!!selectedVehicle} // Désactiver si un véhicule est sélectionné
                                        />
                                    </Col>)
                            }

                            <Col md={2}>
                                <Form.Group>
                                    <Form.Control
                                        style={{ height: "40px" }}
                                        type="date"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={2}>
                                <Form.Group>
                                    <Form.Control
                                        style={{ height: "40px" }}
                                        type="date"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>


                            <Col md={2}>
                                <Button onClick={handleFilter} className="mt-3" size="sm"> <MdSearch /> </Button>
                            </Col>

                        </Row>
                    </div>

                    <div className="calendar-container">
                        <BigCalendar
                            localizer={localizer}
                            events={events}
                            startAccessor="start"
                            endAccessor="end"
                            style={{ height: 500 }}
                            eventPropGetter={eventStyleGetter}
                            views={['month', 'week', 'day']}
                            defaultView="month"
                            popup={true}
                            onSelectEvent={handleShow}
                        />
                    </div>

                    <div className="mt-4">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Reservation</th>
                                    <th>Client</th>
                                    <th>Dates</th>
                                    <th>Statut</th>
                                    <th>Coût Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedEvents.map((event, index) => (
                                    <tr key={index} onClick={() => handleShow(event)}>
                                        <td>{event.ordre}</td>
                                        <td>{event && event.resource && event.resource.client && event.resource.client.firstname ? event.resource.client.firstname : ""} {event && event.resource && event.resource.client && event.resource.client.lastname ? event.resource.client.lastname : ""}</td>
                                        <td>{`${moment(event.start).format('DD MMM YYYY HH:MM')} - ${moment(event.end).format('DD MMM YYYY HH:MM')}`}</td>
                                        <td>{renderStatusBadge(event.resource?.status)}</td>
                                        <td> <strong>{Number(event.resource?.totalPrice).toFixed(2)}</strong></td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div className="d-flex justify-content-start mt-3">
                            <Button className="m-1" onClick={handlePreviousPage} disabled={currentPage === 1} size="sm">Précédent</Button>
                            <Button className="m-1" onClick={handleNextPage} disabled={currentPage * itemsPerPage >= events.length} size="sm">Suivant</Button>
                        </div>
                    </div>

                    {selectedEvent && (
                        <Modal show={showModal} onHide={handleClose} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Détails de la réservation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="vehicle-info">
                                    <strong>Patrimoine :</strong> {selectedEvent.resource?.vehicle?.model || selectedEvent.resource?.property?.name || ""}<br />
                                </div>
                                <div className="client-info">
                                    <strong>Client :</strong> {selectedEvent.resource?.client?.firstname || ""} {selectedEvent.resource?.client?.lastname || ""}<br />
                                    <strong>Email :</strong> {selectedEvent.resource?.client?.email || ""}<br />
                                    <strong>Téléphone :</strong> {selectedEvent.resource?.client?.phone || ""}
                                </div>
                                <div className="reservation-details">
                                    <strong>Dates :</strong> {moment(selectedEvent.start).format('DD MMM YYYY HH:MM')} - {moment(selectedEvent.end).format('DD MMM YYYY HH:MM')}<br />
                                    <strong>Statut :</strong> {renderStatusBadge(selectedEvent.resource?.status)}<br />
                                    <strong>Coût Total :</strong> {Number(selectedEvent.resource?.totalPrice).toFixed(2)} DZD
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" size='sm' onClick={() => {
                                    handleClose();
                                    navigate(`/${ROUTES.DASHBOARD}/${ROUTES.RESERVATION_VIEW}/${selectedEvent.resource?._id}`)
                                }}>
                                    <RiEye2Fill /> Details
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    )}

                </Card.Body>
            </Card>
        </div>
    );
};

export default ReservationCalendarPage;