import axios from "axios";
import { FETCH_MOTIFS_FAILURE, FETCH_MOTIFS_REQUEST, FETCH_MOTIFS_SUCCESS, FETCH_MOTIFS_SUCCESS_2, FETCH_MOTIF_FAILURE, FETCH_MOTIF_REQUEST, FETCH_MOTIF_SUCCESS } from "../../app/actions/actions";
import { ROUTES } from "../../config/routingUrl";
import { dureeDeVie, setWithExpiration } from "../../utils/storage/localvalueFuction";
import { localStorageData, localStorageKeys } from "../../utils/storage/localvalue";
import { baseurl } from "../../utils/url/baseurl";
import { saveDataToFile } from "../DataLocal";



// Create Motif
export const MotifCreate = (
    data,
    toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_MOTIF_REQUEST });
        await axios
            .post(
                `${baseurl.url}/api/v1/motifs/`,
                data,
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                }
            )
            .then((response) => {
                toast.success(" Nouvelle assurances créer avec succès");
                dispatch({ type: FETCH_MOTIF_SUCCESS });
            })
            .catch((error) => {
                toast.error("Type de Motif non ajouté")
                dispatch({ type: FETCH_MOTIF_FAILURE, payload: error.message });
            });
    };
}



// update insurance 
export const MotifUpdateById = (idMotif, data, toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_MOTIF_REQUEST });
        await axios
            .put(`${baseurl.url}/api/v1/motifs/update/${idMotif}`, data, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            })
            .then((response) => {
                dispatch({ type: FETCH_MOTIF_SUCCESS });
                dispatch(fetchMotifById(idMotif));
                toast.success("Type de Motif mis à jour");
            })
            .catch((error) => {
                dispatch({ type: FETCH_MOTIF_FAILURE, payload: error.message });
                toast.error("Type de Motif non mis à jour")
            });
    };
}





// All insurance of plateforme
export function fetchMotifsAll() {
    return async (dispatch) => {
        dispatch({ type: FETCH_MOTIFS_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/motifs/get_motifs`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_MOTIFS_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_MOTIFS_SUCCESS_2, payload: response.data.data });
            saveDataToFile(response.data.data, localStorageData.Motifs);
        })
            .catch((error) => {
                //console.log(error);
                dispatch({ type: FETCH_MOTIFS_FAILURE, payload: error.message });
            });
    }
}


// get insurance by id 
export function fetchMotifById(idMotif) {
    return async (dispatch) => {
        dispatch({ type: FETCH_MOTIF_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/motifs/get_motif/${idMotif}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_MOTIF_SUCCESS, payload: response.data.data });
        })
            .catch((error) => {
                dispatch({ type: FETCH_MOTIF_FAILURE, payload: error.message })
                //console.log(error);
            });
    }
}

