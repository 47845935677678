import axios from "axios";
import { FETCH_RESERVATION_TYPES_FAILURE, FETCH_RESERVATION_TYPES_REQUEST, FETCH_RESERVATION_TYPES_SUCCESS, FETCH_RESERVATION_TYPES_SUCCESS_2, FETCH_RESERVATION_TYPE_FAILURE, FETCH_RESERVATION_TYPE_REQUEST, FETCH_RESERVATION_TYPE_SUCCESS } from "../../app/actions/actions";
import { localStorageData,} from "../../utils/storage/localvalue";
import { baseurl } from "../../utils/url/baseurl";
import { saveDataToFile } from "../DataLocal";



// Create ReservationType
export const ReservationTypeCreate = (
    data,
    toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_RESERVATION_TYPE_REQUEST });
        await axios
            .post(
                `${baseurl.url}/api/v1/reservation_types/`,
                data,
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                }
            )
            .then((response) => {
                toast.success("nouvel type de véhicule ajouté");
                dispatch({ type: FETCH_RESERVATION_TYPE_SUCCESS });
            })
            .catch((error) => {
                toast.error("type de véhicule non ajouté")
                dispatch({ type: FETCH_RESERVATION_TYPE_FAILURE, payload: error.message });
            });
    };
}



// update insurance 
export const ReservationTypeUpdateById = (idReservationType, data, toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_RESERVATION_TYPE_REQUEST });
        await axios
            .put(`${baseurl.url}/api/v1/reservation_types/update/${idReservationType}`, data, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            })
            .then((response) => {
                dispatch({ type: FETCH_RESERVATION_TYPE_SUCCESS });
                dispatch(fetchReservationTypeById(idReservationType));
                toast.success("Type de véhicle mis à jour");
            })
            .catch((error) => {
                dispatch({ type: FETCH_RESERVATION_TYPE_FAILURE, payload: error.message });
                toast.error("Véhicule non mis à jour")
            });
    };
}





// All insurance of plateforme
export function fetchReservationTypesAll() {
    return async (dispatch) => {
        dispatch({ type: FETCH_RESERVATION_TYPES_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/reservation_types/get_reservation_types`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_RESERVATION_TYPES_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_RESERVATION_TYPES_SUCCESS_2, payload: response.data.data });
            saveDataToFile(response.data.data, localStorageData.ReservationTypes);
        })
            .catch((error) => {
                //console.log(error);
                dispatch({ type: FETCH_RESERVATION_TYPES_FAILURE, payload: error.message });
            });
    }
}


// get insurance by id 
export function fetchReservationTypeById(idReservationType) {
    return async (dispatch) => {
        dispatch({ type: FETCH_RESERVATION_TYPE_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/reservation_types/get_reservation_type/${idReservationType}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_RESERVATION_TYPE_SUCCESS, payload: response.data.data });
        })
            .catch((error) => {
                dispatch({ type: FETCH_RESERVATION_TYPE_FAILURE, payload: error.message })
                //console.log(error);
            });
    }
}

