import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, ListGroup, ListGroupItem, Row, Col, Table, Button, Modal } from 'react-bootstrap';
import { fetchReservationById } from '../../../actions/request/ReservationRequest';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import html2pdf from 'html2pdf.js';
import { MdDownload } from 'react-icons/md';

const ReservationInvoicePage = () => {
    const reservationParam = useParams();
    const dispatch = useDispatch();
    const reservation = useSelector((state) => state.reservations.reservation);
    const invoiceRef = useRef(); // Ref to target the section to export

    const [idReservation, setidReservation] = useState();

    useEffect(() => {
        setidReservation(reservationParam?.id)
        dispatch(fetchReservationById(reservationParam?.id || idReservation));
    }, [dispatch, reservationParam?.id]);

    const [showModalPdfConfirm, setshowModalPdfConfirm] = useState(false);




    if (!reservation) {
        return <p>Aucune Facture trouvée sur cette reservation.</p>;
    }

    const {
        vehicle,
        property,
        client,
        firstname, lastname, email, codePostal, phone,
        startDate,
        endDate,
        totalPrice,
        deliveryFee,
        discount,
        commissionPercentage,
        numberOfGuests,
        specialRequests,
    } = reservation;


    const calculeDiscount = () => {
        const remiseDiscount = Number(Number(totalPrice || 0) - Number(discount || 0))
        return remiseDiscount
    }

    // Calcul des jours de location
    const start = new Date(startDate);
    const end = new Date(endDate);
    const numberOfDays = Math.ceil((end - start) / (1000 * 60 * 60 * 24));

    const calculateDuration = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const durationInMs = end - start;
        const durationInHours = durationInMs / (1000 * 60 * 60);

        let days = Math.floor(durationInHours / 24);
        let hours = Math.floor(durationInHours % 24);
        let minutes = Math.floor((durationInHours % 1) * 60);

        // Si on dépasse l'heure ou les minutes, on arrondit au jour suivant
        if (hours > 0 || minutes > 0) {
            days += 1;  // Ajout d'un jour supplémentaire si on dépasse
            hours = 0;  // On remet les heures à 0
            minutes = 0; // On remet les minutes à 0
        }
        return { days, hours, minutes };
    };

    const duration = calculateDuration(startDate, endDate);
    const commissionAmount = (deliveryFee * (commissionPercentage / 100)).toFixed(2);

    const handleDownloadPDF = () => {
        const element = invoiceRef.current;
        const options = {
            margin: 1,
            filename: `Facture_Reservation_${reservation && reservation.ordre ? reservation.ordre : ""}.pdf`, // Nom du fichier PDF
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
            // Ajout des métadonnées du PDF
            pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
            pdfCallback: (pdf) => {
                pdf.setProperties({
                    title: 'Facture de réservation', // Titre du document PDF
                    subject: 'Détails de la réservation et paiement',
                    author: 'Votre Application',
                    keywords: 'facture, réservation, paiement',
                    creator: 'Resaplus'
                });
            }
        };

        html2pdf().set(options).from(element).save();
    };


    const handleShowPdfConfirm = () => { setshowModalPdfConfirm(true) }
    const handleClosePdfConfirm = () => { setshowModalPdfConfirm(false) }




    return (
        <div className="">
            <Card>
                <Card.Header>
                    <h3>Facture de réservation</h3>
                    <Button onClick={handleShowPdfConfirm} variant="primary" size='sm'><MdDownload />
                        <span class="text-sm"> Télécharger </span>
                    </Button>
                </Card.Header>
                <Card.Body ref={invoiceRef}>
                    <Row>
                        {
                            vehicle && (
                                <Col md={6}>
                                    <h4>Détails du véhicule</h4>
                                    <ListGroup>
                                        <ListGroupItem><strong>Nom / designation:</strong> {vehicle.brand || ""}</ListGroupItem>
                                        <ListGroupItem><strong>Marque:</strong> {vehicle.vehiclebrand || ""}</ListGroupItem>
                                        <ListGroupItem><strong>Modèle:</strong> {vehicle.model || ""}</ListGroupItem>
                                        <ListGroupItem><strong>Coleur:</strong> {vehicle.color || ""}</ListGroupItem>
                                    </ListGroup>
                                </Col>
                            )
                        }

                        {
                            property &&
                            (
                                <Col md={6}>
                                    <h4>Détails de la propriété</h4>
                                    <ListGroup>
                                        <ListGroupItem><strong>Nom/Designation :</strong> {property.name || ""}</ListGroupItem>
                                        <ListGroupItem><strong>Addresse : </strong> {property.address || ""}</ListGroupItem>
                                        <ListGroupItem><strong>Année:</strong> {property.yearBuilt || ""}</ListGroupItem>
                                    </ListGroup>
                                </Col>
                            )
                        }


                        <Col md={6}>
                            <h4>Détails du client</h4>
                            <ListGroup>
                                <ListGroupItem><strong>Nom:</strong> {firstname ? firstname : ""} {lastname ? lastname : ""}</ListGroupItem>
                                <ListGroupItem><strong>Email:</strong> {email ? email : ""}</ListGroupItem>
                                <ListGroupItem><strong>Téléphone:</strong> {codePostal?.indicatif || ""} {phone ? phone : ""}</ListGroupItem>
                                {
                                    reservation?.companyAirport && reservation?.companyAirport?.name &&
                                    <ListGroupItem>
                                        <strong>Compagnie aérienne :</strong>
                                        <span>{reservation?.companyAirport?.name || ""}</span>
                                    </ListGroupItem>
                                }
                                {
                                    reservation?.numberVolAiport &&
                                    <ListGroupItem>
                                        <strong>N° de Vol :</strong>
                                        <span>{reservation?.numberVolAiport}</span>
                                    </ListGroupItem>
                                }
                            </ListGroup>
                        </Col>
                    </Row>

                    <hr />

                    <Row>
                        <Col>
                            <h4>Détails de la réservation</h4>
                            <Table bordered>
                                <thead>
                                    <tr>
                                        <th>Date de début</th>
                                        <th>Date de fin</th>
                                        <th>Nombre de jours</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{start.toLocaleDateString() || ""} - {moment(startDate).format("HH:mm") || ""}</td>
                                        <td>{end.toLocaleDateString() || ""} - {moment(endDate).format("HH:mm") || ""}</td>
                                        <td>{duration?.days || ""} jour{duration.days > 1 ? 's' : ''}
                                            {/*, {duration.hours} heure{duration.hours > 1 ? 's' : ''}, {duration.minutes} minute{duration.minutes > 1 ? 's' : ''} */}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>

                            {/*<h5>Demande spéciale :</h5>
                            <p>{specialRequests || "Aucune demande spéciale."}</p> */}
                        </Col>
                    </Row>

                    <hr />

                    <Row>
                        <Col>
                            <h4>Détails du paiement</h4>
                            <Table bordered>
                                <thead>
                                    <tr>
                                        <th>Informations</th>
                                        <th>Montant (DZD)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        vehicle && (
                                            <tr>
                                                <td>Prix ({vehicle?.basePricePerDay} / jour)</td>
                                                <td>{(vehicle?.basePricePerDay * numberOfDays).toFixed(0)} </td>
                                            </tr>
                                        )
                                    }
                                    {
                                        property && (
                                            <tr>
                                                <td>Prix ( {property?.basePricePerDay}/Jour ) </td>
                                                <td>{(property?.basePricePerDay * numberOfDays).toFixed(0)} </td>
                                            </tr>
                                        )
                                    }

                                    {
                                        reservation && reservation.deliveryFee && reservation && Number(reservation.deliveryFee) > 0 ?
                                            <tr>
                                                <td>Frais Supplementaires</td>
                                                <td>{reservation && reservation.deliveryFee ? reservation.deliveryFee : "0"} </td>
                                            </tr>
                                            : ""
                                    }



                                    {
                                        commissionAmount && commissionAmount != 0 && (
                                            <tr>
                                                <td>Accompte </td>
                                                <td>{Number(commissionAmount).toFixed(0) || 0} </td>
                                            </tr>
                                        )
                                    }
                                    {
                                        reservation && reservation.discount && reservation.discount != 0 ?
                                            <tr>
                                                <td>Remise </td>
                                                <td>{Number(reservation.discount).toFixed(0)} </td>
                                            </tr> :
                                            ""
                                    }

                                    <tr>
                                        <td><strong>Total</strong></td>
                                        <td><strong style={{ fontWeight: "bold" }}>{Number(totalPrice || 0).toFixed(0)} </strong></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>


            <Modal show={showModalPdfConfirm} onHide={handleClosePdfConfirm} centered >
                <Modal.Header>Confirmer téléchargement</Modal.Header>
                <Modal.Body>
                    <p>Voulez télécharger la facture de la réservation</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='light' size='sm' onClick={handleClosePdfConfirm}> Annuler </Button>
                    <Button variant='primary' size='sm' onClick={handleDownloadPDF}><MdDownload /> <span>Télécharger</span> </Button>
                </Modal.Footer>

            </Modal>


        </div>
    );
};

export default ReservationInvoicePage;
