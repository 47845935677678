import React, { useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { UserUpdatePassword } from '../../../actions/request/UserRequest';
import { toast } from 'react-toastify';
import { getAndCheckLocalStorage } from '../../../utils/storage/localvalueFuction';
import { localStorageKeys } from '../../../utils/storage/localvalue';
import { handleImageUploadCloudOnly } from '../../../actions/upload/UploadFileCloud';


function ProfilePassword() {
    const [key, setKey] = useState('profile');
    const dispatch = useDispatch();
    const loadingUser = useSelector((state) => state.users.loadingUser);
    const codepostals = useSelector((state) => state.codepostals.codepostals);


    const [showPassword, setShowPassword] = useState(false);


    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: '',
    });




    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };



    const handleSubmit = (e) => {
        e.preventDefault();
        // Submit logic here
        if (!formData.password || !formData.confirmPassword) {
            toast.error("Champs avec * non requis", { position: "bottom-right" })
        } else if (formData.password !== formData.confirmPassword) {
            toast.error("Champs avec * non requis", { position: "bottom-right" })
        }
        dispatch(UserUpdatePassword(getAndCheckLocalStorage(localStorageKeys.userId), formData.password, toast))
    };



    return (

        <div>
            <Form onSubmit={handleSubmit}>
                {loadingUser ? (
                    <div className="text-center">
                        <Spinner animation="border" variant="primary" />
                        <p>Chargement en cours...</p>
                    </div>
                ) : (
                    <>
                        <div class="row">

                            <div className="col-md-4 form-group">
                                <label>Nouveau de mot de passe</label>
                                <input style={{ height: "40px" }}
                                    type={showPassword ? "text" : "password"}
                                    className="form-control"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div className="col-md-4 form-group">
                                <label>Confimer mot de passe:</label>
                                <input style={{ height: "40px" }}
                                    type={showPassword ? "text" : "password"}
                                    className="form-control"
                                    name="confirmPassword"
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                    required
                                />
                            </div>


                            <div className="col-md-12 " style={{ display: "flex", alignItems:"center" }}>
                                <input style={{ height: "20px", width: "20px" }} class="p-2"
                                    type="checkbox"
                                    name="firstname" title="Afficher mof de passe"
                                    checked={showPassword}
                                    onChange={() => { setShowPassword(!showPassword) }}
                                />
                                <label class="p-2">Afficher mot de passe </label>


                            </div>
                        </div>
                        <hr class="my-5" />
                        <button type="submit" className="btn  btn-sm btn-primary mt-5">Mise à jour</button>
                    </>
                )}
            </Form>
        </div>
    );
}

export default ProfilePassword;
