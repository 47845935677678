import React from 'react';
import { Container, Row, Col, Card, ListGroup, Image } from 'react-bootstrap';
import './AboutUsPage.css'; // Import custom CSS for styling
import { ROUTES } from '../../config/routingUrl';
import { Link } from 'react-router-dom';

const AboutUsPage = () => {


    const  propertyUrl = "aboutpropriete.jpg";
    const vehicleImgUrl = "images/gallery/pic7.jpg";


    const statifaction1 = "statifaction.jpg";
    const statifaction2 = "resavation-illustration.png";
    const statifaction3 = "support.png";
    const statifaction4 = "process.png";
    const statifaction5 = "offres.png";
    const statifaction6 = "security.png";



    return (
        <Container className="about-page">
            <Row className="justify-content-center text-center">
                <Col md={8}>
                    <h2>À Propos de Rezaplus</h2>
                    <p>Découvrez les nombreux avantages de Rezaplus, votre plateforme de réservation de véhicules et de propriétés.</p>
                </Col>
            </Row>

            <section className="promo-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h3>Location de voitures à des tarifs imbattables</h3>
                            <p>Chez Rezaplus, nous vous proposons une large gamme de véhicules et proprités adaptés à tous vos besoins. Que ce soit pour un voyage d{"'"}affaires, des vacances en famille ou une escapade entre amis, nous avons le véhicule parfait pour vous.</p>
                            <p>Profitez de nos tarifs compétitifs et de nos offres exceptionnelles qui vous permettent de voyager sans vous ruiner. Avec Rezaplus, la route est à vous !</p>
                            <Link to={`/${ROUTES.CAR_LIST_WEB}`} className="btn btn-primary">Réservez dès maintenant</Link>
                        </div>
                        <div className="col-md-6">
                            <img src={vehicleImgUrl} alt="Offre de véhicules" className="img-fluid"  />
                        </div>
                    </div>
                </div>
            </section>

            <section className="promo-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <img src={propertyUrl} alt="Offre de véhicules" className="img-fluid"  />
                        </div>
                        <div className="col-md-6">
                            <h3>Votre partenaire de confiance pour la location </h3>
                            <p>Choisissez Rezaplus pour une expérience de location de voiture sans tracas. Nous offrons une variété de modèles récents et bien entretenus, garantissant confort et sécurité lors de vos déplacements.</p>
                            <p>Avec notre processus de réservation simple et rapide, vous pouvez facilement planifier votre voyage et vous concentrer sur ce qui compte le plus – créer des souvenirs inoubliables.</p>
                        </div>
                    </div>
                </div>
            </section>




            <Row className="advantages mt-4">
                <Col md={12}>
                    <h3 className="text-center">Avantages</h3>
                    <Row className="mt-4">
                        {[
                            { title: "Taux de satisfaction client élevé", imgSrc: statifaction1, description: "Nous nous engageons à fournir un service de qualité, comme en témoignent les retours positifs de nos clients." },
                            { title: "Large choix de véhicules et de propriétés", imgSrc: statifaction2, description: "Nous offrons une vaste gamme de véhicules et de propriétés pour répondre à tous vos besoins." },
                            { title: "Service client réactif et disponible", imgSrc: statifaction3, description: "Notre équipe est toujours prête à vous aider, 24/7." },
                            { title: "Processus de réservation simplifié", imgSrc: statifaction4, description: "Réservez facilement en quelques clics grâce à notre plateforme intuitive." },
                            { title: "Offres promotionnelles régulières", imgSrc: statifaction5, description: "Profitez de remises et d'offres spéciales pour économiser sur vos réservations." },
                            { title: "Engagement envers la qualité", imgSrc: statifaction6, description: "Nous garantissons que chaque véhicule et propriété répond à des normes élevées de qualité et de sécurité." },
                        ].map((advantage, index) => (
                            <Col md={4} key={index} className="mb-4">
                                <Card>
                                    <Card.Body className="text-center">
                                        <Card.Img variant="top" src={advantage.imgSrc} style={{ width: "100px", marginBottom: "20px" }} />
                                        <Card.Title>
                                            <h6>{advantage.title}</h6>
                                        </Card.Title>
                                        <Card.Text>
                                            {advantage.description}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>


            {/* Descriptive Block */}
            <Row className="why-choose-us mt-4">
                <Col md={12}>
                    <h3>Pourquoi Choisir Rezaplus ?</h3>
                    <p>
                        Chez Rezaplus, nous nous engageons à offrir à nos clients une expérience de réservation exceptionnelle. Voici quelques raisons pour lesquelles vous devriez nous choisir :
                    </p>
                    <Card className="mt-3">
                        <Card.Body>
                            <ListGroup>
                                <ListGroup.Item>✅ Un processus de réservation simple et rapide.</ListGroup.Item>
                                <ListGroup.Item>✅ Un large éventail de véhicules et de propriétés adaptées à tous les besoins.</ListGroup.Item>
                                <ListGroup.Item>✅ Une équipe de support dédiée prête à vous aider à chaque étape.</ListGroup.Item>
                                <ListGroup.Item>✅ Des offres spéciales et des promotions régulières pour vous faire économiser.</ListGroup.Item>
                                <ListGroup.Item>✅ Des avis clients élogieux et un taux de satisfaction élevé.</ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>





            <Row className="available-vehicles mt-4">
                <Col md={12}>
                    <h3>Marques de Véhicules</h3>
                    <p>Découvrez notre sélection de marques de véhicules disponibles :</p>
                    <ListGroup>
                        <ListGroup.Item>
                            ✅ Honda
                        </ListGroup.Item>
                        <ListGroup.Item>
                            ✅ Toyota
                        </ListGroup.Item>
                        <ListGroup.Item>
                            ✅ Ford
                        </ListGroup.Item>
                        <ListGroup.Item>
                            ✅ BMW
                        </ListGroup.Item>
                        <ListGroup.Item>
                            ✅ Mercedes-Benz
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
            </Row>

            <Row className="rental-info mt-4">
                <Col md={12}>
                    <h3>Location de Véhicules</h3>
                    <p>Réservez des véhicules de différentes marques au mois :</p>
                    <ListGroup>
                        <ListGroup.Item>
                            ✅ Location de véhicules Honda
                        </ListGroup.Item>
                        <ListGroup.Item>
                            ✅ Location de véhicules Toyota
                        </ListGroup.Item>
                        <ListGroup.Item>
                            ✅ Location de véhicules Ford
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
            </Row>


        </Container>
    );
};

export default AboutUsPage;


