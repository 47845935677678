import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { FaRegUserCircle } from 'react-icons/fa';
import { MdExitToApp } from 'react-icons/md';
import { toast } from 'react-toastify';
import { ROUTES } from '../../config/routingUrl';
import { getAndCheckLocalStorage, handleClearLocalStorage } from '../../utils/storage/localvalueFuction';
import { localStorageKeys } from '../../utils/storage/localvalue';
import { logoApplication } from '../../utils/dataApi/dataFormApi';
import "./HeaderWeb.css";


const HeaderWeb = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [modalDisconnect, setModalDisconnect] = useState(false);

    const handleShowModalDisconnect = () => setModalDisconnect(true);
    const handleCloseModalDisconnect = () => setModalDisconnect(false);

    const handleDisconnect = () => {
        handleClearLocalStorage();
        window.localStorage.removeItem(localStorageKeys.userId);
        window.location.href = "/";
        toast.success('Déconnexion réussie !');
    };

    return (
        <header className="site-header">
            <Navbar expand="lg" className="bg-white">
                <div className="container">
                    <Link to="/" className="navbar-brand">
                        <img src={logoApplication} style={{ maxHeight: "50px", height: "50px" }} alt="Logo" />
                    </Link>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link as={Link} to="/" className={location.pathname === '/' ? 'active' : ''}>Accueil</Nav.Link>
                            <Nav.Link as={Link} to={`/${ROUTES.CAR_LIST_WEB}`} className={location.pathname === `/${ROUTES.CAR_LIST_WEB}` ? 'active' : ''}>Réservations</Nav.Link>
                            <Nav.Link as={Link} to={`/${ROUTES.ABOUTUS}`} className={location.pathname === `/${ROUTES.ABOUTUS}` ? 'active' : ''}>En savoir plus </Nav.Link>
                            
                            {/* Account Dropdown */}
                            <NavDropdown title={<><FaRegUserCircle size={20} /> Compte</>} id="account-dropdown">
                                {!getAndCheckLocalStorage(localStorageKeys.userId) ? (
                                    <NavDropdown.Item as={Link} to={`/${ROUTES.LOGIN}`}>Se Connecter</NavDropdown.Item>
                                ) : (
                                    <>
                                        <NavDropdown.Item as={Link} to={`/${ROUTES.DASHBOARD}`}>Tableau de bord</NavDropdown.Item>
                                        <NavDropdown.Item as="button" onClick={handleShowModalDisconnect}>
                                            <MdExitToApp /> Déconnexion
                                        </NavDropdown.Item>
                                    </>
                                )}
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar>

            <Modal show={modalDisconnect} onHide={handleCloseModalDisconnect} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Déconnexion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Voulez-vous vous déconnecter ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseModalDisconnect}>Annuler</Button>
                    <Button variant="primary" onClick={() => { handleCloseModalDisconnect(); handleDisconnect(); }}>Se Déconnecter</Button>
                </Modal.Footer>
            </Modal>
        </header>
    );
};

export default HeaderWeb;
