import { FETCH_MAINTENANCE_SHEDULES_FAILURE, FETCH_MAINTENANCE_SHEDULES_REQUEST, FETCH_MAINTENANCE_SHEDULES_SUCCESS, FETCH_MAINTENANCE_SHEDULES_SUCCESS_2, FETCH_MAINTENANCE_SHEDULE_FAILURE, FETCH_MAINTENANCE_SHEDULE_REQUEST, FETCH_MAINTENANCE_SHEDULE_SUCCESS } from "../actions/actions";



// maintenanceSheduleReducer.js
const initialState = {
    maintenanceShedules: [],
    maintenanceShedules2: [],
    maintenanceShedule: {},
    maintenanceSheduleSelect: {},
    loadingMaintenanceShedule:false,
    loadingMaintenanceSheduleSelect:false,
    loadingMaintenanceShedules:false,
    errorMaintenanceShedules:null,
    errorMaintenanceShedule:null
    // ... autres états spécifiques à maintenanceSheduleReducer
  };
  
  const maintenanceSheduleReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_MAINTENANCE_SHEDULE_REQUEST:
        // Dispatch une action pour définir loading sur true dans le globalReducer
        return {
          ...state,
          loadingMaintenanceShedule:true,
          errorMaintenanceShedules:""
          // Autres manipulations d'état pour la demande de récupération des maintenanceShedules
        };
        case FETCH_MAINTENANCE_SHEDULE_SUCCESS:
        return {
          ...state,
          loadingMaintenanceShedule:false,
          errorMaintenanceShedules:"",
          maintenanceShedule:action.payload
        };
        case FETCH_MAINTENANCE_SHEDULE_FAILURE:
        return {
          ...state,
          loadingMaintenanceShedule:false,
          errorMaintenanceShedules:action.payload
        };


      case FETCH_MAINTENANCE_SHEDULES_REQUEST:
        return {
          ...state,
          loadingMaintenanceShedules:true,
          errorMaintenanceShedules:""
        };
       
      case FETCH_MAINTENANCE_SHEDULES_SUCCESS:
        return {
          ...state,
          maintenanceShedules: action.payload,
          loadingMaintenanceShedules:false,
          errorMaintenanceShedules:""
          // Autres manipulations d'état pour le succès de la récupération des maintenanceShedules
        };
        case FETCH_MAINTENANCE_SHEDULES_SUCCESS_2:
        return {
          ...state,
          maintenanceShedules2: action.payload,
          loadingMaintenanceShedules:false,
          errorMaintenanceShedules:""
          // Autres manipulations d'état pour le succès de la récupération des maintenanceShedules
        };
        
      case FETCH_MAINTENANCE_SHEDULES_FAILURE:
        // Dispatch une action pour définir loading sur false dans le globalReducer et enregistrer l'erreur
        return {
          ...state,
          loadingMaintenanceShedules:false,
          errorMaintenanceShedules:action.payload
          // Autres manipulations d'état pour l'échec de la récupération des maintenanceShedules
        };
      // ... autres cas pour d'autres actions liées aux maintenanceShedules

        
      default:
        return state;
    }
  };
  
  export default maintenanceSheduleReducer;
  