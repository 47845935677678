import { FETCH_ASSESSORYS_FAILURE, FETCH_ASSESSORYS_REQUEST, FETCH_ASSESSORYS_SUCCESS, FETCH_ASSESSORYS_SUCCESS_2, FETCH_ASSESSORY_FAILURE, FETCH_ASSESSORY_REQUEST, FETCH_ASSESSORY_SUCCESS } from "../actions/actions";



// assessoryReducer.js
const initialState = {
    assessorys: [],
    assessorys2: [],
    assessory: {},
    assessorySelect: {},
    loadingAssessory:false,
    loadingAssessorySelect:false,
    loadingAssessorys:false,
    errorAssessorys:null,
    errorAssessory:null
    // ... autres états spécifiques à assessoryReducer
  };
  
  const assessoryReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_ASSESSORY_REQUEST:
        // Dispatch une action pour définir loading sur true dans le globalReducer
        return {
          ...state,
          loadingAssessory:true,
          errorAssessorys:""
          // Autres manipulations d'état pour la demande de récupération des assessorys
        };
        case FETCH_ASSESSORY_SUCCESS:
        return {
          ...state,
          loadingAssessory:false,
          errorAssessorys:"",
          assessory:action.payload
        };
        case FETCH_ASSESSORY_FAILURE:
        return {
          ...state,
          loadingAssessory:false,
          errorAssessorys:action.payload
        };


      case FETCH_ASSESSORYS_REQUEST:
        return {
          ...state,
          loadingAssessorys:true,
          errorAssessorys:""
        };
       
      case FETCH_ASSESSORYS_SUCCESS:
        return {
          ...state,
          assessorys: action.payload,
          loadingAssessorys:false,
          errorAssessorys:""
          // Autres manipulations d'état pour le succès de la récupération des assessorys
        };
        case FETCH_ASSESSORYS_SUCCESS_2:
        return {
          ...state,
          assessorys2: action.payload,
          loadingAssessorys:false,
          errorAssessorys:""
          // Autres manipulations d'état pour le succès de la récupération des assessorys
        };
        
      case FETCH_ASSESSORYS_FAILURE:
        // Dispatch une action pour définir loading sur false dans le globalReducer et enregistrer l'erreur
        return {
          ...state,
          loadingAssessorys:false,
          errorAssessorys:action.payload
          // Autres manipulations d'état pour l'échec de la récupération des assessorys
        };
      // ... autres cas pour d'autres actions liées aux assessorys

        
      default:
        return state;
    }
  };
  
  export default assessoryReducer;

  

