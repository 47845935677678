import { FETCH_RESERVATIONS_FAILURE, FETCH_RESERVATIONS_REQUEST, FETCH_RESERVATIONS_SUCCESS, FETCH_RESERVATIONS_SUCCESS_2, FETCH_RESERVATION_FAILURE, FETCH_RESERVATION_REQUEST, FETCH_RESERVATION_SUCCESS } from "../actions/actions";



// reservationReducer.js
const initialState = {
    reservations: [],
    reservations2: [],
    reservation: {},
    reservationSelect: {},
    loadingReservation:false,
    loadingReservationSelect:false,
    loadingReservations:false,
    errorReservations:null,
    errorReservation:null
    // ... autres états spécifiques à reservationReducer
  };
  
  const reservationReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_RESERVATION_REQUEST:
        // Dispatch une action pour définir loading sur true dans le globalReducer
        return {
          ...state,
          loadingReservation:true,
          errorReservations:""
          // Autres manipulations d'état pour la demande de récupération des reservations
        };
        case FETCH_RESERVATION_SUCCESS:
        return {
          ...state,
          loadingReservation:false,
          errorReservations:"",
          reservation:action.payload
        };
        case FETCH_RESERVATION_FAILURE:
        return {
          ...state,
          loadingReservation:false,
          errorReservations:action.payload
        };


      case FETCH_RESERVATIONS_REQUEST:
        return {
          ...state,
          loadingReservations:true,
          errorReservations:""
        };
       
      case FETCH_RESERVATIONS_SUCCESS:
        return {
          ...state,
          reservations: action.payload,
          loadingReservations:false,
          errorReservations:""
          // Autres manipulations d'état pour le succès de la récupération des reservations
        };
        case FETCH_RESERVATIONS_SUCCESS_2:
        return {
          ...state,
          reservations2: action.payload,
          loadingReservations:false,
          errorReservations:""
          // Autres manipulations d'état pour le succès de la récupération des reservations
        };
        
      case FETCH_RESERVATIONS_FAILURE:
        // Dispatch une action pour définir loading sur false dans le globalReducer et enregistrer l'erreur
        return {
          ...state,
          loadingReservations:false,
          errorReservations:action.payload
          // Autres manipulations d'état pour l'échec de la récupération des reservations
        };
      // ... autres cas pour d'autres actions liées aux reservations

        
      default:
        return state;
    }
  };
  
  export default reservationReducer;

  

