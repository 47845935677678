import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Container, Button, Card } from 'react-bootstrap';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { PropertyCreate, PropertyUpdateById } from '../../../actions/request/PropertyRequest';
import { getAndCheckLocalStorage } from '../../../utils/storage/localvalueFuction';
import { localStorageKeys } from '../../../utils/storage/localvalue';
import { toast } from 'react-toastify';
import { fetchPropertyTypesAll } from '../../../actions/request/PropertyTypeRequest';
import axios from 'axios';
import { baseurl } from '../../../utils/url/baseurl';
import { useNavigate, useParams } from 'react-router-dom';
import { FETCH_PROPERTY_FAILURE, FETCH_PROPERTY_REQUEST, FETCH_PROPERTY_SUCCESS } from '../../../app/actions/actions';
import { MdClose, MdRemove } from 'react-icons/md';

function UpdatePropertyPage() {

    const navigate = useNavigate();
    const paramProperty = useParams();

    const dispatch = useDispatch();
    const propertyTypes = useSelector((state) => state.propertyTypes.propertyTypes);

    const [newProperty, setNewProperty] = useState({
        type: '',
        address: "",
        lat: null,
        lng: null,
        name: '',
        size: 0,
        price: '',
        pricePerMonth: 0,
        yearBuilt: 0,
        images: [],
        owner: getAndCheckLocalStorage(localStorageKeys.userId),
        ownerName: '',
        addBy: getAndCheckLocalStorage(localStorageKeys.userId),
        details: {},
        // infos supplementaire
        deliveryFee: 0,
        // commissionPercentage: 0,
        basePricePerDay: 0,
        numberProperty: "",
    });

    const [typeOptions, setTypeOptions] = useState([]);
    const [errors, setErrors] = useState({});
    const [uploading, setUploading] = useState(false);




    async function fetchPropertyById() {
        dispatch({ type: FETCH_PROPERTY_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/propertys/get_property/${paramProperty.id || ""}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.typeToken} ${baseurl.token}`
            }
        }).then((response) => {
            // console.log(response.data.data);
            dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: response.data.data });
            const repoonData = response.data.data;
            setNewProperty({
                type: repoonData.type._id,
                address: repoonData.address,
                lat: repoonData.lat,
                lng: repoonData.lng,
                name: repoonData.name,
                size: repoonData.size,
                price: repoonData.price,
                pricePerMonth: repoonData.pricePerMonth,
                yearBuilt: repoonData.yearBuilt,
                images: repoonData.images,
                ownerName: repoonData.ownerName,
                // infos supplementaire
                deliveryFee: repoonData.deliveryFee,
                // commissionPercentage: repoonData.commissionPercentage,
                basePricePerDay: repoonData.basePricePerDay,
                numberProperty: repoonData.numberProperty,
                isVisible: repoonData.isVisible,
            })
            setPickupLocation(repoonData.address);



        })
            .catch((error) => {
                dispatch({ type: FETCH_PROPERTY_FAILURE, payload: error.message })
                // console.log(error);
                toast.error("Proprité non récupérer", { position: "bottom-right" })
            });
    }


    // Gestion des types de propriétés
    useEffect(() => {
        dispatch(fetchPropertyTypesAll());
        fetchPropertyById();
    }, [dispatch]);

    useEffect(() => {
        if (propertyTypes && propertyTypes.length > 0) {
            setTypeOptions(propertyTypes.map(pt => ({ label: pt.name, value: pt._id })));
        }
    }, [propertyTypes]);

    // Fonction de téléchargement des images vers Cloudinary
    const handleImageChange = async (e) => {
        const files = Array.from(e.target.files);
        const uploadPromises = files.map(file => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('upload_preset', baseurl.cloudinaryUploadPreset);

            return axios.post(`https://api.cloudinary.com/v1_1/${baseurl.cloudinaryCloudName}/image/upload`, formData)
                .then(response => {
                    toast.success('Image téléchargée avec succès', { position: "bottom-right" });
                    return response.data.secure_url;
                })
                .catch(error => {
                    toast.error('Erreur lors du téléchargement de l\'image', { position: "bottom-right" });
                    console.error('Erreur lors du téléchargement de l\'image :', error);
                    return null;
                });
        });

        const uploadedImageUrls = await Promise.all(uploadPromises);
        setNewProperty(prevProperty => ({
            ...prevProperty,
            images: [...prevProperty.images, ...uploadedImageUrls.filter(url => url !== null)]
        }));
    };

    // Validation du formulaire
    const validateForm = () => {
        let formErrors = {};
        if (!newProperty.name) formErrors.type = "Le titre est requis";
        if (!newProperty.type) formErrors.type = "Le type est requis";
        if (!newProperty.address) formErrors.address = "L'adresse est requise";
        if (!newProperty.size) formErrors.size = "La taille est requise";
        if (!newProperty.ownerName) formErrors.size = "Propriétaire requis";
        if (!newProperty.basePricePerDay) formErrors.basePricePerDay = "Le prix journalier est requis ";
        if (!newProperty.numberProperty) formErrors.numberProperty = "Numéro d'identification de la propriété est requis";
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    // Fonction de traitement d'ajout de propriété
    const handleAddProperty = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                setUploading(true);
                dispatch(PropertyUpdateById(paramProperty.id || "", getAndCheckLocalStorage(localStorageKeys.userId), newProperty.type || "", newProperty, navigate, toast));
                // toast.success("Propriété ajoutée avec succès");
                fetchPropertyById();
            } catch (error) {
                console.error("Erreur lors de l'ajout de la propriété:", error);
            } finally {
                setUploading(false);
            }
        }
    };

    // Fonction pour gérer les changements dans les champs de type nombre et empêcher les valeurs négatives
    const handleNumberInputChange = (e, field) => {
        const value = parseFloat(e.target.value);
        if (value < 0) {
            setNewProperty(prevState => ({ ...prevState, [field]: '' }));
        } else {
            setNewProperty(prevState => ({ ...prevState, [field]: value }));
        }
    };




    const [pickupLocation, setPickupLocation] = useState('');
    const [pickupSuggestions, setPickupSuggestions] = useState([]);

    // Obtenir les suggestions de lieu de départ
    const getPlacesByCountryStartLocation = async (query) => {
        try {
            const response = await axios.get(`${baseurl.url}/api/v1/places`, {
                params: { query: query },
            });
            const places = response.data.results;
            return places.map(place => place);
        } catch (error) {
            console.error("Erreur lors de la récupération des lieux : ", error);
        }
    };
    const handlePickupLocationChange = async (e) => {
        const value = e.target.value;
        setPickupLocation(value);
        if (value.length > 2) {
            const suggestions = await getPlacesByCountryStartLocation(value);
            setPickupSuggestions(suggestions);
        } else {
            setPickupSuggestions([]); // Réinitialiser la liste des suggestions
        }
    };
    const handlePickupSelection = (suggestion) => {
        // Met à jour le pickupLocation
        setPickupLocation(`${suggestion.name} - ${suggestion.formatted_address}`);
        // Met à jour newVehicle en une seule fois avec toutes les nouvelles valeurs
        setNewProperty(prevState => ({
            ...prevState,
            address: `${suggestion.name} - ${suggestion.formatted_address}`,
            lat: suggestion?.geometry?.location?.lat,
            lng: suggestion?.geometry?.location?.lng
        }));

        // Vérifie les valeurs dans suggestion
        // console.log(suggestion?.geometry?.location?.lat); // Affiche la latitude
        // console.log(suggestion?.geometry?.location?.lng); // Affiche la longitude
        // console.log(`${suggestion.name} - ${suggestion.formatted_address}`); // Affiche l'adresse complète

        // Vide les suggestions après la sélection
        setPickupSuggestions([]);
    };

    const handleCleanSugestions = () => {
        setPickupSuggestions([]);
    }

    return (
        <div className="mb-5" onClick={handleCleanSugestions}>
            <Card>
                <Card.Header>
                    <h3 className="mb-4">Modifier cette propriété</h3>
                </Card.Header>
                <Card.Body>

                    <Form onSubmit={handleAddProperty}>
                        <Row>
                            <Col md={4}>
                                <Form.Group controlId="formPropertyType">
                                    <Form.Label>Type de propriété</Form.Label>
                                    <Select
                                        options={typeOptions}
                                        value={typeOptions.find(option => option.value === newProperty.type)}
                                        onChange={(selectedOption) => {
                                            setNewProperty({ ...newProperty, type: selectedOption ? selectedOption.value : '' })
                                        }}
                                        placeholder=""
                                    />
                                    {errors.type && <div className="invalid-feedback d-block">{errors.type}</div>}
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="formPropertyAddress">
                                    <Form.Label>Nom/Désignation de la propriété</Form.Label>
                                    <Form.Control
                                        style={{ height: "40px" }}
                                        type="text"
                                        placeholder=""
                                        value={newProperty.name}
                                        onChange={(e) => setNewProperty({ ...newProperty, name: e.target.value })}
                                        isInvalid={!!errors.address}
                                    />
                                    {errors.name && <div className="invalid-feedback d-block">{errors.name}</div>}
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="formPropertyAddress">
                                    <Form.Label>Adresse</Form.Label>
                                    <Form.Control
                                        style={{ height: "40px" }}
                                        type="text"
                                        placeholder=""
                                        value={pickupLocation}
                                        onChange={(e) => handlePickupLocationChange(e)}
                                        isInvalid={!!errors.address}
                                    />
                                    {errors.address && <div className="invalid-feedback d-block">{errors.address}</div>}
                                    {/* Liste déroulante des suggestions */}
                                    {pickupSuggestions && pickupSuggestions.length > 0 && (
                                        <ul className="rounded p-1 border" style={{ position: 'absolute', zIndex: 1000, backgroundColor: 'white', maxwidth: "400px", listStyleType: 'none', padding: 0, maxHeight: "200px", overflow: "auto" }}>
                                            {pickupSuggestions.map((suggestion, index) => (
                                                <li className="border"
                                                    key={index}
                                                    style={{ padding: '5px', cursor: 'pointer' }}
                                                    onClick={() => handlePickupSelection(suggestion)}
                                                >
                                                    {`${suggestion.name} - ${suggestion.formatted_address}`}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group controlId="formPropertySize">
                                    <Form.Label>Superficie totale (m²)</Form.Label>
                                    <Form.Control
                                        style={{ height: "40px" }}

                                        type="number"
                                        placeholder=""
                                        value={newProperty.size}
                                        onChange={(e) =>{handleNumberInputChange(e,"size")}}
                                        isInvalid={!!errors.size}
                                    />
                                    {errors.size && <div className="invalid-feedback d-block">{errors.size}</div>}
                                </Form.Group>
                            </Col>


                            <Col md={6}>
                                <Form.Group controlId="formPropertyYearBuilt">
                                    <Form.Label>Année de construction</Form.Label>
                                    <Form.Control
                                        style={{ height: "40px" }}
                                        type="number"
                                        placeholder=""
                                        value={newProperty.yearBuilt}
                                        
                                        onChange={(e) =>{handleNumberInputChange(e,"yearBuilt")}}
                                        isInvalid={!!errors.yearBuilt}
                                    />
                                    {errors.yearBuilt && <div className="invalid-feedback d-block">{errors.yearBuilt}</div>}
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group controlId="formPropertyYearBuilt">
                                    <Form.Label>N° de la propriété </Form.Label>
                                    <Form.Control
                                        style={{ height: "40px" }}
                                        type="text"
                                        placeholder=""
                                        value={newProperty.numberProperty}
                                        onChange={(e) => setNewProperty({ ...newProperty, numberProperty: e.target.value })}
                                        isInvalid={!!errors.numberProperty}
                                    />
                                    {errors.numberProperty && <div className="invalid-feedback d-block">{errors.numberProperty}</div>}
                                </Form.Group>
                            </Col>


                            <Col md={6}>
                                <Form.Group controlId="formPropertyOwnerName">
                                    <Form.Label>Nom du propriétaire</Form.Label>
                                    <Form.Control
                                        style={{ height: "40px" }}
                                        type="text"
                                        placeholder=""
                                        value={newProperty.ownerName}
                                        onChange={(e) => setNewProperty({ ...newProperty, ownerName: e.target.value })}
                                        isInvalid={!!errors.ownerName}
                                    />
                                    {errors.ownerName && <div className="invalid-feedback d-block">{errors.ownerName}</div>}
                                </Form.Group>
                            </Col>


                            <Col md={6}>
                                <Form.Group controlId="formPropertyOwnerName">
                                    <Form.Label>Prix Journalier</Form.Label>
                                    <Form.Control
                                        style={{ height: "40px" }}
                                        type="number"
                                        min={0}
                                        max={1000000}
                                        placeholder=""
                                        value={newProperty.basePricePerDay}
                                        onChange={(e) => { handleNumberInputChange(e, "basePricePerDay") }}
                                        isInvalid={!!errors.basePricePerDay}
                                    />
                                    {errors.basePricePerDay && <div className="invalid-feedback d-block">{errors.basePricePerDay}</div>}
                                </Form.Group>
                            </Col>
                            {
                                /*
                            <Col md={4}>
                                <Form.Group controlId="formPropertyOwnerName">
                                    <Form.Label>Commision ( % )</Form.Label>
                                    <Form.Control
                                        style={{ height: "40px" }}
                                        type="number"
                                        min={0}
                                        max={100}
                                        placeholder=""
                                        value={newProperty.commissionPercentage}
                                        onChange={(e) => { handleNumberInputChange(e, "commissionPercentage") }}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.commissionPercentage}</Form.Control.Feedback>
                                </Form.Group>
                            </Col> */
                            }

                            <Col md={6}>
                                <Form.Group controlId="formPropertyOwnerName">
                                    <Form.Label>Frais Supplementaires </Form.Label>
                                    <Form.Control
                                        style={{ height: "40px" }}
                                        type="number"
                                        min={0}
                                        max={1000000}
                                        placeholder=""
                                        value={newProperty.deliveryFee}
                                        onChange={(e) => { handleNumberInputChange(e, "deliveryFee") }}
                                        isInvalid={!!errors.deliveryFee}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.deliveryFee}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col md={12}>
                                <Form.Group controlId="formPropertyImages">
                                    <Form.Label>Photos de l{"'"}état initial</Form.Label>
                                    <Form.Control

                                        type="file"
                                        accept=".jpg,.jpeg,.png"
                                        multiple
                                        onChange={handleImageChange}
                                    />
                                    <div className="mt-2">
                                        {newProperty.images.length > 0 && (
                                            <div>
                                                <h5>Images sélectionnées :</h5>
                                                <ul class="flex" style={{ display: "flex", flexWrap: "wrap" }}>
                                                    {newProperty.images.map((image, index) => (
                                                        <li key={index} class="p-1" >
                                                            <span class="text-danger text-xs"
                                                                onClick={() => {
                                                                    // On supprime l'image correspondant à l'index
                                                                    const updatedImages = newProperty.images.filter((_, i) => i !== index);
                                                                    setNewProperty({ ...newProperty, images: updatedImages });
                                                                }}
                                                            >
                                                                <MdClose />
                                                            </span>
                                                            <div>
                                                                <img src={image} style={{ height: "50px", width: "50px" }} />
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}

                                    </div>

                                </Form.Group>
                            </Col>

                            <div className="col-md-12 " style={{ display: "flex", alignItems: "center" }}>
                                <input style={{ height: "30px", width: "30px" }} class="p-2"
                                    type="checkbox"
                                    name="isVisible" title="Afficher sur la plateforme"
                                    checked={newProperty.isVisible}
                                    onChange={(e) => { setNewProperty({ ...newProperty, isVisible: e.target.checked }); }}
                                />
                                <label class="p-2"> Mise en ligne sur la plateforme </label>


                            </div>
                        </Row>

                        {
                            uploading ?
                                <p>Chargement ...</p>
                                :
                                <Button
                                    variant="primary"
                                    size='sm'
                                    type='submit'
                                    className="mt-5"
                                >
                                    {uploading ? "Téléchargement..." : "Mise a à jour"}
                                </Button>
                        }

                    </Form>


                </Card.Body>

            </Card>
        </div>
    );
}

export default UpdatePropertyPage;
