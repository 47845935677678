import { FETCH_VEHICLE_TYPES_FAILURE, FETCH_VEHICLE_TYPES_REQUEST, FETCH_VEHICLE_TYPES_SUCCESS, FETCH_VEHICLE_TYPES_SUCCESS_2, FETCH_VEHICLE_TYPE_FAILURE, FETCH_VEHICLE_TYPE_REQUEST, FETCH_VEHICLE_TYPE_SUCCESS } from "../actions/actions";



// vehicleTypeReducer.js
const initialState = {
    vehicleTypes: [],
    vehicleTypes2: [],
    vehicleType: {},
    vehicleTypeSelect: {},
    loadingVehicleType:false,
    loadingVehicleTypeSelect:false,
    loadingVehicleTypes:false,
    errorVehicleTypes:null,
    errorVehicleType:null
    // ... autres états spécifiques à vehicleTypeReducer
  };
  
  const vehicleTypeReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_VEHICLE_TYPE_REQUEST:
        // Dispatch une action pour définir loading sur true dans le globalReducer
        return {
          ...state,
          loadingVehicleType:true,
          errorVehicleTypes:""
          // Autres manipulations d'état pour la demande de récupération des vehicleTypes
        };
        case FETCH_VEHICLE_TYPE_SUCCESS:
        return {
          ...state,
          loadingVehicleType:false,
          errorVehicleTypes:"",
          vehicleType:action.payload
        };
        case FETCH_VEHICLE_TYPE_FAILURE:
        return {
          ...state,
          loadingVehicleType:false,
          errorVehicleTypes:action.payload
        };


      case FETCH_VEHICLE_TYPES_REQUEST:
        return {
          ...state,
          loadingVehicleTypes:true,
          errorVehicleTypes:""
        };
       
      case FETCH_VEHICLE_TYPES_SUCCESS:
        return {
          ...state,
          vehicleTypes: action.payload,
          loadingVehicleTypes:false,
          errorVehicleTypes:""
          // Autres manipulations d'état pour le succès de la récupération des vehicleTypes
        };
        case FETCH_VEHICLE_TYPES_SUCCESS_2:
        return {
          ...state,
          vehicleTypes2: action.payload,
          loadingVehicleTypes:false,
          errorVehicleTypes:""
          // Autres manipulations d'état pour le succès de la récupération des vehicleTypes
        };
        
      case FETCH_VEHICLE_TYPES_FAILURE:
        // Dispatch une action pour définir loading sur false dans le globalReducer et enregistrer l'erreur
        return {
          ...state,
          loadingVehicleTypes:false,
          errorVehicleTypes:action.payload
          // Autres manipulations d'état pour l'échec de la récupération des vehicleTypes
        };
      // ... autres cas pour d'autres actions liées aux vehicleTypes

        
      default:
        return state;
    }
  };
  
  export default vehicleTypeReducer;

  

