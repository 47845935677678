import { FETCH_DAMAGES_FAILURE, FETCH_DAMAGES_REQUEST, FETCH_DAMAGES_SUCCESS, FETCH_DAMAGES_SUCCESS_2, FETCH_DAMAGE_FAILURE, FETCH_DAMAGE_REQUEST, FETCH_DAMAGE_SUCCESS } from "../actions/actions";



// damageReducer.js
const initialState = {
    damages: [],
    damages2: [],
    damage: {},
    damageSelect: {},
    loadingDamage:false,
    loadingDamageSelect:false,
    loadingDamages:false,
    errorDamages:null,
    errorDamage:null
    // ... autres états spécifiques à damageReducer
  };
  
  const damageReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_DAMAGE_REQUEST:
        // Dispatch une action pour définir loading sur true dans le globalReducer
        return {
          ...state,
          loadingDamage:true,
          errorDamages:""
          // Autres manipulations d'état pour la demande de récupération des damages
        };
        case FETCH_DAMAGE_SUCCESS:
        return {
          ...state,
          loadingDamage:false,
          errorDamages:"",
          damage:action.payload
        };
        case FETCH_DAMAGE_FAILURE:
        return {
          ...state,
          loadingDamage:false,
          errorDamages:action.payload
        };


      case FETCH_DAMAGES_REQUEST:
        return {
          ...state,
          loadingDamages:true,
          errorDamages:""
        };
       
      case FETCH_DAMAGES_SUCCESS:
        return {
          ...state,
          damages: action.payload,
          loadingDamages:false,
          errorDamages:""
          // Autres manipulations d'état pour le succès de la récupération des damages
        };
        case FETCH_DAMAGES_SUCCESS_2:
        return {
          ...state,
          damages2: action.payload,
          loadingDamages:false,
          errorDamages:""
          // Autres manipulations d'état pour le succès de la récupération des damages
        };
        
      case FETCH_DAMAGES_FAILURE:
        // Dispatch une action pour définir loading sur false dans le globalReducer et enregistrer l'erreur
        return {
          ...state,
          loadingDamages:false,
          errorDamages:action.payload
          // Autres manipulations d'état pour l'échec de la récupération des damages
        };
      // ... autres cas pour d'autres actions liées aux damages

        
      default:
        return state;
    }
  };
  
  export default damageReducer;
  