import { FETCH_MOTIFS_FAILURE, FETCH_MOTIFS_REQUEST, FETCH_MOTIFS_SUCCESS, FETCH_MOTIFS_SUCCESS_2, FETCH_MOTIF_FAILURE, FETCH_MOTIF_REQUEST, FETCH_MOTIF_SUCCESS } from "../actions/actions";



// motifReducer.js
const initialState = {
    motifs: [],
    motifs2: [],
    motif: {},
    motifSelect: {},
    loadingMotif:false,
    loadingMotifSelect:false,
    loadingMotifs:false,
    errorMotifs:null,
    errorMotif:null
    // ... autres états spécifiques à motifReducer
  };
  
  const motifReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_MOTIF_REQUEST:
        // Dispatch une action pour définir loading sur true dans le globalReducer
        return {
          ...state,
          loadingMotif:true,
          errorMotifs:""
          // Autres manipulations d'état pour la demande de récupération des motifs
        };
        case FETCH_MOTIF_SUCCESS:
        return {
          ...state,
          loadingMotif:false,
          errorMotifs:"",
          motif:action.payload
        };
        case FETCH_MOTIF_FAILURE:
        return {
          ...state,
          loadingMotif:false,
          errorMotifs:action.payload
        };


      case FETCH_MOTIFS_REQUEST:
        return {
          ...state,
          loadingMotifs:true,
          errorMotifs:""
        };
       
      case FETCH_MOTIFS_SUCCESS:
        return {
          ...state,
          motifs: action.payload,
          loadingMotifs:false,
          errorMotifs:""
          // Autres manipulations d'état pour le succès de la récupération des motifs
        };
        case FETCH_MOTIFS_SUCCESS_2:
        return {
          ...state,
          motifs2: action.payload,
          loadingMotifs:false,
          errorMotifs:""
          // Autres manipulations d'état pour le succès de la récupération des motifs
        };
        
      case FETCH_MOTIFS_FAILURE:
        // Dispatch une action pour définir loading sur false dans le globalReducer et enregistrer l'erreur
        return {
          ...state,
          loadingMotifs:false,
          errorMotifs:action.payload
          // Autres manipulations d'état pour l'échec de la récupération des motifs
        };
      // ... autres cas pour d'autres actions liées aux motifs

        
      default:
        return state;
    }
  };
  
  export default motifReducer;
  