import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import store from './app/store';
import 'react-toastify/dist/ReactToastify.css';

import LayoutWeb from './layout/LayoutWeb';
import ScrollToTop from './components/ScrollToTop';
import { ROUTES } from './config/routingUrl';
import HomePage from './pages/web/HomePage';
import AboutUsPage from './pages/web/AboutUsPage';
import ContactPage from './pages/web/ContactPage';
import LoginPage from './pages/web/LoginPage';
import RegistrationPage from './pages/web/RegistrationPage';
import CariLstPage from './pages/web/CariLstPage';
import CardDetailPage from './pages/web/CardDetailPage';
import ProfileUserPage from './pages/manager/profile/ProfileUserPage';
import ReservationDetailPage from './pages/manager/reservations/ReservationDetailPage';
import VehicleManagementPage from './pages/manager/vehicles/VehicleManagementPage';
import UpdateVehiclePage from './pages/manager/vehicles/UpdateVehiclePage';
import NewVehiclePage from './pages/manager/vehicles/NewVehiclePage';
import DetailVehiclePage from './pages/manager/vehicles/DetailVehiclePage';
import LayoutAdmin from './layout/LayoutAdmin';
import StatisticsPage from './pages/manager/StatisticsPage';
import PropertyManagementPage from './pages/manager/propertys/PropertyManagementPage';
import DetailPropertyPage from './pages/manager/propertys/DetailPropertyPage';
import NewPropertyPage from './pages/manager/propertys/NewPropertyPage';
import UpdatePropertyPage from './pages/manager/propertys/UpdatePropertyPage';
import RequestReservationPage from './pages/manager/reservations/RequestReservationPage';
import CustomerManagementPage from './pages/manager/customers/CustomerManagementPage';
import PaiementsCuctomersPage from './pages/manager/payments/PaiementsCuctomersPage';
import AlertsPage from './pages/manager/alerts/AlertsPage';
import DamageReportPage from './pages/manager/damages/DamageReportPage';
import DamageVehicleListPage from './pages/manager/damages/DamageVehicleListPage';
import InsuranceManagementPage from './pages/manager/insurances/InsuranceManagementPage';
import ReservationCalendarPage from './pages/manager/reservations/ReservationCalendarPage';
import CarRentalInsurancePage from './pages/manager/settings/CarRentalInsurancePage';
import TransmissionListPage from './pages/manager/settings/TransmissionListPage';
import VehicleTypeListPage from './pages/manager/settings/VehicleTypeListPage';
import DeviceListPaymentPage from './pages/manager/settings/DeviceListPaymentPage';
import InvoiceReservationWebPage from './pages/web/InvoiceReservationWebPage';
import ReservationInvoicePage from './pages/manager/reservations/ReservationInvoicePage';
import NewInsuranceHeritagePage from './pages/manager/insurances/NewInsuranceHeritagePage';
import InsuranceInvoiceDetailPage from './pages/manager/insurances/InsuranceInvoiceDetailPage';
import DamageInvoiceDetailPage from './pages/manager/damages/DamageInvoiceDetailPage';
import UpdateInsuranceHeritagePage from './pages/manager/insurances/UpdateInsuranceHeritagePage';
import CarUserPage from './pages/web/CarUserPage';
import NotFound from './pages/web/NotFound';
import VehicleSearchResultsPage from './pages/web/VehicleSearchResultsPage';
import ReservationFormPage from './pages/web/ReservationFormPage';
import CodePromoPage from './pages/manager/codepromo/CodePromoPage';
import PaymentDetailsPage from './pages/manager/payments/PaiementDetailPage';
import MaintenanceDetailPage from './pages/manager/maintenances/MaintenanceDetailPage';
import MaintenanceManagementPage from './pages/manager/maintenances/MaintenanceManagementPage';
import MaintenanceCalendarPage from './pages/manager/maintenances/MaintenanceCalendarPage';
import NotificationPage from './pages/manager/notifications/NotificationPage';
import UserManagementPage from './pages/manager/users/UserManagementPage';
import AdminitratorPage from './pages/manager/support/AdminitratorPage';
import AdminLoginPage from './pages/auth/AdminLoginPage';
import StatisticsReportPage from './pages/manager/StatisticsReportPage';
import ExpensesPage from './pages/manager/expenses/ExpensesPage';
import ForgotPasswordPage from './pages/web/ForgotPasswordPage';

function App() {

  
  return (
    <Provider store={store}>
      <ToastContainer />
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {/* Routes pour Layout Web */}
          <Route path='' element={<LayoutWeb />}>
            <Route path='' element={<HomePage />} />
            <Route path={`${ROUTES.RESULTS_PAGE}`} element={<VehicleSearchResultsPage />} />

            <Route path={`${ROUTES.CAR_LIST_WEB}`} element={<CariLstPage />} />
            <Route path={`${ROUTES.CAR_DETAIL_USER_PATRIMOINE_LIST}/:id/person-or-company/:name`} element={<CarUserPage />} />

            <Route path={`${ROUTES.CAR_DETAIL_VIEW}/:id/name/:name/description/:description`} element={<CardDetailPage />} />

            <Route path={`${ROUTES.RESERVATION_VIEW}/:id`} element={<ReservationDetailPage />} />
            <Route path={`${ROUTES.RESERVATION_FORM_PAGE_WEB}`} element={<ReservationFormPage />} />
            <Route path={`${ROUTES.RESERVATIONS_INVOICE_WEB}`} element={<InvoiceReservationWebPage />} />



            <Route path={`/${ROUTES.ABOUTUS}`} element={<AboutUsPage />} />
            <Route path={`/${ROUTES.CONTACT}`} element={<ContactPage />} />
            <Route path={`${ROUTES.LOGIN}`} element={<LoginPage />} />
            <Route path={`${ROUTES.SIGNUP}`} element={<RegistrationPage />} />
            <Route path={`${ROUTES.FORGETPASSWORDHASH}`} element={<ForgotPasswordPage />} />

          </Route>

          {/* Routes pour Layout Admin */}
          <Route path={`${ROUTES.DASHBOARD}`} element={<LayoutAdmin />}>
            <Route path={``} element={<StatisticsPage />} />
            <Route path={`${ROUTES.DASHBOARDREPORT}`} element={<   StatisticsReportPage />} />

            <Route path={`${ROUTES.PROFILE}`} element={<ProfileUserPage />} />
            {/*Vehicule */}
            <Route path={`${ROUTES.CARS_LIST}`} element={<VehicleManagementPage />} />
            <Route path={`${ROUTES.CARS_DETAIL}/:id`} element={<DetailVehiclePage />} />
            <Route path={`${ROUTES.CARS_ADD}`} element={<NewVehiclePage />} />
            <Route path={`${ROUTES.CARS_EDIT}/:id`} element={<UpdateVehiclePage />} />

            {/*proprités */}
            <Route path={`${ROUTES.PROPERTYS_LIST}`} element={<PropertyManagementPage />} />
            <Route path={`${ROUTES.PROPERTYS_DETAIL}/:id`} element={<DetailPropertyPage />} />
            <Route path={`${ROUTES.PROPERTYS_ADD}`} element={<NewPropertyPage />} />
            <Route path={`${ROUTES.PROPERTYS_EDIT}/:id`} element={<UpdatePropertyPage />} />
            {/*Reservation */}
            <Route path={`${ROUTES.RESERVATIONS_REQUEST_ADD}`} element={<ReservationFormPage />} />
            <Route path={`${ROUTES.RESERVATIONS_INVOICE_WEB}`} element={<InvoiceReservationWebPage />} />
            <Route path={`${ROUTES.RESERVATIONS_REQUEST_LIST}`} element={<RequestReservationPage />} />
            <Route path={`${ROUTES.RESERVATIONS_LIST_CALENDAR}`} element={<ReservationCalendarPage />} />
            <Route path={`${ROUTES.RESERVATION_VIEW}/:id`} element={<ReservationDetailPage />} />
            <Route path={`${ROUTES.RESERVATIONS_INVOICE_VIEW_ADMIN}/:id`} element={<ReservationInvoicePage />} />
            {/*Dépenses */}
            <Route path={`${ROUTES.EXPENCES_LIST}`} element={<ExpensesPage />} />
            {/*Paiement */}
            <Route path={`${ROUTES.PAYMENTS_LIST_PLATEFORM}`} element={<PaiementsCuctomersPage />} />
            <Route path={`${ROUTES.PAYMENTS_DETAIL_PLATEFORM}/:id`} element={<PaymentDetailsPage />} />
            <Route path={`${ROUTES.ALERTS_LIST_PLATEFORM}`} element={<AlertsPage />} />
            {/*Domages */}
            <Route path={`${ROUTES.DAMAGES_LIST_PLATEFORM}`} element={<DamageVehicleListPage />} />
            <Route path={`${ROUTES.DAMAGES_PLATEFORM_ADD}`} element={<DamageReportPage />} />
            <Route path={`${ROUTES.DAMAGES_LIST_PLATEFORM_DETAIL}/:id`} element={<DamageInvoiceDetailPage />} />
            {/*Assurances */}
            <Route path={`${ROUTES.INSURANCES_LIST_PLATEFORM}`} element={<InsuranceManagementPage />} />
            <Route path={`${ROUTES.INSURANCES_LIST_PLATEFORM_ADD}`} element={<NewInsuranceHeritagePage />} />
            <Route path={`${ROUTES.INSURANCES_LIST_PLATEFORM_DETAIL}/:id`} element={<InsuranceInvoiceDetailPage />} />
            <Route path={`${ROUTES.INSURANCES_LIST_PLATEFORM_EDIT}/:id`} element={<UpdateInsuranceHeritagePage />} />
            {/*Administrauer */}
            <Route path={`${ROUTES.CUSTOMERS_LIST_PLATEFORM}`} element={<CustomerManagementPage />} />
            <Route path={`${ROUTES.INSURANCES_LIST_ADMIN}`} element={<CarRentalInsurancePage />} />
            <Route path={`${ROUTES.TRANSMISSIONS_LIST_ADMIN}`} element={<TransmissionListPage />} />
            <Route path={`${ROUTES.CATEGORYS_LIST_ADMIN}`} element={<VehicleTypeListPage />} />
            <Route path={`${ROUTES.PAYMENTS_LIST_ADMIN}`} element={<DeviceListPaymentPage />} />
            {/*Reduction */}
            <Route path={`${ROUTES.CODEPROMOS_LIST}`} element={<CodePromoPage />} />
            


            {/* Maintenance */}
            <Route path={`${ROUTES.MAINTENANCE_LIST_PLATEFORME}`} element={<MaintenanceManagementPage />} />
            <Route path={`${ROUTES.MAINTENANCE_LIST_CALENDAR}`} element={<MaintenanceCalendarPage />} />
            <Route path={`${ROUTES.MAINTENANCE_PLATEFORME_DETAIL}/:id`} element={<MaintenanceDetailPage />} />

            {/*Notification */}
            <Route path={`${ROUTES.NOTIFICATIONS_LIST}`} element={<NotificationPage />} />

            <Route path={`${ROUTES.USERS_LIST_PLATEFORM}`} element={<UserManagementPage />} />



          </Route>

          <Route path={`${ROUTES.d27aK9Ab59G55J27VZv9WGxd8vtvZmwR}`} element={<AdminLoginPage />} />



          <Route path='/*' element={<NotFound />} />


        </Routes>


      </BrowserRouter>
    </Provider>
  );
}

export default App;
