import { FETCH_PARKINGS_FAILURE, FETCH_PARKINGS_REQUEST, FETCH_PARKINGS_SUCCESS, FETCH_PARKINGS_SUCCESS_2, FETCH_PARKING_FAILURE, FETCH_PARKING_REQUEST, FETCH_PARKING_SUCCESS } from "../actions/actions";



// parkingReducer.js
const initialState = {
    parkings: [],
    parkings2: [],
    parking: {},
    parkingSelect: {},
    loadingParking:false,
    loadingParkingSelect:false,
    loadingParkings:false,
    errorParkings:null,
    errorParking:null
    // ... autres états spécifiques à parkingReducer
  };
  
  const parkingReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_PARKING_REQUEST:
        // Dispatch une action pour définir loading sur true dans le globalReducer
        return {
          ...state,
          loadingParking:true,
          errorParkings:""
          // Autres manipulations d'état pour la demande de récupération des parkings
        };
        case FETCH_PARKING_SUCCESS:
        return {
          ...state,
          loadingParking:false,
          errorParkings:"",
          parking:action.payload
        };
        case FETCH_PARKING_FAILURE:
        return {
          ...state,
          loadingParking:false,
          errorParkings:action.payload
        };


      case FETCH_PARKINGS_REQUEST:
        return {
          ...state,
          loadingParkings:true,
          errorParkings:""
        };
       
      case FETCH_PARKINGS_SUCCESS:
        return {
          ...state,
          parkings: action.payload,
          loadingParkings:false,
          errorParkings:""
          // Autres manipulations d'état pour le succès de la récupération des parkings
        };
        case FETCH_PARKINGS_SUCCESS_2:
        return {
          ...state,
          parkings2: action.payload,
          loadingParkings:false,
          errorParkings:""
          // Autres manipulations d'état pour le succès de la récupération des parkings
        };
        
      case FETCH_PARKINGS_FAILURE:
        // Dispatch une action pour définir loading sur false dans le globalReducer et enregistrer l'erreur
        return {
          ...state,
          loadingParkings:false,
          errorParkings:action.payload
          // Autres manipulations d'état pour l'échec de la récupération des parkings
        };
      // ... autres cas pour d'autres actions liées aux parkings

        
      default:
        return state;
    }
  };
  
  export default parkingReducer;

  

