import axios from "axios";
import { FETCH_CODE_PROMOS_FAILURE, FETCH_CODE_PROMOS_REQUEST, FETCH_CODE_PROMOS_SUCCESS, FETCH_CODE_PROMOS_SUCCESS_2, FETCH_CODE_PROMO_FAILURE, FETCH_CODE_PROMO_REQUEST, FETCH_CODE_PROMO_SUCCESS, FETCH_NOTIFICATIONS_FAILURE, FETCH_NOTIFICATIONS_REQUEST, FETCH_NOTIFICATIONS_SUCCESS, FETCH_NOTIFICATIONS_SUCCESS_2, FETCH_NOTIFICATIONS_SUCCESS_D } from "../../app/actions/actions";
import { baseurl } from "../../utils/url/baseurl";




// Récupérer toutes les notifications avec des paramètres facultatifs
export function fetchNotificationsAll(user, client, vehicle, property, reservation, maintenance, insurance, startDate, endDate) {
    return async (dispatch) => {
        dispatch({ type: FETCH_NOTIFICATIONS_REQUEST });

        const params = new URLSearchParams();
        if (user) params.append("user", user);
        if (client) params.append("client", client);
        if (vehicle) params.append("vehicle", vehicle);
        if (property) params.append("property", property);
        if (reservation) params.append("reservation", reservation);
        if (maintenance) params.append("maintenance", maintenance);
        if (insurance) params.append("insurance", insurance);
        if (startDate) params.append("startDate", startDate);
        if (endDate) params.append("endDate", endDate);

        await axios.get(`${baseurl.url}/api/v1/notifications/get_notifications?${params.toString()}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        })
        .then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_NOTIFICATIONS_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_NOTIFICATIONS_SUCCESS_2, payload: response.data.data });
            dispatch({ type: FETCH_NOTIFICATIONS_SUCCESS_D, payload: response.data.data });
        })
        .catch((error) => {
            //console.log(error);
            dispatch({ type: FETCH_NOTIFICATIONS_FAILURE, payload: error.message });
        });
    };
}





// Récupérer une notification par ID
export function fetchNotificationById(idNotification) {
    return async (dispatch) => {
        dispatch({ type: FETCH_CODE_PROMO_REQUEST });

        await axios.get(`${baseurl.url}/api/v1/notifications/get_notification/${idNotification}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_CODE_PROMO_SUCCESS, payload: response.data.data });
        })
            .catch((error) => {
                dispatch({ type: FETCH_CODE_PROMO_FAILURE, payload: error.message });
                //console.log(error);
            });
    };
}
