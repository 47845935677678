// store.js
import { createStore, combineReducers, applyMiddleware } from 'redux';
import {thunk} from 'redux-thunk';
import conversationReducer from './reducers/conversationReducer';
import languageReducer from './reducers/languageReducer';
import paymentReducer from './reducers/paymentReducer';
import userReducer from './reducers/userReducer';
import agendaReducer from './reducers/agendaReducer';
import assessoryReducer from './reducers/assessoryReducer';
import damageReducer from './reducers/damageReducer';
import damageTypeReducer from './reducers/damageTypeReducer';
import insuranceReducer from './reducers/insuranceReducer';
import insuranceTypeReducer from './reducers/insuranceTypeReducer';
import parkingReducer from './reducers/parkingReducer';
import paymentTypeReducer from './reducers/paymentTypeReducer';
import propertyReducer from './reducers/propertyReducer';
import propertyTypeReducer from './reducers/propertyTypeReducer';
import reservationReducer from './reducers/reservationReducer';
import reservationTypeReducer from './reducers/reservationTypeReducer';
import tripReducer from './reducers/tripReducer';
import vehicleReducer from './reducers/vehicleReducer';
import vehicleTypeReducer from './reducers/vehicleTypeReducer';
import notificationReducer from './reducers/notificationReducer';
import customerReducer from './reducers/customerReducer';
import messageReducer from './reducers/messageReducer';
import currencyReducer from './reducers/currencyReducer';
import codepostalReducer from './reducers/codepostalReducer';
import motifReducer from './reducers/motifReducer';
import vehiclebrandReducer from './reducers/vehiclebrandReducer';
import vehiclemodelReducer from './reducers/vehiclemodelReducer';
import maintenanceSheduleReducer from './reducers/maintenanceSheduleReducer';
import codepromoReducer from './reducers/codepromoReducer';
import maintenanceReducer from './reducers/maintenanceReducer';
import adminReducer from './reducers/adminReducer';
import expenseReducer from './reducers/expenseReducer';
import expensecategoryReducer from './reducers/expensecategoryReducer';
import maintenanceCategoryReducer from './reducers/maintenanceCategoryReducer';
import companyairportReducer from './reducers/companyairportReducer';



const rootReducer = combineReducers({

    agendas :agendaReducer,
    assessorys :assessoryReducer,
    damages :damageReducer,
    codepostals:codepostalReducer,
    companyairports:companyairportReducer,
    codepromos:codepromoReducer,
    conversations :conversationReducer,
    customers: customerReducer,
    damageTypes :damageTypeReducer,
    motifs :motifReducer,
    currencys: currencyReducer,
    insurances :insuranceReducer,
    insuranceTypes :insuranceTypeReducer,
    language:languageReducer,
    notifications :notificationReducer,
    messages: messageReducer,
    parkings :parkingReducer,
    payments:paymentReducer,
    paymentTypes:paymentTypeReducer,
    propertys:propertyReducer,
    propertyTypes:propertyTypeReducer,
    reservations:reservationReducer,
    reservationTypes:reservationTypeReducer,
    trips:tripReducer,
    vehiclebrands:vehiclebrandReducer,
    vehiclemodels:vehiclemodelReducer,
    maintenanceShedules:maintenanceSheduleReducer,
    vehicles:vehicleReducer,
    vehicleTypes:vehicleTypeReducer,
    users: userReducer,
    admins: adminReducer,
    maintenances:maintenanceReducer,
    expenses:expenseReducer,
    expensecategorys:expensecategoryReducer,
    maintenanceCategorys:maintenanceCategoryReducer
});


const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;